import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  ConnectionCursor: { input: any; output: any };
  ConnectionLimitInt: { input: any; output: any };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  Email: { input: any; output: any };
  HTML: { input: any; output: any };
  JSONObject: { input: any; output: any };
  URL: { input: any; output: any };
};

export type AbandonedCheckout = {
  __typename?: "AbandonedCheckout";
  cartStep: CartStepEnum;
  contactInfo?: Maybe<CartContactInfo>;
  createdAt: Scalars["DateTime"]["output"];
  emailStatus?: Maybe<EmailStatus>;
  id: Scalars["ID"]["output"];
  isReserved?: Maybe<Scalars["Boolean"]["output"]>;
  reservedAt?: Maybe<Scalars["DateTime"]["output"]>;
  shippingDetails?: Maybe<CartShippingDetails>;
  status: CartStatusEnum;
  total?: Maybe<Money>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type AbandonedCheckoutActions = {
  __typename?: "AbandonedCheckoutActions";
  cancelAbandonedCheckoutEmail: Cart;
  exportAbandonedCheckouts: ExportAbandonedCheckoutPayload;
  sendAbandonedCheckoutEmail: Cart;
  updateAbandonedCheckoutSettings: AbandonedCheckoutSettings;
};

export type AbandonedCheckoutActionsCancelAbandonedCheckoutEmailArgs = {
  cartId: Scalars["ID"]["input"];
};

export type AbandonedCheckoutActionsExportAbandonedCheckoutsArgs = {
  input: ExportAbandonedCheckoutInput;
};

export type AbandonedCheckoutActionsSendAbandonedCheckoutEmailArgs = {
  cartId: Scalars["ID"]["input"];
};

export type AbandonedCheckoutActionsUpdateAbandonedCheckoutSettingsArgs = {
  settings: AbandonedCheckoutSettingsInput;
};

export type AbandonedCheckoutConnection = {
  __typename?: "AbandonedCheckoutConnection";
  nodes: Array<AbandonedCheckout>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AbandonedCheckoutConnectionInput = {
  after?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  before?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  first?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  last?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<AbandonedCheckoutSortByField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type AbandonedCheckoutEdge = {
  __typename?: "AbandonedCheckoutEdge";
  cursor: Scalars["ConnectionCursor"]["output"];
  node?: Maybe<Cart>;
};

export type AbandonedCheckoutFilterInput = {
  createdAt?: InputMaybe<DateTimeRangeInput>;
  currentStep?: InputMaybe<Array<InputMaybe<CartStepEnum>>>;
  emailStatus?: InputMaybe<Array<InputMaybe<EmailStatus>>>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  lastStep?: InputMaybe<Array<InputMaybe<CartStepEnum>>>;
  searchQuery?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Array<InputMaybe<CartStatusEnum>>>;
  updatedAt?: InputMaybe<DateTimeRangeInput>;
};

export type AbandonedCheckoutQueries = {
  __typename?: "AbandonedCheckoutQueries";
  abandonedCheckout: Cart;
  abandonedCheckoutSettings: AbandonedCheckoutSettings;
  abandonedCheckouts: AbandonedCheckoutConnection;
};

export type AbandonedCheckoutQueriesAbandonedCheckoutArgs = {
  id: Scalars["ID"]["input"];
};

export type AbandonedCheckoutQueriesAbandonedCheckoutsArgs = {
  connection?: InputMaybe<AbandonedCheckoutConnectionInput>;
  filter?: InputMaybe<AbandonedCheckoutFilterInput>;
};

export type AbandonedCheckoutSettings = {
  __typename?: "AbandonedCheckoutSettings";
  autoSendEmails: Scalars["Boolean"]["output"];
  /** The recipient of the abandoned checkout email, for now ALL or NONE */
  emailRecipient: EmailRecipientEnum;
  emailSettings?: Maybe<EmailSettings>;
  /** The time delay in hours before sending the abandoned checkout email */
  emailTimeDelay: Scalars["Int"]["output"];
};

export type AbandonedCheckoutSettingsInput = {
  autoSendEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  emailRecipient?: InputMaybe<EmailRecipientEnum>;
  emailSettings?: InputMaybe<EmailSettingsInput>;
  emailTimeDelay?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum AbandonedCheckoutSortByField {
  CheckoutSerial = "checkoutSerial",
  CreatedAt = "createdAt",
  Status = "status",
  UpdatedAt = "updatedAt",
}

export type AboutInput = {
  content?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  locale: Scalars["String"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type AboutTranslationInput = {
  content?: InputMaybe<Scalars["String"]["input"]>;
  locale: Scalars["String"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type AboutType = {
  __typename?: "AboutType";
  content?: Maybe<Scalars["String"]["output"]>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  locales?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  title?: Maybe<Scalars["String"]["output"]>;
  translations?: Maybe<Array<Maybe<StoreAboutTranslation>>>;
};

export enum ActiveFooter {
  Custom = "CUSTOM",
  Splitted = "SPLITTED",
}

export type AddCustomerAddressInput = {
  addressLine1: Scalars["String"]["input"];
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  cityId?: InputMaybe<Scalars["String"]["input"]>;
  cityName?: InputMaybe<Scalars["String"]["input"]>;
  countryId?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  regionId?: InputMaybe<Scalars["String"]["input"]>;
  regionName?: InputMaybe<Scalars["String"]["input"]>;
  secondPhone?: InputMaybe<Scalars["String"]["input"]>;
  stateId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Address = {
  __typename?: "Address";
  addressLine1: Scalars["String"]["output"];
  addressLine2?: Maybe<Scalars["String"]["output"]>;
  areaSnapshot?: Maybe<AreaSnapshot>;
  notes?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  secondPhone?: Maybe<Scalars["String"]["output"]>;
};

export type AddressAreaInput = {
  cityId?: InputMaybe<Scalars["String"]["input"]>;
  countryId: Scalars["String"]["input"];
  regionId?: InputMaybe<Scalars["String"]["input"]>;
  stateId: Scalars["String"]["input"];
};

export type AddressInput = {
  addressLine1: Scalars["String"]["input"];
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  area?: InputMaybe<AddressAreaInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  secondPhone?: InputMaybe<Scalars["String"]["input"]>;
};

export type AdminActions = {
  __typename?: "AdminActions";
  checkout: AbandonedCheckoutActions;
  customCheckout?: Maybe<CustomCheckoutSettingActions>;
  discounts?: Maybe<DiscountActions>;
  orders?: Maybe<OrderActions>;
  promoCode?: Maybe<PromoCodeActions>;
  stock?: Maybe<StockActions>;
  tax?: Maybe<TaxActions>;
  wallet: WalletActions;
  x?: Maybe<Scalars["String"]["output"]>;
};

export type AdminCart = {
  customerContactInfo: CreateContactInfoInput;
  items: Array<SimpleItemInput>;
  promoCode?: InputMaybe<Scalars["String"]["input"]>;
  provider?: InputMaybe<WuiltShipmentProvider>;
  shipping: ShippingInfoInput;
};

export type AdminQueries = {
  __typename?: "AdminQueries";
  checkout: AbandonedCheckoutQueries;
  discounts?: Maybe<DiscountQueries>;
  getWallet: Wallet;
  orders?: Maybe<OrderQueries>;
  promoCode?: Maybe<PromoCodeQueries>;
  tax?: Maybe<TaxQueries>;
  wallet?: Maybe<WalletQueries>;
  x?: Maybe<Scalars["String"]["output"]>;
};

export type AllTimeTopSellingProduct = {
  __typename?: "AllTimeTopSellingProduct";
  count: Scalars["Int"]["output"];
  product: Product;
  productId: Scalars["String"]["output"];
  revenue: Scalars["Float"]["output"];
};

export type AmountOff = {
  __typename?: "AmountOff";
  customerBuys: CustomerBuysAmountOff;
  discount: AmountOffDiscounted;
};

export type AmountOffDiscounted = {
  __typename?: "AmountOffDiscounted";
  amount?: Maybe<Scalars["Float"]["output"]>;
  currencyCode?: Maybe<CurrencyCode>;
  percentage?: Maybe<Scalars["Float"]["output"]>;
};

export type AmountOffDiscountedInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  currencyCode?: InputMaybe<CurrencyCode>;
  percentage?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AmountOffInput = {
  customerBuys: CustomerBuysAmountOffInput;
  discount: AmountOffDiscountedInput;
};

export type AnalyticalAccount = {
  __typename?: "AnalyticalAccount";
  id: Scalars["String"]["output"];
  isActive: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
};

export type AnalyticalAccountInput = {
  id: Scalars["String"]["input"];
  isActive: Scalars["Boolean"]["input"];
  name: Scalars["String"]["input"];
};

export type Answer = {
  __typename?: "Answer";
  answer?: Maybe<Scalars["String"]["output"]>;
  answerNo?: Maybe<Scalars["Int"]["output"]>;
  question?: Maybe<Scalars["String"]["output"]>;
  questionNo?: Maybe<Scalars["Int"]["output"]>;
};

export type AnswerInput = {
  answer?: InputMaybe<Scalars["String"]["input"]>;
  answerNo?: InputMaybe<Scalars["Int"]["input"]>;
  question?: InputMaybe<Scalars["String"]["input"]>;
  questionNo?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AramexAdditionalInfo = {
  allowToOpenPackage?: InputMaybe<Scalars["Boolean"]["input"]>;
  pickUpLocation: Scalars["ID"]["input"];
  pickupDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export enum AramexStatusEnum {
  AttemptsCompleted = "ATTEMPTS_COMPLETED",
  CancelledByShipper = "CANCELLED_BY_SHIPPER",
  ConfirmRejectedReason = "CONFIRM_REJECTED_REASON",
  Damaged = "DAMAGED",
  Delivered = "DELIVERED",
  InTransit = "IN_TRANSIT",
  Lost = "LOST",
  NotPickedYet = "NOT_PICKED_YET",
  OnHold = "ON_HOLD",
  OutForDelivery = "OUT_FOR_DELIVERY",
  Picked = "PICKED",
  RejectedByShipper = "REJECTED_BY_SHIPPER",
  Rescheduled = "RESCHEDULED",
  ReturnedToShipper = "RETURNED_TO_SHIPPER",
  ReAttemptDelivery = "RE_ATTEMPT_DELIVERY",
  ReAttemptDeliveryReady = "RE_ATTEMPT_DELIVERY_READY",
  ReAttemptPickup = "RE_ATTEMPT_PICKUP",
  SentTo = "SENT_TO",
  ShipmentScanned = "SHIPMENT_SCANNED",
}

export type ArchiveProductsInput = {
  productIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
  storeId: Scalars["ID"]["input"];
};

export type ArchiveProductsPayload = {
  __typename?: "ArchiveProductsPayload";
  products: Array<Maybe<Product>>;
};

export type AreaSnapshot = {
  __typename?: "AreaSnapshot";
  cityName?: Maybe<Scalars["String"]["output"]>;
  countryName?: Maybe<Scalars["String"]["output"]>;
  regionName?: Maybe<Scalars["String"]["output"]>;
  stateName?: Maybe<Scalars["String"]["output"]>;
};

export type AssignOptionsToProductInput = {
  id: Scalars["ID"]["input"];
  options: Array<InputMaybe<OptionsToAssignInput>>;
  storeId: Scalars["ID"]["input"];
};

export type Attribute = {
  attributeId: Scalars["ID"]["input"];
  valuesIds: Array<Scalars["ID"]["input"]>;
};

export type AttributeProductsCount = {
  __typename?: "AttributeProductsCount";
  attributeValue?: Maybe<ProductAttributeValue>;
  attributeValueId?: Maybe<Scalars["ID"]["output"]>;
  count?: Maybe<Scalars["Int"]["output"]>;
};

export type Banner = {
  __typename?: "Banner";
  bannerLink?: Maybe<Link>;
  id?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Image>;
  mobileImage?: Maybe<Image>;
};

export type BaseCartItem = {
  id: Scalars["ID"]["output"];
  product?: Maybe<Product>;
  quantity: Scalars["Int"]["output"];
  subtotal: Money;
  totalPrice: Money;
};

export type BaseItem = {
  discount?: Maybe<OrderItemDiscountDetails>;
  discounts?: Maybe<Array<OrderItemDiscountDetails>>;
  fulfilledItems?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  price?: Maybe<Money>;
  productSnapshot?: Maybe<ProductSnapshot>;
  quantity: Scalars["Int"]["output"];
  sourceType: OrderItemSourceType;
  subtotal?: Maybe<Money>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type BillingDataInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type BostaAccessibleInfo = {
  __typename?: "BostaAccessibleInfo";
  cities: Array<Maybe<BostaCity>>;
};

export type BostaAdditionalInfo = {
  mappedShippingAddress: MappedShippingAddress;
  packageDetails: PackageDetails;
};

export type BostaCity = {
  __typename?: "BostaCity";
  dropOffAvailability: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type BostaDistrict = {
  __typename?: "BostaDistrict";
  dropOffAvailability: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export enum BostaFlyer {
  HeavyBulky = "HEAVY_BULKY",
  Large = "LARGE",
  LightBulky = "LIGHT_BULKY",
  Medium = "MEDIUM",
  Small = "SMALL",
  XxLarge = "XX_LARGE",
  XLarge = "X_LARGE",
}

export type BostaIntegrationSettings = {
  __typename?: "BostaIntegrationSettings";
  allowedCountries: Array<Scalars["ID"]["output"]>;
  coveredCountries: CountryConnection;
  defaultPackageSize?: Maybe<BostaPackageSizes>;
  defaultPackageType?: Maybe<BostaPackageTypes>;
  provider: ProviderName;
  storeId: Scalars["ID"]["output"];
};

export type BostaIntegrationSettingsInput = {
  defaultPackageSize?: InputMaybe<BostaPackageSizes>;
  defaultPackageType?: InputMaybe<BostaPackageTypes>;
  stateMapping?: InputMaybe<Array<BostaMappingItemInput>>;
};

export type BostaMappedState = {
  __typename?: "BostaMappedState";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type BostaMappingItemInput = {
  bostaCityId: Scalars["ID"]["input"];
  storeStateId: Scalars["ID"]["input"];
};

export enum BostaPackageSizes {
  HeavyBulky = "HeavyBulky",
  Large = "LARGE",
  LightBulky = "LightBulky",
  Medium = "MEDIUM",
  Small = "SMALL",
}

export enum BostaPackageTypes {
  Document = "Document",
  HeavyBulky = "HeavyBulky",
  LightBulky = "LightBulky",
  Parcel = "Parcel",
}

export type BulkCustomProductCategoryTranslationInput = {
  id: Array<Scalars["ID"]["input"]>;
  translations?: InputMaybe<
    Array<InputMaybe<CustomProductCategoryTranslationInput>>
  >;
};

export type BulkProductAttributeTranslationInput = {
  id: Scalars["ID"]["input"];
  translations?: InputMaybe<ProductAttributeTranslationInput>;
};

export type BulkProductAttributeValueTranslationInput = {
  id: Scalars["ID"]["input"];
  translations?: InputMaybe<ProductAttributeValueTranslationInput>;
};

export type BulkProductOptionTranslationInput = {
  id: Scalars["ID"]["input"];
  translations?: InputMaybe<ProductOptionTranslationInput>;
};

export type BulkProductOptionValueTranslationInput = {
  id: Scalars["ID"]["input"];
  translations?: InputMaybe<ProductOptionValueTranslationInput>;
};

export type BulkShippingRateTranslationInput = {
  id: Scalars["ID"]["input"];
  translations?: InputMaybe<Array<InputMaybe<ShippingRateTranslationInput>>>;
};

export type BulkTranslatePreviewResult = {
  __typename?: "BulkTranslatePreviewResult";
  errors?: Maybe<Array<Maybe<UserRequestError>>>;
  resourcesToBeTranslated?: Maybe<Scalars["Int"]["output"]>;
  resourcesToBeUpdated?: Maybe<Scalars["Int"]["output"]>;
  sheetRecords?: Maybe<Scalars["Int"]["output"]>;
  warnings?: Maybe<Array<Maybe<UserRequestWarning>>>;
};

export type BulkUploadPreviewResult = {
  __typename?: "BulkUploadPreviewResult";
  errors?: Maybe<Array<Maybe<UserRequestError>>>;
  previewProduct?: Maybe<ProductPreview>;
  uploadStatistics?: Maybe<BulkUploadStatistics>;
};

export type BulkUploadStatistics = {
  __typename?: "BulkUploadStatistics";
  ImagesCount?: Maybe<Scalars["Int"]["output"]>;
  editedCollectionsCount?: Maybe<Scalars["Int"]["output"]>;
  editedProductsCount?: Maybe<Scalars["Int"]["output"]>;
  editedVariantsCount?: Maybe<Scalars["Int"]["output"]>;
  newCollectionsCount?: Maybe<Scalars["Int"]["output"]>;
  newProductsCount?: Maybe<Scalars["Int"]["output"]>;
  newVariantsCount?: Maybe<Scalars["Int"]["output"]>;
  skuCount?: Maybe<Scalars["Int"]["output"]>;
};

export type Button = {
  __typename?: "Button";
  align?: Maybe<Scalars["String"]["output"]>;
  link?: Maybe<Link>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type ButtonInput = {
  align?: InputMaybe<Scalars["String"]["input"]>;
  link?: InputMaybe<LinkInput>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type ButtonTranslationItem = {
  __typename?: "ButtonTranslationItem";
  text?: Maybe<Scalars["String"]["output"]>;
};

export type ButtonTranslationItemInput = {
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export enum BuyNowButtonDisplay {
  Always = "always",
  None = "none",
  OnHover = "onHover",
}

export type BuyNowButtonSettings = {
  __typename?: "BuyNowButtonSettings";
  backgroundColor?: Maybe<Scalars["String"]["output"]>;
  borderColor?: Maybe<Scalars["String"]["output"]>;
  display?: Maybe<BuyNowButtonDisplay>;
  fontColor?: Maybe<Scalars["String"]["output"]>;
};

export type BuyNowButtonSettingsInput = {
  backgroundColor?: InputMaybe<Scalars["String"]["input"]>;
  borderColor?: InputMaybe<Scalars["String"]["input"]>;
  display?: InputMaybe<BuyNowButtonDisplay>;
  fontColor?: InputMaybe<Scalars["String"]["input"]>;
};

export type BuyXGetYDiscount = {
  __typename?: "BuyXGetYDiscount";
  customerBuys: CustomerBuys;
  customerGets: CustomerGets;
};

export type BuyXGetYDiscountInput = {
  customerBuys: CustomerBuysInput;
  customerGets: CustomerGetsInput;
};

export type CodDetails = {
  __typename?: "CODDetails";
  amount: Money;
  fees: Money;
  finalAmount: Money;
  status: CodStatus;
  transactionId?: Maybe<Scalars["String"]["output"]>;
};

export type CalculateCartInput = {
  customItems?: InputMaybe<Array<CustomItemInput>>;
  promoCode?: InputMaybe<Scalars["String"]["input"]>;
  simpleItems?: InputMaybe<Array<SimpleItemInput>>;
};

export type CalculateCartResponse = {
  __typename?: "CalculateCartResponse";
  cartErrors?: Maybe<Array<CartError>>;
  items: Array<CartItem>;
  promoCode?: Maybe<PromoCode>;
  receipt: CartReceipt;
};

export type CalculateItemsDiscountsInput = {
  customItem?: InputMaybe<CreateCustomItemInput>;
  simpleItem?: InputMaybe<CalculateSimpleItemDiscountInput>;
};

export type CalculateSimpleItemDiscountInput = {
  collectionIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  price: MoneyInput;
  productId: Scalars["ID"]["input"];
  quantity: Scalars["Int"]["input"];
  variantId: Scalars["ID"]["input"];
};

export type CalculatedDiscountBaseItem = {
  __typename?: "CalculatedDiscountBaseItem";
  collectionIds?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
  discount?: Maybe<OrderItemDiscountDetails>;
  discounts?: Maybe<Array<OrderItemDiscountDetails>>;
  price: Money;
  productId: Scalars["ID"]["output"];
  quantity: Scalars["Int"]["output"];
  variantId: Scalars["ID"]["output"];
};

export type CancelFulfillInput = {
  orderId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export enum CancelShipmentError {
  OrderNotFound = "ORDER_NOT_FOUND",
  OrderShipmentNotOngoing = "ORDER_SHIPMENT_NOT_ONGOING",
  ProviderShipmentStillActive = "PROVIDER_SHIPMENT_STILL_ACTIVE",
}

export type CancelShipmentPayload = {
  orderId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type CancelShipmentResponse = {
  __typename?: "CancelShipmentResponse";
  error?: Maybe<CancelShipmentError>;
  status: Scalars["Boolean"]["output"];
};

export type Card = {
  __typename?: "Card";
  brand: Scalars["String"]["output"];
  expMonth: Scalars["String"]["output"];
  expYear: Scalars["String"]["output"];
  last4: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type CardDetails = {
  __typename?: "CardDetails";
  brand: Scalars["String"]["output"];
  expMonth: Scalars["String"]["output"];
  expYear: Scalars["String"]["output"];
  last4: Scalars["String"]["output"];
};

export type CardSourceInput = {
  cvc: Scalars["String"]["input"];
  expMonth: Scalars["Int"]["input"];
  expYear: Scalars["Int"]["input"];
  number: Scalars["String"]["input"];
};

export type Cart = Node & {
  __typename?: "Cart";
  cartErrors?: Maybe<Array<CartError>>;
  cartStep: CartStepEnum;
  contactInfo?: Maybe<CartContactInfo>;
  createdAt: Scalars["DateTime"]["output"];
  customer?: Maybe<CustomerUser>;
  emailSentAt?: Maybe<Scalars["DateTime"]["output"]>;
  emailStatus?: Maybe<EmailStatus>;
  id: Scalars["ID"]["output"];
  isReserved?: Maybe<Scalars["Boolean"]["output"]>;
  items: Array<CartItem>;
  lastStep: CartStepEnum;
  paymentIntent?: Maybe<PaymentIntent>;
  paymentIntentId?: Maybe<Scalars["ID"]["output"]>;
  promoCode?: Maybe<PromoCode>;
  receipt: CartReceipt;
  recoveryId: Scalars["ID"]["output"];
  refCode?: Maybe<Scalars["String"]["output"]>;
  reservedAt?: Maybe<Scalars["DateTime"]["output"]>;
  sessionId: Scalars["ID"]["output"];
  shippingDetails?: Maybe<CartShippingDetails>;
  shippingDiscount?: Maybe<Discount>;
  shippingRate?: Maybe<CartShippingRate>;
  status: CartStatusEnum;
  store: Store;
  updatedAt: Scalars["DateTime"]["output"];
};

export type CartActions = {
  __typename?: "CartActions";
  addCustomItem?: Maybe<Cart>;
  addSimpleItem?: Maybe<Cart>;
  applyPromoCode?: Maybe<Cart>;
  checkoutCart?: Maybe<Cart>;
  removePromoCode?: Maybe<Cart>;
  replaceCartItemsWithCustomItem?: Maybe<Cart>;
  replaceCartItemsWithSimpleItem?: Maybe<Cart>;
  setCartRefCode?: Maybe<Cart>;
  setCartShippingDetails?: Maybe<Cart>;
  setCartShippingRate?: Maybe<Cart>;
  syncCart: Cart;
  updateContactInfo?: Maybe<Cart>;
  updateCustomItem?: Maybe<Cart>;
  updateSimpleItem?: Maybe<Cart>;
};

export type CartActionsAddCustomItemArgs = {
  item: CustomItemInput;
};

export type CartActionsAddSimpleItemArgs = {
  item: SimpleItemInput;
};

export type CartActionsApplyPromoCodeArgs = {
  cartId: Scalars["ID"]["input"];
  code: Scalars["String"]["input"];
};

export type CartActionsCheckoutCartArgs = {
  cartId: Scalars["ID"]["input"];
};

export type CartActionsRemovePromoCodeArgs = {
  cartId: Scalars["ID"]["input"];
};

export type CartActionsReplaceCartItemsWithCustomItemArgs = {
  item: CustomItemInput;
};

export type CartActionsReplaceCartItemsWithSimpleItemArgs = {
  item: SimpleItemInput;
};

export type CartActionsSetCartRefCodeArgs = {
  cartId: Scalars["ID"]["input"];
  refCode: Scalars["String"]["input"];
};

export type CartActionsSetCartShippingDetailsArgs = {
  cartId: Scalars["ID"]["input"];
  shippingDetails: ShippingDetailsInput;
};

export type CartActionsSetCartShippingRateArgs = {
  cartId: Scalars["ID"]["input"];
  provider?: InputMaybe<WuiltShipmentProvider>;
  shippingRateId: Scalars["ID"]["input"];
};

export type CartActionsSyncCartArgs = {
  input: SyncCartInput;
};

export type CartActionsUpdateContactInfoArgs = {
  cartId: Scalars["ID"]["input"];
  info: CreateContactInfoInput;
};

export type CartActionsUpdateCustomItemArgs = {
  item: CustomItemInput;
};

export type CartActionsUpdateSimpleItemArgs = {
  item: SimpleItemInput;
};

export type CartContactInfo = {
  __typename?: "CartContactInfo";
  email?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
};

export type CartCustomItem = BaseCartItem & {
  __typename?: "CartCustomItem";
  categories: Array<CartItemCustomBuildCategory>;
  discount?: Maybe<CustomItemDiscountDetails>;
  id: Scalars["ID"]["output"];
  price: Money;
  product?: Maybe<Product>;
  quantity: Scalars["Int"]["output"];
  subtotal: Money;
  totalPrice: Money;
};

export type CartCustomer = {
  __typename?: "CartCustomer";
  addresses: Array<CartCustomerAddress>;
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  isGuest?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
};

export type CartCustomerAddress = {
  __typename?: "CartCustomerAddress";
  addressLine1?: Maybe<Scalars["String"]["output"]>;
  addressLine2?: Maybe<Scalars["String"]["output"]>;
  area?: Maybe<CartShippingDetailsArea>;
  id: Scalars["ID"]["output"];
  isDefault?: Maybe<Scalars["Boolean"]["output"]>;
  notes?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  secondPhone?: Maybe<Scalars["String"]["output"]>;
};

export type CartError = {
  __typename?: "CartError";
  code: CheckoutServiceErrorCodes;
  params?: Maybe<CartErrorParam>;
};

export type CartErrorParam = {
  __typename?: "CartErrorParam";
  availableCurrency?: Maybe<Scalars["String"]["output"]>;
  availablePrice?: Maybe<Scalars["Float"]["output"]>;
  availableQuantity?: Maybe<Scalars["Int"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  requestedCurrency?: Maybe<Scalars["String"]["output"]>;
  requestedPrice?: Maybe<Scalars["Float"]["output"]>;
  requestedQuantity?: Maybe<Scalars["Int"]["output"]>;
  resourceId?: Maybe<Scalars["ID"]["output"]>;
};

export type CartItem = CartCustomItem | CartSimpleItem;

export type CartItemCustomBuildCategory = {
  __typename?: "CartItemCustomBuildCategory";
  category: CartItemCustomProductCategory;
  selectedVariants: Array<CartItemSelectedVariant>;
};

export type CartItemCustomProductCategory = {
  __typename?: "CartItemCustomProductCategory";
  categoryType: CategoryType;
  id: Scalars["ID"]["output"];
  image?: Maybe<Image>;
  isRequired?: Maybe<Scalars["Boolean"]["output"]>;
  maxQuantity?: Maybe<Scalars["Int"]["output"]>;
  maxSelect?: Maybe<Scalars["Int"]["output"]>;
  name: Scalars["String"]["output"];
};

export type CartItemSelectedVariant = {
  __typename?: "CartItemSelectedVariant";
  id: Scalars["ID"]["output"];
  price: Money;
  quantity: Scalars["Int"]["output"];
  subtotal?: Maybe<Money>;
  variant?: Maybe<ProductVariant>;
};

export type CartReceipt = {
  __typename?: "CartReceipt";
  automaticDiscount: Money;
  discount: Money;
  shipping: Money;
  subtotal: Money;
  tax: Money;
  total: Money;
};

export type CartShippingDetails = {
  __typename?: "CartShippingDetails";
  addressLine1?: Maybe<Scalars["String"]["output"]>;
  addressLine2?: Maybe<Scalars["String"]["output"]>;
  area?: Maybe<CartShippingDetailsArea>;
  id?: Maybe<Scalars["ID"]["output"]>;
  notes?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  secondPhone?: Maybe<Scalars["String"]["output"]>;
};

export type CartShippingDetailsArea = {
  __typename?: "CartShippingDetailsArea";
  city?: Maybe<City>;
  cityId?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars["String"]["output"]>;
  region?: Maybe<Region>;
  regionId?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<State>;
  stateId?: Maybe<Scalars["String"]["output"]>;
};

export type CartShippingRate = {
  __typename?: "CartShippingRate";
  cost: Money;
  details: ShippingRate;
};

export type CartSimpleItem = BaseCartItem & {
  __typename?: "CartSimpleItem";
  discount?: Maybe<OrderItemDiscountDetails>;
  discounts?: Maybe<Array<OrderItemDiscountDetails>>;
  id: Scalars["ID"]["output"];
  price: Money;
  product?: Maybe<Product>;
  quantity: Scalars["Int"]["output"];
  subtotal: Money;
  totalPrice: Money;
  variant?: Maybe<ProductVariant>;
};

export enum CartStatusEnum {
  Abandoned = "ABANDONED",
  Active = "ACTIVE",
  CheckedOut = "CHECKED_OUT",
  Expired = "EXPIRED",
  Recovered = "RECOVERED",
}

export enum CartStepEnum {
  Completed = "COMPLETED",
  Delivery = "DELIVERY",
  Information = "INFORMATION",
  Payment = "PAYMENT",
  Shopping = "SHOPPING",
}

export type CatalogFilters = {
  __typename?: "CatalogFilters";
  attributeValues?: Maybe<Array<Maybe<ProductAttributeValue>>>;
  collections?: Maybe<Array<Maybe<ProductCollection>>>;
  optionValues?: Maybe<Array<Maybe<ProductOptionValue>>>;
};

export type CatalogSearchFilters = {
  attributeValueSelectors?: InputMaybe<
    Array<InputMaybe<AttributeValueSelector>>
  >;
  collectionIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  optionValueSelectors?: InputMaybe<Array<InputMaybe<OptionValueSelector>>>;
  priceRange?: InputMaybe<PriceRange>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type CatalogSearchMetaData = {
  __typename?: "CatalogSearchMetaData";
  attributesProductsDistribution?: Maybe<Array<Maybe<AttributeProductsCount>>>;
  collectionsProductsDistribution?: Maybe<
    Array<Maybe<CollectionProductsCount>>
  >;
  optionsProductsDistribution?: Maybe<Array<Maybe<OptionProductsCount>>>;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type CatalogSearchResults = {
  __typename?: "CatalogSearchResults";
  metaData?: Maybe<CatalogSearchMetaData>;
  products?: Maybe<Array<Maybe<Product>>>;
};

export type CatalogSectionBody =
  | SectionAttributesRowBody
  | SectionCollectionsRowBody
  | SectionProductsRowBody;

export enum CategoryType {
  Multiple = "MULTIPLE",
  Single = "SINGLE",
}

export type ChatIdentificationPayload = {
  __typename?: "ChatIdentificationPayload";
  email: Scalars["String"]["output"];
  token: Scalars["String"]["output"];
};

export type Checkout = {
  __typename?: "Checkout";
  locale?: Maybe<Scalars["String"]["output"]>;
  locales?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  successMessage?: Maybe<Scalars["String"]["output"]>;
  translations?: Maybe<Array<Maybe<StoreCheckoutTranslation>>>;
};

export type CheckoutError = {
  __typename?: "CheckoutError";
  code: ErrorCode;
  params?: Maybe<Params>;
  resourceId?: Maybe<Scalars["String"]["output"]>;
};

export enum CheckoutFieldOptions {
  Inactive = "INACTIVE",
  Mandatory = "MANDATORY",
  Optional = "OPTIONAL",
}

export type CheckoutNote = {
  __typename?: "CheckoutNote";
  content: Scalars["String"]["output"];
  enabled: Scalars["Boolean"]["output"];
  placement: CheckoutNotePlacement;
};

export type CheckoutNoteInput = {
  content: Scalars["String"]["input"];
  enabled: Scalars["Boolean"]["input"];
  placement: CheckoutNotePlacement;
};

export enum CheckoutNotePlacement {
  AfterPaymentMethods = "AFTER_PAYMENT_METHODS",
  BeforePaymentMethods = "BEFORE_PAYMENT_METHODS",
}

export enum CheckoutServiceErrorCodes {
  AddContactInfoFailed = "ADD_CONTACT_INFO_FAILED",
  AddItemFailed = "ADD_ITEM_FAILED",
  BadRequestError = "BAD_REQUEST_ERROR",
  CalculationError = "CALCULATION_ERROR",
  /** Cart is already reserved and cannot be modified until it is released. */
  CartIsReserved = "CART_IS_RESERVED",
  CategoryMaxQuantityExceeded = "CATEGORY_MAX_QUANTITY_EXCEEDED",
  CategoryMaxSelectionExceeded = "CATEGORY_MAX_SELECTION_EXCEEDED",
  ClearCartFailed = "CLEAR_CART_FAILED",
  ClearContactInfoFailed = "CLEAR_CONTACT_INFO_FAILED",
  Conflict = "CONFLICT",
  CurrencyMismatch = "CURRENCY_MISMATCH",
  DiscountAmountOffDataNotProvided = "DiscountAmountOffDataNotProvided",
  DiscountBuyXGetYDataNotProvided = "DiscountBuyXGetYDataNotProvided",
  DiscountCollectionsOrProductsNotProvided = "DiscountCollectionsOrProductsNotProvided",
  DiscountItemsTypeNotProvided = "DiscountItemsTypeNotProvided",
  DiscountScheduleStartDateNotProvided = "DiscountScheduleStartDateNotProvided",
  EmailNotSentError = "EmailNotSentError",
  FailedToGetItemsSnapshot = "FAILED_TO_GET_ITEMS_SNAPSHOT",
  FailedToGetShippingSnapshot = "FAILED_TO_GET_SHIPPING_SNAPSHOT",
  Forbidden = "FORBIDDEN",
  InsufficientQuantity = "INSUFFICIENT_QUANTITY",
  InternalServerError = "INTERNAL_SERVER_ERROR",
  Invalid = "INVALID",
  InvalidCheckoutNotes = "INVALID_CHECKOUT_NOTES",
  InvalidCheckoutPostcode = "INVALID_CHECKOUT_POSTCODE",
  InvalidCustomerIdentifier = "INVALID_CUSTOMER_IDENTIFIER",
  InvalidCustomerName = "INVALID_CUSTOMER_NAME",
  InvalidDiscount = "INVALID_DISCOUNT",
  InvalidInput = "INVALID_INPUT",
  InvalidSecondaryPhone = "INVALID_SECONDARY_PHONE",
  InvalidShippingDetails = "INVALID_SHIPPING_DETAILS",
  InvalidShippingRate = "INVALID_SHIPPING_RATE",
  MaxQuantityExceeded = "MAX_QUANTITY_EXCEEDED",
  MinQuantityNotMet = "MIN_QUANTITY_NOT_MET",
  MaximumNumberOfBuyXGetYDiscountsReached = "MaximumNumberOfBuyXGetYDiscountsReached",
  NotFound = "NOT_FOUND",
  NoPaymentMethods = "NO_PAYMENT_METHODS",
  NoVariantsSelected = "NO_VARIANTS_SELECTED",
  PaymentIntentCreationFailed = "PAYMENT_INTENT_CREATION_FAILED",
  PriceChanged = "PRICE_CHANGED",
  ProductNotAvailable = "PRODUCT_NOT_AVAILABLE",
  PromoCodeNotValid = "PROMO_CODE_NOT_VALID",
  PromoCodeUsageLimitReached = "PromoCodeUsageLimitReached",
  ReplaceCartItemsFailed = "REPLACE_CART_ITEMS_FAILED",
  RequiredCategoryMissing = "REQUIRED_CATEGORY_MISSING",
  StockReservationFailed = "STOCK_RESERVATION_FAILED",
  Unauthorized = "UNAUTHORIZED",
  UnauthorizedAction = "UNAUTHORIZED_ACTION",
  UpdateContactInfoFailed = "UPDATE_CONTACT_INFO_FAILED",
  UpdateItemFailed = "UPDATE_ITEM_FAILED",
}

export type CheckoutTranslationInput = {
  locale: Scalars["String"]["input"];
  successMessage?: InputMaybe<Scalars["String"]["input"]>;
};

export type City = Node & {
  __typename?: "City";
  code?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  isPredefined?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
  region?: Maybe<Region>;
  regions?: Maybe<RegionConnection>;
  stateId: Scalars["ID"]["output"];
  storeId?: Maybe<Scalars["ID"]["output"]>;
};

export type CityRegionArgs = {
  id: Scalars["ID"]["input"];
};

export type CityConnection = {
  __typename?: "CityConnection";
  edges?: Maybe<Array<Maybe<CityEdge>>>;
  nodes?: Maybe<Array<Maybe<City>>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CityEdge = {
  __typename?: "CityEdge";
  cursor: Scalars["ConnectionCursor"]["output"];
  node?: Maybe<City>;
};

export type CityInput = {
  id: Scalars["ID"]["input"];
  regions?: InputMaybe<Array<InputMaybe<RegionInput>>>;
};

export type CityUnion = CityConnection | ZoneCityConnection;

export type ClaimDomainInput = {
  claimId: Scalars["ID"]["input"];
  contact: ContactInput;
  domainName: Scalars["String"]["input"];
  resourceId: Scalars["ID"]["input"];
};

export type ClientData = {
  __typename?: "ClientData";
  email?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type CmsIntegrationLocalesIDs = {
  __typename?: "CmsIntegrationLocalesIDs";
  ar: Scalars["ID"]["output"];
  en: Scalars["ID"]["output"];
  fr: Scalars["ID"]["output"];
  tr: Scalars["ID"]["output"];
};

export enum CodStatus {
  Failed = "FAILED",
  Pending = "PENDING",
  Success = "SUCCESS",
}

export type CollectionAddProductsPayload = {
  __typename?: "CollectionAddProductsPayload";
  collection?: Maybe<ProductCollection>;
};

export type CollectionInput = {
  descriptionHtml?: InputMaybe<Scalars["String"]["input"]>;
  handle?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<ImageInput>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  productsToAdd?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  seo?: InputMaybe<ProductCollectionSeoInput>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  translations?: InputMaybe<Array<InputMaybe<CollectionTranslationInput>>>;
};

export type CollectionProductsConnectionInput = {
  after?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  before?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  first?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  last?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<CollectionProductsSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type CollectionProductsCount = {
  __typename?: "CollectionProductsCount";
  collection?: Maybe<ProductCollection>;
  collectionId?: Maybe<Scalars["ID"]["output"]>;
  count?: Maybe<Scalars["Int"]["output"]>;
};

export enum CollectionProductsSortBy {
  CreatedAt = "createdAt",
  Id = "id",
  Manual = "manual",
  Price = "price",
  Title = "title",
  UpdatedAt = "updatedAt",
}

export type CollectionRemoveProductsPayload = {
  __typename?: "CollectionRemoveProductsPayload";
  collection?: Maybe<ProductCollection>;
};

export type CollectionTranslationInput = {
  descriptionHtml?: InputMaybe<Scalars["String"]["input"]>;
  locale: Scalars["String"]["input"];
  seo?: InputMaybe<ProductCollectionSeoInput>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Comment = {
  __typename?: "Comment";
  body?: Maybe<Scalars["String"]["output"]>;
};

export type ContactInfo = {
  __typename?: "ContactInfo";
  email?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  newOrderEmailRecipients?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  phone?: Maybe<Scalars["String"]["output"]>;
};

export type ContactInfoInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  newOrderEmailRecipients?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type ContactInput = {
  addressLine1: Scalars["String"]["input"];
  addressLine2: Scalars["String"]["input"];
  city: Scalars["String"]["input"];
  country: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  organization: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
  postalCode: Scalars["String"]["input"];
  state: Scalars["String"]["input"];
};

export type Country = ICountry &
  Node & {
    __typename?: "Country";
    code2: Scalars["String"]["output"];
    code3: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    isPredefined?: Maybe<Scalars["Boolean"]["output"]>;
    name: Scalars["String"]["output"];
    state?: Maybe<State>;
    states?: Maybe<StateConnection>;
    storeId?: Maybe<Scalars["ID"]["output"]>;
  };

export type CountryStateArgs = {
  id: Scalars["ID"]["input"];
};

export type CountryConnection = {
  __typename?: "CountryConnection";
  edges?: Maybe<Array<Maybe<CountryEdge>>>;
  nodes?: Maybe<Array<Maybe<Country>>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CountryEdge = {
  __typename?: "CountryEdge";
  cursor: Scalars["ConnectionCursor"]["output"];
  node?: Maybe<Country>;
};

export type Coupon = {
  __typename?: "Coupon";
  amount_off: Scalars["Float"]["output"];
  created?: Maybe<Scalars["Int"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  duration: Scalars["String"]["output"];
  duration_in_months?: Maybe<Scalars["Int"]["output"]>;
  max_redemptions?: Maybe<Scalars["Int"]["output"]>;
  name: Scalars["String"]["output"];
  percent_off: Scalars["Float"]["output"];
  redeem_by?: Maybe<Scalars["Int"]["output"]>;
  times_redeemed?: Maybe<Scalars["Int"]["output"]>;
  valid: Scalars["Boolean"]["output"];
};

export type CreateCollectionPayload = {
  __typename?: "CreateCollectionPayload";
  collection?: Maybe<ProductCollection>;
};

export type CreateContactInfoInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateCustomBuildItemInput = {
  options?: InputMaybe<Array<InputMaybe<OrderSelectedOptionInput>>>;
  price: MoneyInput;
  productTax?: InputMaybe<Scalars["Float"]["input"]>;
  quantity: Scalars["Int"]["input"];
  taxable?: InputMaybe<Scalars["Boolean"]["input"]>;
  variantId: Scalars["ID"]["input"];
};

export type CreateCustomItemInput = {
  categories: Array<CustomItemCategoryInput>;
  discount?: InputMaybe<OrderItemDiscountDetailsInput>;
  productId: Scalars["ID"]["input"];
  quantity: Scalars["Int"]["input"];
  sourceType: OrderItemSourceType;
  title: Scalars["String"]["input"];
};

export type CreateIntegrationPayload = {
  __typename?: "CreateIntegrationPayload";
  integration: Integration;
};

export type CreateItemInput = {
  customItem?: InputMaybe<CreateCustomItemInput>;
  simpleItem?: InputMaybe<CreateSimpleItemInput>;
};

export type CreateMaterialInput = {
  description: Scalars["String"]["input"];
  dimensions: MaterialDimensionsInput;
  minPerOrder: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  price: MoneyInput;
  provider: WuiltShipmentProvider;
  type: MaterialType;
  weight: Scalars["Float"]["input"];
};

export type CreateMaterialOrderInput = {
  items: Array<OrderItemInput>;
  provider: WuiltShipmentProvider;
  shippingAddress: OrderMaterialShippingDetailsInput;
};

export type CreateMediaInput = {
  alt?: InputMaybe<Scalars["String"]["input"]>;
  originalSource: Scalars["String"]["input"];
};

export type CreateMenuItemInput = {
  clientId?: InputMaybe<Scalars["ID"]["input"]>;
  icon?: InputMaybe<Scalars["String"]["input"]>;
  link?: InputMaybe<MenuLinkInput>;
  locale: Scalars["String"]["input"];
  parentId?: InputMaybe<Scalars["ID"]["input"]>;
  title: Scalars["String"]["input"];
  type: MenuItemType;
};

export type CreateOrderCustomerInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  isSubscribedToNewsLetter: Scalars["Boolean"]["input"];
  name: Scalars["String"]["input"];
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateOrderInput = {
  billingAddress: AddressInput;
  customer?: InputMaybe<CreateOrderCustomerInput>;
  customerId?: InputMaybe<Scalars["String"]["input"]>;
  items: Array<CreateItemInput>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  promoCode?: InputMaybe<Scalars["String"]["input"]>;
  refCode?: InputMaybe<Scalars["String"]["input"]>;
  shippingAddress: AddressInput;
  shippingRateId: Scalars["ID"]["input"];
  storeData?: InputMaybe<StoreDataInput>;
  storeId: Scalars["ID"]["input"];
};

export type CreatePaymentSourceSetupPayload = {
  __typename?: "CreatePaymentSourceSetupPayload";
  clientSecret: Scalars["String"]["output"];
};

export type CreatePickUpAdditionalInfo = {
  allowToOpenPackage?: InputMaybe<Scalars["Boolean"]["input"]>;
  pickUpLocation: Scalars["ID"]["input"];
  pickupDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CreateProductAttributeInput = {
  locale: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  storeId: Scalars["ID"]["input"];
  type?: InputMaybe<ProductAttributeType>;
};

export type CreateProductAttributeValueInput = {
  attributeId: Scalars["ID"]["input"];
  image?: InputMaybe<CreateMediaInput>;
  locale: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type CreateProductMediaPayload = {
  __typename?: "CreateProductMediaPayload";
  product?: Maybe<Product>;
};

export type CreateProductPayload = {
  __typename?: "CreateProductPayload";
  product: Product;
};

export type CreateProductReviewInput = {
  content?: InputMaybe<Scalars["String"]["input"]>;
  headline: Scalars["String"]["input"];
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
  productId: Scalars["ID"]["input"];
  rating: Scalars["Int"]["input"];
  showName: Scalars["Boolean"]["input"];
};

export type CreatePromoCodeInput = {
  code: Scalars["String"]["input"];
  fixedAmount?: InputMaybe<Scalars["Float"]["input"]>;
  minimumOrderAmountToApply?: InputMaybe<Scalars["Float"]["input"]>;
  percentageOff?: InputMaybe<Scalars["Float"]["input"]>;
  schedule?: InputMaybe<DiscountScheduleInput>;
  status?: InputMaybe<PromoCodeStatusEnum>;
  storeId: Scalars["ID"]["input"];
  type: PromoCodeTypeEnum;
  usageLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CreateSimpleItemInput = {
  collectionIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  options?: InputMaybe<Array<InputMaybe<OrderSelectedOptionInput>>>;
  price: MoneyInput;
  productId: Scalars["ID"]["input"];
  productTax?: InputMaybe<Scalars["Float"]["input"]>;
  quantity: Scalars["Int"]["input"];
  sourceType: OrderItemSourceType;
  taxable?: InputMaybe<Scalars["Boolean"]["input"]>;
  title: Scalars["String"]["input"];
  variantId: Scalars["ID"]["input"];
};

export type CreateStoreBannerSectionInput = {
  banners: Array<StoreBannerInput>;
};

export type CreateStoreEmbedSectionInput = {
  content: Scalars["String"]["input"];
};

export type CreateStoreInput = {
  currencyCode?: InputMaybe<Scalars["String"]["input"]>;
  industry?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CreateStoreMenuInput = {
  items?: InputMaybe<Array<InputMaybe<CreateMenuItemInput>>>;
  title: Scalars["String"]["input"];
};

export type CreateStorePageCatalogSectionInput = {
  AttributesInput?: InputMaybe<CreateStorePageSectionAttributesInput>;
  CollectionsInput?: InputMaybe<CreateStorePageSectionCollectionsInput>;
  CommonInput: CreateStorePageSectionCatalogCommonInput;
  ProductsInput?: InputMaybe<CreateStorePageSectionProductsInput>;
};

export type CreateStorePageInput = {
  handle?: InputMaybe<Scalars["String"]["input"]>;
  locale: Locale;
  name: Scalars["String"]["input"];
  pageType?: InputMaybe<StorePageTypeEnum>;
  seo?: InputMaybe<SeoInput>;
  status?: InputMaybe<PageStatusEnum>;
};

export type CreateStorePageSectionAttributesInput = {
  attributeValuesIds: Array<Scalars["ID"]["input"]>;
};

export type CreateStorePageSectionCatalogCommonInput = {
  background?: InputMaybe<BackgroundInput>;
  displayAs?: InputMaybe<DisplayAsEnum>;
  header?: InputMaybe<Scalars["String"]["input"]>;
  itemsPerRow?: InputMaybe<Scalars["Int"]["input"]>;
  locale: Scalars["String"]["input"];
  showItemName?: InputMaybe<Scalars["Boolean"]["input"]>;
  showSectionHeader?: InputMaybe<Scalars["Boolean"]["input"]>;
  stretchToFullWidth?: InputMaybe<Scalars["Boolean"]["input"]>;
  type: SectionTypeEnum;
};

export type CreateStorePageSectionCollectionsInput = {
  collectionsIds: Array<Scalars["ID"]["input"]>;
};

export type CreateStorePageSectionCommonInput = {
  background?: InputMaybe<BackgroundInput>;
  displayAs?: InputMaybe<DisplayAsEnum>;
  header?: InputMaybe<Scalars["String"]["input"]>;
  itemsPerRow?: InputMaybe<Scalars["Int"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  showSectionHeader?: InputMaybe<Scalars["Boolean"]["input"]>;
  stretchToFullWidth?: InputMaybe<Scalars["Boolean"]["input"]>;
  type: SectionTypeEnum;
};

export type CreateStorePageSectionInput = {
  BannerInput?: InputMaybe<CreateStoreBannerSectionInput>;
  CommonInput: CreateStorePageSectionCommonInput;
  EmbedInput?: InputMaybe<CreateStoreEmbedSectionInput>;
  TextInput?: InputMaybe<CreateStoreTextSectionInput>;
  VideoInput?: InputMaybe<CreateStoreVideoSectionInput>;
};

export type CreateStorePageSectionProductsInput = {
  fromCollectionId: Scalars["ID"]["input"];
  productsDisplayNumber?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CreateStorePayload = {
  __typename?: "CreateStorePayload";
  store: Store;
};

export type CreateStoreTextSectionInput = {
  content: Scalars["String"]["input"];
};

export type CreateStoreVideoSectionInput = {
  videosUrls: Array<Scalars["String"]["input"]>;
};

export type CreateTemplateInput = {
  htmlTemplate?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreatedStores = {
  __typename?: "CreatedStores";
  currentPeriod?: Maybe<Scalars["Int"]["output"]>;
  previousPeriod?: Maybe<Scalars["Int"]["output"]>;
};

export type Credentials =
  | IntegrationApiAuthCredentials
  | IntegrationBasicAuthCredentials
  | IntegrationNoAuthCredentials
  | IntegrationTokenAuthCredentials;

/** Currency codes. */
export enum CurrencyCode {
  /** Andorran Peseta (ADP). */
  Adp = "ADP",
  /** United Arab Emirates Dirham (AED). */
  Aed = "AED",
  /** Afghan Afghani (AFN). */
  Afn = "AFN",
  /** Albanian Lek (ALL). */
  All = "ALL",
  /** Armenian Dram (AMD). */
  Amd = "AMD",
  /** Netherlands Antillean Guilder. */
  Ang = "ANG",
  /** Angolan Kwanza (AOA). */
  Aoa = "AOA",
  /** Argentine Austral (ARA). */
  Ara = "ARA",
  /** Argentine Pesos (ARS). */
  Ars = "ARS",
  /** Austrian Schilling (ATS). */
  Ats = "ATS",
  /** Australian Dollars (AUD). */
  Aud = "AUD",
  /** Aruban Florin (AWG). */
  Awg = "AWG",
  /** Azerbaijani Manat (AZN). */
  Azn = "AZN",
  /** Bosnia and Herzegovina Convertible Mark (BAM). */
  Bam = "BAM",
  /** Barbadian Dollar (BBD). */
  Bbd = "BBD",
  /** Bangladesh Taka (BDT). */
  Bdt = "BDT",
  /** Belgian Franc (BEF). */
  Bef = "BEF",
  /** Bulgarian Lev (BGN). */
  Bgn = "BGN",
  /** Bahraini Dinar (BHD). */
  Bhd = "BHD",
  /** Burundian Franc (BIF). */
  Bif = "BIF",
  /** Bermudian Dollar (BMD). */
  Bmd = "BMD",
  /** Brunei Dollar (BND). */
  Bnd = "BND",
  /** Bolivian Boliviano (BOB). */
  Bob = "BOB",
  /** Bolivian Peso (BOP). */
  Bop = "BOP",
  /** Bolivian Mvdol (BOV). */
  Bov = "BOV",
  /** Brazilian Real (BRL). */
  Brl = "BRL",
  /** Bahamian Dollar (BSD). */
  Bsd = "BSD",
  /** Bhutanese Ngultrum (BTN). */
  Btn = "BTN",
  /** Burmese Kyat (BUK). */
  Buk = "BUK",
  /** Botswana Pula (BWP). */
  Bwp = "BWP",
  /** Belarusian Ruble (BYN). */
  Byn = "BYN",
  /** Belize Dollar (BZD). */
  Bzd = "BZD",
  /** Canadian Dollars (CAD). */
  Cad = "CAD",
  /** Congolese franc (CDF). */
  Cdf = "CDF",
  /** Swiss Francs (CHF). */
  Chf = "CHF",
  /** Chilean Peso (CLP). */
  Clp = "CLP",
  /** Chinese Yuan Renminbi (CNY). */
  Cny = "CNY",
  /** Colombian Peso (COP). */
  Cop = "COP",
  /** Costa Rican Colones (CRC). */
  Crc = "CRC",
  /** Czechoslovak Hard Koruna (CSK). */
  Csk = "CSK",
  /** Cuban Convertible Peso (CUC). */
  Cuc = "CUC",
  /** Cuban Peso (CUP). */
  Cup = "CUP",
  /** Cape Verdean escudo (CVE). */
  Cve = "CVE",
  /** Cypriot Pound (CYP). */
  Cyp = "CYP",
  /** Czech Koruny (CZK). */
  Czk = "CZK",
  /** East German Mark (DDM). */
  Ddm = "DDM",
  /** German Mark (DEM). */
  Dem = "DEM",
  /** Djiboutian Franc (DJF). */
  Djf = "DJF",
  /** Danish Kroner (DKK). */
  Dkk = "DKK",
  /** Dominican Peso (DOP). */
  Dop = "DOP",
  /** Algerian Dinar (DZD). */
  Dzd = "DZD",
  /** Estonian Kroon (EEK). */
  Eek = "EEK",
  /** Egyptian Pound (EGP). */
  Egp = "EGP",
  /** Eritrean Nakfa (ERN). */
  Ern = "ERN",
  /** Spanish Peseta (ESP). */
  Esp = "ESP",
  /** Ethiopian Birr (ETB). */
  Etb = "ETB",
  /** Euro (EUR). */
  Eur = "EUR",
  /** Finnish Markka (FIM). */
  Fim = "FIM",
  /** Fijian Dollars (FJD). */
  Fjd = "FJD",
  /** Falkland Islands Pounds (FKP). */
  Fkp = "FKP",
  /** French Franc (FRF). */
  Frf = "FRF",
  /** United Kingdom Pounds (GBP). */
  Gbp = "GBP",
  /** Georgian Lari (GEL). */
  Gel = "GEL",
  /** Ghanaian Cedi (GHS). */
  Ghs = "GHS",
  /** Gibraltar Pounds (GIP). */
  Gip = "GIP",
  /** Gambian Dalasi (GMD). */
  Gmd = "GMD",
  /** Guinean Franc (GNF). */
  Gnf = "GNF",
  /** Guinean Syli (GNS). */
  Gns = "GNS",
  /** Equatorial Guinean Ekwele (GQE). */
  Gqe = "GQE",
  /** Greek Drachma (GRD). */
  Grd = "GRD",
  /** Guatemalan Quetzal (GTQ). */
  Gtq = "GTQ",
  /** Portuguese Guinea Escudo (GWE). */
  Gwe = "GWE",
  /** Guinea-Bissau Peso (GWP). */
  Gwp = "GWP",
  /** Guyanese Dollar (GYD). */
  Gyd = "GYD",
  /** Hong Kong Dollars (HKD). */
  Hkd = "HKD",
  /** Honduran Lempira (HNL). */
  Hnl = "HNL",
  /** Croatian Dinar (HRD). */
  Hrd = "HRD",
  /** Croatian Kuna (HRK). */
  Hrk = "HRK",
  /** Haitian Gourde (HTG). */
  Htg = "HTG",
  /** Hungarian Forint (HUF). */
  Huf = "HUF",
  /** Indonesian Rupiah (IDR). */
  Idr = "IDR",
  /** Irish Pound (IEP). */
  Iep = "IEP",
  /** Israeli New Shekel (NIS). */
  Ils = "ILS",
  /** Indian Rupees (INR). */
  Inr = "INR",
  /** Iraqi Dinar (IQD). */
  Iqd = "IQD",
  /** Iranian Rial (IRR). */
  Irr = "IRR",
  /** Icelandic Kronur (ISK). */
  Isk = "ISK",
  /** Italian Lira (ITL). */
  Itl = "ITL",
  /** Jersey Pound. */
  Jep = "JEP",
  /** Jamaican Dollars (JMD). */
  Jmd = "JMD",
  /** Jordanian Dinar (JOD). */
  Jod = "JOD",
  /** Japanese Yen (JPY). */
  Jpy = "JPY",
  /** Kenyan Shilling (KES). */
  Kes = "KES",
  /** Kyrgyzstani Som (KGS). */
  Kgs = "KGS",
  /** Cambodian Riel. */
  Khr = "KHR",
  /** Comorian Franc (KMF). */
  Kmf = "KMF",
  /** North Korean Won (KPW). */
  Kpw = "KPW",
  /** South Korean Won (KRW). */
  Krw = "KRW",
  /** Kuwaiti Dinar (KWD). */
  Kwd = "KWD",
  /** Cayman Dollars (KYD). */
  Kyd = "KYD",
  /** Kazakhstani Tenge (KZT). */
  Kzt = "KZT",
  /** Laotian Kip (LAK). */
  Lak = "LAK",
  /** Lebanese Pounds (LBP). */
  Lbp = "LBP",
  /** Sri Lankan Rupees (LKR). */
  Lkr = "LKR",
  /** Liberian Dollar (LRD). */
  Lrd = "LRD",
  /** Lesotho Loti (LSL). */
  Lsl = "LSL",
  /** Lithuanian Litai (LTL). */
  Ltl = "LTL",
  /** Lithuanian Talonas (LTT). */
  Ltt = "LTT",
  /** Luxembourgian Convertible Franc (LUC). */
  Luc = "LUC",
  /** Luxembourgian Franc (LUF). */
  Luf = "LUF",
  /** Luxembourg Financial Franc (LUL). */
  Lul = "LUL",
  /** Latvian Lati (LVL). */
  Lvl = "LVL",
  /** Latvian Ruble (LVR). */
  Lvr = "LVR",
  /** Libyan Dinar (LYD). */
  Lyd = "LYD",
  /** Moroccan Dirham. */
  Mad = "MAD",
  /** Moroccan Franc (MAF). */
  Maf = "MAF",
  /** Moldovan Leu (MDL). */
  Mdl = "MDL",
  /** Malagasy Ariary (MGA). */
  Mga = "MGA",
  /** Malagasy Franc (MGF). */
  Mgf = "MGF",
  /** Macedonia Denar (MKD). */
  Mkd = "MKD",
  /** Malian Franc (MLF). */
  Mlf = "MLF",
  /** Burmese Kyat (MMK). */
  Mmk = "MMK",
  /** Mongolian Tugrik. */
  Mnt = "MNT",
  /** Macanese Pataca (MOP). */
  Mop = "MOP",
  /** Mauritanian Ougiya (MRO). */
  Mro = "MRO",
  /** Mauritanian Ouguiya (MRU). */
  Mru = "MRU",
  /** Maltese Lira (MTL). */
  Mtl = "MTL",
  /** Maltese Pound (MTP). */
  Mtp = "MTP",
  /** Mauritian Rupee (MUR). */
  Mur = "MUR",
  /** Maldivian Rufiyaa (MVR). */
  Mvr = "MVR",
  /** Malawian Kwacha (MWK). */
  Mwk = "MWK",
  /** Mexican Pesos (MXN). */
  Mxn = "MXN",
  /** Malaysian Ringgits (MYR). */
  Myr = "MYR",
  /** Mozambican Escudo (MZE). */
  Mze = "MZE",
  /** Mozambican Metical. */
  Mzn = "MZN",
  /** Namibian Dollar. */
  Nad = "NAD",
  /** Nigerian Naira (NGN). */
  Ngn = "NGN",
  /** Nicaraguan Córdoba (NIO). */
  Nio = "NIO",
  /** Dutch Guilder (NLG). */
  Nlg = "NLG",
  /** Norwegian Kroner (NOK). */
  Nok = "NOK",
  /** Nepalese Rupee (NPR). */
  Npr = "NPR",
  /** New Zealand Dollars (NZD). */
  Nzd = "NZD",
  /** Omani Rial (OMR). */
  Omr = "OMR",
  /** Panamian Balboa (PAB). */
  Pab = "PAB",
  /** Peruvian Nuevo Sol (PEN). */
  Pen = "PEN",
  /** Papua New Guinean Kina (PGK). */
  Pgk = "PGK",
  /** Philippine Peso (PHP). */
  Php = "PHP",
  /** Pakistani Rupee (PKR). */
  Pkr = "PKR",
  /** Polish Zlotych (PLN). */
  Pln = "PLN",
  /** Portuguese Escudo (PTE). */
  Pte = "PTE",
  /** Paraguayan Guarani (PYG). */
  Pyg = "PYG",
  /** Qatari Rial (QAR). */
  Qar = "QAR",
  /** Rhodesian Dollar (RHD). */
  Rhd = "RHD",
  /** Romanian Lei (RON). */
  Ron = "RON",
  /** Serbian dinar (RSD). */
  Rsd = "RSD",
  /** Russian Rubles (RUB). */
  Rub = "RUB",
  /** Rwandan Franc (RWF). */
  Rwf = "RWF",
  /** Saudi Riyal (SAR). */
  Sar = "SAR",
  /** Solomon Islands Dollar (SBD). */
  Sbd = "SBD",
  /** Seychellois Rupee (SCR). */
  Scr = "SCR",
  /** Sudanese Pound (SDG). */
  Sdg = "SDG",
  /** Swedish Kronor (SEK). */
  Sek = "SEK",
  /** Singapore Dollars (SGD). */
  Sgd = "SGD",
  /** Saint Helena Pounds (SHP). */
  Shp = "SHP",
  /** Slovenian Tolar (SIT). */
  Sit = "SIT",
  /** Slovak Koruna (SKK). */
  Skk = "SKK",
  /** Sierra Leonean Leone (SLL). */
  Sll = "SLL",
  /** Somali Shilling (SOS). */
  Sos = "SOS",
  /** Surinamese Dollar (SRD). */
  Srd = "SRD",
  /** Surinamese Guilder (SRG). */
  Srg = "SRG",
  /** South Sudanese Pound (SSP). */
  Ssp = "SSP",
  /** Sao Tome And Principe Dobra (STD). */
  Std = "STD",
  /** Soviet Rouble (SUR). */
  Sur = "SUR",
  /** Salvadoran Colón (SVC). */
  Svc = "SVC",
  /** Syrian Pound (SYP). */
  Syp = "SYP",
  /** Swazi Lilangeni (SZL). */
  Szl = "SZL",
  /** Thai baht (THB). */
  Thb = "THB",
  /** Tajikistani Ruble (TJR). */
  Tjr = "TJR",
  /** Tajikistani Somoni (TJS). */
  Tjs = "TJS",
  /** Turkmenistani Manat (TMT). */
  Tmt = "TMT",
  /** Tunisian Dinar (TND). */
  Tnd = "TND",
  /** Tongan Pa'anga (TOP). */
  Top = "TOP",
  /** Timorese Escudo (TPE). */
  Tpe = "TPE",
  /** Turkish Lira (TRY). */
  Try = "TRY",
  /** Trinidad and Tobago Dollars (TTD). */
  Ttd = "TTD",
  /** Taiwan Dollars (TWD). */
  Twd = "TWD",
  /** Tanzanian Shilling (TZS). */
  Tzs = "TZS",
  /** Ukrainian Hryvnia (UAH). */
  Uah = "UAH",
  /** Ugandan Shilling (UGX). */
  Ugx = "UGX",
  /** United States Dollars (USD). */
  Usd = "USD",
  /** Uruguayan Pesos (UYU). */
  Uyu = "UYU",
  /** Uzbekistan som (UZS). */
  Uzs = "UZS",
  /** Venezuelan Bolivares (VEF). */
  Vef = "VEF",
  /** Vietnamese đồng (VND). */
  Vnd = "VND",
  /** Vanuatu Vatu (VUV). */
  Vuv = "VUV",
  /** Samoan Tala (WST). */
  Wst = "WST",
  /** Central African CFA Franc (XAF). */
  Xaf = "XAF",
  /** East Caribbean Dollar (XCD). */
  Xcd = "XCD",
  /** European Currency Unit (XEU). */
  Xeu = "XEU",
  /** French Gold Franc (XFO). */
  Xfo = "XFO",
  /** West African CFA franc (XOF). */
  Xof = "XOF",
  /** CFP Franc (XPF). */
  Xpf = "XPF",
  /** Yemeni Dinar (YDD). */
  Ydd = "YDD",
  /** Yemeni Rial (YER). */
  Yer = "YER",
  /** South African Rand (ZAR). */
  Zar = "ZAR",
  /** Zambian Kwacha (ZMW). */
  Zmw = "ZMW",
}

export type CurrencyRate = {
  __typename?: "CurrencyRate";
  currency: Scalars["String"]["output"];
  rate: Scalars["Float"]["output"];
};

export type CustomBuildCategory = {
  __typename?: "CustomBuildCategory";
  category: CustomProductCategory;
  selectedVariants: Array<SelectedVariant>;
};

export type CustomCheckoutSetting = {
  __typename?: "CustomCheckoutSetting";
  checkoutNote: CheckoutNote;
  id: Scalars["ID"]["output"];
  identifier: CustomerIdentifier;
  name: NameInputOptions;
  notesToSeller: CheckoutFieldOptions;
  orderConfirmationNotes: OrderConfirmationNotes;
  postalCode: CheckoutFieldOptions;
  secondaryPhone: CheckoutFieldOptions;
};

export type CustomCheckoutSettingActions = {
  __typename?: "CustomCheckoutSettingActions";
  customizeCheckoutFormFields?: Maybe<CustomCheckoutSetting>;
};

export type CustomCheckoutSettingActionsCustomizeCheckoutFormFieldsArgs = {
  input: CustomizeCheckoutInput;
};

export type CustomCityInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
};

export type CustomCountryInput = {
  code2: Scalars["String"]["input"];
  code3: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
};

export type CustomItem = BaseItem & {
  __typename?: "CustomItem";
  categories: Array<CustomBuildCategory>;
  discount?: Maybe<OrderItemDiscountDetails>;
  discounts?: Maybe<Array<OrderItemDiscountDetails>>;
  fulfilledItems?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  price?: Maybe<Money>;
  productSnapshot?: Maybe<ProductSnapshot>;
  quantity: Scalars["Int"]["output"];
  sourceType: OrderItemSourceType;
  subtotal?: Maybe<Money>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type CustomItemCategoryInput = {
  categoryId: Scalars["String"]["input"];
  categoryName: Scalars["String"]["input"];
  categoryType: CategoryType;
  selectedVariants: Array<CreateCustomBuildItemInput>;
};

export type CustomItemDiscountDetails = {
  __typename?: "CustomItemDiscountDetails";
  discountSource: DiscountSource;
  info: OrderItemDiscountDetailsInfo;
  perItem: Money;
  total: Money;
};

export type CustomItemInput = {
  itemId?: InputMaybe<Scalars["ID"]["input"]>;
  productId: Scalars["ID"]["input"];
  quantity: Scalars["Int"]["input"];
  selectedVariants: Array<SelectedVariantInput>;
};

export type CustomProductCategory = {
  __typename?: "CustomProductCategory";
  categoryType: CategoryType;
  id: Scalars["ID"]["output"];
  image?: Maybe<Image>;
  isRequired: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  settings?: Maybe<CustomProductCategorySettings>;
  translations?: Maybe<Array<CustomProductCategoryTranslation>>;
  variants?: Maybe<Array<Maybe<CustomProductCategoryVariant>>>;
};

export type CustomProductCategoryInput = {
  categoryType: CategoryType;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<ImageInput>;
  isRequired: Scalars["Boolean"]["input"];
  name: Scalars["String"]["input"];
  settings: CustomProductCategorySettingsInput;
  translations?: InputMaybe<Array<CustomProductCategoryTranslationInput>>;
  variants: Array<InputMaybe<CustomProductCategoryVariantInput>>;
};

export type CustomProductCategorySettings = {
  __typename?: "CustomProductCategorySettings";
  maxQuantity?: Maybe<Scalars["Int"]["output"]>;
  maxSelect?: Maybe<Scalars["Int"]["output"]>;
};

export type CustomProductCategorySettingsInput = {
  maxQuantity?: InputMaybe<Scalars["Int"]["input"]>;
  maxSelect?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CustomProductCategoryTranslation = {
  __typename?: "CustomProductCategoryTranslation";
  locale: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type CustomProductCategoryTranslationInput = {
  locale: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type CustomProductCategoryVariant = {
  __typename?: "CustomProductCategoryVariant";
  isRecommended?: Maybe<Scalars["Boolean"]["output"]>;
  preselected?: Maybe<Scalars["Boolean"]["output"]>;
  variant?: Maybe<ProductVariant>;
};

export type CustomProductCategoryVariantInput = {
  isRecommended?: InputMaybe<Scalars["Boolean"]["input"]>;
  preselected: Scalars["Boolean"]["input"];
  variantId: Scalars["String"]["input"];
};

export type CustomProductDiscount = {
  __typename?: "CustomProductDiscount";
  fixed?: Maybe<Money>;
  percent?: Maybe<Scalars["Float"]["output"]>;
};

export type CustomProductDiscountInput = {
  fixed?: InputMaybe<MoneyInput>;
  percent?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CustomProductSnapshotCategory = {
  __typename?: "CustomProductSnapshotCategory";
  categoryType: CustomProductSnapshotCategoryType;
  id: Scalars["ID"]["output"];
  image?: Maybe<Image>;
  isRequired: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  settings?: Maybe<CustomProductSnapshotCategorySettings>;
  variants?: Maybe<Array<Maybe<CustomProductSnapshotCategoryVariant>>>;
};

export type CustomProductSnapshotCategorySettings = {
  __typename?: "CustomProductSnapshotCategorySettings";
  maxQuantity?: Maybe<Scalars["Int"]["output"]>;
  maxSelect?: Maybe<Scalars["Int"]["output"]>;
};

export enum CustomProductSnapshotCategoryType {
  Multiple = "MULTIPLE",
  Single = "SINGLE",
}

export type CustomProductSnapshotCategoryVariant = {
  __typename?: "CustomProductSnapshotCategoryVariant";
  isRecommended?: Maybe<Scalars["Boolean"]["output"]>;
  preselected?: Maybe<Scalars["Boolean"]["output"]>;
  variantId?: Maybe<Scalars["ID"]["output"]>;
};

export type CustomProductSnapshotDiscount = {
  __typename?: "CustomProductSnapshotDiscount";
  fixed?: Maybe<Money>;
  percent?: Maybe<Scalars["Float"]["output"]>;
};

export type CustomRegionInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
};

export type CustomStateInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
};

export type Customer = {
  __typename?: "Customer";
  email?: Maybe<Scalars["String"]["output"]>;
  isSubscribedToNewsLetter?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
};

export type CustomerActions = {
  __typename?: "CustomerActions";
  cart?: Maybe<CartActions>;
  x?: Maybe<Scalars["String"]["output"]>;
};

export type CustomerAddress = {
  __typename?: "CustomerAddress";
  addressLine1: Scalars["String"]["output"];
  addressLine2?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<City>;
  country?: Maybe<Country>;
  id: Scalars["ID"]["output"];
  isDefault: Scalars["Boolean"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  region?: Maybe<Region>;
  secondPhone?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<State>;
};

export type CustomerBuys = {
  __typename?: "CustomerBuys";
  items?: Maybe<CustomerBuysItems>;
  itemsType?: Maybe<DiscountItemsType>;
  quantity?: Maybe<Scalars["Int"]["output"]>;
  value?: Maybe<Money>;
};

export type CustomerBuysAmountOff = {
  __typename?: "CustomerBuysAmountOff";
  items?: Maybe<CustomerBuysItems>;
  itemsType?: Maybe<DiscountItemsType>;
};

export type CustomerBuysAmountOffInput = {
  items?: InputMaybe<CustomerBuysItemsInput>;
  itemsType?: InputMaybe<DiscountItemsType>;
};

export type CustomerBuysInput = {
  items?: InputMaybe<CustomerBuysItemsInput>;
  itemsType?: InputMaybe<DiscountItemsType>;
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
  value?: InputMaybe<MoneyInput>;
};

export type CustomerBuysItems = {
  __typename?: "CustomerBuysItems";
  collectionIds?: Maybe<Array<Scalars["ID"]["output"]>>;
  collections?: Maybe<Array<ProductCollection>>;
  productVariantIds?: Maybe<Array<Scalars["ID"]["output"]>>;
  productVariants?: Maybe<Array<ProductVariant>>;
};

export type CustomerBuysItemsInput = {
  collections?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  productVariants?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type CustomerCountryInput = {
  code2: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type CustomerGets = {
  __typename?: "CustomerGets";
  items: CustomerGetsItems;
  itemsType: DiscountItemsType;
  percentage: Scalars["Float"]["output"];
  quantity?: Maybe<Scalars["Int"]["output"]>;
};

export type CustomerGetsInput = {
  items: CustomerGetsItemsInput;
  itemsType: DiscountItemsType;
  percentage: Scalars["Float"]["input"];
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CustomerGetsItems = {
  __typename?: "CustomerGetsItems";
  collectionIds?: Maybe<Array<Scalars["ID"]["output"]>>;
  collections?: Maybe<Array<ProductCollection>>;
  productVariantIds?: Maybe<Array<Scalars["ID"]["output"]>>;
  productVariants?: Maybe<Array<ProductVariant>>;
};

export type CustomerGetsItemsInput = {
  collections?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  productVariants?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export enum CustomerIdentifier {
  Email = "EMAIL",
  EmailAndPhone = "EMAIL_AND_PHONE",
  Phone = "PHONE",
}

export type CustomerQueries = {
  __typename?: "CustomerQueries";
  calculateCart: CalculateCartResponse;
  cart?: Maybe<Cart>;
  wuiltShipment: WuiltShipmentCustomerQueries;
  x?: Maybe<Scalars["String"]["output"]>;
};

export type CustomerQueriesCalculateCartArgs = {
  cart: CalculateCartInput;
};

export type CustomerQueriesCartArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CustomerSchemaFilterInput = {
  hasOrders?: InputMaybe<Scalars["Boolean"]["input"]>;
  searchQuery?: InputMaybe<Scalars["String"]["input"]>;
};

export enum CustomerSortByField {
  CreatedAt = "createdAt",
  Id = "id",
  Name = "name",
  UpdatedAt = "updatedAt",
}

export type CustomerState = {
  __typename?: "CustomerState";
  code: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type CustomerStateInput = {
  code: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type CustomerUser = Node & {
  __typename?: "CustomerUser";
  addresses?: Maybe<Array<CustomerAddress>>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  isGuest?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  orders?: Maybe<OrderConnection>;
  phone?: Maybe<Scalars["String"]["output"]>;
  reviews: Array<ProductReview>;
  storeId?: Maybe<Scalars["String"]["output"]>;
  totalSpending?: Maybe<Money>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type CustomerUserOrdersArgs = {
  connection?: InputMaybe<OrdersConnectionInput>;
};

export type CustomerUserReviewsArgs = {
  connection?: InputMaybe<ReviewsConnectionInput>;
};

export type CustomerUserConnection = {
  __typename?: "CustomerUserConnection";
  nodes?: Maybe<Array<Maybe<CustomerUser>>>;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type CustomersConnectionInput = {
  after?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  before?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  first?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  last?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  offset: Scalars["Int"]["input"];
  sortBy?: InputMaybe<CustomerSortByField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type CustomersFilterInput = {
  date?: InputMaybe<DateInput>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  searchQuery?: InputMaybe<Scalars["String"]["input"]>;
};

export type CustomizeCheckoutInput = {
  checkoutNote?: InputMaybe<CheckoutNoteInput>;
  identifier?: InputMaybe<CustomerIdentifier>;
  name?: InputMaybe<NameInputOptions>;
  notesToSeller?: InputMaybe<CheckoutFieldOptions>;
  orderConfirmationNotes?: InputMaybe<OrderConfirmationNotesInput>;
  postalCode?: InputMaybe<CheckoutFieldOptions>;
  secondaryPhone?: InputMaybe<CheckoutFieldOptions>;
  storeId: Scalars["String"]["input"];
};

export type DashboardAnalytics = {
  __typename?: "DashboardAnalytics";
  allTimeTopSellingProducts?: Maybe<Array<Maybe<AllTimeTopSellingProduct>>>;
  weeklyMetrics?: Maybe<WeeklyStoreMetrics>;
};

export type DateFilterInput = {
  gte: Scalars["Date"]["input"];
  lte: Scalars["Date"]["input"];
};

export type DateInput = {
  from: Scalars["DateTime"]["input"];
  to: Scalars["DateTime"]["input"];
};

export type DateTimeRangeInput = {
  gte: Scalars["DateTime"]["input"];
  lte: Scalars["DateTime"]["input"];
};

export type Deletable = {
  isDeleted: Scalars["Boolean"]["output"];
};

export type DeleteProductMediaPayload = {
  __typename?: "DeleteProductMediaPayload";
  product?: Maybe<Product>;
};

export type DeleteProductPayload = {
  __typename?: "DeleteProductPayload";
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DeleteProductResourcesPayload = {
  __typename?: "DeleteProductResourcesPayload";
  deleted: Scalars["Boolean"]["output"];
  linkedProducts?: Maybe<Array<ProductLink>>;
};

export type DeleteResourcePayload = {
  __typename?: "DeleteResourcePayload";
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
};

export type Dimensions = {
  __typename?: "Dimensions";
  height?: Maybe<Scalars["Float"]["output"]>;
  length?: Maybe<Scalars["Float"]["output"]>;
  width?: Maybe<Scalars["Float"]["output"]>;
};

export type DimensionsInput = {
  height?: InputMaybe<Scalars["Float"]["input"]>;
  length?: InputMaybe<Scalars["Float"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Discount = {
  __typename?: "Discount";
  BuyXGetYDiscount?: Maybe<BuyXGetYDiscount>;
  amount?: Maybe<Money>;
  amountOff?: Maybe<AmountOff>;
  appliedOn?: Maybe<DiscountAppliedOnType>;
  customerBuys?: Maybe<CustomerBuys>;
  id: Scalars["ID"]["output"];
  locale?: Maybe<Scalars["String"]["output"]>;
  percentage?: Maybe<Scalars["Float"]["output"]>;
  schedule?: Maybe<DiscountSchedule>;
  status?: Maybe<DiscountStatus>;
  title?: Maybe<Scalars["String"]["output"]>;
  translations?: Maybe<Array<Maybe<DiscountTranslation>>>;
};

export type DiscountActions = {
  __typename?: "DiscountActions";
  createAutomaticDiscount: Discount;
  deleteAutomaticDiscount?: Maybe<Scalars["Boolean"]["output"]>;
  translateAutomaticDiscount: Discount;
  updateAutomaticDiscount: Discount;
};

export type DiscountActionsCreateAutomaticDiscountArgs = {
  input: DiscountInput;
};

export type DiscountActionsDeleteAutomaticDiscountArgs = {
  id: Scalars["ID"]["input"];
};

export type DiscountActionsTranslateAutomaticDiscountArgs = {
  id: Scalars["ID"]["input"];
  translation: Array<DiscountTranslationInput>;
};

export type DiscountActionsUpdateAutomaticDiscountArgs = {
  id: Scalars["ID"]["input"];
  input: DiscountInput;
};

export enum DiscountAppliedOnType {
  All = "ALL",
  AmountOff = "AMOUNT_OFF",
  BuyXGetY = "BUY_X_GET_Y",
  Shipping = "SHIPPING",
}

export type DiscountInput = {
  BuyXGetYDiscount?: InputMaybe<BuyXGetYDiscountInput>;
  amount?: InputMaybe<MoneyInput>;
  amountOff?: InputMaybe<AmountOffInput>;
  appliedOn: DiscountAppliedOnType;
  customerBuys?: InputMaybe<CustomerBuysInput>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  percentage?: InputMaybe<Scalars["Float"]["input"]>;
  schedule?: InputMaybe<DiscountScheduleInput>;
  status: DiscountStatus;
  title: Scalars["String"]["input"];
};

export enum DiscountItemsType {
  Collections = "COLLECTIONS",
  Products = "PRODUCTS",
}

export type DiscountQueries = {
  __typename?: "DiscountQueries";
  discount?: Maybe<Discount>;
  discounts: Array<Discount>;
};

export type DiscountQueriesDiscountArgs = {
  id: Scalars["ID"]["input"];
};

export type DiscountSchedule = {
  __typename?: "DiscountSchedule";
  end?: Maybe<Scalars["DateTime"]["output"]>;
  start: Scalars["DateTime"]["output"];
};

export type DiscountScheduleInput = {
  end?: InputMaybe<Scalars["DateTime"]["input"]>;
  start: Scalars["DateTime"]["input"];
};

export type DiscountSnapshot = {
  __typename?: "DiscountSnapshot";
  BuyXGetYDiscount?: Maybe<BuyXGetYDiscount>;
  amount?: Maybe<Money>;
  appliedOn?: Maybe<DiscountAppliedOnType>;
  id?: Maybe<Scalars["String"]["output"]>;
  percentage?: Maybe<Scalars["Float"]["output"]>;
  schedule?: Maybe<DiscountSchedule>;
  title?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<DiscountType>;
};

export enum DiscountSource {
  AmountOff = "AMOUNT_OFF",
  BuyXGetY = "BUY_X_GET_Y",
  CustomDiscount = "CUSTOM_DISCOUNT",
  Mixed = "MIXED",
}

export enum DiscountStatus {
  Active = "ACTIVE",
  Draft = "DRAFT",
  Expired = "EXPIRED",
  Scheduled = "SCHEDULED",
}

export type DiscountTranslation = {
  __typename?: "DiscountTranslation";
  locale?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type DiscountTranslationInput = {
  locale: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
};

export enum DiscountType {
  Automatic = "AUTOMATIC",
  Manual = "MANUAL",
}

export enum DisplayAsEnum {
  Grid = "Grid",
  Slider = "Slider",
}

export type DnsRecord = {
  __typename?: "DnsRecord";
  host?: Maybe<Scalars["String"]["output"]>;
  priority?: Maybe<Scalars["Int"]["output"]>;
  ttl?: Maybe<Scalars["Int"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type Domain = {
  __typename?: "Domain";
  createdAt?: Maybe<Scalars["String"]["output"]>;
  domainName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  store?: Maybe<Store>;
  type?: Maybe<DomainType>;
  updatedAt?: Maybe<Scalars["String"]["output"]>;
};

export type DomainClaim = {
  __typename?: "DomainClaim";
  createdAt: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
};

export type DomainInput = {
  domainName: Scalars["String"]["input"];
  storeId: Scalars["ID"]["input"];
  type?: InputMaybe<DomainType>;
};

export type DomainSearchResult = {
  __typename?: "DomainSearchResult";
  available: Scalars["Boolean"]["output"];
  domainName: Scalars["String"]["output"];
  premium: Scalars["Boolean"]["output"];
  price?: Maybe<Scalars["Float"]["output"]>;
};

export type DropshippingCategory = {
  __typename?: "DropshippingCategory";
  meta?: Maybe<DropshippingCategoryMeta>;
  name: Scalars["String"]["output"];
};

export type DropshippingCategoryMeta = {
  __typename?: "DropshippingCategoryMeta";
  text?: Maybe<Scalars["String"]["output"]>;
};

export type DropshippingProduct = {
  __typename?: "DropshippingProduct";
  category?: Maybe<Scalars["String"]["output"]>;
  cost?: Maybe<Money>;
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  images?: Maybe<Array<Scalars["String"]["output"]>>;
  meta?: Maybe<DropshippingProductMeta>;
  name: Scalars["String"]["output"];
  price: Money;
  profit?: Maybe<Money>;
  variationsCount?: Maybe<Scalars["Int"]["output"]>;
};

export type DropshippingProductConnection = {
  __typename?: "DropshippingProductConnection";
  nodes: Array<DropshippingProduct>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DropshippingProductConnectionInput = {
  itemsPerPage?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DropshippingProductMeta = {
  __typename?: "DropshippingProductMeta";
  howToUse?: Maybe<Scalars["String"]["output"]>;
  isExpired?: Maybe<Scalars["Boolean"]["output"]>;
  productAvailability?: Maybe<Scalars["String"]["output"]>;
  specifications?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export enum EmailRecipientEnum {
  All = "ALL",
  None = "NONE",
}

export type EmailRecord = {
  __typename?: "EmailRecord";
  address?: Maybe<Scalars["Email"]["output"]>;
  verified?: Maybe<Scalars["Boolean"]["output"]>;
};

export type EmailRecordInput = {
  address?: InputMaybe<Scalars["Email"]["input"]>;
  verified?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type EmailSettings = {
  __typename?: "EmailSettings";
  body: Scalars["String"]["output"];
  subject: Scalars["String"]["output"];
};

export type EmailSettingsInput = {
  body: Scalars["String"]["input"];
  subject: Scalars["String"]["input"];
};

export enum EmailStatus {
  NotSent = "NOT_SENT",
  Scheduled = "SCHEDULED",
  Sent = "SENT",
}

export type EnableProductReviewsInput = {
  enableGuestReviews: Scalars["Boolean"]["input"];
  showCustomerAvatar: Scalars["Boolean"]["input"];
  showOverAllRating: Scalars["Boolean"]["input"];
  showReviewDate: Scalars["Boolean"]["input"];
  showReviewsCount: Scalars["Boolean"]["input"];
  showStars: Scalars["Boolean"]["input"];
  starsColor: Scalars["String"]["input"];
  storeId: Scalars["String"]["input"];
};

export enum ErrorCode {
  ActionLimitReached = "ACTION_LIMIT_REACHED",
  BulkUploadOverwriteMustBeEnabled = "BULK_UPLOAD_OVERWRITE_MUST_BE_ENABLED",
  CheckoutTimeout = "CHECKOUT_TIMEOUT",
  CollectionNotFound = "COLLECTION_NOT_FOUND",
  CurrencyChanged = "CURRENCY_CHANGED",
  CustomerCreationError = "CUSTOMER_CREATION_ERROR",
  DocumentSizeTooLarge = "DOCUMENT_SIZE_TOO_LARGE",
  DuplicateSku = "DUPLICATE_SKU",
  DuplicateVariants = "DUPLICATE_VARIANTS",
  ExtraHeaders = "EXTRA_HEADERS",
  InconsistentVariation = "INCONSISTENT_VARIATION",
  InsufficientQuantity = "INSUFFICIENT_QUANTITY",
  IntegratedItemsQuantityValidationError = "INTEGRATED_ITEMS_QUANTITY_VALIDATION_ERROR",
  InvalidCustomerCheckoutData = "INVALID_CUSTOMER_CHECKOUT_DATA",
  InvalidFieldValue = "INVALID_FIELD_VALUE",
  InvalidFileExtension = "INVALID_FILE_EXTENSION",
  InvalidHandle = "INVALID_HANDLE",
  InvalidRecord = "INVALID_RECORD",
  InvalidSlug = "INVALID_SLUG",
  InvalidTranslationLocale = "INVALID_TRANSLATION_LOCALE",
  MaxQuantityExceeded = "MAX_QUANTITY_EXCEEDED",
  MinQuantityNotMet = "MIN_QUANTITY_NOT_MET",
  MissingHeaders = "MISSING_HEADERS",
  NoPaymentMethods = "NO_PAYMENT_METHODS",
  OptionMustHaveValidValue = "OPTION_MUST_HAVE_VALID_VALUE",
  OrderCanceledByCustomer = "ORDER_CANCELED_BY_CUSTOMER",
  OrderRestorationFailed = "ORDER_RESTORATION_FAILED",
  PaymentError = "PAYMENT_ERROR",
  PlanLimitExceeded = "PLAN_LIMIT_EXCEEDED",
  PriceCalculationError = "PRICE_CALCULATION_ERROR",
  PriceChanged = "PRICE_CHANGED",
  ProductNotActive = "PRODUCT_NOT_ACTIVE",
  ProductNotAvailable = "PRODUCT_NOT_AVAILABLE",
  ProductNotFound = "PRODUCT_NOT_FOUND",
  RecordNotFound = "RECORD_NOT_FOUND",
  ShippingError = "SHIPPING_ERROR",
  UnexpectedError = "UNEXPECTED_ERROR",
  UnverifiedJwtToken = "UNVERIFIED_JWT_TOKEN",
  VariantNotAvailable = "VARIANT_NOT_AVAILABLE",
}

export type ExportAbandonedCheckoutInput = {
  createdAt?: InputMaybe<DateTimeRangeInput>;
  email: Scalars["String"]["input"];
  filter?: InputMaybe<AbandonedCheckoutFilterInput>;
  locale: Locale;
  storeName: Scalars["String"]["input"];
  timeDifference: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<DateTimeRangeInput>;
};

export type ExportAbandonedCheckoutPayload = {
  __typename?: "ExportAbandonedCheckoutPayload";
  message: Scalars["String"]["output"];
  statusCode: Scalars["Int"]["output"];
};

export type ExportCustomersInput = {
  email: Scalars["String"]["input"];
  filter?: InputMaybe<CustomersFilterInput>;
  storeId: Scalars["ID"]["input"];
  storeName: Scalars["String"]["input"];
  timeDifference: Scalars["Int"]["input"];
};

export type ExportCustomersPayload = {
  __typename?: "ExportCustomersPayload";
  isSuccessful: Scalars["Boolean"]["output"];
};

export type ExportOptions = {
  attributes: Scalars["Boolean"]["input"];
  collections: Scalars["Boolean"]["input"];
  options: Scalars["Boolean"]["input"];
  products: Scalars["Boolean"]["input"];
};

export type ExportOrdersInput = {
  email: Scalars["String"]["input"];
  filter?: InputMaybe<OrdersFilterInput>;
  storeId: Scalars["ID"]["input"];
  storeName: Scalars["String"]["input"];
  timeDifference: Scalars["String"]["input"];
};

export type ExportOrdersPayload = {
  __typename?: "ExportOrdersPayload";
  message: Scalars["String"]["output"];
  statusCode: Scalars["Int"]["output"];
};

export type ExportProductsInput = {
  email: Scalars["String"]["input"];
  filter?: InputMaybe<ProductsFilterInput>;
  locale: Scalars["String"]["input"];
  storeDomain: Scalars["String"]["input"];
  storeId: Scalars["ID"]["input"];
  storeName: Scalars["String"]["input"];
  timeDifference: Scalars["String"]["input"];
};

export type ExportProductsPayload = {
  __typename?: "ExportProductsPayload";
  message: Scalars["String"]["output"];
  statusCode: Scalars["Int"]["output"];
};

export type FacebookSalesChannelSettings = {
  __typename?: "FacebookSalesChannelSettings";
  password: Scalars["String"]["output"];
  provider: ProviderName;
  title: Scalars["String"]["output"];
  username: Scalars["String"]["output"];
};

export enum FatooraConfigurationAction {
  Activate = "Activate",
  RenewCertificate = "RenewCertificate",
  RevokeCertificate = "RevokeCertificate",
  Save = "Save",
}

export type FatooraConfigurationInput = {
  CRNid?: InputMaybe<Scalars["String"]["input"]>;
  VATnumber: Scalars["String"]["input"];
  action: FatooraConfigurationAction;
  branchName: Scalars["String"]["input"];
  buildingNumber: Scalars["String"]["input"];
  cityName: Scalars["String"]["input"];
  citySubdivisionName: Scalars["String"]["input"];
  country: Scalars["String"]["input"];
  domainName: Scalars["String"]["input"];
  industry: Scalars["String"]["input"];
  installationId: Scalars["ID"]["input"];
  otp?: InputMaybe<Scalars["String"]["input"]>;
  plotIdentification: Scalars["String"]["input"];
  postalZone: Scalars["String"]["input"];
  storeId: Scalars["ID"]["input"];
  streetName: Scalars["String"]["input"];
  taxPayerProvidedId: Scalars["String"]["input"];
  taxpayerName: Scalars["String"]["input"];
};

export type FatooraSettings = {
  __typename?: "FatooraSettings";
  CRNid?: Maybe<Scalars["String"]["output"]>;
  VATnumber: Scalars["String"]["output"];
  branchName: Scalars["String"]["output"];
  buildingNumber: Scalars["String"]["output"];
  cityName: Scalars["String"]["output"];
  citySubdivisionName: Scalars["String"]["output"];
  domainName: Scalars["String"]["output"];
  isActivated: Scalars["Boolean"]["output"];
  plotIdentification: Scalars["String"]["output"];
  postalZone: Scalars["String"]["output"];
  provider: ProviderName;
  streetName: Scalars["String"]["output"];
  taxPayerProvidedId: Scalars["String"]["output"];
  taxpayerName: Scalars["String"]["output"];
};

export type Feature = {
  __typename?: "Feature";
  description?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type FilterInput = {
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  type?: InputMaybe<IntegrationType>;
};

export type FontInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  fontFamily?: InputMaybe<Scalars["String"]["input"]>;
  isItalic?: InputMaybe<Scalars["Boolean"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<FontTypeEnum>;
  typeFace?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  weight?: InputMaybe<Scalars["String"]["input"]>;
};

export type FontType = {
  __typename?: "FontType";
  category?: Maybe<Scalars["String"]["output"]>;
  fontFamily?: Maybe<Scalars["String"]["output"]>;
  isItalic?: Maybe<Scalars["Boolean"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  locales?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  translations?: Maybe<Array<Maybe<FontTypeTranslation>>>;
  type?: Maybe<FontTypeEnum>;
  typeFace?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  weight?: Maybe<Scalars["String"]["output"]>;
};

export enum FontTypeEnum {
  Body = "Body",
  Headers = "Headers",
}

export type FontTypeTranslation = {
  __typename?: "FontTypeTranslation";
  category?: Maybe<Scalars["String"]["output"]>;
  fontFamily?: Maybe<Scalars["String"]["output"]>;
  isItalic?: Maybe<Scalars["Boolean"]["output"]>;
  locale: Scalars["String"]["output"];
  type?: Maybe<FontTypeEnum>;
  typeFace?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  weight?: Maybe<Scalars["String"]["output"]>;
};

export type FooterInput = {
  activeFooter: ActiveFooter;
  customPart?: InputMaybe<Scalars["String"]["input"]>;
  endPart?: InputMaybe<Scalars["String"]["input"]>;
  locale: Scalars["String"]["input"];
  startPart?: InputMaybe<Scalars["String"]["input"]>;
};

export type FooterTranslationInput = {
  customPart?: InputMaybe<Scalars["String"]["input"]>;
  endPart?: InputMaybe<Scalars["String"]["input"]>;
  locale: Scalars["String"]["input"];
  startPart?: InputMaybe<Scalars["String"]["input"]>;
};

export type FooterType = {
  __typename?: "FooterType";
  activeFooter?: Maybe<ActiveFooter>;
  customPart?: Maybe<Scalars["String"]["output"]>;
  endPart?: Maybe<Scalars["String"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  locales?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  startPart?: Maybe<Scalars["String"]["output"]>;
  translations?: Maybe<Array<Maybe<StoreFooterTranslation>>>;
};

export type FulfillInput = {
  items: Array<InputMaybe<FulfillItemsInput>>;
  orderId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type FulfillItemsInput = {
  fulfilledItems: Scalars["Int"]["input"];
  id: Scalars["ID"]["input"];
};

export enum FulfillStatusEnum {
  Fulfilled = "FULFILLED",
  PartiallyFulfilled = "PARTIALLY_FULFILLED",
  Unfulfilled = "UNFULFILLED",
}

export type GameballIntegrationSettings = {
  __typename?: "GameballIntegrationSettings";
  provider: ProviderName;
};

export type GameballScriptControls = {
  __typename?: "GameballScriptControls";
  apiKey: Scalars["String"]["output"];
  provider: ProviderName;
};

export enum GeneralShipmentStatus {
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  Draft = "DRAFT",
  Ongoing = "ONGOING",
}

export type GenerateStoreLegalPageInput = {
  locale?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type GenerateStoreStaticPageInput = {
  locale?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type GeoLocation = {
  __typename?: "GeoLocation";
  latitude: Scalars["Float"]["output"];
  longitude: Scalars["Float"]["output"];
};

export type GeoLocationInput = {
  latitude: Scalars["Float"]["input"];
  longitude: Scalars["Float"]["input"];
};

export type GoAffProIntegrationSettings = {
  __typename?: "GoAffProIntegrationSettings";
  provider: ProviderName;
  token: Scalars["String"]["output"];
};

export type GoogleMerchantSalesChannelSettings = {
  __typename?: "GoogleMerchantSalesChannelSettings";
  password: Scalars["String"]["output"];
  provider: ProviderName;
  title: Scalars["String"]["output"];
  username: Scalars["String"]["output"];
};

export enum HandleType {
  Attribute = "attribute",
  AttributesValue = "attributesValue",
  Collection = "collection",
  Option = "option",
  OptionsValue = "optionsValue",
  Product = "product",
}

export type HubSpotIntegrationSettings = {
  __typename?: "HubSpotIntegrationSettings";
  provider: ProviderName;
  token: Scalars["String"]["output"];
};

export type ICity = {
  code?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  isPredefined?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
  regions?: Maybe<RegionUnion>;
  stateId: Scalars["ID"]["output"];
  storeId?: Maybe<Scalars["ID"]["output"]>;
};

export type ICountry = {
  code2: Scalars["String"]["output"];
  code3: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isPredefined?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
  storeId?: Maybe<Scalars["ID"]["output"]>;
};

export type IRegion = {
  cityId: Scalars["ID"]["output"];
  code?: Maybe<Scalars["String"]["output"]>;
  countryId: Scalars["ID"]["output"];
  id: Scalars["ID"]["output"];
  isPredefined?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
  stateId: Scalars["ID"]["output"];
  storeId?: Maybe<Scalars["ID"]["output"]>;
};

export type IState = {
  cities?: Maybe<CityUnion>;
  code?: Maybe<Scalars["String"]["output"]>;
  countryId?: Maybe<Scalars["ID"]["output"]>;
  id: Scalars["ID"]["output"];
  isPredefined?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
  numOfCities?: Maybe<Scalars["Int"]["output"]>;
};

export type Image = Node & {
  __typename?: "Image";
  altText?: Maybe<Scalars["String"]["output"]>;
  format?: Maybe<Scalars["String"]["output"]>;
  height?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  originalSrc?: Maybe<Scalars["String"]["output"]>;
  src?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<MediaStatus>;
  tranformedSrc?: Maybe<Scalars["URL"]["output"]>;
  width?: Maybe<Scalars["Int"]["output"]>;
};

export type ImageTranformedSrcArgs = {
  preferredContentType?: InputMaybe<ImageContentType>;
};

export enum ImageContentType {
  Jpg = "JPG",
  Png = "PNG",
  Webp = "WEBP",
}

export type ImageInput = {
  altText?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  src?: InputMaybe<Scalars["String"]["input"]>;
};

export type ImageSnapshot = {
  __typename?: "ImageSnapshot";
  altText?: Maybe<Scalars["String"]["output"]>;
  src: Scalars["String"]["output"];
};

export type ImportProductsInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  installationId?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  productIds: Array<Scalars["String"]["input"]>;
  storeCurrency?: InputMaybe<CurrencyCode>;
  storeId: Scalars["ID"]["input"];
};

export type InfoBar = {
  __typename?: "InfoBar";
  background: Scalars["String"]["output"];
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  isDismissible?: Maybe<Scalars["Boolean"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  locales?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  text: Scalars["HTML"]["output"];
  translations?: Maybe<Array<Maybe<InfoBarTranslation>>>;
};

export type InfoBarInput = {
  background: Scalars["String"]["input"];
  isActive: Scalars["Boolean"]["input"];
  isDismissible: Scalars["Boolean"]["input"];
  locale: Scalars["String"]["input"];
  text: Scalars["HTML"]["input"];
};

export type InfoBarTranslation = {
  __typename?: "InfoBarTranslation";
  locale: Scalars["String"]["output"];
  text: Scalars["HTML"]["output"];
};

export type InfoBarTranslationInput = {
  locale: Scalars["String"]["input"];
  text: Scalars["HTML"]["input"];
};

export type Integration = Node & {
  __typename?: "Integration";
  /** @deprecated Use CMS instead */
  about?: Maybe<Scalars["HTML"]["output"]>;
  authType: IntegrationAuthType;
  category?: Maybe<IntegrationCategory>;
  cmsIds?: Maybe<CmsIntegrationLocalesIDs>;
  /** @deprecated Use CMS instead */
  connectAccountInstructions?: Maybe<Scalars["HTML"]["output"]>;
  /** @deprecated Use CMS instead */
  createAccountInstructions?: Maybe<Scalars["HTML"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  /** @deprecated Use CMS instead */
  description?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use CMS instead */
  features?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** @deprecated Use CMS instead */
  icon?: Maybe<Logo>;
  id: Scalars["ID"]["output"];
  installationId?: Maybe<Scalars["ID"]["output"]>;
  isBeta?: Maybe<Scalars["Boolean"]["output"]>;
  isPaginated: Scalars["Boolean"]["output"];
  /** @deprecated Use CMS instead */
  links?: Maybe<IntegrationLinks>;
  /** @deprecated Use CMS instead */
  locale: Scalars["String"]["output"];
  /** @deprecated Use CMS instead */
  locales: Array<Scalars["String"]["output"]>;
  /** @deprecated Use CMS instead */
  logo?: Maybe<Logo>;
  name: Scalars["String"]["output"];
  provider?: Maybe<ProviderName>;
  searchingMechanism?: Maybe<Array<SearchingMechanism>>;
  /** @deprecated Use CMS instead */
  translations: Array<IntegrationTranslation>;
  type?: Maybe<IntegrationType>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type IntegrationInstallationIdArgs = {
  storeId?: InputMaybe<Scalars["String"]["input"]>;
};

export type IntegrationAccessibleInfo =
  | BostaAccessibleInfo
  | KlaviyoAccessibleInfo
  | MailchimpAccessibleInfo
  | NoAccessibleInfo;

export type IntegrationApiAuthCredentials = {
  __typename?: "IntegrationApiAuthCredentials";
  key: Scalars["String"]["output"];
  secret: Scalars["String"]["output"];
};

export type IntegrationApiAuthCredentialsInput = {
  key: Scalars["String"]["input"];
  secret: Scalars["String"]["input"];
};

export enum IntegrationAuthType {
  Api = "Api",
  Basic = "Basic",
  Custom = "Custom",
  NoAuth = "NoAuth",
  Token = "Token",
}

export type IntegrationBasicAuthCredentials = {
  __typename?: "IntegrationBasicAuthCredentials";
  password: Scalars["String"]["output"];
  username: Scalars["String"]["output"];
};

export type IntegrationBasicAuthCredentialsInput = {
  password: Scalars["String"]["input"];
  username: Scalars["String"]["input"];
};

export enum IntegrationCategory {
  Dropshipping = "Dropshipping",
  EInvoice = "EInvoice",
  Marketing = "Marketing",
  Shipping = "Shipping",
  SocialMediaSalesChannel = "SocialMediaSalesChannel",
}

export type IntegrationConnection = {
  __typename?: "IntegrationConnection";
  nodes?: Maybe<Array<Maybe<Integration>>>;
  totalCount: Scalars["Int"]["output"];
};

export type IntegrationConnectionInput = {
  after?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  before?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  first?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  last?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<IntegrationSortByField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type IntegrationCredentialsInput = {
  apiCredentials?: InputMaybe<IntegrationApiAuthCredentialsInput>;
  basicCredentials?: InputMaybe<IntegrationBasicAuthCredentialsInput>;
  tokenCredentials?: InputMaybe<IntegrationTokenAuthCredentialsInput>;
};

export type IntegrationInstallation = {
  __typename?: "IntegrationInstallation";
  accessibleInfo?: Maybe<IntegrationAccessibleInfo>;
  credentials?: Maybe<Credentials>;
  id: Scalars["ID"]["output"];
  integrationAuthType: IntegrationAuthType;
  integrationId: Scalars["ID"]["output"];
  settings?: Maybe<Settings>;
  storeId: Scalars["ID"]["output"];
};

export type IntegrationInstallationInput = {
  credentials?: InputMaybe<IntegrationCredentialsInput>;
  integrationId: Scalars["ID"]["input"];
  settings?: InputMaybe<IntegrationInstallationSettingsInput>;
  storeId: Scalars["ID"]["input"];
};

export type IntegrationInstallationSettingsInput = {
  bostaIntegrationSettings?: InputMaybe<BostaIntegrationSettingsInput>;
  fatooraIntegrationSettings?: InputMaybe<FatooraConfigurationInput>;
  klaviyoIntegrationSettings?: InputMaybe<KlaviyoIntegrationSettingsInput>;
  mailChimpIntegrationSettings?: InputMaybe<MailChimpIntegrationSettingsInput>;
  optimonkIntegrationSettings?: InputMaybe<OptimonkIntegrationSettingsInput>;
  socialIntegrationSettings?: InputMaybe<SocialIntegrationSettingsInput>;
};

export type IntegrationLinks = {
  __typename?: "IntegrationLinks";
  createAccount?: Maybe<Scalars["String"]["output"]>;
  dashboard?: Maybe<Scalars["String"]["output"]>;
  support?: Maybe<Scalars["String"]["output"]>;
  websiteLink?: Maybe<Scalars["String"]["output"]>;
};

export type IntegrationLinksInput = {
  createAccount?: InputMaybe<Scalars["String"]["input"]>;
  support?: InputMaybe<Scalars["String"]["input"]>;
  websiteLink?: InputMaybe<Scalars["String"]["input"]>;
};

export type IntegrationNoAuthCredentials = {
  __typename?: "IntegrationNoAuthCredentials";
  authType?: Maybe<Scalars["String"]["output"]>;
};

export type IntegrationProviderDetails = {
  __typename?: "IntegrationProviderDetails";
  icon?: Maybe<IntegrationProviderImage>;
  name?: Maybe<Scalars["String"]["output"]>;
  provider?: Maybe<Scalars["String"]["output"]>;
  websiteLink?: Maybe<Scalars["String"]["output"]>;
};

export type IntegrationProviderDetailsInput = {
  icon?: InputMaybe<IntegrationProviderImageInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  provider?: InputMaybe<Scalars["String"]["input"]>;
  websiteLink?: InputMaybe<Scalars["String"]["input"]>;
};

export type IntegrationProviderDetailsSnapshot = {
  __typename?: "IntegrationProviderDetailsSnapshot";
  icon?: Maybe<ImageSnapshot>;
  name?: Maybe<Scalars["String"]["output"]>;
  provider?: Maybe<Scalars["String"]["output"]>;
  websiteLink?: Maybe<Scalars["String"]["output"]>;
};

export type IntegrationProviderImage = {
  __typename?: "IntegrationProviderImage";
  altText?: Maybe<Scalars["String"]["output"]>;
  src: Scalars["String"]["output"];
};

export type IntegrationProviderImageInput = {
  altText?: InputMaybe<Scalars["String"]["input"]>;
  src: Scalars["String"]["input"];
};

export type IntegrationProviderNoSettings = {
  __typename?: "IntegrationProviderNoSettings";
  provider: ProviderName;
};

export type IntegrationScriptControls =
  | GameballScriptControls
  | KlaviyoScriptControls
  | MailchimpScriptControls
  | NoScriptControls
  | OptimonkScriptControls;

export enum IntegrationServiceErrorCodes {
  CannotCancelInProgressOrder = "CANNOT_CANCEL_IN_PROGRESS_ORDER",
  CantRemoveLastPickupLocation = "CANT_REMOVE_LAST_PICKUP_LOCATION",
  Conflict = "CONFLICT",
  InvalidInput = "INVALID_INPUT",
  MaterialNotFound = "MATERIAL_NOT_FOUND",
  MaterialOrderNotFound = "MATERIAL_ORDER_NOT_FOUND",
  MaxPickupLocationsReached = "MAX_PICKUP_LOCATIONS_REACHED",
  NotFound = "NOT_FOUND",
  WizardNotCompleted = "WIZARD_NOT_COMPLETED",
}

export enum IntegrationSortByField {
  CreatedAt = "createdAt",
  Id = "id",
  Name = "name",
  UpdatedAt = "updatedAt",
}

export type IntegrationTokenAuthCredentials = {
  __typename?: "IntegrationTokenAuthCredentials";
  token: Scalars["String"]["output"];
};

export type IntegrationTokenAuthCredentialsInput = {
  token: Scalars["String"]["input"];
};

export type IntegrationTranslation = {
  __typename?: "IntegrationTranslation";
  about?: Maybe<Scalars["HTML"]["output"]>;
  connectAccountInstructions?: Maybe<Scalars["HTML"]["output"]>;
  createAccountInstructions?: Maybe<Scalars["HTML"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  features?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  links?: Maybe<IntegrationLinks>;
  locale: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
};

export enum IntegrationType {
  Dropshipping = "Dropshipping",
  EInvoice = "EInvoice",
  Marketing = "Marketing",
  Shipping = "Shipping",
  SocialMediaSalesChannel = "SocialMediaSalesChannel",
}

export type Invoice = {
  __typename?: "Invoice";
  amount: Scalars["Float"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  discount: Scalars["Float"]["output"];
  dueDate: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  paid: Scalars["Boolean"]["output"];
  periodEnd: Scalars["DateTime"]["output"];
  periodStart: Scalars["DateTime"]["output"];
  promotionCode?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  subscription: PlanSubscription;
  totalAmount: Scalars["Float"]["output"];
};

export type ItemsQuantityInput = {
  id: Scalars["ID"]["input"];
  quantity: Scalars["Int"]["input"];
};

export type KanwatIntegrationSettings = {
  __typename?: "KanwatIntegrationSettings";
  provider: ProviderName;
  storeUrl?: Maybe<Scalars["String"]["output"]>;
};

export type KlaviyoAccessibleInfo = {
  __typename?: "KlaviyoAccessibleInfo";
  lists: Array<KlaviyoUserList>;
};

export type KlaviyoIntegrationSettings = {
  __typename?: "KlaviyoIntegrationSettings";
  customerSignUpFlowEnabled: Scalars["Boolean"]["output"];
  listId: Scalars["String"]["output"];
  orderCreatedFlowEnabled: Scalars["Boolean"]["output"];
  provider: ProviderName;
  publicId: Scalars["String"]["output"];
};

export type KlaviyoIntegrationSettingsInput = {
  customerSignUpFlowEnabled: Scalars["Boolean"]["input"];
  listId: Scalars["String"]["input"];
  orderCreatedFlowEnabled: Scalars["Boolean"]["input"];
  publicId: Scalars["String"]["input"];
};

export type KlaviyoScriptControls = {
  __typename?: "KlaviyoScriptControls";
  provider: ProviderName;
  publicKey: Scalars["String"]["output"];
};

export type KlaviyoUserList = {
  __typename?: "KlaviyoUserList";
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type LegalPageStoreByHandleInput = {
  handle?: InputMaybe<Scalars["String"]["input"]>;
  storeId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Link = {
  __typename?: "Link";
  resource?: Maybe<LinkResource>;
  resourceId?: Maybe<Scalars["String"]["output"]>;
  type: LinkType;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type LinkInput = {
  resourceId?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type LinkResource =
  | Product
  | ProductCollection
  | StoreLegalPage
  | StorePage
  | StoreStaticPage;

export enum LinkType {
  Collection = "Collection",
  ExternalLink = "ExternalLink",
  None = "None",
  Page = "Page",
  Product = "Product",
  Shop = "SHOP",
}

export type ListReviewsConnection = {
  __typename?: "ListReviewsConnection";
  count: Scalars["Int"]["output"];
  reviews: Array<ProductReview>;
};

export enum Locale {
  Ar = "ar",
  De = "de",
  En = "en",
  Fr = "fr",
  Tr = "tr",
}

export type Location = {
  __typename?: "Location";
  address: Scalars["String"]["output"];
  lat?: Maybe<Scalars["String"]["output"]>;
  long?: Maybe<Scalars["String"]["output"]>;
};

export type Logo = {
  __typename?: "Logo";
  altText?: Maybe<Scalars["String"]["output"]>;
  src: Scalars["String"]["output"];
};

export type LogoInput = {
  altText?: InputMaybe<Scalars["String"]["input"]>;
  src: Scalars["String"]["input"];
};

export enum LogoSize {
  Large = "LARGE",
  Medium = "MEDIUM",
  Small = "SMALL",
}

export type MailChimpIntegrationSettingsInput = {
  listId: Scalars["String"]["input"];
};

export type MailchimpAccessibleInfo = {
  __typename?: "MailchimpAccessibleInfo";
  isActive: Scalars["Boolean"]["output"];
  lists: Array<MailchimpListDetails>;
};

export type MailchimpConfigurationInput = {
  installationId: Scalars["ID"]["input"];
  listId?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type MailchimpIntegrationSettings = {
  __typename?: "MailchimpIntegrationSettings";
  listId?: Maybe<Scalars["String"]["output"]>;
  provider?: Maybe<ProviderName>;
};

export type MailchimpListDetails = {
  __typename?: "MailchimpListDetails";
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type MailchimpScriptControls = {
  __typename?: "MailchimpScriptControls";
  apiKey: Scalars["String"]["output"];
  listId: Scalars["String"]["output"];
  provider: ProviderName;
  server: Scalars["String"]["output"];
};

export type MaintenanceMode = {
  __typename?: "MaintenanceMode";
  isEnabled: Scalars["Boolean"]["output"];
  message?: Maybe<Scalars["String"]["output"]>;
};

export type MaintenanceModeInput = {
  isEnabled: Scalars["Boolean"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
};

export type ManagementActions = {
  __typename?: "ManagementActions";
  x?: Maybe<Scalars["String"]["output"]>;
};

export type ManagementQueries = {
  __typename?: "ManagementQueries";
  x?: Maybe<Scalars["String"]["output"]>;
};

export type MappedShippingAddress = {
  AddToCustomMapping: Scalars["Boolean"]["input"];
  bostaCityId: Scalars["String"]["input"];
};

export type MarkOrdersPaidInput = {
  orderIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
  paidAmount: MoneyInput;
  storeId: Scalars["ID"]["input"];
};

export type Material = {
  __typename?: "Material";
  description: Scalars["String"]["output"];
  dimensions: MaterialDimensions;
  id: Scalars["ID"]["output"];
  minPerOrder: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  price: Money;
  provider: WuiltShipmentProvider;
  translations: Array<Maybe<MaterialTranslations>>;
  type: MaterialType;
  weight: Scalars["Float"]["output"];
};

export type MaterialDimensions = {
  __typename?: "MaterialDimensions";
  height: Scalars["Float"]["output"];
  length: Scalars["Float"]["output"];
  width: Scalars["Float"]["output"];
};

export type MaterialDimensionsInput = {
  height: Scalars["Float"]["input"];
  length: Scalars["Float"]["input"];
  width: Scalars["Float"]["input"];
};

export type MaterialOrder = {
  __typename?: "MaterialOrder";
  createdAt: Scalars["DateTime"]["output"];
  createdBy: Person;
  id: Scalars["ID"]["output"];
  items: Array<OrderItem>;
  orderHistory?: Maybe<Array<Maybe<MaterialOrderHistory>>>;
  provider: WuiltShipmentProvider;
  reason?: Maybe<Scalars["String"]["output"]>;
  receipt: Receipt;
  serial?: Maybe<Scalars["String"]["output"]>;
  shippingAddress: PickUpLocation;
  status: MaterialOrderStatus;
  store: Store;
  trackingNumber?: Maybe<Scalars["String"]["output"]>;
  transactionId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type MaterialOrderConnection = {
  __typename?: "MaterialOrderConnection";
  edges?: Maybe<Array<Maybe<MaterialOrderEdge>>>;
  nodes?: Maybe<Array<Maybe<MaterialOrder>>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type MaterialOrderEdge = {
  __typename?: "MaterialOrderEdge";
  cursor: Scalars["ConnectionCursor"]["output"];
  node?: Maybe<MaterialOrder>;
};

export type MaterialOrderHistory = {
  __typename?: "MaterialOrderHistory";
  actor: Person;
  createdAt: Scalars["DateTime"]["output"];
  eventType: OrderEventType;
  id: Scalars["ID"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  orderId: Scalars["ID"]["output"];
  payload: MaterialOrder;
  reason?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export enum MaterialOrderSortByField {
  CreatedAt = "createdAt",
  Serial = "serial",
  UpdatedAt = "updatedAt",
}

export enum MaterialOrderStatus {
  Canceled = "CANCELED",
  Delivered = "DELIVERED",
  Pending = "PENDING",
  Processing = "PROCESSING",
  Rejected = "REJECTED",
}

export type MaterialOrdersConnectionInput = {
  after?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  before?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  first?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  last?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<MaterialOrderSortByField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type MaterialOrdersFilterInput = {
  date?: InputMaybe<DateInput>;
  orderIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  provider?: InputMaybe<WuiltShipmentProvider>;
  searchQuery?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<MaterialOrderStatus>;
};

export type MaterialTranslations = {
  __typename?: "MaterialTranslations";
  description: Scalars["String"]["output"];
  locale: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export enum MaterialType {
  Bag = "BAG",
  Box = "BOX",
  Bubbles = "BUBBLES",
  Flyer = "FLYER",
  Holder = "HOLDER",
  Seal = "SEAL",
  Sticker = "STICKER",
}

export enum MediaStatus {
  Failed = "FAILED",
  Processing = "PROCESSING",
  Ready = "READY",
  Uploaded = "UPLOADED",
}

export enum MenuItemType {
  Collection = "Collection",
  EmailAddress = "EmailAddress",
  ExternalLink = "ExternalLink",
  Folder = "Folder",
  LegalPage = "LegalPage",
  Page = "Page",
  PhoneNumber = "PhoneNumber",
  Product = "Product",
  StaticPage = "StaticPage",
}

export type MenuLink = {
  __typename?: "MenuLink";
  openInNewTab?: Maybe<Scalars["Boolean"]["output"]>;
  resource?: Maybe<LinkResource>;
  resourceId?: Maybe<Scalars["String"]["output"]>;
  staticPageType?: Maybe<StaticPageEnum>;
  type: MenuItemType;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type MenuLinkInput = {
  openInNewTab?: InputMaybe<Scalars["Boolean"]["input"]>;
  resourceId?: InputMaybe<Scalars["String"]["input"]>;
  staticPageType?: InputMaybe<StaticPageEnum>;
  type?: InputMaybe<MenuItemType>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type MobileAppData = {
  __typename?: "MobileAppData";
  deviceName: Scalars["String"]["output"];
  deviceToken: Scalars["String"]["output"];
  storeId: Scalars["ID"]["output"];
};

export type MobileAppDataInput = {
  deviceName: Scalars["String"]["input"];
  deviceToken: Scalars["String"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type Money = {
  __typename?: "Money";
  amount: Scalars["Float"]["output"];
  currencyCode: CurrencyCode;
};

export type MoneyInput = {
  amount: Scalars["Float"]["input"];
  currencyCode: CurrencyCode;
};

export type MoneyMetric = {
  __typename?: "MoneyMetric";
  growth: Scalars["Float"]["output"];
  total: Money;
};

export type MoreProducts = {
  __typename?: "MoreProducts";
  isRandom: Scalars["Boolean"]["output"];
  products: Array<Product>;
};

export type MoveInput = {
  id: Scalars["ID"]["input"];
  newPosition: Scalars["Int"]["input"];
};

export type MoveSectionItemsInput = {
  newIndex: Scalars["Int"]["input"];
  oldIndex: Scalars["Int"]["input"];
};

export type Mutation = {
  __typename?: "Mutation";
  addCustomerAddress: CustomerAddress;
  addMobileAppData: Scalars["Boolean"]["output"];
  addProductsToCollection: CollectionAddProductsPayload;
  adjustItemsQuantity: Order;
  adminActions?: Maybe<AdminActions>;
  archiveProducts: ArchiveProductsPayload;
  archiveShippingRate: ShippingRate;
  archiveShippingZone: ShippingZone;
  assignOptionsToProduct?: Maybe<Product>;
  bulkUpload?: Maybe<UserRequestStatus>;
  cancelItemsFulfillment: Order;
  cancelPayment?: Maybe<PaymentIntent>;
  cancelShipment?: Maybe<CancelShipmentResponse>;
  cancelSubscription: PlanSubscription;
  changeStoreCurrency?: Maybe<Store>;
  claimDomain: RegisteredDomain;
  completeClientDemo: User;
  confirmPaymentIntent?: Maybe<PaymentIntent>;
  createCollection: CreateCollectionPayload;
  createCustomer?: Maybe<CustomerUser>;
  createOrderHistory: OrderHistory;
  createPaymentSource: PaymentSource;
  createPaymentSourceSetup: CreatePaymentSourceSetupPayload;
  createProduct: CreateProductPayload;
  createProductAttribute?: Maybe<ProductAttribute>;
  createProductAttributeValue?: Maybe<ProductAttributeValue>;
  createProductMedia?: Maybe<CreateProductMediaPayload>;
  createProductOption?: Maybe<ProductOption>;
  createProductOptionValue?: Maybe<ProductOptionValue>;
  createProductVariant: ProductVariant;
  createShippingRate: ShippingRate;
  createShippingZone: ShippingZone;
  createShippingZoneV2: ShippingZone;
  createSiteSubscription: PlanSubscription;
  createStore: CreateStorePayload;
  createStoreDomain?: Maybe<Domain>;
  createStoreLegalPage?: Maybe<StoreLegalPage>;
  createStoreMenu: StoreMenu;
  createStorePage: StorePage;
  createStorePageCatalogSection?: Maybe<StorePageCatalogSection>;
  createStorePageSection: StorePageSection;
  createStoreRedirect: Scalars["Boolean"]["output"];
  createStoreRobotsTxt: Scalars["Boolean"]["output"];
  /** @deprecated Use createStoreLegalPage instead */
  createStoreStaticPage?: Maybe<StoreStaticPage>;
  createStoreStaticTemplate?: Maybe<StoreStaticTemplate>;
  createStoreSubscription: PlanSubscription;
  createSubscription: PlanSubscription;
  createSubscriptionByVodafoneHub: PlanSubscription;
  createUser: User;
  createUserByVendor: UserVendor;
  customerActions?: Maybe<CustomerActions>;
  deleteAccount: Scalars["Boolean"]["output"];
  deleteCustomerAddress: Scalars["Boolean"]["output"];
  deleteProduct: DeleteProductPayload;
  deleteProductAttribute?: Maybe<ProductAttribute>;
  deleteProductAttributeValue?: Maybe<ProductAttributeValue>;
  deleteProductMedia?: Maybe<DeleteProductMediaPayload>;
  deleteProductOption?: Maybe<DeleteProductResourcesPayload>;
  deleteProductOptionValue?: Maybe<DeleteProductResourcesPayload>;
  deleteProductVariant: ProductVariant;
  deleteReview: Scalars["Boolean"]["output"];
  deleteShippingRate?: Maybe<Scalars["ID"]["output"]>;
  deleteShippingZone?: Maybe<Scalars["ID"]["output"]>;
  deleteStoreLegalPage?: Maybe<Scalars["Boolean"]["output"]>;
  deleteStoreMenu: Scalars["Int"]["output"];
  deleteStorePage: Scalars["Boolean"]["output"];
  deleteStorePageCatalogSection?: Maybe<Scalars["Boolean"]["output"]>;
  deleteStorePageSection: Scalars["Boolean"]["output"];
  deleteStoreRedirect: Scalars["Boolean"]["output"];
  deleteStoreRobotsTxt: Scalars["Boolean"]["output"];
  /** @deprecated Use deleteStoreLegalPage instead */
  deleteStoreStaticPage?: Maybe<Scalars["Boolean"]["output"]>;
  deleteStoreStaticTemplate?: Maybe<Scalars["Boolean"]["output"]>;
  disableProductReviews: Scalars["Boolean"]["output"];
  disableStorePaymentMethod?: Maybe<Array<Maybe<StorePaymentMethod>>>;
  editReview: ProductReview;
  enableProductReviews: StoreReviewsSettings;
  enableStorePaymentMethod?: Maybe<Array<Maybe<StorePaymentMethod>>>;
  exportBulkTranslateCSV?: Maybe<ExportProductsPayload>;
  exportOrders?: Maybe<ExportOrdersPayload>;
  exportProducts?: Maybe<ExportProductsPayload>;
  forgetCustomerPassword: Scalars["Boolean"]["output"];
  generateApiKey: PermissionDetails;
  generateStoreLegalPage?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use generateStoreLegalPage instead */
  generateStoreStaticPage?: Maybe<Scalars["String"]["output"]>;
  identifyChat: ChatIdentificationPayload;
  importProducts: Scalars["Boolean"]["output"];
  installIntegration: IntegrationInstallation;
  installTheme: Template;
  makeDefaultAddress: CustomerAddress;
  manageCustomerReviews: Scalars["Boolean"]["output"];
  manageFatooraIntegration: IntegrationInstallation;
  markOrdersAsArchived: Array<Maybe<Order>>;
  markOrdersAsCanceled: Array<Maybe<Order>>;
  markOrdersAsPaid?: Maybe<Array<Maybe<OrderPayment>>>;
  markOrdersAsViewed: Array<Maybe<Order>>;
  markOrdersPaymentAsRefunded?: Maybe<Array<Maybe<OrderPayment>>>;
  purchaseDomainRequest: PurchaseDomainResult;
  refetchParagonDetails?: Maybe<Scalars["Boolean"]["output"]>;
  removeIntegrationInstallation: Scalars["ID"]["output"];
  removeItems: Order;
  removeLocalesFromStore: UpdateStorePayload;
  removeProductVariantImage: ProductVariant;
  removeProductsFromCollection: CollectionRemoveProductsPayload;
  removeStoreDomain?: Maybe<Scalars["Boolean"]["output"]>;
  reorderOptionsInProduct?: Maybe<ReorderOptionsInProductPayload>;
  reorderPageSections: StorePage;
  reorderProductMedia?: Maybe<ReorderProductMediaPayload>;
  reorderProductsInCollection: ReorderProductsInCollectionPayload;
  reorderSectionItems: StorePageSection;
  reorderValuesInOption?: Maybe<Scalars["Boolean"]["output"]>;
  requestOrderPaymentIntent?: Maybe<Scalars["Boolean"]["output"]>;
  resetCustomerPassword: Scalars["String"]["output"];
  restoreArchivedOrders: Array<Maybe<Order>>;
  restoreCanceledOrders: Array<Maybe<Order>>;
  restoreShippingRate: ShippingRate;
  restoreShippingZone: ShippingZone;
  revokeApiKey?: Maybe<Scalars["Boolean"]["output"]>;
  revokeUserStorePermissions?: Maybe<Scalars["Boolean"]["output"]>;
  setItemsFulfillment: Order;
  setStoreLocales: UpdateStorePayload;
  shipOrders: Array<ShipOrderResponse>;
  shipmentMaterial?: Maybe<ShipmentMaterialActions>;
  signup?: Maybe<Scalars["String"]["output"]>;
  stagedUploadsCreate?: Maybe<StagedUploadsCreatePayload>;
  submitReview: ProductReview;
  submitStoreContactForm: SubmitStoreContactFormPayload;
  translateStore: UpdateStorePayload;
  translateStoreAbout: UpdateStorePayload;
  translateStoreCheckout: UpdateStorePayload;
  translateStoreFonts: UpdateStorePayload;
  translateStoreFooter: UpdateStorePayload;
  translateStoreHomeAttributes?: Maybe<StoreHomeAttributesRows>;
  translateStoreHomeCollections?: Maybe<StoreHomeCollectionsRows>;
  translateStoreHomeHeroSlider: UpdateStorePayload;
  translateStoreHomeProducts?: Maybe<StoreHomeProductsRows>;
  translateStoreInfoBar: UpdateStorePayload;
  translateStoreLegalPage?: Maybe<StoreLegalPage>;
  translateStoreMenu: StoreMenu;
  translateStorePage: StorePage;
  translateStorePageCatalogSection?: Maybe<StorePageCatalogSection>;
  translateStorePageSection: StorePageSection;
  /** @deprecated Use translateStoreLegalPage instead */
  translateStoreStaticPage?: Maybe<StoreStaticPage>;
  updateApiKeyPermissions: PermissionDetails;
  updateBulkProductAttributeValuesTranslations?: Maybe<
    Array<Maybe<ProductAttributeValue>>
  >;
  updateBulkProductAttributesTranslations?: Maybe<
    Array<Maybe<ProductAttribute>>
  >;
  updateBulkProductOptionValuesTranslations?: Maybe<
    Array<Maybe<ProductOptionValue>>
  >;
  updateBulkProductOptionsTranslations?: Maybe<Array<Maybe<ProductOption>>>;
  updateBulkShippingRateTranslations?: Maybe<Array<Maybe<ShippingRate>>>;
  updateCategoryTranslations?: Maybe<Scalars["Int"]["output"]>;
  updateCollection: UpdateCollectionPayload;
  updateCollectionTranslations?: Maybe<ProductCollection>;
  updateCustomer?: Maybe<CustomerUser>;
  updateCustomerAddress: CustomerAddress;
  updateCustomerByAdmin?: Maybe<CustomerUser>;
  updateIntegrationInstallation: IntegrationInstallation;
  updateOrder: Order;
  updateOrderPaymentMethod?: Maybe<PaymentIntent>;
  updateOrderPromo: Order;
  updateProduct: UpdateProductPayload;
  updateProductAttribute?: Maybe<ProductAttribute>;
  updateProductAttributeValue?: Maybe<ProductAttributeValue>;
  updateProductDisplaySettings?: Maybe<ProductDisplaySettings>;
  updateProductReviewsSettings: StoreReviewsSettings;
  updateProductTranslations?: Maybe<Product>;
  updateProductVariant: ProductVariant;
  updateProductVariantImage: ProductVariant;
  updateProductsStatus: UpdateProductsStatusPayload;
  updateProductsVisibility: UpdateProductsVisibilityPayload;
  updateResourceShippingRateCost?: Maybe<ShippingRate>;
  updateShippingRate: ShippingRate;
  updateShippingRateTranslations?: Maybe<ShippingRate>;
  updateShippingZone: ShippingZone;
  updateShippingZoneV2: ShippingZone;
  updateStore: UpdateStorePayload;
  updateStoreAbout: UpdateStorePayload;
  updateStoreAnalyticalAccount: UpdateStorePayload;
  updateStoreCheckout: UpdateStorePayload;
  updateStoreColors: UpdateStorePayload;
  updateStoreCustomCities: Array<City>;
  updateStoreCustomCountries: Array<Country>;
  updateStoreCustomRegions: Array<Region>;
  updateStoreCustomStates: Array<State>;
  updateStoreDomain?: Maybe<Domain>;
  updateStoreFavIcon: UpdateStorePayload;
  updateStoreFonts: UpdateStorePayload;
  updateStoreFooter: UpdateStorePayload;
  updateStoreHero: UpdateStorePayload;
  updateStoreHomeAttributes?: Maybe<StoreHomeAttributesRows>;
  updateStoreHomeCollections?: Maybe<StoreHomeCollectionsRows>;
  updateStoreHomeProducts?: Maybe<StoreHomeProductsRows>;
  updateStoreInfoBar: UpdateStorePayload;
  updateStoreLegalPage?: Maybe<StoreLegalPage>;
  updateStoreLogo: UpdateStorePayload;
  updateStoreLogoSettings: UpdateStorePayload;
  updateStoreMenu: StoreMenu;
  updateStorePage: StorePage;
  updateStorePageCatalogSection?: Maybe<StorePageCatalogSection>;
  updateStorePageSection: StorePageSection;
  updateStoreRedirect: Scalars["Boolean"]["output"];
  updateStoreRobotsTxt: Scalars["Boolean"]["output"];
  updateStoreSocialMedia: UpdateStorePayload;
  /** @deprecated Use updateStoreLegalPage instead */
  updateStoreStaticPage?: Maybe<StoreStaticPage>;
  updateStoreStaticTemplate?: Maybe<StoreStaticTemplate>;
  updateUser: User;
  updateUserFlags?: Maybe<User>;
  updateUserQuestionnaireAnswers: Questionnaire;
  updateUserStorePermissions?: Maybe<Array<StoreUser>>;
  updateZonePaymentMethod?: Maybe<StoreZoneAllowedMethods>;
  uploadTranslations?: Maybe<UserRequestStatus>;
  verifyEmail: User;
  wuiltShipment?: Maybe<WuiltShipmentActions>;
};

export type MutationAddCustomerAddressArgs = {
  customerId: Scalars["ID"]["input"];
  input: AddCustomerAddressInput;
};

export type MutationAddMobileAppDataArgs = {
  input: MobileAppDataInput;
};

export type MutationAddProductsToCollectionArgs = {
  id: Scalars["ID"]["input"];
  productIds: Array<Scalars["ID"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type MutationAdjustItemsQuantityArgs = {
  input: AdjustItemsQuantityInput;
};

export type MutationAdminActionsArgs = {
  storeId: Scalars["ID"]["input"];
};

export type MutationArchiveProductsArgs = {
  input: ArchiveProductsInput;
};

export type MutationArchiveShippingRateArgs = {
  input: ArchiveShippingRateInput;
};

export type MutationArchiveShippingZoneArgs = {
  input: ArchiveShippingZoneInput;
};

export type MutationAssignOptionsToProductArgs = {
  input?: InputMaybe<AssignOptionsToProductInput>;
};

export type MutationBulkUploadArgs = {
  productsFileURL: Scalars["String"]["input"];
  shouldOverwrite?: InputMaybe<Scalars["Boolean"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type MutationCancelItemsFulfillmentArgs = {
  input: CancelFulfillInput;
};

export type MutationCancelPaymentArgs = {
  cartId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationCancelShipmentArgs = {
  input: CancelShipmentPayload;
};

export type MutationCancelSubscriptionArgs = {
  subscriptionId: Scalars["ID"]["input"];
};

export type MutationChangeStoreCurrencyArgs = {
  conversionRate?: InputMaybe<Scalars["Float"]["input"]>;
  newCurrencyCode?: InputMaybe<CurrencyCode>;
  precision?: InputMaybe<Scalars["Int"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type MutationClaimDomainArgs = {
  input: ClaimDomainInput;
};

export type MutationCompleteClientDemoArgs = {
  userId: Scalars["ID"]["input"];
};

export type MutationConfirmPaymentIntentArgs = {
  id: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationCreateCollectionArgs = {
  input: CollectionInput;
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateCustomerArgs = {
  input?: InputMaybe<CustomerUserWithoutPasswordInput>;
  storeId: Scalars["ID"]["input"];
};

export type MutationCreateOrderHistoryArgs = {
  input: OrderHistoryInput;
};

export type MutationCreatePaymentSourceArgs = {
  input: PaymentSourceInput;
};

export type MutationCreateProductArgs = {
  input: ProductInput;
  locale: Scalars["String"]["input"];
  media?: InputMaybe<Array<CreateMediaInput>>;
};

export type MutationCreateProductAttributeArgs = {
  input: CreateProductAttributeInput;
};

export type MutationCreateProductAttributeValueArgs = {
  input: CreateProductAttributeValueInput;
};

export type MutationCreateProductMediaArgs = {
  media: Array<CreateMediaInput>;
  productId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationCreateProductOptionArgs = {
  input?: InputMaybe<ProductOptionInput>;
};

export type MutationCreateProductOptionValueArgs = {
  input?: InputMaybe<ProductOptionValueInput>;
};

export type MutationCreateProductVariantArgs = {
  input: ProductVariantInput;
};

export type MutationCreateShippingRateArgs = {
  input: ShippingRateInput;
  locale: Scalars["String"]["input"];
};

export type MutationCreateShippingZoneArgs = {
  input: ShippingZoneInput;
};

export type MutationCreateShippingZoneV2Args = {
  input: ShippingZoneInput;
};

export type MutationCreateSiteSubscriptionArgs = {
  paymentSource: PaymentSourceInput;
  planId: Scalars["ID"]["input"];
  promotionCode?: InputMaybe<Scalars["String"]["input"]>;
  siteId: Scalars["ID"]["input"];
};

export type MutationCreateStoreArgs = {
  input: CreateStoreInput;
};

export type MutationCreateStoreDomainArgs = {
  input: DomainInput;
};

export type MutationCreateStoreLegalPageArgs = {
  input?: InputMaybe<StoreLegalPageInput>;
};

export type MutationCreateStoreMenuArgs = {
  input?: InputMaybe<CreateStoreMenuInput>;
  storeId: Scalars["ID"]["input"];
};

export type MutationCreateStorePageArgs = {
  input: CreateStorePageInput;
  storeId: Scalars["ID"]["input"];
};

export type MutationCreateStorePageCatalogSectionArgs = {
  input: CreateStorePageCatalogSectionInput;
  pageId?: InputMaybe<Scalars["ID"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type MutationCreateStorePageSectionArgs = {
  input: CreateStorePageSectionInput;
  pageId?: InputMaybe<Scalars["ID"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type MutationCreateStoreRedirectArgs = {
  newUrl: Scalars["String"]["input"];
  oldUrl: Scalars["String"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationCreateStoreRobotsTxtArgs = {
  content: Scalars["String"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationCreateStoreStaticPageArgs = {
  input?: InputMaybe<StoreStaticPageInput>;
};

export type MutationCreateStoreStaticTemplateArgs = {
  input?: InputMaybe<CreateTemplateInput>;
};

export type MutationCreateStoreSubscriptionArgs = {
  paymentSource: PaymentSourceInput;
  planId: Scalars["ID"]["input"];
  promotionCode?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type MutationCreateSubscriptionArgs = {
  paymentSource: PaymentSourceInput;
  planId: Scalars["ID"]["input"];
  promotionCode?: InputMaybe<Scalars["String"]["input"]>;
  resourceId: Scalars["ID"]["input"];
};

export type MutationCreateSubscriptionByVodafoneHubArgs = {
  paymentMethod?: InputMaybe<Scalars["String"]["input"]>;
  planId: Scalars["ID"]["input"];
  promotionCode?: InputMaybe<Scalars["String"]["input"]>;
  resourceId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
};

export type MutationCreateUserArgs = {
  input: UserInput;
};

export type MutationCreateUserByVendorArgs = {
  input: UserInput;
};

export type MutationCustomerActionsArgs = {
  storeId: Scalars["ID"]["input"];
};

export type MutationDeleteAccountArgs = {
  password: Scalars["String"]["input"];
};

export type MutationDeleteCustomerAddressArgs = {
  addressId: Scalars["ID"]["input"];
  customerId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationDeleteProductArgs = {
  id: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationDeleteProductAttributeArgs = {
  id: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationDeleteProductAttributeValueArgs = {
  attributeId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationDeleteProductMediaArgs = {
  mediaIds: Array<Scalars["ID"]["input"]>;
  productId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationDeleteProductOptionArgs = {
  optionId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationDeleteProductOptionValueArgs = {
  optionValueId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationDeleteProductVariantArgs = {
  id: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationDeleteReviewArgs = {
  reviewId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationDeleteShippingRateArgs = {
  input: DeleteShippingRateInput;
};

export type MutationDeleteShippingZoneArgs = {
  input: DeleteShippingZoneInput;
};

export type MutationDeleteStoreLegalPageArgs = {
  id: Scalars["ID"]["input"];
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationDeleteStoreMenuArgs = {
  id: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationDeleteStorePageArgs = {
  id: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationDeleteStorePageCatalogSectionArgs = {
  pageId?: InputMaybe<Scalars["ID"]["input"]>;
  sectionId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationDeleteStorePageSectionArgs = {
  id: Scalars["ID"]["input"];
  pageId?: InputMaybe<Scalars["ID"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type MutationDeleteStoreRedirectArgs = {
  redirectId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationDeleteStoreRobotsTxtArgs = {
  storeId: Scalars["ID"]["input"];
};

export type MutationDeleteStoreStaticPageArgs = {
  id: Scalars["ID"]["input"];
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationDeleteStoreStaticTemplateArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationDisableProductReviewsArgs = {
  storeId: Scalars["ID"]["input"];
};

export type MutationDisableStorePaymentMethodArgs = {
  paymentMethod?: InputMaybe<StorePaymentMethods>;
  storeId: Scalars["ID"]["input"];
};

export type MutationEditReviewArgs = {
  review: UpdateProductReviewInput;
  reviewId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationEnableProductReviewsArgs = {
  input: EnableProductReviewsInput;
};

export type MutationEnableStorePaymentMethodArgs = {
  paymentMethod?: InputMaybe<StorePaymentMethods>;
  storeId: Scalars["ID"]["input"];
  storeName?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationExportBulkTranslateCsvArgs = {
  ExportOptions: ExportOptions;
  email: Scalars["String"]["input"];
  locale: Scalars["String"]["input"];
  storeId: Scalars["ID"]["input"];
  storeName: Scalars["String"]["input"];
};

export type MutationExportOrdersArgs = {
  input: ExportOrdersInput;
};

export type MutationExportProductsArgs = {
  input: ExportProductsInput;
};

export type MutationForgetCustomerPasswordArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["ID"]["input"];
  storeUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationGenerateApiKeyArgs = {
  name: Scalars["String"]["input"];
  permissions: Array<StoreUserPermissionInput>;
  storeId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
};

export type MutationGenerateStoreLegalPageArgs = {
  input?: InputMaybe<GenerateStoreLegalPageInput>;
};

export type MutationGenerateStoreStaticPageArgs = {
  input?: InputMaybe<GenerateStoreStaticPageInput>;
};

export type MutationImportProductsArgs = {
  input?: InputMaybe<ImportProductsInput>;
};

export type MutationInstallIntegrationArgs = {
  input: IntegrationInstallationInput;
};

export type MutationInstallThemeArgs = {
  handle: Scalars["String"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationMakeDefaultAddressArgs = {
  addressId: Scalars["ID"]["input"];
  customerId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationManageCustomerReviewsArgs = {
  reviewIds: Array<Scalars["ID"]["input"]>;
  storeId: Scalars["ID"]["input"];
  updates: ReviewManagementInput;
};

export type MutationManageFatooraIntegrationArgs = {
  input: FatooraConfigurationInput;
};

export type MutationMarkOrdersAsArchivedArgs = {
  input: StoresOrdersInput;
};

export type MutationMarkOrdersAsCanceledArgs = {
  input: StoresOrdersInput;
};

export type MutationMarkOrdersAsPaidArgs = {
  input: MarkOrdersPaidInput;
};

export type MutationMarkOrdersAsViewedArgs = {
  input: StoresOrdersInput;
};

export type MutationMarkOrdersPaymentAsRefundedArgs = {
  input: StoresOrdersInput;
};

export type MutationPurchaseDomainRequestArgs = {
  input: PurchaseDomainInput;
};

export type MutationRefetchParagonDetailsArgs = {
  storeId: Scalars["ID"]["input"];
};

export type MutationRemoveIntegrationInstallationArgs = {
  id: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationRemoveItemsArgs = {
  input: RemoveItemsInput;
};

export type MutationRemoveLocalesFromStoreArgs = {
  id: Scalars["ID"]["input"];
  locales?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MutationRemoveProductVariantImageArgs = {
  id: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationRemoveProductsFromCollectionArgs = {
  id: Scalars["ID"]["input"];
  productIds: Array<Scalars["ID"]["input"]>;
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationRemoveStoreDomainArgs = {
  storeId: Scalars["ID"]["input"];
};

export type MutationReorderOptionsInProductArgs = {
  id: Scalars["ID"]["input"];
  moves: Array<MoveInput>;
  storeId: Scalars["ID"]["input"];
};

export type MutationReorderPageSectionsArgs = {
  id: Scalars["ID"]["input"];
  input: ReorderItemsInput;
  storeId: Scalars["ID"]["input"];
};

export type MutationReorderProductMediaArgs = {
  moves: Array<MoveInput>;
  productId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationReorderProductsInCollectionArgs = {
  id: Scalars["ID"]["input"];
  moves: Array<ProductMove>;
  storeId: Scalars["ID"]["input"];
};

export type MutationReorderSectionItemsArgs = {
  id: Scalars["ID"]["input"];
  input: ReorderItemsInput;
  storeId: Scalars["ID"]["input"];
};

export type MutationReorderValuesInOptionArgs = {
  id: Scalars["ID"]["input"];
  moves: Array<MoveInput>;
  storeId: Scalars["ID"]["input"];
};

export type MutationRequestOrderPaymentIntentArgs = {
  orderId: Scalars["ID"]["input"];
};

export type MutationResetCustomerPasswordArgs = {
  newPassword?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["ID"]["input"];
  token?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationRestoreArchivedOrdersArgs = {
  input: StoresOrdersInput;
};

export type MutationRestoreCanceledOrdersArgs = {
  input: StoresOrdersInput;
};

export type MutationRestoreShippingRateArgs = {
  input: ArchiveShippingRateInput;
};

export type MutationRestoreShippingZoneArgs = {
  input: ArchiveShippingZoneInput;
};

export type MutationRevokeApiKeyArgs = {
  apiKeyId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
};

export type MutationRevokeUserStorePermissionsArgs = {
  storeId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
};

export type MutationSetItemsFulfillmentArgs = {
  input: FulfillInput;
};

export type MutationSetStoreLocalesArgs = {
  id: Scalars["ID"]["input"];
  locales?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MutationShipOrdersArgs = {
  input: ShipOrdersInput;
};

export type MutationShipmentMaterialArgs = {
  storeId: Scalars["ID"]["input"];
};

export type MutationSignupArgs = {
  input: CustomerUserInput;
  storeId: Scalars["ID"]["input"];
};

export type MutationStagedUploadsCreateArgs = {
  input?: InputMaybe<Array<InputMaybe<StagedUploadCreateInput>>>;
};

export type MutationSubmitReviewArgs = {
  review: CreateProductReviewInput;
  storeId: Scalars["ID"]["input"];
};

export type MutationSubmitStoreContactFormArgs = {
  input: SubmitStoreContactFormInput;
};

export type MutationTranslateStoreArgs = {
  id: Scalars["ID"]["input"];
  translations?: InputMaybe<Array<InputMaybe<StoreTranslationInput>>>;
};

export type MutationTranslateStoreAboutArgs = {
  id: Scalars["ID"]["input"];
  translations?: InputMaybe<Array<InputMaybe<AboutTranslationInput>>>;
};

export type MutationTranslateStoreCheckoutArgs = {
  id: Scalars["ID"]["input"];
  translations?: InputMaybe<Array<InputMaybe<CheckoutTranslationInput>>>;
};

export type MutationTranslateStoreFontsArgs = {
  id: Scalars["ID"]["input"];
  translations?: InputMaybe<Array<InputMaybe<StoreFontsTranslationInput>>>;
};

export type MutationTranslateStoreFooterArgs = {
  id: Scalars["ID"]["input"];
  translations?: InputMaybe<Array<InputMaybe<FooterTranslationInput>>>;
};

export type MutationTranslateStoreHomeAttributesArgs = {
  input: Array<StoreHomeAttributesTranslationInput>;
  storeId: Scalars["ID"]["input"];
};

export type MutationTranslateStoreHomeCollectionsArgs = {
  input: Array<InputMaybe<StoreHomeCollectionsTranslationInput>>;
  storeId: Scalars["ID"]["input"];
};

export type MutationTranslateStoreHomeHeroSliderArgs = {
  id: Scalars["ID"]["input"];
  translations?: InputMaybe<
    Array<InputMaybe<StoreHomeHeroSliderTranslationInput>>
  >;
};

export type MutationTranslateStoreHomeProductsArgs = {
  input: Array<InputMaybe<StoreHomeProductsTranslationInput>>;
  storeId: Scalars["ID"]["input"];
};

export type MutationTranslateStoreInfoBarArgs = {
  id: Scalars["ID"]["input"];
  translations?: InputMaybe<Array<InputMaybe<InfoBarTranslationInput>>>;
};

export type MutationTranslateStoreLegalPageArgs = {
  id: Scalars["ID"]["input"];
  input?: InputMaybe<TranslateStoreLegalPageInput>;
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationTranslateStoreMenuArgs = {
  id: Scalars["ID"]["input"];
  items: Array<InputMaybe<StoreMenuItemTranslationInput>>;
  storeId: Scalars["ID"]["input"];
};

export type MutationTranslateStorePageArgs = {
  id: Scalars["ID"]["input"];
  input: StorePageTranslationInput;
  storeId: Scalars["ID"]["input"];
};

export type MutationTranslateStorePageCatalogSectionArgs = {
  input: StorePageCatalogSectionTranslationInput;
  sectionId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationTranslateStorePageSectionArgs = {
  id: Scalars["ID"]["input"];
  input: StorePageSectionTranslationInput;
  storeId: Scalars["ID"]["input"];
};

export type MutationTranslateStoreStaticPageArgs = {
  id: Scalars["ID"]["input"];
  input?: InputMaybe<TranslateStoreStaticPageInput>;
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationUpdateApiKeyPermissionsArgs = {
  apiKeyId: Scalars["ID"]["input"];
  newApiKeyName?: InputMaybe<Scalars["String"]["input"]>;
  newPermissions?: InputMaybe<Array<InputMaybe<StoreUserPermissionInput>>>;
  storeId: Scalars["ID"]["input"];
};

export type MutationUpdateBulkProductAttributeValuesTranslationsArgs = {
  storeId: Scalars["ID"]["input"];
  translations?: InputMaybe<Array<BulkProductAttributeValueTranslationInput>>;
};

export type MutationUpdateBulkProductAttributesTranslationsArgs = {
  storeId: Scalars["ID"]["input"];
  translations?: InputMaybe<Array<BulkProductAttributeTranslationInput>>;
};

export type MutationUpdateBulkProductOptionValuesTranslationsArgs = {
  storeId: Scalars["ID"]["input"];
  translations?: InputMaybe<Array<BulkProductOptionValueTranslationInput>>;
};

export type MutationUpdateBulkProductOptionsTranslationsArgs = {
  storeId: Scalars["ID"]["input"];
  translations?: InputMaybe<Array<BulkProductOptionTranslationInput>>;
};

export type MutationUpdateBulkShippingRateTranslationsArgs = {
  storeId: Scalars["ID"]["input"];
  translations: Array<BulkShippingRateTranslationInput>;
};

export type MutationUpdateCategoryTranslationsArgs = {
  storeId: Scalars["ID"]["input"];
  translations: Array<BulkCustomProductCategoryTranslationInput>;
};

export type MutationUpdateCollectionArgs = {
  input: CollectionInput;
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateCollectionTranslationsArgs = {
  id: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
  translations: Array<CollectionTranslationInput>;
};

export type MutationUpdateCustomerArgs = {
  customerId: Scalars["ID"]["input"];
  input?: InputMaybe<UpdateCustomerUserInput>;
  storeId: Scalars["ID"]["input"];
};

export type MutationUpdateCustomerAddressArgs = {
  addressId: Scalars["ID"]["input"];
  customerId: Scalars["ID"]["input"];
  input: UpdateCustomerAddressInput;
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationUpdateCustomerByAdminArgs = {
  customerId: Scalars["ID"]["input"];
  input?: InputMaybe<UpdateCustomerUserAdminInput>;
  storeId: Scalars["ID"]["input"];
};

export type MutationUpdateIntegrationInstallationArgs = {
  input: UpdateIntegrationInstallationInput;
};

export type MutationUpdateOrderArgs = {
  input: UpdateOrderInput;
};

export type MutationUpdateOrderPaymentMethodArgs = {
  orderId: Scalars["ID"]["input"];
  paymentMethod?: InputMaybe<PaymentMethodEnum>;
  storeId: Scalars["ID"]["input"];
};

export type MutationUpdateOrderPromoArgs = {
  input: UpdateOrderPromoInput;
};

export type MutationUpdateProductArgs = {
  input: ProductInput;
  locale: Scalars["String"]["input"];
};

export type MutationUpdateProductAttributeArgs = {
  input: UpdateProductAttributeInput;
};

export type MutationUpdateProductAttributeValueArgs = {
  input: UpdateProductAttributeValueInput;
};

export type MutationUpdateProductDisplaySettingsArgs = {
  input?: InputMaybe<ProductDisplaySettingsInput>;
  storeId: Scalars["ID"]["input"];
};

export type MutationUpdateProductReviewsSettingsArgs = {
  input?: InputMaybe<UpdateProductReviewsSettingsInput>;
};

export type MutationUpdateProductTranslationsArgs = {
  id: Scalars["ID"]["input"];
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
  translations: Array<ProductTranslationInput>;
};

export type MutationUpdateProductVariantArgs = {
  input: ProductVariantUpdateInput;
};

export type MutationUpdateProductVariantImageArgs = {
  input: ProductVariantImageInput;
};

export type MutationUpdateProductsStatusArgs = {
  input: UpdateProductsStatusInput;
};

export type MutationUpdateProductsVisibilityArgs = {
  input: UpdateProductsVisibilityInput;
  locale: Scalars["String"]["input"];
};

export type MutationUpdateResourceShippingRateCostArgs = {
  id: Scalars["ID"]["input"];
  input?: InputMaybe<Array<InputMaybe<UpdateShippingRateCostInput>>>;
  storeId: Scalars["ID"]["input"];
};

export type MutationUpdateShippingRateArgs = {
  input: UpdateShippingRateInput;
  locale: Scalars["String"]["input"];
};

export type MutationUpdateShippingRateTranslationsArgs = {
  id: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
  translations: Array<ShippingRateTranslationInput>;
};

export type MutationUpdateShippingZoneArgs = {
  input: UpdateShippingZoneInput;
};

export type MutationUpdateShippingZoneV2Args = {
  input: UpdateShippingZoneInput;
};

export type MutationUpdateStoreArgs = {
  input: UpdateStoreInput;
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateStoreAboutArgs = {
  about?: InputMaybe<AboutInput>;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateStoreAnalyticalAccountArgs = {
  analyticalAccount: AnalyticalAccountInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateStoreCheckoutArgs = {
  checkout: StoreCheckoutInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateStoreColorsArgs = {
  colors: ThemeColorsInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateStoreCustomCitiesArgs = {
  input: UpdateStoreCustomCitiesInput;
};

export type MutationUpdateStoreCustomCountriesArgs = {
  input: UpdateStoreCustomCountriesInput;
};

export type MutationUpdateStoreCustomRegionsArgs = {
  input: UpdateStoreCustomRegionsInput;
};

export type MutationUpdateStoreCustomStatesArgs = {
  input: UpdateStoreCustomStatesInput;
};

export type MutationUpdateStoreDomainArgs = {
  input: DomainInput;
};

export type MutationUpdateStoreFavIconArgs = {
  id: Scalars["ID"]["input"];
  image?: InputMaybe<ImageInput>;
};

export type MutationUpdateStoreFontsArgs = {
  fonts?: InputMaybe<Array<InputMaybe<FontInput>>>;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateStoreFooterArgs = {
  footer?: InputMaybe<FooterInput>;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateStoreHeroArgs = {
  heroSlider: Array<InputMaybe<StoreHomeHeroInput>>;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateStoreHomeAttributesArgs = {
  input: Array<StoreHomeAttributesInput>;
  storeId: Scalars["ID"]["input"];
};

export type MutationUpdateStoreHomeCollectionsArgs = {
  input: Array<StoreHomeCollectionsInput>;
  storeId: Scalars["ID"]["input"];
};

export type MutationUpdateStoreHomeProductsArgs = {
  input: Array<StoreHomeProductsInput>;
  storeId: Scalars["ID"]["input"];
};

export type MutationUpdateStoreInfoBarArgs = {
  id: Scalars["ID"]["input"];
  infoBar: InfoBarInput;
};

export type MutationUpdateStoreLegalPageArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  input?: InputMaybe<UpdateStoreLegalPageInput>;
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationUpdateStoreLogoArgs = {
  id: Scalars["ID"]["input"];
  image?: InputMaybe<ImageInput>;
};

export type MutationUpdateStoreLogoSettingsArgs = {
  id: Scalars["ID"]["input"];
  size?: InputMaybe<LogoSize>;
};

export type MutationUpdateStoreMenuArgs = {
  id: Scalars["ID"]["input"];
  input?: InputMaybe<UpdateStoreMenuInput>;
  storeId: Scalars["ID"]["input"];
};

export type MutationUpdateStorePageArgs = {
  id: Scalars["ID"]["input"];
  input: UpdateStorePageInput;
  storeId: Scalars["ID"]["input"];
};

export type MutationUpdateStorePageCatalogSectionArgs = {
  input: UpdateStorePageCatalogSectionInput;
  sectionId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationUpdateStorePageSectionArgs = {
  id: Scalars["ID"]["input"];
  input: UpdateStorePageSectionInput;
  storeId: Scalars["ID"]["input"];
};

export type MutationUpdateStoreRedirectArgs = {
  newUrl: Scalars["String"]["input"];
  oldUrl: Scalars["String"]["input"];
  redirectId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationUpdateStoreRobotsTxtArgs = {
  content: Scalars["String"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationUpdateStoreSocialMediaArgs = {
  id: Scalars["ID"]["input"];
  socialLinks?: InputMaybe<Array<InputMaybe<SocialLinkInput>>>;
};

export type MutationUpdateStoreStaticPageArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  input?: InputMaybe<UpdateStoreStaticPageInput>;
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationUpdateStoreStaticTemplateArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  input?: InputMaybe<UpdateTemplateInput>;
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationUpdateUserArgs = {
  input: UserInput;
};

export type MutationUpdateUserFlagsArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  flags?: InputMaybe<Array<InputMaybe<UserFlagInput>>>;
};

export type MutationUpdateUserQuestionnaireAnswersArgs = {
  done?: InputMaybe<Scalars["Boolean"]["input"]>;
  questionnaireAnswers?: InputMaybe<QuestionnaireInput>;
  userId: Scalars["ID"]["input"];
};

export type MutationUpdateUserStorePermissionsArgs = {
  permissions?: InputMaybe<UserStorePermissionInput>;
  storeId: Scalars["ID"]["input"];
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationUpdateZonePaymentMethodArgs = {
  paymentMethod: PaymentMethodInput;
  shippingZoneId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type MutationUploadTranslationsArgs = {
  locale: Locale;
  storeId: Scalars["ID"]["input"];
  translationSheetUrl: Scalars["String"]["input"];
};

export type MutationVerifyEmailArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationWuiltShipmentArgs = {
  storeId: Scalars["ID"]["input"];
};

export type MylerzAdditionalInfo = {
  allowToOpenPackage?: InputMaybe<Scalars["Boolean"]["input"]>;
  pickUpLocation: Scalars["ID"]["input"];
  pickupDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export enum MylerzStatusEnum {
  CancelledByShipper = "CANCELLED_BY_SHIPPER",
  ConfirmRejectedReason = "CONFIRM_REJECTED_REASON",
  Damaged = "DAMAGED",
  Delivered = "DELIVERED",
  InTransitReady = "IN_TRANSIT_READY",
  InTransitReceivedByMyler = "IN_TRANSIT_RECEIVED_BY_MYLER",
  InTransitReceivedInHub = "IN_TRANSIT_RECEIVED_IN_HUB",
  Lost = "LOST",
  NotPickedYetReady = "NOT_PICKED_YET_READY",
  OutForDelivery = "OUT_FOR_DELIVERY",
  Picked = "PICKED",
  RejectedByShipper = "REJECTED_BY_SHIPPER",
  Rescheduled = "RESCHEDULED",
  ReturnedToShipper = "RETURNED_TO_SHIPPER",
  ReAttemptDelivery = "RE_ATTEMPT_DELIVERY",
  ReAttemptDeliveryReady = "RE_ATTEMPT_DELIVERY_READY",
  ReAttemptPickup = "RE_ATTEMPT_PICKUP",
  SentTo = "SENT_TO",
}

export enum NameInputOptions {
  FirstAndLast = "FIRST_AND_LAST",
  FirstOnly = "FIRST_ONLY",
}

export type NewPlanInput = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  interval: PricingInterval;
  name?: InputMaybe<Scalars["String"]["input"]>;
  price: MoneyInput;
  resource?: InputMaybe<PlanResourceType>;
  stripe_plan_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type NoAccessibleInfo = {
  __typename?: "NoAccessibleInfo";
  provider?: Maybe<ProviderName>;
};

export type NoScriptControls = {
  __typename?: "NoScriptControls";
  provider: ProviderName;
};

export type Node = {
  id: Scalars["ID"]["output"];
};

export type NodeEdge = {
  cursor: Scalars["ConnectionCursor"]["output"];
  node?: Maybe<Node>;
};

export enum OperationType {
  Credit = "CREDIT",
  Debit = "DEBIT",
}

export type OptimonkIntegrationSettings = {
  __typename?: "OptimonkIntegrationSettings";
  accountId: Scalars["ID"]["output"];
  provider: ProviderName;
};

export type OptimonkIntegrationSettingsInput = {
  accountId: Scalars["ID"]["input"];
};

export type OptimonkScriptControls = {
  __typename?: "OptimonkScriptControls";
  accountId: Scalars["String"]["output"];
  provider: ProviderName;
};

export type OptionProductsCount = {
  __typename?: "OptionProductsCount";
  count?: Maybe<Scalars["Int"]["output"]>;
  optionValue?: Maybe<ProductOptionValue>;
  optionValueId?: Maybe<Scalars["ID"]["output"]>;
};

export type OptionsToAssignInput = {
  optionId: Scalars["ID"]["input"];
  valueId: Scalars["ID"]["input"];
};

export type Order = Node & {
  __typename?: "Order";
  billingAddress: Address;
  cartId?: Maybe<Scalars["ID"]["output"]>;
  cod?: Maybe<CodDetails>;
  createdAt: Scalars["DateTime"]["output"];
  customer: Customer;
  customerId: Scalars["ID"]["output"];
  discounts?: Maybe<Array<Maybe<DiscountSnapshot>>>;
  errors?: Maybe<Array<Maybe<CheckoutError>>>;
  externalActionMessage?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  fulfillmentStatus?: Maybe<FulfillStatusEnum>;
  id: Scalars["ID"]["output"];
  isArchived: Scalars["Boolean"]["output"];
  isCanceled: Scalars["Boolean"]["output"];
  isViewed: Scalars["Boolean"]["output"];
  items: Array<Maybe<BaseItem>>;
  notes?: Maybe<Scalars["String"]["output"]>;
  orderHistory?: Maybe<Array<Maybe<OrderHistory>>>;
  orderSerial?: Maybe<Scalars["ID"]["output"]>;
  packagingDetails?: Maybe<PackagingDetails>;
  paidAmount?: Maybe<Money>;
  paymentIntent?: Maybe<PaymentIntent>;
  paymentMethod?: Maybe<PaymentMethodEnum>;
  paymentStatus?: Maybe<OrderPaymentStatusEnum>;
  promoCodeSnapshot?: Maybe<PromoCodeSnapshot>;
  receipt?: Maybe<OrderReceipt>;
  refCode?: Maybe<Scalars["String"]["output"]>;
  returnShipmentDetails?: Maybe<ShipmentDetails>;
  reviews: Array<ProductReview>;
  shipmentDetails?: Maybe<ShipmentDetails>;
  shippingAddress: Address;
  shippingDetails?: Maybe<OrderShippingDetails>;
  shippingRate?: Maybe<ShippingRate>;
  shippingRateCost: Money;
  shippingRateName?: Maybe<Scalars["String"]["output"]>;
  shippingStatus?: Maybe<ShippingStatus>;
  status?: Maybe<OrderStatusEnum>;
  store: Store;
  storeId: Scalars["ID"]["output"];
  subtotal?: Maybe<Money>;
  totalPrice?: Maybe<Money>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  wuiltShipmentProvider?: Maybe<WuiltShipmentProvider>;
};

export type OrderActions = {
  __typename?: "OrderActions";
  createOrder: Cart;
};

export type OrderActionsCreateOrderArgs = {
  cart: AdminCart;
};

export type OrderAggregatedAnalyticsReading = {
  __typename?: "OrderAggregatedAnalyticsReading";
  averageProfitPerOrder?: Maybe<Scalars["Float"]["output"]>;
  ordersCount?: Maybe<Scalars["Int"]["output"]>;
  readingIndex?: Maybe<Scalars["Int"]["output"]>;
  sales?: Maybe<Scalars["Float"]["output"]>;
};

export type OrderAnalytics = {
  __typename?: "OrderAnalytics";
  aggregatedAnalytics?: Maybe<Array<Maybe<OrderAggregatedAnalyticsReading>>>;
  topSellingProducts?: Maybe<Array<Maybe<TopSellingProduct>>>;
};

export type OrderAnalyticsAggregatedAnalyticsArgs = {
  aggregatedTimePeriod: Scalars["Int"]["input"];
  endAt: Scalars["DateTime"]["input"];
  startAt: Scalars["DateTime"]["input"];
};

export type OrderAnalyticsTopSellingProductsArgs = {
  during: Period;
  queryOptions?: InputMaybe<TopSellingProductsOptions>;
};

export type OrderConfirmationNotes = {
  __typename?: "OrderConfirmationNotes";
  content: Scalars["String"]["output"];
  enabled: Scalars["Boolean"]["output"];
};

export type OrderConfirmationNotesInput = {
  content: Scalars["String"]["input"];
  enabled: Scalars["Boolean"]["input"];
};

export type OrderConnection = {
  __typename?: "OrderConnection";
  edges?: Maybe<Array<Maybe<OrderEdge>>>;
  nodes?: Maybe<Array<Maybe<Order>>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type OrderEdge = {
  __typename?: "OrderEdge";
  cursor: Scalars["ConnectionCursor"]["output"];
  node?: Maybe<Order>;
};

export enum OrderErrorEnum {
  CheckoutTimeout = "CHECKOUT_TIMEOUT",
  PaymentFailed = "PAYMENT_FAILED",
  QuantityNotAvailable = "QUANTITY_NOT_AVAILABLE",
}

export enum OrderEventType {
  Canceled = "CANCELED",
  Created = "CREATED",
  Delivered = "DELIVERED",
  Proceed = "PROCEED",
  Rejected = "REJECTED",
}

export type OrderHistory = {
  __typename?: "OrderHistory";
  eventType: OrderHistoryTypes;
  id: Scalars["ID"]["output"];
  orderId: Scalars["ID"]["output"];
  payload: OrderHistoryPayload;
  shippingPayload?: Maybe<ShippingPayload>;
  storeId: Scalars["ID"]["output"];
  timestamp: Scalars["DateTime"]["output"];
  user?: Maybe<UserInfo>;
};

export type OrderHistoryInput = {
  comment?: InputMaybe<Scalars["String"]["input"]>;
  eventType: OrderHistoryTypes;
  orderId: Scalars["ID"]["input"];
  payload?: InputMaybe<CreateOrderInput>;
  storeId: Scalars["ID"]["input"];
};

export type OrderHistoryPayload = Comment | Order;

export enum OrderHistoryTypes {
  ItemsFulfilled = "ItemsFulfilled",
  ItemsFulfillmentCanceled = "ItemsFulfillment_canceled",
  ItemsFulfillmentUpdated = "ItemsFulfillment_updated",
  ItemsQuantityUpdated = "ItemsQuantity_updated",
  ItemsRemoved = "ItemsRemoved",
  ItemsUnFulfilled = "ItemsUnFulfilled",
  OrderArchived = "OrderArchived",
  OrderCanceled = "OrderCanceled",
  OrderComment = "OrderComment",
  OrderCreated = "OrderCreated",
  OrderPaid = "OrderPaid",
  OrderRefunded = "OrderRefunded",
  OrderUnArchive = "OrderUnArchive",
  OrderUncanceled = "OrderUncanceled",
  OrderUpdated = "OrderUpdated",
  OrderViewed = "OrderViewed",
  OrderProviderUpdated = "Order_Provider_Updated",
  ShippingStatusUpdated = "ShippingStatus_updated",
}

export type OrderItem = {
  __typename?: "OrderItem";
  item: Material;
  quantity: Scalars["Int"]["output"];
};

export type OrderItemDiscountDetails = {
  __typename?: "OrderItemDiscountDetails";
  data?: Maybe<Discount>;
  discountSource: DiscountSource;
  info: OrderItemDiscountDetailsInfo;
  perItem: Money;
  quantity: Scalars["Int"]["output"];
  total: Money;
};

export type OrderItemDiscountDetailsInfo = {
  __typename?: "OrderItemDiscountDetailsInfo";
  fixed?: Maybe<Money>;
  percentage?: Maybe<Scalars["Float"]["output"]>;
};

export type OrderItemDiscountDetailsInfoInput = {
  fixed?: InputMaybe<MoneyInput>;
  percentage?: InputMaybe<Scalars["Float"]["input"]>;
};

export type OrderItemDiscountDetailsInput = {
  discountSource: DiscountSource;
  info: OrderItemDiscountDetailsInfoInput;
  perItem: MoneyInput;
  quantity: Scalars["Int"]["input"];
  total: MoneyInput;
};

export type OrderItemInput = {
  materialId: Scalars["ID"]["input"];
  quantity: Scalars["Int"]["input"];
};

export enum OrderItemSourceType {
  Integration = "INTEGRATION",
  Manual = "MANUAL",
}

export type OrderMaterialShippingDetailsInput = {
  address: Scalars["String"]["input"];
  city: Scalars["String"]["input"];
  cityId: Scalars["ID"]["input"];
  country: Scalars["String"]["input"];
  countryId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  location: GeoLocationInput;
  name: Scalars["String"]["input"];
  state: Scalars["String"]["input"];
  stateId: Scalars["ID"]["input"];
};

export type OrderPayment = {
  __typename?: "OrderPayment";
  id: Scalars["ID"]["output"];
  payment?: Maybe<OrderPayment>;
  paymentIntent?: Maybe<Array<Maybe<PaymentIntent>>>;
  paymentTransactions?: Maybe<Array<Maybe<PaymentTransaction>>>;
  status?: Maybe<OrderPaymentStatusEnum>;
};

export enum OrderPaymentStatusEnum {
  Failed = "FAILED",
  Paid = "PAID",
  Pending = "PENDING",
  Refunded = "REFUNDED",
}

export type OrderQueries = {
  __typename?: "OrderQueries";
  validateAdminOrder: ValidatedAdminCart;
};

export type OrderQueriesValidateAdminOrderArgs = {
  cart: ValidateAdminCartInput;
};

export type OrderReceipt = {
  __typename?: "OrderReceipt";
  automaticDiscount?: Maybe<Money>;
  discount?: Maybe<Money>;
  shipping?: Maybe<Money>;
  subtotal?: Maybe<Money>;
  tax?: Maybe<Money>;
  total?: Maybe<Money>;
};

export type OrderSelectedOption = {
  __typename?: "OrderSelectedOption";
  name: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type OrderSelectedOptionInput = {
  name: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type OrderShipment = {
  __typename?: "OrderShipment";
  cod?: Maybe<CodDetails>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  order: Order;
  orderSerialNumber: Scalars["String"]["output"];
  packagingDetails?: Maybe<PackagingDetails>;
  provider: WuiltShipmentProvider;
  receipt: WalletReceipt;
  shipmentDetails?: Maybe<ShipmentDetails>;
  shipmentStatus: GeneralShipmentStatus;
  shippingCost: Money;
  transactionId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type OrderShipmentConnection = {
  __typename?: "OrderShipmentConnection";
  nodes?: Maybe<Array<Maybe<OrderShipment>>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export enum OrderShipmentSortByField {
  CreatedAt = "createdAt",
  Serial = "serial",
  UpdatedAt = "updatedAt",
}

export type OrderShipmentsConnectionInput = {
  after?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  before?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  first?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  last?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<OrderShipmentSortByField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type OrderShipmentsFilterInput = {
  codStatus?: InputMaybe<CodStatus>;
  date?: InputMaybe<DateInput>;
  orderIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  provider?: InputMaybe<WuiltShipmentProvider>;
  serial?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<GeneralShipmentStatus>;
};

export type OrderShippingDetails = {
  __typename?: "OrderShippingDetails";
  airWayBill?: Maybe<Scalars["String"]["output"]>;
  deliveryCompany?: Maybe<Scalars["String"]["output"]>;
  orderTrackingNumber?: Maybe<Scalars["String"]["output"]>;
  shippedWith?: Maybe<Integration>;
  shippingStatus?: Maybe<Scalars["String"]["output"]>;
  timestamp?: Maybe<Scalars["DateTime"]["output"]>;
  trackingURL?: Maybe<Scalars["String"]["output"]>;
};

export enum OrderSortByField {
  CreatedAt = "createdAt",
  Id = "id",
  OrderSerial = "orderSerial",
  UpdatedAt = "updatedAt",
}

export enum OrderStatusEnum {
  Failed = "FAILED",
  Processing = "PROCESSING",
  Successful = "SUCCESSFUL",
}

export type OrderUpdateInput = {
  billingAddress?: InputMaybe<AddressInput>;
  customer?: InputMaybe<CreateOrderCustomerInput>;
  newItems?: InputMaybe<Array<InputMaybe<CreateItemInput>>>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  shippingAddress?: InputMaybe<AddressInput>;
  shippingRateId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type OrdersConnectionInput = {
  after?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  before?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  first?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  last?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<OrderSortByField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type OrdersFilterInput = {
  date?: InputMaybe<DateInput>;
  fulfillmentStatus?: InputMaybe<FulfillStatusEnum>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCanceled?: InputMaybe<Scalars["Boolean"]["input"]>;
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
  orderIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  paymentStatus?: InputMaybe<PaymentStatusEnum>;
  searchQuery?: InputMaybe<Scalars["String"]["input"]>;
};

export type OrdersToShip = {
  orderId: Scalars["ID"]["input"];
  providerAdditionalInfo: ProviderAdditionalInfo;
};

export type OtoIntegrationSettings = {
  __typename?: "OtoIntegrationSettings";
  authorizationKey: Scalars["String"]["output"];
  provider: ProviderName;
};

export type OtoMappedState = {
  __typename?: "OtoMappedState";
  provider?: Maybe<ShippingProviders>;
};

export type PackageDetails = {
  size: BostaPackageSizes;
  type: BostaPackageTypes;
};

export type PackagingDetails = {
  __typename?: "PackagingDetails";
  extraVolumetricWeight?: Maybe<Scalars["Float"]["output"]>;
  extraWeight?: Maybe<Scalars["Float"]["output"]>;
  shippingCostDetails?: Maybe<ShippingCostDetails>;
};

export type PageEdge = {
  __typename?: "PageEdge";
  cursor: Scalars["ConnectionCursor"]["output"];
  node: StorePage;
};

export type PageFilterInput = {
  search?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<PageStatusEnum>;
};

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["ConnectionCursor"]["output"]>;
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  startCursor?: Maybe<Scalars["ConnectionCursor"]["output"]>;
};

export type PageSection = {
  __typename?: "PageSection";
  id: Scalars["ID"]["output"];
  sectionType: SectionTypeEnum;
};

export type PageSectionInput = {
  id: Scalars["ID"]["input"];
  sectionType: SectionTypeEnum;
};

export type PageSeo = {
  __typename?: "PageSeo";
  description?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export enum PageSortByFields {
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
}

export enum PageStatusEnum {
  Draft = "DRAFT",
  Published = "PUBLISHED",
}

export type PagesConnection = {
  __typename?: "PagesConnection";
  edges?: Maybe<Array<Maybe<PageEdge>>>;
  nodes?: Maybe<Array<StorePage>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PagesConnectionInput = {
  first?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<PageSortByFields>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type ParagonIntegration = {
  __typename?: "ParagonIntegration";
  credentialId?: Maybe<Scalars["String"]["output"]>;
  enabled: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  providerId?: Maybe<Scalars["String"]["output"]>;
  sharedSettings?: Maybe<Array<Maybe<ParagonIntegrationSharedSetting>>>;
  workflows?: Maybe<Array<Maybe<ParagonIntegrationWorkflow>>>;
};

export type ParagonIntegrationSharedSetting = {
  __typename?: "ParagonIntegrationSharedSetting";
  name: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type ParagonIntegrationWorkflow = {
  __typename?: "ParagonIntegrationWorkflow";
  enabled: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  settings?: Maybe<Array<Maybe<ParagonIntegrationWorkflowSetting>>>;
};

export type ParagonIntegrationWorkflowSetting = {
  __typename?: "ParagonIntegrationWorkflowSetting";
  name: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type ParagonStoreIntegrations = Node & {
  __typename?: "ParagonStoreIntegrations";
  id: Scalars["ID"]["output"];
  integrations?: Maybe<Array<Maybe<ParagonIntegration>>>;
  storeId?: Maybe<Scalars["ID"]["output"]>;
};

export type Params = {
  __typename?: "Params";
  availableCurrency?: Maybe<Scalars["String"]["output"]>;
  availablePrice?: Maybe<Scalars["Float"]["output"]>;
  availableQuantity?: Maybe<Scalars["Int"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  requestedCurrency?: Maybe<Scalars["String"]["output"]>;
  requestedPrice?: Maybe<Scalars["Float"]["output"]>;
  requestedQuantity?: Maybe<Scalars["Int"]["output"]>;
};

export type PaymentAccount = {
  __typename?: "PaymentAccount";
  accessToken?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  liveApiKey?: Maybe<Scalars["String"]["output"]>;
  moneyHashAccountEmbed?: Maybe<Scalars["String"]["output"]>;
  moneyHashId?: Maybe<Scalars["String"]["output"]>;
  testApiKey?: Maybe<Scalars["String"]["output"]>;
};

export enum PaymentCollectionMethod {
  Alinma = "ALINMA",
  AlRajhi = "AL_RAJHI",
  Automatic = "AUTOMATIC",
  CashOnHand = "CASH_ON_HAND",
  Chargedesk = "CHARGEDESK",
  Cib = "CIB",
  Free = "FREE",
  Paymob = "PAYMOB",
  SaudieInitiative = "SAUDIE_INITIATIVE",
  Tap = "TAP",
}

export type PaymentIntent = Node & {
  __typename?: "PaymentIntent";
  cartId?: Maybe<Scalars["ID"]["output"]>;
  embedUrl?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  key?: Maybe<Scalars["String"]["output"]>;
  paymentMethod?: Maybe<PaymentMethodEnum>;
  paymentMethods?: Maybe<Array<Maybe<PaymentMethodEnum>>>;
  status?: Maybe<PaymentIntentStatusEnum>;
};

export enum PaymentIntentStatusEnum {
  Cancelled = "CANCELLED",
  Done = "DONE",
  Expired = "EXPIRED",
  Failed = "FAILED",
  Pending = "PENDING",
  PendingExternalAction = "PENDING_EXTERNAL_ACTION",
  Successful = "SUCCESSFUL",
  Unprocessed = "UNPROCESSED",
}

export type PaymentMethod = {
  __typename?: "PaymentMethod";
  gatewayId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  provider?: Maybe<StorePaymentProvider>;
};

export enum PaymentMethodEnum {
  AmanMasary = "AMAN_MASARY",
  ApplePay = "APPLE_PAY",
  BankInstallments = "BANK_INSTALLMENTS",
  BankTransfers = "BANK_TRANSFERS",
  Benefit = "BENEFIT",
  Card = "CARD",
  CashCollection = "CASH_COLLECTION",
  CashOnDelivary = "CASH_ON_DELIVARY",
  CashOnDelivery = "CASH_ON_DELIVERY",
  CashOutlet = "CASH_OUTLET",
  CryptoWallet = "CRYPTO_WALLET",
  Efawateercom = "EFAWATEERCOM",
  Forsa = "FORSA",
  Getgo = "GETGO",
  GooglePay = "GOOGLE_PAY",
  Knet = "KNET",
  Mada = "MADA",
  MobileMoney = "MOBILE_MONEY",
  MobileWallet = "MOBILE_WALLET",
  Mokafaa = "MOKAFAA",
  MPesa = "M_PESA",
  Naps = "NAPS",
  None = "NONE",
  Omannet = "OMANNET",
  OnlinePayment = "ONLINE_PAYMENT",
  Paypal = "PAYPAL",
  PayAtFawry = "PAY_AT_FAWRY",
  Qpay = "QPAY",
  Razer = "RAZER",
  ReferenceNumber = "REFERENCE_NUMBER",
  Sadad = "SADAD",
  SamsungPay = "SAMSUNG_PAY",
  SelfserveWallet = "SELFSERVE_WALLET",
  Shahry = "SHAHRY",
  Souhoola = "SOUHOOLA",
  Stc = "STC",
  Sympl = "SYMPL",
  Tabby = "TABBY",
  Tamara = "TAMARA",
  Ussd = "USSD",
  Valu = "VALU",
}

export type PaymentMethodInput = {
  enabled: Scalars["Boolean"]["input"];
  type: StorePaymentMethods;
};

export enum PaymentProvider {
  Internal = "INTERNAL",
  Stripe = "STRIPE",
  VodafoneHub = "VODAFONE_HUB",
}

export type PaymentSetupInput = {
  provider: WalletPaymentProvider;
  walletId: Scalars["ID"]["input"];
};

export type PaymentSource = Node & {
  __typename?: "PaymentSource";
  card?: Maybe<Card>;
  collectionMethod: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  provider: PaymentProvider;
  providerId: Scalars["ID"]["output"];
  type: PaymentSourceType;
};

export type PaymentSourceInput = {
  card?: InputMaybe<CardSourceInput>;
  externalId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  type?: InputMaybe<PaymentSourceType>;
};

export enum PaymentSourceType {
  BankDeposit = "BANK_DEPOSIT",
  Card = "CARD",
  Cash = "CASH",
  Initiative = "INITIATIVE",
  PaymentLink = "PAYMENT_LINK",
  VodafoneHub = "VODAFONE_HUB",
}

export enum PaymentStatusEnum {
  Paid = "PAID",
  Pending = "PENDING",
  Refunded = "REFUNDED",
}

export type PaymentTransaction = {
  __typename?: "PaymentTransaction";
  id: Scalars["ID"]["output"];
};

export type Payout = {
  __typename?: "Payout";
  actor: Person;
  amount: Scalars["Float"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  currencyCode: CurrencyCode;
  fees: Scalars["Float"]["output"];
  finalAmount: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  payoutSettings: PayoutSettings;
  reason?: Maybe<Scalars["String"]["output"]>;
  serial: Scalars["Int"]["output"];
  status: PayoutStatus;
  statusChangedBy?: Maybe<Person>;
  store?: Maybe<Store>;
  transaction?: Maybe<Transaction>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type PayoutConnection = {
  __typename?: "PayoutConnection";
  nodes: Array<Payout>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PayoutSettings = {
  __typename?: "PayoutSettings";
  IBAN?: Maybe<Scalars["String"]["output"]>;
  accountNumber?: Maybe<Scalars["String"]["output"]>;
  accountOwnerName?: Maybe<Scalars["String"]["output"]>;
  bank?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  isVerified: Scalars["Boolean"]["output"];
  phone?: Maybe<Scalars["String"]["output"]>;
  type: PayoutSettingsType;
};

export type PayoutSettingsInput = {
  IBAN?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  accountOwnerName?: InputMaybe<Scalars["String"]["input"]>;
  bank?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<PayoutSettingsType>;
};

export enum PayoutSettingsType {
  Bank = "BANK",
  Wallet = "WALLET",
}

export enum PayoutSortByField {
  Amount = "amount",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
}

export enum PayoutStatus {
  Approved = "APPROVED",
  Canceled = "CANCELED",
  Failed = "FAILED",
  Pending = "PENDING",
  Processing = "PROCESSING",
  Rejected = "REJECTED",
}

export type PayoutsAdminFilterInput = {
  createdAt?: InputMaybe<DateTimeRangeInput>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  payoutSettingsStatus?: InputMaybe<Scalars["Boolean"]["input"]>;
  searchQuery?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<PayoutStatus>;
  updatedAt?: InputMaybe<DateTimeRangeInput>;
};

export type PayoutsConnectionInput = {
  after?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  before?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  first?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  last?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<PayoutSortByField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type Period = {
  endAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  startAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type Permission = {
  __typename?: "Permission";
  permissions?: Maybe<Array<Maybe<StoreUserPermission>>>;
  role?: Maybe<PermissionRole>;
  storeId: Scalars["ID"]["output"];
};

export enum PermissionAction {
  Create = "CREATE",
  Delete = "DELETE",
  Read = "READ",
  Update = "UPDATE",
}

export type PermissionDetails = {
  __typename?: "PermissionDetails";
  apiKey?: Maybe<Scalars["String"]["output"]>;
  apiKeyName?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  permissions: Array<StoreUserPermission>;
  storeId: Scalars["ID"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  userAccessType: UserAccessType;
  userId: Scalars["ID"]["output"];
};

export enum PermissionResource {
  AbandonedCheckout = "ABANDONED_CHECKOUT",
  Account = "ACCOUNT",
  Analytics = "ANALYTICS",
  Catalog = "CATALOG",
  Customer = "CUSTOMER",
  Discount = "DISCOUNT",
  Domain = "DOMAIN",
  Integration = "INTEGRATION",
  Order = "ORDER",
  Page = "PAGE",
  Review = "REVIEW",
  Shipping = "SHIPPING",
  Store = "STORE",
  StorePayment = "STORE_PAYMENT",
  Subscription = "SUBSCRIPTION",
  Wallet = "WALLET",
}

export enum PermissionRole {
  Custom = "CUSTOM",
  Owner = "OWNER",
  SuperAdmin = "SUPER_ADMIN",
}

export type Person = {
  __typename?: "Person";
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  ip?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  phone?: Maybe<Scalars["String"]["output"]>;
  role: Role;
};

export type PickUpLocation = {
  __typename?: "PickUpLocation";
  address: Scalars["String"]["output"];
  city: City;
  country: Country;
  id: Scalars["ID"]["output"];
  isDefault?: Maybe<Scalars["Boolean"]["output"]>;
  location?: Maybe<GeoLocation>;
  name: Scalars["String"]["output"];
  state: State;
  status?: Maybe<PickUpLocationStatus>;
};

export type PickUpLocationInput = {
  address: Scalars["String"]["input"];
  cityId: Scalars["ID"]["input"];
  countryId: Scalars["ID"]["input"];
  location: GeoLocationInput;
  name: Scalars["String"]["input"];
  stateId: Scalars["ID"]["input"];
  status?: InputMaybe<PickUpLocationStatus>;
};

export enum PickUpLocationStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export type Plan = Node & {
  __typename?: "Plan";
  active: Scalars["Boolean"]["output"];
  features: Array<Feature>;
  handle: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  interval: PricingInterval;
  name: Scalars["String"]["output"];
  price: Money;
  resource: Scalars["String"]["output"];
  stripe_plan_id?: Maybe<Scalars["String"]["output"]>;
  translations: Array<PlanTranslations>;
  visible: Scalars["Boolean"]["output"];
};

export enum PlanResourceType {
  Site = "Site",
  Store = "Store",
}

export type PlanSubscription = {
  __typename?: "PlanSubscription";
  active: Scalars["Boolean"]["output"];
  amountDue: Money;
  cancelAt?: Maybe<Scalars["DateTime"]["output"]>;
  cancelAtPeriodEnd?: Maybe<Scalars["Boolean"]["output"]>;
  canceledAt?: Maybe<Scalars["DateTime"]["output"]>;
  collectionMethod?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  currentPeriodEnd?: Maybe<Scalars["DateTime"]["output"]>;
  currentPeriodStart?: Maybe<Scalars["DateTime"]["output"]>;
  downgradePlan?: Maybe<Plan>;
  endedAt?: Maybe<Scalars["DateTime"]["output"]>;
  externalId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  invoices: Array<Invoice>;
  name: Scalars["String"]["output"];
  paymentMethod?: Maybe<Scalars["String"]["output"]>;
  paymentSource?: Maybe<PaymentSource>;
  plan: Plan;
  promotionCode?: Maybe<PromotionCode>;
  resource?: Maybe<SubscriptionResource>;
  site?: Maybe<Site>;
  startedAt: Scalars["DateTime"]["output"];
  status: SubscriptionStatus;
  trialDays: Scalars["Int"]["output"];
  user?: Maybe<User>;
};

export type PlanTranslations = {
  __typename?: "PlanTranslations";
  features: Array<Feature>;
  locale: Scalars["String"]["output"];
};

export type PriceRange = {
  max?: InputMaybe<Scalars["Float"]["input"]>;
  min?: InputMaybe<Scalars["Float"]["input"]>;
};

export enum PricingInterval {
  Month = "MONTH",
  Year = "YEAR",
}

export type Product = Node & {
  __typename?: "Product";
  attributes?: Maybe<Array<Maybe<ProductAttribute>>>;
  categories?: Maybe<Array<Maybe<CustomProductCategory>>>;
  collections: ProductCollectionConnection;
  createdAt: Scalars["DateTime"]["output"];
  descriptionHtml?: Maybe<Scalars["HTML"]["output"]>;
  discount?: Maybe<CustomProductDiscount>;
  handle: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  images: Array<Maybe<Image>>;
  initialPrice?: Maybe<Money>;
  integrationCategory?: Maybe<Scalars["String"]["output"]>;
  integrationInstallationId?: Maybe<Scalars["String"]["output"]>;
  integrationProvider?: Maybe<IntegrationProviderDetails>;
  isArchived: Scalars["Boolean"]["output"];
  isInStock: Scalars["Boolean"]["output"];
  isVisible: Scalars["Boolean"]["output"];
  locale: Scalars["String"]["output"];
  locales: Array<Scalars["String"]["output"]>;
  moreProducts: MoreProducts;
  options?: Maybe<Array<Maybe<ProductOption>>>;
  productTax?: Maybe<Scalars["Float"]["output"]>;
  reviews?: Maybe<Array<ProductReview>>;
  reviewsStatistics: ProductReviewsStatistics;
  seo?: Maybe<ProductSeo>;
  shortDescription?: Maybe<Scalars["String"]["output"]>;
  source?: Maybe<SourceType>;
  status: ProductStatus;
  store: Store;
  storeId: Scalars["ID"]["output"];
  taxable?: Maybe<Scalars["Boolean"]["output"]>;
  title: Scalars["String"]["output"];
  totalVariants: Scalars["Int"]["output"];
  translations: Array<ProductTranslation>;
  type?: Maybe<ProductType>;
  updatedAt: Scalars["DateTime"]["output"];
  variants?: Maybe<ProductVariantConnection>;
};

export type ProductReviewsArgs = {
  connection: ReviewsConnectionInput;
};

export type ProductVariantsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  reverse?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ProductAttribute = Node & {
  __typename?: "ProductAttribute";
  handle: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  locale: Scalars["String"]["output"];
  locales: Array<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  translations: Array<ProductAttributeTranslation>;
  type?: Maybe<ProductAttributeType>;
  values: Array<ProductAttributeValue>;
};

export type ProductAttributeFilterInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductAttributeTranslation = {
  __typename?: "ProductAttributeTranslation";
  locale: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type ProductAttributeTranslationInput = {
  locale: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export enum ProductAttributeType {
  Image = "IMAGE",
  Text = "TEXT",
}

export type ProductAttributeValue = Node & {
  __typename?: "ProductAttributeValue";
  attribute?: Maybe<ProductAttribute>;
  handle?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  image?: Maybe<Image>;
  locale?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  translations?: Maybe<Array<Maybe<ProductAttributeValueTranslation>>>;
};

export type ProductAttributeValueTranslation = {
  __typename?: "ProductAttributeValueTranslation";
  locale?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type ProductAttributeValueTranslationInput = {
  locale: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type ProductCollection = Node & {
  __typename?: "ProductCollection";
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  descriptionHtml?: Maybe<Scalars["String"]["output"]>;
  handle: Scalars["String"]["output"];
  hasProduct: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  image?: Maybe<Image>;
  isArchived?: Maybe<Scalars["Boolean"]["output"]>;
  isDeleted?: Maybe<Scalars["Boolean"]["output"]>;
  isVisible?: Maybe<Scalars["Boolean"]["output"]>;
  locale: Scalars["String"]["output"];
  locales: Array<Scalars["String"]["output"]>;
  products: ProductConnection;
  productsCount: Scalars["Int"]["output"];
  seo?: Maybe<ProductCollectionSeo>;
  shortDescription?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  translations?: Maybe<Array<Maybe<TranslatedCollection>>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ProductCollectionHasProductArgs = {
  id: Scalars["ID"]["input"];
};

export type ProductCollectionProductsArgs = {
  connection?: InputMaybe<CollectionProductsConnectionInput>;
  filter?: InputMaybe<ProductsFilterInput>;
};

export type ProductCollectionConnection = {
  __typename?: "ProductCollectionConnection";
  edges?: Maybe<Array<Maybe<ProductCollectionEdge>>>;
  nodes?: Maybe<Array<Maybe<ProductCollection>>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ProductCollectionConnectionInput = {
  after?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  before?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  first?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  last?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<ProductCollectionSortByField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type ProductCollectionEdge = {
  __typename?: "ProductCollectionEdge";
  cursor: Scalars["ConnectionCursor"]["output"];
  node?: Maybe<ProductCollection>;
};

export type ProductCollectionFilterInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductCollectionSeo = {
  __typename?: "ProductCollectionSEO";
  description?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ProductCollectionSeoInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export enum ProductCollectionSortByField {
  CreatedAt = "createdAt",
  Id = "id",
  Title = "title",
  UpdatedAt = "updatedAt",
}

export type ProductConnection = {
  __typename?: "ProductConnection";
  edges?: Maybe<Array<Maybe<ProductEdge>>>;
  nodes: Array<Product>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ProductDisplaySettings = {
  __typename?: "ProductDisplaySettings";
  buyNowButton?: Maybe<BuyNowButtonSettings>;
  productImageZoomBehavior?: Maybe<ProductImageZoomBehavior>;
};

export type ProductDisplaySettingsInput = {
  buyNowButton?: InputMaybe<BuyNowButtonSettingsInput>;
  productImageZoomBehavior?: InputMaybe<ProductImageZoomBehavior>;
};

export type ProductEdge = {
  __typename?: "ProductEdge";
  cursor: Scalars["ConnectionCursor"]["output"];
  node?: Maybe<Product>;
};

export enum ProductImageZoomBehavior {
  None = "none",
  OnHover = "onHover",
}

export type ProductInput = {
  attributes?: InputMaybe<Array<InputMaybe<Attribute>>>;
  categories?: InputMaybe<Array<InputMaybe<CustomProductCategoryInput>>>;
  collectionsToJoin?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  collectionsToLeave?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  descriptionHtml?: InputMaybe<Scalars["String"]["input"]>;
  discount?: InputMaybe<CustomProductDiscountInput>;
  handle?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  initialPrice?: InputMaybe<MoneyInput>;
  integrationCategory?: InputMaybe<Scalars["String"]["input"]>;
  integrationInstallationId?: InputMaybe<Scalars["String"]["input"]>;
  integrationProvider?: InputMaybe<IntegrationProviderDetailsInput>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<Array<ProductOptionInput>>;
  productTax?: InputMaybe<Scalars["Float"]["input"]>;
  seo?: InputMaybe<ProductSeoInput>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<SourceType>;
  specifications?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ProductStatus>;
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
  taxable?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  translations?: InputMaybe<Array<InputMaybe<ProductTranslationInput>>>;
  type?: InputMaybe<ProductType>;
  variants?: InputMaybe<Array<InputMaybe<ProductVariantInput>>>;
};

export type ProductLink = {
  __typename?: "ProductLink";
  handle: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  title: Scalars["String"]["output"];
};

export type ProductMove = {
  id: Scalars["ID"]["input"];
  newPosition: Scalars["Int"]["input"];
};

export type ProductOption = Node & {
  __typename?: "ProductOption";
  handle: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  locale: Scalars["String"]["output"];
  locales: Array<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  position: Scalars["Int"]["output"];
  translations: Array<ProductOptionTranslation>;
  values: Array<ProductOptionValue>;
};

export type ProductOptionInput = {
  handle?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  position: Scalars["Int"]["input"];
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ProductOptionTranslation = {
  __typename?: "ProductOptionTranslation";
  locale: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type ProductOptionTranslationInput = {
  locale: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type ProductOptionValue = Node & {
  __typename?: "ProductOptionValue";
  handle?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  locale?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  option?: Maybe<ProductOption>;
  translations?: Maybe<Array<Maybe<ProductOptionValueTranslation>>>;
};

export type ProductOptionValueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  optionId?: InputMaybe<Scalars["ID"]["input"]>;
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ProductOptionValueTranslation = {
  __typename?: "ProductOptionValueTranslation";
  locale?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type ProductOptionValueTranslationInput = {
  locale: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type ProductPackageDetails = {
  __typename?: "ProductPackageDetails";
  dimensions?: Maybe<Dimensions>;
  weight?: Maybe<Scalars["Float"]["output"]>;
};

export type ProductPackageDetailsInput = {
  dimensions?: InputMaybe<DimensionsInput>;
  weight?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ProductPreview = {
  __typename?: "ProductPreview";
  collections?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  description?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<ProductStatus>;
  title?: Maybe<Scalars["String"]["output"]>;
  variants?: Maybe<Array<Maybe<VariantPreview>>>;
};

export type ProductReview = {
  __typename?: "ProductReview";
  content?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  customer?: Maybe<CustomerUser>;
  customerId: Scalars["ID"]["output"];
  headline: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isViewed: Scalars["Boolean"]["output"];
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars["ID"]["output"]>;
  product: Product;
  productId: Scalars["ID"]["output"];
  rating: Scalars["Int"]["output"];
  reply?: Maybe<StoreAdminReply>;
  showName: Scalars["Boolean"]["output"];
  snapshotForSearch?: Maybe<SnapshotForSearch>;
  status: ReviewStatus;
  storeId: Scalars["ID"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type ProductReviewsStatistics = {
  __typename?: "ProductReviewsStatistics";
  average: Scalars["Float"]["output"];
  starsCount: ProductStarsCount;
  total: Scalars["Int"]["output"];
};

export type ProductSeo = {
  __typename?: "ProductSEO";
  description?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ProductSeoInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductSalesAnalytics = {
  __typename?: "ProductSalesAnalytics";
  discounts?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
  totalPrice?: Maybe<Scalars["Float"]["output"]>;
  totalQuantitySold?: Maybe<Scalars["Int"]["output"]>;
  totalSales?: Maybe<Scalars["Float"]["output"]>;
};

export type ProductSnapshot = {
  __typename?: "ProductSnapshot";
  categories?: Maybe<Array<Maybe<CustomProductSnapshotCategory>>>;
  collectionIds?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
  createdAt: Scalars["DateTime"]["output"];
  descriptionHtml?: Maybe<Scalars["HTML"]["output"]>;
  discount?: Maybe<CustomProductSnapshotDiscount>;
  handle: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  images: Array<Maybe<Image>>;
  initialPrice?: Maybe<Money>;
  integrationProvider?: Maybe<IntegrationProviderDetailsSnapshot>;
  isArchived: Scalars["Boolean"]["output"];
  isVisible: Scalars["Boolean"]["output"];
  locale: Scalars["String"]["output"];
  options: Array<Scalars["ID"]["output"]>;
  seo?: Maybe<ProductSnapshotSeo>;
  shortDescription?: Maybe<Scalars["String"]["output"]>;
  status: ProductSnapshotStatus;
  storeId: Scalars["ID"]["output"];
  title: Scalars["String"]["output"];
  type: ProductSnapshotType;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ProductSnapshotSeo = {
  __typename?: "ProductSnapshotSEO";
  description?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export enum ProductSnapshotStatus {
  Active = "ACTIVE",
  Draft = "DRAFT",
  Unlisted = "UNLISTED",
}

export enum ProductSnapshotType {
  Custom = "CUSTOM",
  Simple = "SIMPLE",
}

export enum ProductSortByField {
  CreatedAt = "createdAt",
  Id = "id",
  Price = "price",
  Title = "title",
  UpdatedAt = "updatedAt",
}

export type ProductStarsCount = {
  __typename?: "ProductStarsCount";
  five: Scalars["Int"]["output"];
  four: Scalars["Int"]["output"];
  one: Scalars["Int"]["output"];
  three: Scalars["Int"]["output"];
  two: Scalars["Int"]["output"];
};

export enum ProductStatus {
  Active = "ACTIVE",
  Draft = "DRAFT",
  Pending = "PENDING",
  Unlisted = "UNLISTED",
}

export enum ProductStatusFilter {
  Active = "ACTIVE",
  All = "ALL",
  Draft = "DRAFT",
  Unlisted = "UNLISTED",
}

export type ProductTranslation = {
  __typename?: "ProductTranslation";
  descriptionHtml?: Maybe<Scalars["String"]["output"]>;
  locale: Scalars["String"]["output"];
  seo?: Maybe<ProductSeo>;
  shortDescription: Scalars["String"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ProductTranslationInput = {
  descriptionHtml?: InputMaybe<Scalars["String"]["input"]>;
  locale: Scalars["String"]["input"];
  seo?: InputMaybe<ProductSeoInput>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductTranslationOutput = {
  __typename?: "ProductTranslationOutput";
  id: Scalars["ID"]["output"];
  translations: Array<ProductTranslation>;
};

export enum ProductType {
  All = "ALL",
  Custom = "CUSTOM",
  Simple = "SIMPLE",
}

export type ProductVariant = Node & {
  __typename?: "ProductVariant";
  cartLimitsEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  compareAtPrice?: Maybe<Money>;
  cost?: Maybe<Money>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  displayName: Scalars["String"]["output"];
  externalId?: Maybe<Scalars["String"]["output"]>;
  handle: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  image?: Maybe<Image>;
  locale: Scalars["String"]["output"];
  locales: Array<Scalars["String"]["output"]>;
  maxPerCart?: Maybe<Scalars["Int"]["output"]>;
  minPerCart?: Maybe<Scalars["Int"]["output"]>;
  packageDetails?: Maybe<ProductPackageDetails>;
  position: Scalars["Int"]["output"];
  price: Money;
  product: Product;
  productId: Scalars["ID"]["output"];
  quantity?: Maybe<Scalars["Int"]["output"]>;
  selectedOptions: Array<SelectedOption>;
  sku?: Maybe<Scalars["String"]["output"]>;
  store: Store;
  storeId: Scalars["ID"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
  trackQuantity?: Maybe<Scalars["Boolean"]["output"]>;
  translations?: Maybe<Array<Maybe<TranslatedProductVariant>>>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ProductVariantConnection = {
  __typename?: "ProductVariantConnection";
  nodes: Array<ProductVariant>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ProductVariantImageInput = {
  id: Scalars["ID"]["input"];
  imageId?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type ProductVariantInput = {
  cartLimitsEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  compareAtPrice?: InputMaybe<MoneyInput>;
  cost?: InputMaybe<MoneyInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  imageId?: InputMaybe<Scalars["String"]["input"]>;
  maxPerCart?: InputMaybe<Scalars["Int"]["input"]>;
  minPerCart?: InputMaybe<Scalars["Int"]["input"]>;
  options?: InputMaybe<Array<Scalars["String"]["input"]>>;
  packageDetails?: InputMaybe<ProductPackageDetailsInput>;
  price?: InputMaybe<MoneyInput>;
  productId?: InputMaybe<Scalars["ID"]["input"]>;
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
  sku?: InputMaybe<Scalars["String"]["input"]>;
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
  trackQuantity?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ProductVariantSnapshot = {
  __typename?: "ProductVariantSnapshot";
  compareAtPrice?: Maybe<Money>;
  createdAt: Scalars["DateTime"]["output"];
  externalId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  image?: Maybe<Image>;
  price: Money;
  productId: Scalars["ID"]["output"];
  productImages?: Maybe<Array<Maybe<Image>>>;
  productTitle?: Maybe<Scalars["String"]["output"]>;
  quantity?: Maybe<Scalars["Int"]["output"]>;
  selectedOptionValuesIds: Array<Scalars["ID"]["output"]>;
  sku?: Maybe<Scalars["String"]["output"]>;
  storeId: Scalars["ID"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
  trackQuantity?: Maybe<Scalars["Boolean"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ProductVariantStatus = {
  __typename?: "ProductVariantStatus";
  id: Scalars["ID"]["output"];
  quantity?: Maybe<Scalars["Int"]["output"]>;
  status: ProductStatus;
  trackQuantity?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ProductVariantUpdateInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  imageId?: InputMaybe<Scalars["String"]["input"]>;
  options?: InputMaybe<Array<Scalars["String"]["input"]>>;
  productId?: InputMaybe<Scalars["ID"]["input"]>;
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ProductsConnectionInput = {
  after?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  before?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  first?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  last?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<ProductSortByField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type ProductsFilterInput = {
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxPrice?: InputMaybe<Scalars["Float"]["input"]>;
  minPrice?: InputMaybe<Scalars["Float"]["input"]>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  showDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  source?: InputMaybe<SourceType>;
  status?: InputMaybe<ProductStatusFilter>;
  storeIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<ProductType>;
};

export type PromoCode = Node & {
  __typename?: "PromoCode";
  code: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  fixedAmount?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["ID"]["output"];
  isArchived: Scalars["Boolean"]["output"];
  minimumOrderAmountToApply?: Maybe<Scalars["Float"]["output"]>;
  numberOfUsage?: Maybe<Scalars["Int"]["output"]>;
  percentageOff?: Maybe<Scalars["Float"]["output"]>;
  schedule?: Maybe<DiscountSchedule>;
  status: PromoCodeStatusEnum;
  type?: Maybe<PromoCodeTypeEnum>;
  updatedAt: Scalars["DateTime"]["output"];
  usageLimit?: Maybe<Scalars["Int"]["output"]>;
};

export type PromoCodeActions = {
  __typename?: "PromoCodeActions";
  archivePromoCode: PromoCode;
  createPromoCode: PromoCode;
  deletePromoCode: Scalars["Boolean"]["output"];
  restorePromoCode: PromoCode;
  updatePromoCode: PromoCode;
};

export type PromoCodeActionsArchivePromoCodeArgs = {
  id: Scalars["ID"]["input"];
};

export type PromoCodeActionsCreatePromoCodeArgs = {
  input: CreatePromoCodeInput;
};

export type PromoCodeActionsDeletePromoCodeArgs = {
  id: Scalars["ID"]["input"];
};

export type PromoCodeActionsRestorePromoCodeArgs = {
  id: Scalars["ID"]["input"];
};

export type PromoCodeActionsUpdatePromoCodeArgs = {
  input: UpdatePromoCodeInput;
};

export type PromoCodeEdge = {
  __typename?: "PromoCodeEdge";
  cursor: Scalars["ConnectionCursor"]["output"];
  node?: Maybe<PromoCode>;
};

export type PromoCodeQueries = {
  __typename?: "PromoCodeQueries";
  promoCode?: Maybe<PromoCode>;
  promoCodeByCode?: Maybe<PromoCode>;
  promoCodes?: Maybe<PromoCodesConnection>;
};

export type PromoCodeQueriesPromoCodeArgs = {
  id: Scalars["ID"]["input"];
};

export type PromoCodeQueriesPromoCodeByCodeArgs = {
  code: Scalars["String"]["input"];
};

export type PromoCodeQueriesPromoCodesArgs = {
  connection?: InputMaybe<PromoCodesConnectionInput>;
  filter?: InputMaybe<PromoCodesFilterInput>;
};

export type PromoCodeSnapshot = {
  __typename?: "PromoCodeSnapshot";
  code: Scalars["String"]["output"];
  fixedAmount?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["ID"]["output"];
  percentageOff?: Maybe<Scalars["Float"]["output"]>;
  status: PromoCodeStatusEnum;
  type?: Maybe<PromoCodeTypeEnum>;
};

export enum PromoCodeStatusEnum {
  Active = "ACTIVE",
  All = "ALL",
  Inactive = "INACTIVE",
  Scheduled = "SCHEDULED",
}

export enum PromoCodeTypeEnum {
  FixedAmount = "FIXED_AMOUNT",
  Percentage = "PERCENTAGE",
}

export type PromoCodesConnection = {
  __typename?: "PromoCodesConnection";
  nodes?: Maybe<Array<Maybe<PromoCode>>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PromoCodesConnectionInput = {
  after?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  before?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  first?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  last?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<PromoCodesSortByField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type PromoCodesFilterInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  searchQuery?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<PromoCodeStatusEnum>;
};

export type PromotionCode = {
  __typename?: "PromotionCode";
  active: Scalars["Boolean"]["output"];
  code: Scalars["String"]["output"];
  coupon: Coupon;
  created: Scalars["Int"]["output"];
  expires_at: Scalars["Int"]["output"];
  id: Scalars["String"]["output"];
  max_redemptions: Scalars["Int"]["output"];
};

export type ProviderAdditionalInfo = {
  bostaAdditionalInfo?: InputMaybe<BostaAdditionalInfo>;
};

export enum ProviderName {
  Aliexpress = "Aliexpress",
  Aramex = "Aramex",
  Bosta = "Bosta",
  Facebook = "Facebook",
  Fatoora = "Fatoora",
  Gameball = "Gameball",
  GoAffPro = "GoAffPro",
  GoogleMerchant = "GoogleMerchant",
  HubSpot = "HubSpot",
  Klaviyo = "Klaviyo",
  Knawat = "Knawat",
  MailChimp = "MailChimp",
  Mylerz = "Mylerz",
  Oto = "OTO",
  Optimonk = "Optimonk",
  SnapChat = "SnapChat",
  Taager = "Taager",
  TikTok = "TikTok",
}

export type PurchaseDomainInput = {
  contact: ContactInput;
  domainName: Scalars["String"]["input"];
  resourceId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type PurchaseDomainResult = {
  __typename?: "PurchaseDomainResult";
  domain?: Maybe<Domain>;
  domainId: Scalars["ID"]["output"];
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type QuantityMetric = {
  __typename?: "QuantityMetric";
  amount: Scalars["Int"]["output"];
  growth: Scalars["Float"]["output"];
};

export type Query = {
  __typename?: "Query";
  adminQueries?: Maybe<AdminQueries>;
  apiKeyDetails: Array<Maybe<PermissionDetails>>;
  calculateDiscounts: Array<CalculatedDiscountBaseItem>;
  canAddCustomProducts?: Maybe<Scalars["Boolean"]["output"]>;
  canAddGoogleTagManager?: Maybe<Scalars["Boolean"]["output"]>;
  canAddMoreLanguages?: Maybe<Scalars["Boolean"]["output"]>;
  canAddMoreProducts?: Maybe<Scalars["Boolean"]["output"]>;
  canAddMoreStaffAccounts?: Maybe<Scalars["Boolean"]["output"]>;
  canEnableMoreShippingLevels?: Maybe<Scalars["Boolean"]["output"]>;
  canEnableMoreSocialIntegrations?: Maybe<Scalars["Boolean"]["output"]>;
  canStoreBulkUpload: Scalars["Boolean"]["output"];
  canUserBookDemo: Scalars["Boolean"]["output"];
  checkVariantsStatus: Array<Maybe<ProductVariantStatus>>;
  collection?: Maybe<ProductCollection>;
  collections?: Maybe<ProductCollectionConnection>;
  customerAddresses: Array<CustomerAddress>;
  customerLogin?: Maybe<Scalars["String"]["output"]>;
  customerLoginV2?: Maybe<Scalars["String"]["output"]>;
  customerOrders?: Maybe<CustomerUser>;
  customerQueries?: Maybe<CustomerQueries>;
  domain?: Maybe<RegisteredDomain>;
  domainClaim: DomainClaim;
  domainClaimForResource?: Maybe<DomainClaim>;
  exportCustomers: ExportCustomersPayload;
  findPromotionCode?: Maybe<PromotionCode>;
  fullTextSearchAndFilter?: Maybe<SearchAndFilterResults>;
  getAllProducts: DropshippingProductConnection;
  getApplicableFilters?: Maybe<CatalogFilters>;
  getAvailablePromotionCodes: Array<PromotionCode>;
  getCategories?: Maybe<Array<DropshippingCategory>>;
  getParagonIntegrations?: Maybe<ParagonStoreIntegrations>;
  getParagonUserToken?: Maybe<Scalars["String"]["output"]>;
  getProductsByCategory?: Maybe<Array<Maybe<DropshippingProduct>>>;
  getShippingIntegrationCustomFieldValues?: Maybe<
    Array<Maybe<ShippingProviderLocation>>
  >;
  getStoreRedirect?: Maybe<Scalars["String"]["output"]>;
  getStoreRedirects?: Maybe<Array<StoreRedirect>>;
  getStoreRobotsTxt?: Maybe<RobotsTxt>;
  image?: Maybe<Image>;
  installation?: Maybe<IntegrationInstallation>;
  integration?: Maybe<Integration>;
  integrationsList?: Maybe<IntegrationConnection>;
  isAttributeUsedInProduct?: Maybe<Scalars["Boolean"]["output"]>;
  isAttributeValueUsedInProduct?: Maybe<Scalars["Boolean"]["output"]>;
  listCustomerReviews: Array<ProductReview>;
  listReviews: ReviewsConnection;
  me?: Maybe<CustomerUser>;
  order?: Maybe<Order>;
  orderByCart?: Maybe<Order>;
  orders?: Maybe<OrderConnection>;
  page?: Maybe<StorePage>;
  paymentIntent?: Maybe<PaymentIntent>;
  paymentIntentByOrderId?: Maybe<PaymentIntent>;
  paymentSource?: Maybe<PaymentSource>;
  paymentSources?: Maybe<Array<Maybe<PaymentSource>>>;
  paymentStatusByIntentId?: Maybe<PaymentIntentStatusEnum>;
  plan: Plan;
  plans: Array<Plan>;
  product?: Maybe<Product>;
  productAttribute?: Maybe<ProductAttribute>;
  productAttributeValue?: Maybe<ProductAttributeValue>;
  productAttributeValues?: Maybe<Array<Maybe<ProductAttributeValue>>>;
  productAttributes: Array<Maybe<ProductAttribute>>;
  productOption?: Maybe<ProductOption>;
  productOptions: Array<ProductOption>;
  productOptionsValues: Array<ProductOptionValue>;
  productVariant?: Maybe<ProductVariant>;
  productVariants?: Maybe<ProductVariantConnection>;
  products: ProductConnection;
  regexSearchAndFilter?: Maybe<SearchAndFilterResults>;
  searchCatalog?: Maybe<CatalogSearchResults>;
  searchRegisterDomain: Array<DomainSearchResult>;
  shipmentMaterial?: Maybe<ShipmentMaterialQueries>;
  shippingRate?: Maybe<ShippingRate>;
  shippingRates?: Maybe<Array<Maybe<ShippingRate>>>;
  shippingZone?: Maybe<ShippingZone>;
  shippingZones?: Maybe<ShippingZoneConnection>;
  store?: Maybe<Store>;
  storeAnalytics?: Maybe<StoreAnalytics>;
  storeByDomain?: Maybe<Store>;
  storeByHandle?: Maybe<Store>;
  storeCheckoutPaymentMethods?: Maybe<Array<Maybe<PaymentMethodEnum>>>;
  storeCollectionByHandle?: Maybe<ProductCollection>;
  storeCustomers?: Maybe<CustomerUserConnection>;
  storeHomeCatalog?: Maybe<StoreHomeCatalog>;
  storeIntegrationsScriptControls: Array<IntegrationScriptControls>;
  storeLegalPageByHandle?: Maybe<StoreLegalPage>;
  storeLegalPageById?: Maybe<StoreLegalPage>;
  storeMenus?: Maybe<Array<Maybe<StoreMenu>>>;
  storePageByHandle?: Maybe<StorePage>;
  storePages?: Maybe<PagesConnection>;
  storePaymentAccount?: Maybe<PaymentAccount>;
  storePaymentMethods?: Maybe<Array<Maybe<StorePaymentMethod>>>;
  storeProductByHandle?: Maybe<Product>;
  storeReviewsSettings?: Maybe<StoreReviewsSettings>;
  /** @deprecated Use storeLegalPageByHandle instead */
  storeStaticPageByHandle?: Maybe<StoreStaticPage>;
  /** @deprecated Use storeLegalPageById instead */
  storeStaticPageById?: Maybe<StoreStaticPage>;
  storeStaticTemplates?: Maybe<Array<Maybe<StoreStaticTemplate>>>;
  storeSubscription?: Maybe<PlanSubscription>;
  storeUser?: Maybe<Array<StoreUser>>;
  storeUsers?: Maybe<StoreUserConnection>;
  stores?: Maybe<Array<Maybe<Store>>>;
  storesList?: Maybe<StoreConnection>;
  subscription?: Maybe<PlanSubscription>;
  subscriptions: Array<PlanSubscription>;
  textIndexSearchAndFilter?: Maybe<SearchAndFilterResults>;
  userByEmail?: Maybe<User>;
  userPermissions?: Maybe<Array<StoreUser>>;
  userQuestionnaireAnswers?: Maybe<Questionnaire>;
  userRequest?: Maybe<UserRequestStatus>;
  userStores?: Maybe<Array<Maybe<Store>>>;
  validateAndPreviewBulkTranslateFile?: Maybe<BulkTranslatePreviewResult>;
  validateAndPreviewBulkUploadFile?: Maybe<BulkUploadPreviewResult>;
  validateSocialIntegrationInstallation?: Maybe<Scalars["Boolean"]["output"]>;
  wuiltShipment?: Maybe<WuiltShipmentQueries>;
};

export type QueryAdminQueriesArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryApiKeyDetailsArgs = {
  apiKeyId?: InputMaybe<Scalars["ID"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type QueryCalculateDiscountsArgs = {
  items: Array<CalculateItemsDiscountsInput>;
  storeId: Scalars["ID"]["input"];
};

export type QueryCanAddCustomProductsArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryCanAddGoogleTagManagerArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryCanAddMoreLanguagesArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryCanAddMoreProductsArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryCanAddMoreStaffAccountsArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryCanEnableMoreShippingLevelsArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryCanEnableMoreSocialIntegrationsArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryCanStoreBulkUploadArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryCheckVariantsStatusArgs = {
  storeId: Scalars["ID"]["input"];
  variantIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
};

export type QueryCollectionArgs = {
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type QueryCollectionsArgs = {
  connection?: InputMaybe<ProductCollectionConnectionInput>;
  filter?: InputMaybe<ProductCollectionFilterInput>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCustomerAddressesArgs = {
  customerId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type QueryCustomerLoginArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  storeId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCustomerLoginV2Args = {
  password: Scalars["String"]["input"];
  phoneOrEmail: Scalars["String"]["input"];
  storeId: Scalars["String"]["input"];
};

export type QueryCustomerOrdersArgs = {
  connection?: InputMaybe<OrdersConnectionInput>;
  id: Scalars["ID"]["input"];
};

export type QueryCustomerQueriesArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryDomainArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryDomainClaimArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryDomainClaimForResourceArgs = {
  resourceId: Scalars["ID"]["input"];
};

export type QueryExportCustomersArgs = {
  input: ExportCustomersInput;
};

export type QueryFindPromotionCodeArgs = {
  coupon: Scalars["String"]["input"];
  planId?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryFullTextSearchAndFilterArgs = {
  connectionSettings?: InputMaybe<ProductsConnectionInput>;
  filters?: InputMaybe<CatalogSearchFilters>;
  storeId: Scalars["ID"]["input"];
};

export type QueryGetAllProductsArgs = {
  connection: DropshippingProductConnectionInput;
  installationId: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["String"]["input"]>;
  storeCurrency?: InputMaybe<CurrencyCode>;
  storeId: Scalars["ID"]["input"];
};

export type QueryGetApplicableFiltersArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryGetCategoriesArgs = {
  installationId: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type QueryGetParagonIntegrationsArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryGetParagonUserTokenArgs = {
  storeId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetProductsByCategoryArgs = {
  category: Scalars["String"]["input"];
  installationId: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type QueryGetShippingIntegrationCustomFieldValuesArgs = {
  fieldName: Scalars["String"]["input"];
  integrationId: Scalars["ID"]["input"];
  parentId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryGetStoreRedirectArgs = {
  storeId: Scalars["ID"]["input"];
  url: Scalars["String"]["input"];
};

export type QueryGetStoreRedirectsArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryGetStoreRobotsTxtArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryImageArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type QueryInstallationArgs = {
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type QueryIntegrationArgs = {
  integrationId: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryIntegrationsListArgs = {
  connection?: InputMaybe<IntegrationConnectionInput>;
  filter?: InputMaybe<FilterInput>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryIsAttributeUsedInProductArgs = {
  attributeId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type QueryIsAttributeValueUsedInProductArgs = {
  attributeId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
  valueId: Scalars["ID"]["input"];
};

export type QueryListCustomerReviewsArgs = {
  input: ListCustomerReviewsInput;
};

export type QueryListReviewsArgs = {
  input: SearchReviewsInput;
};

export type QueryOrderArgs = {
  orderId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type QueryOrderByCartArgs = {
  cartId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type QueryOrdersArgs = {
  connection?: InputMaybe<OrdersConnectionInput>;
  filter?: InputMaybe<OrdersFilterInput>;
  storeId: Scalars["ID"]["input"];
};

export type QueryPageArgs = {
  id: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type QueryPaymentIntentArgs = {
  id: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type QueryPaymentIntentByOrderIdArgs = {
  orderId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type QueryPaymentSourcesArgs = {
  type?: InputMaybe<PaymentSourceType>;
};

export type QueryPaymentStatusByIntentIdArgs = {
  id: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type QueryPlanArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryProductArgs = {
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type QueryProductAttributeArgs = {
  id: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type QueryProductAttributeValueArgs = {
  attributeId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type QueryProductAttributeValuesArgs = {
  attributeId: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["String"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type QueryProductAttributesArgs = {
  filter?: InputMaybe<ProductAttributeFilterInput>;
  storeId: Scalars["ID"]["input"];
};

export type QueryProductOptionArgs = {
  id: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type QueryProductOptionsArgs = {
  locale?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type QueryProductOptionsValuesArgs = {
  locale?: InputMaybe<Scalars["String"]["input"]>;
  optionId: Scalars["ID"]["input"];
  search?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type QueryProductVariantArgs = {
  id: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type QueryProductVariantsArgs = {
  productId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type QueryProductsArgs = {
  connection?: InputMaybe<ProductsConnectionInput>;
  filter?: InputMaybe<ProductsFilterInput>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryRegexSearchAndFilterArgs = {
  connectionSettings?: InputMaybe<ProductsConnectionInput>;
  filters?: InputMaybe<CatalogSearchFilters>;
  storeId: Scalars["ID"]["input"];
};

export type QuerySearchCatalogArgs = {
  connectionSettings?: InputMaybe<ProductsConnectionInput>;
  filters?: InputMaybe<CatalogSearchFilters>;
  storeId: Scalars["ID"]["input"];
};

export type QuerySearchRegisterDomainArgs = {
  query: Scalars["String"]["input"];
};

export type QueryShipmentMaterialArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryShippingRateArgs = {
  shippingRateId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type QueryShippingRatesArgs = {
  filter?: InputMaybe<ShippingRatesFilterInput>;
  storeId: Scalars["ID"]["input"];
};

export type QueryShippingZoneArgs = {
  shippingZoneId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type QueryShippingZonesArgs = {
  connection?: InputMaybe<ShippingZonesConnectionInput>;
  filter?: InputMaybe<ShippingZonesFilterInput>;
  storeId: Scalars["ID"]["input"];
};

export type QueryStoreArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryStoreAnalyticsArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryStoreByDomainArgs = {
  domainName: Scalars["String"]["input"];
};

export type QueryStoreByHandleArgs = {
  handle: Scalars["String"]["input"];
};

export type QueryStoreCheckoutPaymentMethodsArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryStoreCollectionByHandleArgs = {
  handle: Scalars["String"]["input"];
  locale?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type QueryStoreCustomersArgs = {
  connection?: InputMaybe<CustomersConnectionInput>;
  filter?: InputMaybe<CustomerSchemaFilterInput>;
  storeId: Scalars["ID"]["input"];
};

export type QueryStoreHomeCatalogArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryStoreIntegrationsScriptControlsArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryStoreLegalPageByHandleArgs = {
  input?: InputMaybe<StaticPageStoreByHandleInput>;
};

export type QueryStoreLegalPageByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryStoreMenusArgs = {
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryStorePageByHandleArgs = {
  handle: Scalars["String"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type QueryStorePagesArgs = {
  connection: PagesConnectionInput;
  filter: PageFilterInput;
  locale?: InputMaybe<Locale>;
  storeId: Scalars["ID"]["input"];
};

export type QueryStorePaymentAccountArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryStorePaymentMethodsArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryStoreProductByHandleArgs = {
  handle?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type QueryStoreReviewsSettingsArgs = {
  storeId: Scalars["ID"]["input"];
};

export type QueryStoreStaticPageByHandleArgs = {
  input?: InputMaybe<StaticPageStoreByHandleInput>;
};

export type QueryStoreStaticPageByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryStoreSubscriptionArgs = {
  id: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type QueryStoreUserArgs = {
  storeId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
};

export type QueryStoreUsersArgs = {
  connections?: InputMaybe<StoreUsersConnectionInput>;
  storeId: Scalars["ID"]["input"];
};

export type QueryStoresListArgs = {
  connection?: InputMaybe<StoreConnectionInput>;
  filter?: InputMaybe<SchemaFilterInput>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySubscriptionArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryTextIndexSearchAndFilterArgs = {
  connectionSettings?: InputMaybe<ProductsConnectionInput>;
  filters?: InputMaybe<CatalogSearchFilters>;
  storeId: Scalars["ID"]["input"];
};

export type QueryUserByEmailArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryUserPermissionsArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryUserQuestionnaireAnswersArgs = {
  userId: Scalars["ID"]["input"];
  version: Scalars["String"]["input"];
};

export type QueryUserRequestArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryUserStoresArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryValidateAndPreviewBulkTranslateFileArgs = {
  locale: Locale;
  storeId: Scalars["ID"]["input"];
  translationSheetUrl: Scalars["String"]["input"];
};

export type QueryValidateAndPreviewBulkUploadFileArgs = {
  productsFileURL: Scalars["String"]["input"];
  shouldOverwrite?: InputMaybe<Scalars["Boolean"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type QueryValidateSocialIntegrationInstallationArgs = {
  authHeader?: InputMaybe<Scalars["String"]["input"]>;
  provider: ProviderName;
  storeId: Scalars["ID"]["input"];
};

export type QueryWuiltShipmentArgs = {
  storeId: Scalars["ID"]["input"];
};

export type Questionnaire = {
  __typename?: "Questionnaire";
  answers?: Maybe<Array<Maybe<Answer>>>;
  version?: Maybe<Scalars["String"]["output"]>;
};

export type QuestionnaireInput = {
  answers?: InputMaybe<Array<InputMaybe<AnswerInput>>>;
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type Receipt = {
  __typename?: "Receipt";
  shippingFees: Money;
  subTotal: Money;
  total: Money;
};

export type RefreshShipmentStatusResponse = {
  __typename?: "RefreshShipmentStatusResponse";
  error?: Maybe<ShippingErrors>;
  shipmentDetails?: Maybe<ShipmentDetails>;
};

export type Region = IRegion &
  Node & {
    __typename?: "Region";
    cityId: Scalars["ID"]["output"];
    code?: Maybe<Scalars["String"]["output"]>;
    countryId: Scalars["ID"]["output"];
    id: Scalars["ID"]["output"];
    isPredefined?: Maybe<Scalars["Boolean"]["output"]>;
    name: Scalars["String"]["output"];
    stateId: Scalars["ID"]["output"];
    storeId?: Maybe<Scalars["ID"]["output"]>;
  };

export type RegionConnection = {
  __typename?: "RegionConnection";
  edges?: Maybe<Array<Maybe<RegionEdge>>>;
  nodes?: Maybe<Array<Maybe<Region>>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type RegionEdge = {
  __typename?: "RegionEdge";
  cursor: Scalars["ConnectionCursor"]["output"];
  node?: Maybe<Region>;
};

export type RegionInput = {
  id: Scalars["ID"]["input"];
};

export type RegionUnion = RegionConnection | ZoneRegionConnection;

export type RegisteredDomain = {
  __typename?: "RegisteredDomain";
  createdAt: Scalars["String"]["output"];
  dnsRecords: Array<DnsRecord>;
  endTime?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  mainResource?: Maybe<Resource>;
  name: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
  updatedAt: Scalars["String"]["output"];
  user: User;
  verificationStatus: Scalars["String"]["output"];
};

export type RemoveItemsInput = {
  items: Array<InputMaybe<Scalars["ID"]["input"]>>;
  orderId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type ReorderItemsInput = {
  moves: Array<MoveSectionItemsInput>;
};

export type ReorderOptionsInProductPayload = {
  __typename?: "ReorderOptionsInProductPayload";
  product?: Maybe<Product>;
};

export type ReorderProductMediaPayload = {
  __typename?: "ReorderProductMediaPayload";
  product?: Maybe<Product>;
};

export type ReorderProductsInCollectionPayload = {
  __typename?: "ReorderProductsInCollectionPayload";
  collection?: Maybe<ProductCollection>;
};

export type RequestError = {
  __typename?: "RequestError";
  code?: Maybe<ShippingErrors>;
  externalErrorCode?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  params?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export enum RequestStatus {
  Failed = "FAILED",
  Pending = "PENDING",
  Succeeded = "SUCCEEDED",
}

export enum RequestType {
  BulkTranslate = "BULK_TRANSLATE",
  BulkUpload = "BULK_UPLOAD",
}

export type Resource = Site | Store;

export type ResourceSubscription = {
  __typename?: "ResourceSubscription";
  plan: Plan;
  resourceId: Scalars["String"]["output"];
  resourceName: Scalars["String"]["output"];
  subscription: PlanSubscription;
};

export type ReviewEdge = {
  __typename?: "ReviewEdge";
  cursor: Scalars["ConnectionCursor"]["output"];
  node?: Maybe<ProductReview>;
};

export type ReviewFilters = {
  ratings?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  status?: InputMaybe<ReviewStatus>;
  storeId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ReviewManagementInput = {
  isPublished?: InputMaybe<Scalars["Boolean"]["input"]>;
  isViewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  reply?: InputMaybe<Scalars["String"]["input"]>;
};

export enum ReviewStatus {
  Published = "Published",
  Unpublished = "Unpublished",
}

export type ReviewsConnection = {
  __typename?: "ReviewsConnection";
  edges?: Maybe<Array<Maybe<ReviewEdge>>>;
  nodes?: Maybe<Array<ProductReview>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ReviewsConnectionInput = {
  first?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<ReviewsSortByField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export enum ReviewsSortByField {
  CreatedAt = "createdAt",
  Rating = "rating",
  Status = "status",
  UpdatedAt = "updatedAt",
}

export type RobotsTxt = {
  __typename?: "RobotsTxt";
  content: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  storeId: Scalars["ID"]["output"];
};

export enum Role {
  Admin = "ADMIN",
  System = "SYSTEM",
  User = "USER",
}

export type SchemaFilterInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type SearchAndFilterResults = {
  __typename?: "SearchAndFilterResults";
  products?: Maybe<Array<Maybe<Product>>>;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type SearchReviewsInput = {
  connection?: InputMaybe<ReviewsConnectionInput>;
  filters?: InputMaybe<ReviewFilters>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["String"]["input"];
};

export enum SearchingMechanism {
  AllProductSearch = "AllProductSearch",
  CategorySearch = "CategorySearch",
  TextSearch = "TextSearch",
}

export type SectionAttributesRow = {
  __typename?: "SectionAttributesRow";
  attributeValues: Array<ProductAttributeValue>;
  background?: Maybe<SectionBackground>;
  displayAs: DisplayAsEnum;
  header: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  itemsPerRow: Scalars["Int"]["output"];
  locale: Scalars["String"]["output"];
  showItemName: Scalars["Boolean"]["output"];
  showSectionHeader: Scalars["Boolean"]["output"];
  storeId: Scalars["ID"]["output"];
  stretchToFullWidth: Scalars["Boolean"]["output"];
  translations: Array<SectionAttributesTranslation>;
  type: SectionTypeEnum;
};

export type SectionAttributesRowBody = {
  __typename?: "SectionAttributesRowBody";
  attributeValues: Array<ProductAttributeValue>;
};

export type SectionAttributesTranslation = {
  __typename?: "SectionAttributesTranslation";
  header?: Maybe<Scalars["String"]["output"]>;
  locale: Scalars["String"]["output"];
};

export type SectionBackground = {
  __typename?: "SectionBackground";
  color?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<SectionBackgroundTypeEnum>;
};

export enum SectionBackgroundTypeEnum {
  Color = "COLOR",
  None = "NONE",
}

export type SectionBanners = {
  __typename?: "SectionBanners";
  banners?: Maybe<Array<Banner>>;
  type: SectionTypeEnum;
};

export type SectionBody =
  | SectionAttributesRow
  | SectionBanners
  | SectionCollectionsRow
  | SectionContent
  | SectionProductsRow
  | SectionVideos;

export type SectionCollectionsRow = {
  __typename?: "SectionCollectionsRow";
  background?: Maybe<SectionBackground>;
  collections: Array<ProductCollection>;
  displayAs: DisplayAsEnum;
  header: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  itemsPerRow: Scalars["Int"]["output"];
  locale: Scalars["String"]["output"];
  showItemName: Scalars["Boolean"]["output"];
  showSectionHeader: Scalars["Boolean"]["output"];
  storeId: Scalars["ID"]["output"];
  stretchToFullWidth: Scalars["Boolean"]["output"];
  translations: Array<StorePageCollectionsTranslation>;
  type: SectionTypeEnum;
};

export type SectionCollectionsRowBody = {
  __typename?: "SectionCollectionsRowBody";
  collections: Array<ProductCollection>;
};

export type SectionContent = {
  __typename?: "SectionContent";
  content: Scalars["String"]["output"];
  type: SectionTypeEnum;
};

export type SectionProductsRow = {
  __typename?: "SectionProductsRow";
  background?: Maybe<SectionBackground>;
  displayAs: DisplayAsEnum;
  fromCollection?: Maybe<ProductCollection>;
  header: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  itemsPerRow: Scalars["Int"]["output"];
  locale: Scalars["String"]["output"];
  products: Array<Maybe<Product>>;
  productsDisplayNumber: Scalars["Int"]["output"];
  showSectionHeader: Scalars["Boolean"]["output"];
  storeId: Scalars["ID"]["output"];
  stretchToFullWidth: Scalars["Boolean"]["output"];
  translations: Array<StorePageProductsTranslation>;
  type: SectionTypeEnum;
};

export type SectionProductsRowBody = {
  __typename?: "SectionProductsRowBody";
  fromCollection?: Maybe<ProductCollection>;
  products: Array<Maybe<Product>>;
  productsDisplayNumber: Scalars["Int"]["output"];
};

export type SectionTranslations = {
  __typename?: "SectionTranslations";
  banners?: Maybe<Array<Banner>>;
  content?: Maybe<Scalars["String"]["output"]>;
  header?: Maybe<Scalars["String"]["output"]>;
  locale?: Maybe<Locale>;
};

export enum SectionTypeEnum {
  AttributesRow = "ATTRIBUTES_ROW",
  Banner = "BANNER",
  CollectionsRow = "COLLECTIONS_ROW",
  Embed = "EMBED",
  ProductsRow = "PRODUCTS_ROW",
  Text = "TEXT",
  Video = "VIDEO",
}

export type SectionVideos = {
  __typename?: "SectionVideos";
  type: SectionTypeEnum;
  videosUrls?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type SelectedOption = {
  __typename?: "SelectedOption";
  option: ProductOption;
  value: ProductOptionValue;
};

export type SelectedVariant = {
  __typename?: "SelectedVariant";
  price?: Maybe<Money>;
  productVariant?: Maybe<ProductVariant>;
  quantity: Scalars["Int"]["output"];
  selectedOptions: Array<SelectedVariantOption>;
  variantSnapshot?: Maybe<ProductVariantSnapshot>;
};

export type SelectedVariantInput = {
  categoryId: Scalars["ID"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  quantity: Scalars["Int"]["input"];
  variantId: Scalars["ID"]["input"];
};

export type SelectedVariantOption = {
  __typename?: "SelectedVariantOption";
  name: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type SeoInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
};

export type Settings =
  | BostaIntegrationSettings
  | FacebookSalesChannelSettings
  | FatooraSettings
  | GoogleMerchantSalesChannelSettings
  | IntegrationProviderNoSettings
  | KanwatIntegrationSettings
  | KlaviyoIntegrationSettings
  | MailchimpIntegrationSettings
  | OptimonkIntegrationSettings
  | OtoIntegrationSettings
  | SnapChatSalesChannelSettings
  | TikTokSalesChannelSettings;

export type ShipOrderResponse = {
  __typename?: "ShipOrderResponse";
  errors: Array<Maybe<RequestError>>;
  orderId: Scalars["String"]["output"];
};

export type ShipOrdersInput = {
  ordersToShip: Array<OrdersToShip>;
  provider: ShippingProviders;
  storeId: Scalars["ID"]["input"];
};

export type ShipmentDetails = {
  __typename?: "ShipmentDetails";
  airWayBill?: Maybe<Scalars["String"]["output"]>;
  deliveryCompany?: Maybe<Scalars["String"]["output"]>;
  deliveryDate?: Maybe<Scalars["DateTime"]["output"]>;
  orderTrackingNumber?: Maybe<Scalars["String"]["output"]>;
  shippedWith?: Maybe<Scalars["String"]["output"]>;
  shippingStatus?: Maybe<Scalars["String"]["output"]>;
  timestamp?: Maybe<Scalars["DateTime"]["output"]>;
  trackingURL?: Maybe<Scalars["String"]["output"]>;
  trials?: Maybe<Scalars["Int"]["output"]>;
};

export type ShipmentMaterialActions = {
  __typename?: "ShipmentMaterialActions";
  calculateOrderShippingFees: Money;
  cancelMaterialOrder: MaterialOrder;
  createMaterialOrder: MaterialOrder;
};

export type ShipmentMaterialActionsCalculateOrderShippingFeesArgs = {
  provider: WuiltShipmentProvider;
  shippingAddress: OrderMaterialShippingDetailsInput;
};

export type ShipmentMaterialActionsCancelMaterialOrderArgs = {
  id: Scalars["ID"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
};

export type ShipmentMaterialActionsCreateMaterialOrderArgs = {
  input: CreateMaterialOrderInput;
};

export type ShipmentMaterialQueries = {
  __typename?: "ShipmentMaterialQueries";
  materialOrder?: Maybe<MaterialOrder>;
  materialOrders?: Maybe<MaterialOrderConnection>;
  materials: Array<Material>;
};

export type ShipmentMaterialQueriesMaterialOrderArgs = {
  id: Scalars["ID"]["input"];
};

export type ShipmentMaterialQueriesMaterialOrdersArgs = {
  connection?: InputMaybe<MaterialOrdersConnectionInput>;
  filter?: InputMaybe<MaterialOrdersFilterInput>;
};

export type ShipmentMaterialQueriesMaterialsArgs = {
  provider?: InputMaybe<WuiltShipmentProvider>;
};

export enum ShipmentStatus {
  Unknown = "Unknown",
  AddressConfirmed = "addressConfirmed",
  ArrivedDestination = "arrivedDestination",
  ArrivedDestinationTerminal = "arrivedDestinationTerminal",
  ArrivedOriginTerminal = "arrivedOriginTerminal",
  ArrivedPickup = "arrivedPickup",
  AssignedToWarehouse = "assignedToWarehouse",
  BranchAssigned = "branchAssigned",
  Canceled = "canceled",
  ConfirmedReturn = "confirmedReturn",
  Delivered = "delivered",
  Failed = "failed",
  GoingToPickup = "goingToPickup",
  InTransit = "inTransit",
  New = "new",
  OutForDelivery = "outForDelivery",
  PickedUp = "pickedUp",
  PickupFromStore = "pickupFromStore",
  ReturnProcessing = "returnProcessing",
  ReturnShipmentProcessing = "returnShipmentProcessing",
  Returned = "returned",
  SearchingDriver = "searchingDriver",
  SentTo = "sent_to",
  ShipmentCanceled = "shipmentCanceled",
  ShipmentCreated = "shipmentCreated",
  ShipmentInProgress = "shipmentInProgress",
  ShipmentOnHold = "shipmentOnHold",
  ShipmentOnHoldToCancel = "shipmentOnHoldToCancel",
  ShipmentOnHoldWarehouse = "shipmentOnHoldWarehouse",
  UndeliveredAttempt = "undeliveredAttempt",
  WaitingAddressConfirmation = "waitingAddressConfirmation",
}

export enum ShipmentType {
  Delivery = "DELIVERY",
  Return = "RETURN",
}

export type ShippingCostDetails = {
  __typename?: "ShippingCostDetails";
  baseCost?: Maybe<Scalars["Float"]["output"]>;
  baseWeightLimit?: Maybe<Scalars["Float"]["output"]>;
  extraWeightCost?: Maybe<Scalars["Float"]["output"]>;
  extraWeightStep?: Maybe<Scalars["Float"]["output"]>;
};

export type ShippingDetailsInput = {
  addressLine1: Scalars["String"]["input"];
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  area: AddressAreaInput;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  secondPhone?: InputMaybe<Scalars["String"]["input"]>;
};

export enum ShippingErrors {
  BostaError = "BOSTA_ERROR",
  CannotChangeShipmentStatus = "CANNOT_CHANGE_SHIPMENT_STATUS",
  CannotReturnNonCompletedShipment = "CANNOT_RETURN_NON_COMPLETED_SHIPMENT",
  CodAmountExceeded = "COD_AMOUNT_EXCEEDED",
  InvalidAddress = "INVALID_ADDRESS",
  InvalidCity = "INVALID_CITY",
  InvalidDistrict = "INVALID_DISTRICT",
  InvalidPackageSize = "INVALID_PACKAGE_SIZE",
  InvalidPackageType = "INVALID_PACKAGE_TYPE",
  InvalidPhoneNumber = "INVALID_PHONE_NUMBER",
  MismatchedProvider = "MISMATCHED_PROVIDER",
  MissingAdditionalInfo = "MISSING_ADDITIONAL_INFO",
  NonWorkingDay = "NON_WORKING_DAY",
  PickupDueDateInvalid = "PICKUP_DUE_DATE_INVALID",
  PickupLocationNotFound = "PICKUP_LOCATION_NOT_FOUND",
  PickupLocationNotRegisteredAtProvider = "PICKUP_LOCATION_NOT_REGISTERED_AT_PROVIDER",
  PickupLocationNotSupported = "PICKUP_LOCATION_NOT_SUPPORTED",
  PickupTimeExceeded = "PICKUP_TIME_EXCEEDED",
  ProviderNotAvailable = "PROVIDER_NOT_AVAILABLE",
  ProviderNotAvailableHoliday = "PROVIDER_NOT_AVAILABLE_HOLIDAY",
  PushOrderFailed = "PUSH_ORDER_FAILED",
  RefundCodAmountExceeded = "REFUND_COD_AMOUNT_EXCEEDED",
  ShipmentAlreadyExists = "SHIPMENT_ALREADY_EXISTS",
  ShipmentAlreadyOngoing = "SHIPMENT_ALREADY_ONGOING",
  ShipmentNotFound = "SHIPMENT_NOT_FOUND",
  UnexpectedError = "UNEXPECTED_ERROR",
  WalletChargeFailed = "WALLET_CHARGE_FAILED",
  ZoneNotSupported = "ZONE_NOT_SUPPORTED",
}

export type ShippingInfoInput = {
  provider?: InputMaybe<WuiltShipmentProvider>;
  shippingDetails: ShippingDetailsInput;
  shippingRateId: Scalars["ID"]["input"];
};

export type ShippingPayload = {
  __typename?: "ShippingPayload";
  payload?: Maybe<ShipPayload>;
};

export type ShippingProviderLocation = {
  __typename?: "ShippingProviderLocation";
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export enum ShippingProviders {
  Bosta = "Bosta",
  Oto = "OTO",
}

export type ShippingRate = Node & {
  __typename?: "ShippingRate";
  cost: Money;
  id: Scalars["ID"]["output"];
  isArchived: Scalars["Boolean"]["output"];
  locale: Scalars["String"]["output"];
  locales: Array<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  shippingZone: ShippingZone;
  storeId: Scalars["ID"]["output"];
  translations?: Maybe<Array<ShippingRateTranslation>>;
};

export type ShippingRateInput = {
  cost: MoneyInput;
  name: Scalars["String"]["input"];
  shippingZoneId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
  translations?: InputMaybe<Array<InputMaybe<ShippingRateTranslationInput>>>;
};

export type ShippingRateTranslation = {
  __typename?: "ShippingRateTranslation";
  locale: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
};

export type ShippingRateTranslationInput = {
  locale: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type ShippingRatesInput = {
  cityId?: InputMaybe<Scalars["ID"]["input"]>;
  countryId?: InputMaybe<Scalars["ID"]["input"]>;
  regionId?: InputMaybe<Scalars["ID"]["input"]>;
  stateId?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum ShippingStatus {
  DataFullfilled = "DATA_FULLFILLED",
  Pending = "PENDING",
}

export type ShippingZone = {
  __typename?: "ShippingZone";
  countries?: Maybe<ZoneCountryConnection>;
  country?: Maybe<ZoneCountry>;
  id: Scalars["ID"]["output"];
  isArchived: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  settings?: Maybe<ShippingZoneSettings>;
  shippingRates?: Maybe<Array<Maybe<ShippingRate>>>;
  storeId: Scalars["ID"]["output"];
  zonePaymentMethods?: Maybe<StoreZoneAllowedMethods>;
};

export type ShippingZoneCountryArgs = {
  id: Scalars["ID"]["input"];
};

export type ShippingZoneShippingRatesArgs = {
  filter?: InputMaybe<ShippingRatesFilterInput>;
};

export type ShippingZoneConnection = {
  __typename?: "ShippingZoneConnection";
  edges?: Maybe<Array<Maybe<ShippingZoneEdge>>>;
  nodes?: Maybe<Array<Maybe<ShippingZone>>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ShippingZoneEdge = {
  __typename?: "ShippingZoneEdge";
  cursor: Scalars["ConnectionCursor"]["output"];
  node?: Maybe<ShippingZone>;
};

export type ShippingZoneInput = {
  countries: Array<InputMaybe<ZoneCountryInput>>;
  name: Scalars["String"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type ShippingZoneSettings = {
  __typename?: "ShippingZoneSettings";
  isAdvanced?: Maybe<Scalars["Boolean"]["output"]>;
  isCityLevelActive?: Maybe<Scalars["Boolean"]["output"]>;
  isRegionLevelActive?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ShippingZoneSettingsInput = {
  isAdvanced?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCityLevelActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRegionLevelActive?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum ShippingZoneSortByField {
  CreatedAt = "createdAt",
  Id = "id",
  UpdatedAt = "updatedAt",
}

export type ShippingZonesConnectionInput = {
  after?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  before?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  first?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  last?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<ShippingZoneSortByField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type SimpleItem = BaseItem & {
  __typename?: "SimpleItem";
  discount?: Maybe<OrderItemDiscountDetails>;
  discounts?: Maybe<Array<OrderItemDiscountDetails>>;
  fulfilledItems?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  price?: Maybe<Money>;
  product?: Maybe<Product>;
  productSnapshot?: Maybe<ProductSnapshot>;
  productTax?: Maybe<Scalars["Float"]["output"]>;
  productVariant?: Maybe<ProductVariant>;
  quantity: Scalars["Int"]["output"];
  selectedOptions: Array<SelectedVariantOption>;
  sourceType: OrderItemSourceType;
  subtotal?: Maybe<Money>;
  taxable?: Maybe<Scalars["Boolean"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  variantSnapshot?: Maybe<ProductVariantSnapshot>;
};

export type SimpleItemInput = {
  itemId?: InputMaybe<Scalars["ID"]["input"]>;
  quantity: Scalars["Int"]["input"];
  variantId: Scalars["ID"]["input"];
};

export type Site = {
  __typename?: "Site";
  activeSubscription?: Maybe<PlanSubscription>;
  id: Scalars["ID"]["output"];
  plan?: Maybe<Plan>;
  registeredDomain?: Maybe<RegisteredDomain>;
  subscription?: Maybe<PlanSubscription>;
  subscriptions: Array<PlanSubscription>;
};

export type SnapChatSalesChannelSettings = {
  __typename?: "SnapChatSalesChannelSettings";
  password: Scalars["String"]["output"];
  provider: ProviderName;
  username: Scalars["String"]["output"];
};

export type SnapshotForSearch = {
  __typename?: "SnapshotForSearch";
  customerEmail?: Maybe<Scalars["String"]["output"]>;
  customerName?: Maybe<Scalars["String"]["output"]>;
  customerPhone?: Maybe<Scalars["String"]["output"]>;
  productTitle?: Maybe<Scalars["String"]["output"]>;
};

export type SocialIntegrationSettingsInput = {
  password: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  username: Scalars["String"]["input"];
};

export type SocialLink = {
  __typename?: "SocialLink";
  link?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type SocialLinkInput = {
  link: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type SocialLinkType = {
  __typename?: "SocialLinkType";
  link?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export enum SortOrder {
  Asc = "asc",
  Desc = "desc",
}

export enum SourceType {
  Integration = "INTEGRATION",
  Manual = "MANUAL",
}

export type StagedMediaUploadTarget = {
  __typename?: "StagedMediaUploadTarget";
  parameters: Array<StagedUploadParameter>;
  resourceUrl: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type StagedUploadCreateInput = {
  fileSize?: InputMaybe<Scalars["String"]["input"]>;
  filename: Scalars["String"]["input"];
  httpMethod: Scalars["String"]["input"];
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  resource?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type StagedUploadParameter = {
  __typename?: "StagedUploadParameter";
  name?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type StagedUploadsCreatePayload = {
  __typename?: "StagedUploadsCreatePayload";
  stagedTargets: Array<StagedMediaUploadTarget>;
};

export type State = Node & {
  __typename?: "State";
  bostaMapping?: Maybe<BostaMappedState>;
  cities?: Maybe<CityConnection>;
  city?: Maybe<City>;
  code?: Maybe<Scalars["String"]["output"]>;
  countryId?: Maybe<Scalars["ID"]["output"]>;
  id: Scalars["ID"]["output"];
  isPredefined?: Maybe<Scalars["Boolean"]["output"]>;
  isReserved?: Maybe<Scalars["Boolean"]["output"]>;
  mappedTo?: Maybe<StateProviderMapping>;
  name: Scalars["String"]["output"];
  numOfCities?: Maybe<Scalars["Int"]["output"]>;
  storeId?: Maybe<Scalars["ID"]["output"]>;
};

export type StateBostaMappingArgs = {
  locale: Scalars["String"]["input"];
};

export type StateCityArgs = {
  id: Scalars["ID"]["input"];
};

export type StateMappedToArgs = {
  locale: Scalars["String"]["input"];
  provider: ShippingProviders;
};

export type StateConnection = {
  __typename?: "StateConnection";
  edges?: Maybe<Array<Maybe<StateEdge>>>;
  nodes?: Maybe<Array<Maybe<State>>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type StateEdge = {
  __typename?: "StateEdge";
  cursor: Scalars["ConnectionCursor"]["output"];
  node?: Maybe<State>;
};

export type StateInput = {
  cities: Array<InputMaybe<CityInput>>;
  id: Scalars["ID"]["input"];
};

export type StateProviderMapping = BostaMappedState | OtoMappedState;

export type StateUnion = StateConnection | ZoneStateConnection;

export enum StaticPageEnum {
  Contact = "Contact",
  Home = "Home",
  Shop = "Shop",
}

export type StaticPageStoreByHandleInput = {
  handle?: InputMaybe<Scalars["String"]["input"]>;
  storeId?: InputMaybe<Scalars["String"]["input"]>;
};

export type StockActions = {
  __typename?: "StockActions";
  updateProductVariantsPackageDetails?: Maybe<Product>;
  updateVariant?: Maybe<StockUpdateActionResponse>;
};

export type StockActionsUpdateProductVariantsPackageDetailsArgs = {
  packageDetails: ProductPackageDetailsInput;
  productId: Scalars["ID"]["input"];
};

export type StockActionsUpdateVariantArgs = {
  id: Scalars["ID"]["input"];
  input: UpdateStockItemInput;
};

export enum StockError {
  InconsistentCartLimits = "INCONSISTENT_CART_LIMITS",
  InconsistentPricing = "INCONSISTENT_PRICING",
  InvalidCartMax = "INVALID_CART_MAX",
  InvalidCartMin = "INVALID_CART_MIN",
  InvalidCostPrice = "INVALID_COST_PRICE",
  InvalidQuantity = "INVALID_QUANTITY",
  InvalidRegularPrice = "INVALID_REGULAR_PRICE",
  InvalidSalePrice = "INVALID_SALE_PRICE",
  ItemNotFound = "ITEM_NOT_FOUND",
  StockUpdateFailed = "STOCK_UPDATE_FAILED",
}

export type StockItem = {
  __typename?: "StockItem";
  cartLimitsEnabled: Scalars["Boolean"]["output"];
  cost?: Maybe<Money>;
  id: Scalars["ID"]["output"];
  maxPerCart?: Maybe<Scalars["Int"]["output"]>;
  minPerCart?: Maybe<Scalars["Int"]["output"]>;
  packageDetails?: Maybe<ProductPackageDetails>;
  quantity?: Maybe<Scalars["Int"]["output"]>;
  regularPrice: Money;
  salePrice?: Maybe<Money>;
  sku?: Maybe<Scalars["String"]["output"]>;
  trackQuantity: Scalars["Boolean"]["output"];
};

export type StockUpdateActionResponse = {
  __typename?: "StockUpdateActionResponse";
  data?: Maybe<StockItem>;
  errors?: Maybe<Array<StockError>>;
};

export type Store = Node & {
  __typename?: "Store";
  StoreHomePageSections?: Maybe<Array<Maybe<StorePageSection>>>;
  activeSubscription?: Maybe<PlanSubscription>;
  adminContactInfo?: Maybe<ContactInfo>;
  analyticalAccounts?: Maybe<Array<Maybe<AnalyticalAccount>>>;
  appearance?: Maybe<StoreAppearance>;
  areReviewsActivated: Scalars["Boolean"]["output"];
  automaticDiscounts?: Maybe<Array<Maybe<Discount>>>;
  baseLocale: Scalars["String"]["output"];
  cart?: Maybe<Cart>;
  checkout?: Maybe<Checkout>;
  collections?: Maybe<ProductCollectionConnection>;
  contactInfo?: Maybe<ContactInfo>;
  countries?: Maybe<CountryConnection>;
  country?: Maybe<Country>;
  createdAt: Scalars["DateTime"]["output"];
  currency: Scalars["String"]["output"];
  currencyRates?: Maybe<Array<Maybe<CurrencyRate>>>;
  currentSubscription?: Maybe<PlanSubscription>;
  customCheckoutSetting?: Maybe<CustomCheckoutSetting>;
  defaultLocale: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  domain?: Maybe<Domain>;
  expiredAt?: Maybe<Scalars["DateTime"]["output"]>;
  fallbackLocale: Scalars["String"]["output"];
  favIcon?: Maybe<Image>;
  handle: Scalars["String"]["output"];
  homeCatalog?: Maybe<StoreHomeCatalog>;
  homeCollections?: Maybe<StoreHomeCollectionsRows>;
  homeProducts?: Maybe<StoreHomeProductsRows>;
  id: Scalars["ID"]["output"];
  industry?: Maybe<Scalars["String"]["output"]>;
  integrationScriptControls?: Maybe<Array<IntegrationScriptControls>>;
  isExpired?: Maybe<Scalars["Boolean"]["output"]>;
  legalPages?: Maybe<Array<Maybe<StoreLegalPage>>>;
  locale: Scalars["String"]["output"];
  locales: Array<Scalars["String"]["output"]>;
  location?: Maybe<Location>;
  logo?: Maybe<StoreLogo>;
  maintenanceMode?: Maybe<MaintenanceMode>;
  name: Scalars["String"]["output"];
  orderHistory?: Maybe<Array<Maybe<OrderHistory>>>;
  orders?: Maybe<OrderConnection>;
  owner?: Maybe<User>;
  pages?: Maybe<Array<Maybe<StorePage>>>;
  plan?: Maybe<Plan>;
  products: ProductConnection;
  redirects?: Maybe<Array<StoreRedirect>>;
  registeredDomain?: Maybe<RegisteredDomain>;
  robotsTxt?: Maybe<RobotsTxt>;
  seo?: Maybe<StoreSeo>;
  shippingRate?: Maybe<ShippingRate>;
  shippingRates?: Maybe<Array<Maybe<ShippingRate>>>;
  shippingZone?: Maybe<ShippingZone>;
  shippingZones?: Maybe<ShippingZoneConnection>;
  socialMedia?: Maybe<Array<Maybe<SocialLinkType>>>;
  /** @deprecated use legalPages instead */
  staticPages?: Maybe<Array<Maybe<StoreStaticPage>>>;
  storeReviewSettings?: Maybe<StoreReviewsSettings>;
  subscription?: Maybe<PlanSubscription>;
  subscriptions: Array<PlanSubscription>;
  supportedLocales: Array<Scalars["String"]["output"]>;
  tax?: Maybe<Tax>;
  timezone?: Maybe<Scalars["String"]["output"]>;
  translations: Array<StoreTranslation>;
  trialRemainingDays: Scalars["Int"]["output"];
  unViewedOrders?: Maybe<Scalars["Int"]["output"]>;
  unViewedReviews: Scalars["Int"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  userId: Scalars["ID"]["output"];
  wallet?: Maybe<Wallet>;
  weekOrders?: Maybe<Scalars["Int"]["output"]>;
  weekRevenue?: Maybe<Money>;
  wuiltShipment?: Maybe<WuiltShipment>;
};

export type StoreCollectionsArgs = {
  connection?: InputMaybe<ProductCollectionConnectionInput>;
  filter?: InputMaybe<ProductCollectionFilterInput>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type StoreCountryArgs = {
  id: Scalars["ID"]["input"];
};

export type StoreOrdersArgs = {
  connection?: InputMaybe<OrdersConnectionInput>;
};

export type StoreProductsArgs = {
  connection?: InputMaybe<ProductsConnectionInput>;
  filter?: InputMaybe<ProductsFilterInput>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type StoreShippingRateArgs = {
  id: Scalars["ID"]["input"];
};

export type StoreShippingRatesArgs = {
  input?: InputMaybe<ShippingRatesInput>;
};

export type StoreShippingZoneArgs = {
  id: Scalars["ID"]["input"];
};

export type StoreShippingZonesArgs = {
  connection?: InputMaybe<ShippingZonesConnectionInput>;
};

export type StoreAboutTranslation = {
  __typename?: "StoreAboutTranslation";
  content?: Maybe<Scalars["String"]["output"]>;
  locale: Scalars["String"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
};

export type StoreAdminReply = {
  __typename?: "StoreAdminReply";
  content?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type StoreAnalytics = {
  __typename?: "StoreAnalytics";
  dashboardAnalytics?: Maybe<DashboardAnalytics>;
  orderAnalytics?: Maybe<OrderAnalytics>;
};

export type StoreAnalyticsDashboardAnalyticsArgs = {
  topSellingProductsLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StoreApiKeyPermissions = {
  __typename?: "StoreApiKeyPermissions";
  permissions: Array<StoreUserPermission>;
  storeId: Scalars["ID"]["output"];
};

export type StoreAppearance = {
  __typename?: "StoreAppearance";
  about?: Maybe<AboutType>;
  colors?: Maybe<ThemeColors>;
  fonts?: Maybe<Array<Maybe<FontType>>>;
  footer?: Maybe<FooterType>;
  heroSlider?: Maybe<Array<Maybe<StoreHomeHero>>>;
  infoBar?: Maybe<InfoBar>;
  menus?: Maybe<Array<Maybe<StoreMenu>>>;
  productDisplay?: Maybe<ProductDisplaySettings>;
  template?: Maybe<Template>;
};

export type StoreBannerInput = {
  bannerLink?: InputMaybe<LinkInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<ImageInput>;
  mobileImage?: InputMaybe<ImageInput>;
};

export type StoreBranchLocation = {
  __typename?: "StoreBranchLocation";
  address?: Maybe<Scalars["String"]["output"]>;
  location?: Maybe<GeoLocation>;
};

export type StoreBranchLocationInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<GeoLocationInput>;
};

export type StoreCard = {
  __typename?: "StoreCard";
  brand: Scalars["String"]["output"];
  expMonth: Scalars["String"]["output"];
  expYear: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  last4: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type StoreCheckoutInput = {
  locale: Scalars["String"]["input"];
  successMessage?: InputMaybe<Scalars["String"]["input"]>;
};

export type StoreCheckoutTranslation = {
  __typename?: "StoreCheckoutTranslation";
  locale: Scalars["String"]["output"];
  successMessage?: Maybe<Scalars["String"]["output"]>;
};

export enum StoreCitiesByField {
  CreatedAt = "createdAt",
  Id = "id",
  UpdatedAt = "updatedAt",
}

export type StoreConnection = {
  __typename?: "StoreConnection";
  nodes?: Maybe<Array<Maybe<Store>>>;
  totalCount: Scalars["Int"]["output"];
};

export type StoreConnectionInput = {
  after?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  before?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  first?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  last?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<StoreSortByField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type StoreDataInput = {
  domain: Scalars["String"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type StoreFontsInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  fontFamily?: InputMaybe<Scalars["String"]["input"]>;
  isItalic?: InputMaybe<Scalars["Boolean"]["input"]>;
  locale: Scalars["String"]["input"];
  type?: InputMaybe<FontTypeEnum>;
  typeFace?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  weight?: InputMaybe<Scalars["String"]["input"]>;
};

export type StoreFontsTranslationInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  fontFamily?: InputMaybe<Scalars["String"]["input"]>;
  isItalic?: InputMaybe<Scalars["Boolean"]["input"]>;
  locale: Scalars["String"]["input"];
  type?: InputMaybe<FontTypeEnum>;
  typeFace?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  weight?: InputMaybe<Scalars["String"]["input"]>;
};

export type StoreFooterTranslation = {
  __typename?: "StoreFooterTranslation";
  customPart?: Maybe<Scalars["String"]["output"]>;
  endPart?: Maybe<Scalars["String"]["output"]>;
  locale: Scalars["String"]["output"];
  startPart?: Maybe<Scalars["String"]["output"]>;
};

export type StoreGateway = {
  __typename?: "StoreGateway";
  id: Scalars["ID"]["output"];
  provider?: Maybe<StorePaymentProvider>;
};

export type StoreHomeAttributes = {
  __typename?: "StoreHomeAttributes";
  attributeValues?: Maybe<Array<ProductAttributeValue>>;
  attributeValuesPerRow?: Maybe<Scalars["Int"]["output"]>;
  displayAs?: Maybe<DisplayAsEnum>;
  headerText?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  locale: Scalars["String"]["output"];
  showAttributeName?: Maybe<Scalars["Boolean"]["output"]>;
  stretchToFullWidth?: Maybe<Scalars["Boolean"]["output"]>;
  translations: Array<StoreHomeAttributesTranslation>;
};

export type StoreHomeAttributesInput = {
  attributeValuesIds: Array<Scalars["ID"]["input"]>;
  attributeValuesPerRow?: InputMaybe<Scalars["Int"]["input"]>;
  displayAs?: InputMaybe<DisplayAsEnum>;
  headerText?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale: Scalars["String"]["input"];
  showAttributeName?: InputMaybe<Scalars["Boolean"]["input"]>;
  stretchToFullWidth?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StoreHomeAttributesRows = {
  __typename?: "StoreHomeAttributesRows";
  id: Scalars["ID"]["output"];
  rows: Array<StoreHomeAttributes>;
};

export type StoreHomeAttributesTranslation = {
  __typename?: "StoreHomeAttributesTranslation";
  headerText?: Maybe<Scalars["String"]["output"]>;
  locale: Scalars["String"]["output"];
};

export type StoreHomeAttributesTranslationInput = {
  headerText?: InputMaybe<Scalars["String"]["input"]>;
  locale: Scalars["String"]["input"];
  rowId: Scalars["ID"]["input"];
};

export type StoreHomeCatalog = {
  __typename?: "StoreHomeCatalog";
  attributesRows?: Maybe<Array<StoreHomeAttributes>>;
  collectionsRows?: Maybe<Array<StoreHomeCollections>>;
  id: Scalars["ID"]["output"];
  productsRows?: Maybe<Array<StoreHomeProducts>>;
};

export type StoreHomeCollections = {
  __typename?: "StoreHomeCollections";
  collections?: Maybe<Array<Maybe<ProductCollection>>>;
  collectionsPerRow?: Maybe<Scalars["Int"]["output"]>;
  displayAs?: Maybe<DisplayAsEnum>;
  headerText?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  locale: Scalars["String"]["output"];
  showCollectionName?: Maybe<Scalars["Boolean"]["output"]>;
  stretchToFullWidth?: Maybe<Scalars["Boolean"]["output"]>;
  translations: Array<StoreHomeCollectionsTranslation>;
};

export type StoreHomeCollectionsInput = {
  collectionsId: Array<Scalars["ID"]["input"]>;
  collectionsPerRow?: InputMaybe<Scalars["Int"]["input"]>;
  displayAs?: InputMaybe<DisplayAsEnum>;
  headerText?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale: Scalars["String"]["input"];
  showCollectionName?: InputMaybe<Scalars["Boolean"]["input"]>;
  stretchToFullWidth?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StoreHomeCollectionsRows = {
  __typename?: "StoreHomeCollectionsRows";
  id: Scalars["ID"]["output"];
  rows: Array<Maybe<StoreHomeCollections>>;
};

export type StoreHomeCollectionsTranslation = {
  __typename?: "StoreHomeCollectionsTranslation";
  headerText?: Maybe<Scalars["String"]["output"]>;
  locale: Scalars["String"]["output"];
};

export type StoreHomeCollectionsTranslationInput = {
  headerText?: InputMaybe<Scalars["String"]["input"]>;
  locale: Scalars["String"]["input"];
  rowId: Scalars["ID"]["input"];
};

export type StoreHomeHero = {
  __typename?: "StoreHomeHero";
  align?: Maybe<Scalars["String"]["output"]>;
  buttons?: Maybe<Array<Maybe<Button>>>;
  headingText?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  image?: Maybe<Image>;
  locale?: Maybe<Scalars["String"]["output"]>;
  locales?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  mobileImage?: Maybe<Image>;
  subHeadingColor?: Maybe<Scalars["String"]["output"]>;
  subHeadingSize?: Maybe<Scalars["Int"]["output"]>;
  subHeadingText?: Maybe<Scalars["String"]["output"]>;
  translations?: Maybe<Array<Maybe<StoreHomeHeroTranslation>>>;
};

export type StoreHomeHeroInput = {
  align?: InputMaybe<Scalars["String"]["input"]>;
  buttons?: InputMaybe<Array<InputMaybe<ButtonInput>>>;
  headingText?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<ImageInput>;
  locale: Scalars["String"]["input"];
  mobileImage?: InputMaybe<ImageInput>;
  subHeadingColor?: InputMaybe<Scalars["String"]["input"]>;
  subHeadingSize?: InputMaybe<Scalars["Int"]["input"]>;
  subHeadingText?: InputMaybe<Scalars["String"]["input"]>;
};

export type StoreHomeHeroSliderTranslationInput = {
  heroId: Scalars["ID"]["input"];
  translations?: InputMaybe<Array<InputMaybe<StoreHomeHeroTranslationInput>>>;
};

export type StoreHomeHeroTranslation = {
  __typename?: "StoreHomeHeroTranslation";
  buttons?: Maybe<Array<Maybe<ButtonTranslationItem>>>;
  headingText?: Maybe<Scalars["String"]["output"]>;
  locale: Scalars["String"]["output"];
  subHeadingText?: Maybe<Scalars["String"]["output"]>;
};

export type StoreHomeHeroTranslationInput = {
  buttons?: InputMaybe<Array<InputMaybe<ButtonTranslationItemInput>>>;
  headingText?: InputMaybe<Scalars["String"]["input"]>;
  locale: Scalars["String"]["input"];
  subHeadingText?: InputMaybe<Scalars["String"]["input"]>;
};

export type StoreHomeProducts = {
  __typename?: "StoreHomeProducts";
  displayAs?: Maybe<DisplayAsEnum>;
  fromCollection?: Maybe<ProductCollection>;
  headerText?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  locale?: Maybe<Scalars["String"]["output"]>;
  products?: Maybe<Array<Maybe<Product>>>;
  productsDisplayNumber?: Maybe<Scalars["Int"]["output"]>;
  stretchToFullWidth?: Maybe<Scalars["Boolean"]["output"]>;
  translations: Array<StoreHomeProductsTranslation>;
};

export type StoreHomeProductsInput = {
  displayAs?: InputMaybe<DisplayAsEnum>;
  fromCollectionId: Scalars["ID"]["input"];
  headerText?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale: Scalars["String"]["input"];
  productsDisplayNumber?: InputMaybe<Scalars["Int"]["input"]>;
  stretchToFullWidth?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StoreHomeProductsRows = {
  __typename?: "StoreHomeProductsRows";
  id: Scalars["ID"]["output"];
  rows: Array<StoreHomeProducts>;
};

export type StoreHomeProductsTranslation = {
  __typename?: "StoreHomeProductsTranslation";
  headerText?: Maybe<Scalars["String"]["output"]>;
  locale: Scalars["String"]["output"];
};

export type StoreHomeProductsTranslationInput = {
  headerText?: InputMaybe<Scalars["String"]["input"]>;
  locale: Scalars["String"]["input"];
  rowId: Scalars["ID"]["input"];
};

export type StoreLegalPage = {
  __typename?: "StoreLegalPage";
  handle: Scalars["String"]["output"];
  htmlTemplate?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  locale?: Maybe<Scalars["String"]["output"]>;
  locales?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  name?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  translations?: Maybe<Array<Maybe<StoreLegalPageTranslation>>>;
};

export type StoreLegalPageInput = {
  htmlTemplate?: InputMaybe<Scalars["String"]["input"]>;
  isArchived?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["ID"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type StoreLegalPageTranslation = {
  __typename?: "StoreLegalPageTranslation";
  htmlTemplate?: Maybe<Scalars["String"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type StoreLocation = {
  __typename?: "StoreLocation";
  address: Scalars["String"]["output"];
  lat?: Maybe<Scalars["String"]["output"]>;
  long?: Maybe<Scalars["String"]["output"]>;
};

export type StoreLocationInput = {
  address: Scalars["String"]["input"];
  lat?: InputMaybe<Scalars["String"]["input"]>;
  long?: InputMaybe<Scalars["String"]["input"]>;
};

export type StoreLogo = {
  __typename?: "StoreLogo";
  height?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Image>;
  size?: Maybe<LogoSize>;
  url?: Maybe<Scalars["String"]["output"]>;
  width?: Maybe<Scalars["Int"]["output"]>;
};

export type StoreMenu = {
  __typename?: "StoreMenu";
  handle?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  items?: Maybe<Array<Maybe<StoreMenuItem>>>;
  storeId: Scalars["ID"]["output"];
  title: Scalars["String"]["output"];
};

export type StoreMenuItem = {
  __typename?: "StoreMenuItem";
  handle?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  link?: Maybe<MenuLink>;
  locale?: Maybe<Scalars["String"]["output"]>;
  locales?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  parentId?: Maybe<Scalars["ID"]["output"]>;
  title: Scalars["String"]["output"];
  translations?: Maybe<Array<Maybe<StoreMenuItemTranslation>>>;
  type: MenuItemType;
};

export type StoreMenuItemTranslation = {
  __typename?: "StoreMenuItemTranslation";
  id: Scalars["ID"]["output"];
  locale?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type StoreMenuItemTranslationInput = {
  id: Scalars["ID"]["input"];
  locale: Scalars["String"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type StoreMetrics = {
  __typename?: "StoreMetrics";
  averageOrderValue: Scalars["Float"]["output"];
  totalItemsSold: Scalars["Int"]["output"];
  totalOrders: Scalars["Int"]["output"];
  totalSales: Scalars["Float"]["output"];
};

export type StorePage = {
  __typename?: "StorePage";
  handle: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  locale?: Maybe<Locale>;
  name: Scalars["String"]["output"];
  pageType: StorePageTypeEnum;
  sections: Array<Maybe<StorePageSection>>;
  seo?: Maybe<PageSeo>;
  status: PageStatusEnum;
  storeId: Scalars["ID"]["output"];
  translations?: Maybe<Array<Maybe<StorePageTranslation>>>;
};

export type StorePageCatalogSection = {
  __typename?: "StorePageCatalogSection";
  background?: Maybe<SectionBackground>;
  body?: Maybe<CatalogSectionBody>;
  displayAs: DisplayAsEnum;
  header: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  itemsPerRow: Scalars["Int"]["output"];
  locale: Scalars["String"]["output"];
  showItemName: Scalars["Boolean"]["output"];
  showSectionHeader: Scalars["Boolean"]["output"];
  storeId: Scalars["ID"]["output"];
  stretchToFullWidth: Scalars["Boolean"]["output"];
  translations: Array<Maybe<StorePageCatalogSectionTranslation>>;
  type: SectionTypeEnum;
};

export type StorePageCatalogSectionTranslation = {
  __typename?: "StorePageCatalogSectionTranslation";
  header?: Maybe<Scalars["String"]["output"]>;
  locale: Scalars["String"]["output"];
};

export type StorePageCatalogSectionTranslationInput = {
  header?: InputMaybe<Scalars["String"]["input"]>;
  locale: Scalars["String"]["input"];
};

export type StorePageCollectionsTranslation = {
  __typename?: "StorePageCollectionsTranslation";
  header?: Maybe<Scalars["String"]["output"]>;
  locale: Scalars["String"]["output"];
};

export type StorePageProductsTranslation = {
  __typename?: "StorePageProductsTranslation";
  header?: Maybe<Scalars["String"]["output"]>;
  locale: Scalars["String"]["output"];
};

export type StorePageSection = {
  __typename?: "StorePageSection";
  background?: Maybe<SectionBackground>;
  body: SectionBody;
  displayAs?: Maybe<DisplayAsEnum>;
  header?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  itemsPerRow?: Maybe<Scalars["Int"]["output"]>;
  locale?: Maybe<Locale>;
  showSectionHeader?: Maybe<Scalars["Boolean"]["output"]>;
  storeId: Scalars["ID"]["output"];
  stretchToFullWidth?: Maybe<Scalars["Boolean"]["output"]>;
  translations?: Maybe<Array<Maybe<SectionTranslations>>>;
};

export type StorePageSectionTranslationInput = {
  banners?: InputMaybe<Array<StoreBannerInput>>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  header?: InputMaybe<Scalars["String"]["input"]>;
  locale: Scalars["String"]["input"];
};

export type StorePageTranslation = {
  __typename?: "StorePageTranslation";
  locale?: Maybe<Locale>;
  name?: Maybe<Scalars["String"]["output"]>;
  seo?: Maybe<PageSeo>;
};

export type StorePageTranslationInput = {
  locale: Locale;
  name?: InputMaybe<Scalars["String"]["input"]>;
  seo?: InputMaybe<SeoInput>;
};

export enum StorePageTypeEnum {
  Custom = "CUSTOM",
  Home = "HOME",
}

export type StorePaymentMethod = {
  __typename?: "StorePaymentMethod";
  enabled: Scalars["Boolean"]["output"];
  id?: Maybe<Scalars["ID"]["output"]>;
  type: StorePaymentMethods;
};

export enum StorePaymentMethods {
  CashOnDelivary = "CASH_ON_DELIVARY",
  OnlinePayment = "ONLINE_PAYMENT",
}

export type StorePaymentMethodsPayload = {
  __typename?: "StorePaymentMethodsPayload";
  paymentMethods?: Maybe<Array<Maybe<StorePaymentMethod>>>;
};

export enum StorePaymentProvider {
  Cowpay = "COWPAY",
  Stripe = "STRIPE",
  Vapulus = "VAPULUS",
}

export type StoreProductsCounts = {
  __typename?: "StoreProductsCounts";
  active?: Maybe<Scalars["Int"]["output"]>;
  draft?: Maybe<Scalars["Int"]["output"]>;
  unlisted?: Maybe<Scalars["Int"]["output"]>;
};

export type StoreRedirect = {
  __typename?: "StoreRedirect";
  id: Scalars["String"]["output"];
  newUrl: Scalars["String"]["output"];
  oldUrl: Scalars["String"]["output"];
  storeId: Scalars["String"]["output"];
};

export type StoreReviewsSettings = {
  __typename?: "StoreReviewsSettings";
  enableGuestReviews: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  showCustomerAvatar: Scalars["Boolean"]["output"];
  showOverAllRating: Scalars["Boolean"]["output"];
  showReviewDate: Scalars["Boolean"]["output"];
  showReviewsCount: Scalars["Boolean"]["output"];
  showStars: Scalars["Boolean"]["output"];
  starsColor: Scalars["String"]["output"];
  storeId: Scalars["String"]["output"];
};

export type StoreSeo = {
  __typename?: "StoreSEO";
  description?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type StoreSeoInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export enum StoreSortByField {
  CreatedAt = "createdAt",
  Id = "id",
  Name = "name",
  UpdatedAt = "updatedAt",
}

export type StoreStaticPage = {
  __typename?: "StoreStaticPage";
  handle: Scalars["String"]["output"];
  htmlTemplate?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  locale?: Maybe<Scalars["String"]["output"]>;
  locales?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  name?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  translations?: Maybe<Array<Maybe<StoreStaticPageTranslation>>>;
};

export type StoreStaticPageInput = {
  htmlTemplate?: InputMaybe<Scalars["String"]["input"]>;
  isArchived?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["ID"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type StoreStaticPageTranslation = {
  __typename?: "StoreStaticPageTranslation";
  htmlTemplate?: Maybe<Scalars["String"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type StoreStaticTemplate = {
  __typename?: "StoreStaticTemplate";
  htmlTemplate?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type StoreTranslation = {
  __typename?: "StoreTranslation";
  description?: Maybe<Scalars["String"]["output"]>;
  locale: Scalars["String"]["output"];
  location?: Maybe<Location>;
  name?: Maybe<Scalars["String"]["output"]>;
  seo?: Maybe<StoreSeo>;
};

export type StoreTranslationInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  locale: Scalars["String"]["input"];
  location?: InputMaybe<StoreLocationInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  seo?: InputMaybe<StoreSeoInput>;
};

export type StoreUser = {
  __typename?: "StoreUser";
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  permissions?: Maybe<Array<Maybe<StoreUserPermission>>>;
  phone?: Maybe<Scalars["String"]["output"]>;
  role?: Maybe<PermissionRole>;
  storeId?: Maybe<Scalars["ID"]["output"]>;
};

export type StoreUserConnection = {
  __typename?: "StoreUserConnection";
  nodes?: Maybe<Array<Maybe<StoreUser>>>;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type StoreUserPermission = {
  __typename?: "StoreUserPermission";
  actions?: Maybe<Array<Maybe<PermissionAction>>>;
  resource?: Maybe<PermissionResource>;
};

export type StoreUserPermissionInput = {
  actions: Array<PermissionAction>;
  resource?: InputMaybe<PermissionResource>;
};

export type StoreUsersConnectionInput = {
  after?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  before?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  first?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  last?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StoreZoneAllowedMethods = {
  __typename?: "StoreZoneAllowedMethods";
  id?: Maybe<Scalars["ID"]["output"]>;
  paymentMethods?: Maybe<Array<Maybe<StorePaymentMethod>>>;
  shippingRateIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  shippingZoneId?: Maybe<Scalars["String"]["output"]>;
  storeId?: Maybe<Scalars["String"]["output"]>;
};

export type StoresOrdersInput = {
  orderIds: Array<Scalars["ID"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type StoresStatistics = Node & {
  __typename?: "StoresStatistics";
  id: Scalars["ID"]["output"];
  pendingInstaPayRequests: Scalars["Int"]["output"];
  pendingPayoutsRequests: Scalars["Int"]["output"];
  totalCreatedStores?: Maybe<CreatedStores>;
};

export type StoresStatisticsTotalCreatedStoresArgs = {
  date?: InputMaybe<DateFilterInput>;
};

export type SubmitStoreContactFormInput = {
  fromEmail: Scalars["String"]["input"];
  messageBody: Scalars["String"]["input"];
  messageSubject: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type SubmitStoreContactFormPayload = {
  __typename?: "SubmitStoreContactFormPayload";
  message: Scalars["String"]["output"];
  statusCode: Scalars["Int"]["output"];
};

export type SubscriptionResource = RegisteredDomain | Site | Store;

export enum SubscriptionStatus {
  Active = "ACTIVE",
  Canceled = "CANCELED",
  CancelRequested = "CANCEL_REQUESTED",
  Disputed = "DISPUTED",
  DowngradeRequested = "DOWNGRADE_REQUESTED",
  Expired = "EXPIRED",
  PastDue = "PAST_DUE",
  Pending = "PENDING",
  Refunded = "REFUNDED",
}

export type SyncCartInput = {
  cartId?: InputMaybe<Scalars["ID"]["input"]>;
  contactInfo: CreateContactInfoInput;
  customItems?: InputMaybe<Array<CustomItemInput>>;
  promoCode?: InputMaybe<Scalars["String"]["input"]>;
  refCode?: InputMaybe<Scalars["String"]["input"]>;
  simpleItems?: InputMaybe<Array<SimpleItemInput>>;
};

export type Tax = {
  __typename?: "Tax";
  image?: Maybe<Image>;
  percentageOff: Scalars["Float"]["output"];
};

export type TaxActions = {
  __typename?: "TaxActions";
  updateStoreTax?: Maybe<Tax>;
};

export type TaxActionsUpdateStoreTaxArgs = {
  input: UpdateTaxInput;
};

export type TaxQueries = {
  __typename?: "TaxQueries";
  storeTax?: Maybe<Tax>;
};

export type Template = {
  __typename?: "Template";
  addedAt?: Maybe<Scalars["DateTime"]["output"]>;
  handle?: Maybe<Scalars["String"]["output"]>;
};

export type ThemeColors = {
  __typename?: "ThemeColors";
  inkNormal?: Maybe<Scalars["String"]["output"]>;
  inkSubdued?: Maybe<Scalars["String"]["output"]>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type ThemeColorsInput = {
  inkNormal?: InputMaybe<Scalars["String"]["input"]>;
  inkSubdued?: InputMaybe<Scalars["String"]["input"]>;
  primary?: InputMaybe<Scalars["String"]["input"]>;
};

export type TikTokSalesChannelSettings = {
  __typename?: "TikTokSalesChannelSettings";
  password: Scalars["String"]["output"];
  provider: ProviderName;
  title: Scalars["String"]["output"];
  username: Scalars["String"]["output"];
};

export type TopSellingProduct = {
  __typename?: "TopSellingProduct";
  product?: Maybe<Product>;
  productId?: Maybe<Scalars["String"]["output"]>;
  productSalesAnalytics?: Maybe<ProductSalesAnalytics>;
};

export enum TopSellingProductSortOptions {
  TotalQuantitySold = "totalQuantitySold",
  TotalSales = "totalSales",
}

export type TopSellingProductsOptions = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<TopSellingProductSortOptions>;
};

export type TopUpNextAction = {
  __typename?: "TopUpNextAction";
  payload?: Maybe<Scalars["JSONObject"]["output"]>;
  provider: WalletPaymentProvider;
};

export type TopUpWithInstaPayInput = {
  amount: Scalars["Float"]["input"];
  currencyCode: CurrencyCode;
  image: WalletImageInput;
};

export type TopUpWithPayfortCardInput = {
  amount: Scalars["Float"]["input"];
  currencyCode: CurrencyCode;
};

export type Transaction = {
  __typename?: "Transaction";
  actor: Person;
  amount: Scalars["Float"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  currencyCode: CurrencyCode;
  fees?: Maybe<Scalars["Float"]["output"]>;
  finalAmount: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  image?: Maybe<Image>;
  lastUpdatedBy?: Maybe<Person>;
  media?: Maybe<Array<Maybe<Image>>>;
  nextAction?: Maybe<TopUpNextAction>;
  notes?: Maybe<Scalars["String"]["output"]>;
  operationType: OperationType;
  paymentMethod: WalletPaymentMethod;
  paymentSource?: Maybe<WalletPaymentSource>;
  reason?: Maybe<Scalars["String"]["output"]>;
  referenceId?: Maybe<Scalars["String"]["output"]>;
  serial: Scalars["Int"]["output"];
  status: TransactionStatus;
  store?: Maybe<Store>;
  type: TransactionType;
  updatedAt: Scalars["DateTime"]["output"];
};

export type TransactionConnection = {
  __typename?: "TransactionConnection";
  nodes: Array<Transaction>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export enum TransactionSortByField {
  Amount = "amount",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
}

export enum TransactionStatus {
  Canceled = "CANCELED",
  Failed = "FAILED",
  Pending = "PENDING",
  Processing = "PROCESSING",
  Refunded = "REFUNDED",
  Rejected = "REJECTED",
  Success = "SUCCESS",
}

export enum TransactionType {
  AirwayBill = "AIRWAY_BILL",
  CashOnDelivery = "CASH_ON_DELIVERY",
  Deduction = "DEDUCTION",
  Deposit = "DEPOSIT",
  MaterialRequest = "MATERIAL_REQUEST",
  Withdraw = "WITHDRAW",
}

export type TransactionsAdminFilterInput = {
  createdAt?: InputMaybe<DateTimeRangeInput>;
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  operationType?: InputMaybe<OperationType>;
  paymentMethod?: InputMaybe<WalletPaymentMethod>;
  searchQuery?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<TransactionStatus>;
  type?: InputMaybe<TransactionType>;
  updatedAt?: InputMaybe<DateTimeRangeInput>;
};

export type TransactionsConnectionInput = {
  after?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  before?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  first?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  last?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<TransactionSortByField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type TranslateStoreLegalPageInput = {
  htmlTemplate?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type TranslateStoreStaticPageInput = {
  htmlTemplate?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type TranslatedCollection = {
  __typename?: "TranslatedCollection";
  descriptionHtml?: Maybe<Scalars["String"]["output"]>;
  locale: Scalars["String"]["output"];
  seo?: Maybe<ProductCollectionSeo>;
  shortDescription?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type TranslatedProductVariant = {
  __typename?: "TranslatedProductVariant";
  displayName: Scalars["String"]["output"];
  locale: Scalars["String"]["output"];
  selectedOptions: Array<SelectedOption>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type UpdateCollectionPayload = {
  __typename?: "UpdateCollectionPayload";
  collection?: Maybe<ProductCollection>;
};

export type UpdateCustomerAddressInput = {
  addressLine1?: InputMaybe<Scalars["String"]["input"]>;
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  cityId?: InputMaybe<Scalars["String"]["input"]>;
  countryId?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  regionId?: InputMaybe<Scalars["String"]["input"]>;
  secondPhone?: InputMaybe<Scalars["String"]["input"]>;
  stateId?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateIntegrationInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  integrationId: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateIntegrationInstallationInput = {
  id: Scalars["ID"]["input"];
  settings: IntegrationInstallationSettingsInput;
  storeId: Scalars["ID"]["input"];
};

export type UpdateIntegrationPayload = {
  __typename?: "UpdateIntegrationPayload";
  integration: Integration;
};

export type UpdateMaterialInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  dimensions?: InputMaybe<MaterialDimensionsInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<MoneyInput>;
  provider?: InputMaybe<WuiltShipmentProvider>;
  type: MaterialType;
  weight?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UpdateMenuItemInput = {
  clientId?: InputMaybe<Scalars["ID"]["input"]>;
  icon?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  link?: InputMaybe<MenuLinkInput>;
  locale: Scalars["String"]["input"];
  parentId?: InputMaybe<Scalars["ID"]["input"]>;
  title: Scalars["String"]["input"];
  type: MenuItemType;
};

export type UpdateOrderInput = {
  order: OrderUpdateInput;
  orderId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type UpdateOrderPayload = {
  __typename?: "UpdateOrderPayload";
  order?: Maybe<Order>;
};

export type UpdateOrderPromoInput = {
  orderId: Scalars["ID"]["input"];
  promoCode: Scalars["String"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type UpdatePickUpLocationInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  cityId?: InputMaybe<Scalars["ID"]["input"]>;
  countryId?: InputMaybe<Scalars["ID"]["input"]>;
  isDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
  location?: InputMaybe<GeoLocationInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  stateId?: InputMaybe<Scalars["ID"]["input"]>;
  status?: InputMaybe<PickUpLocationStatus>;
};

export type UpdateProductAttributeInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["ID"]["input"];
  type?: InputMaybe<ProductAttributeType>;
};

export type UpdateProductAttributeValueInput = {
  id: Scalars["ID"]["input"];
  image?: InputMaybe<CreateMediaInput>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["ID"]["input"];
};

export type UpdateProductPayload = {
  __typename?: "UpdateProductPayload";
  product: Product;
};

export type UpdateProductReviewInput = {
  content?: InputMaybe<Scalars["String"]["input"]>;
  headline?: InputMaybe<Scalars["String"]["input"]>;
  rating?: InputMaybe<Scalars["Int"]["input"]>;
  showName?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateProductReviewsSettingsInput = {
  enableGuestReviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  showCustomerAvatar?: InputMaybe<Scalars["Boolean"]["input"]>;
  showOverAllRating?: InputMaybe<Scalars["Boolean"]["input"]>;
  showReviewDate?: InputMaybe<Scalars["Boolean"]["input"]>;
  showReviewsCount?: InputMaybe<Scalars["Boolean"]["input"]>;
  showStars?: InputMaybe<Scalars["Boolean"]["input"]>;
  starsColor?: InputMaybe<Scalars["String"]["input"]>;
  storeId: Scalars["String"]["input"];
};

export type UpdateProductsStatusInput = {
  productIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
  status: ProductStatus;
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UpdateProductsStatusPayload = {
  __typename?: "UpdateProductsStatusPayload";
  products: Array<Maybe<Product>>;
};

export type UpdateProductsVisibilityInput = {
  isVisible: Scalars["Boolean"]["input"];
  productIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
  storeId: Scalars["ID"]["input"];
};

export type UpdateProductsVisibilityPayload = {
  __typename?: "UpdateProductsVisibilityPayload";
  updatedCount?: Maybe<Scalars["Int"]["output"]>;
};

export type UpdatePromoCodeInput = {
  fixedAmount?: InputMaybe<Scalars["Float"]["input"]>;
  id: Scalars["ID"]["input"];
  minimumOrderAmountToApply?: InputMaybe<Scalars["Float"]["input"]>;
  percentageOff?: InputMaybe<Scalars["Float"]["input"]>;
  schedule?: InputMaybe<DiscountScheduleInput>;
  status?: InputMaybe<PromoCodeStatusEnum>;
  storeId: Scalars["ID"]["input"];
  type?: InputMaybe<PromoCodeTypeEnum>;
  usageLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UpdateShippingRateCostInput = {
  cost?: InputMaybe<MoneyInput>;
  resourceId: Scalars["ID"]["input"];
};

export type UpdateStockItemInput = {
  cartLimitsEnabled: Scalars["Boolean"]["input"];
  cost?: InputMaybe<MoneyInput>;
  maxPerCart?: InputMaybe<Scalars["Int"]["input"]>;
  minPerCart?: InputMaybe<Scalars["Int"]["input"]>;
  packageDetails?: InputMaybe<ProductPackageDetailsInput>;
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
  regularPrice: MoneyInput;
  salePrice?: InputMaybe<MoneyInput>;
  sku?: InputMaybe<Scalars["String"]["input"]>;
  trackQuantity: Scalars["Boolean"]["input"];
};

export type UpdateStoreBannerSectionInput = {
  banners: Array<StoreBannerInput>;
};

export type UpdateStoreEmbedSectionInput = {
  content?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateStoreInput = {
  adminContactInfo?: InputMaybe<ContactInfoInput>;
  contactInfo?: InputMaybe<ContactInfoInput>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  emails?: InputMaybe<Array<InputMaybe<EmailRecordInput>>>;
  industry?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<StoreLocationInput>;
  maintenanceMode?: InputMaybe<MaintenanceModeInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  seo?: InputMaybe<StoreSeoInput>;
  storeId: Scalars["ID"]["input"];
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateStoreLegalPageInput = {
  htmlTemplate?: InputMaybe<Scalars["String"]["input"]>;
  isArchived?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateStoreMenuInput = {
  items?: InputMaybe<Array<InputMaybe<UpdateMenuItemInput>>>;
  title: Scalars["String"]["input"];
};

export type UpdateStorePageCatalogSectionCommonInput = {
  background?: InputMaybe<BackgroundInput>;
  displayAs?: InputMaybe<DisplayAsEnum>;
  header?: InputMaybe<Scalars["String"]["input"]>;
  itemsPerRow?: InputMaybe<Scalars["Int"]["input"]>;
  showItemName?: InputMaybe<Scalars["Boolean"]["input"]>;
  showSectionHeader?: InputMaybe<Scalars["Boolean"]["input"]>;
  stretchToFullWidth?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateStorePageCatalogSectionInput = {
  AttributesInput?: InputMaybe<CreateStorePageSectionAttributesInput>;
  CollectionsInput?: InputMaybe<CreateStorePageSectionCollectionsInput>;
  CommonInput: UpdateStorePageCatalogSectionCommonInput;
  ProductsInput?: InputMaybe<CreateStorePageSectionProductsInput>;
};

export type UpdateStorePageInput = {
  handle?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  pageType?: InputMaybe<StorePageTypeEnum>;
  seo?: InputMaybe<SeoInput>;
  status?: InputMaybe<PageStatusEnum>;
};

export type UpdateStorePageSectionAttributesInput = {
  attributeValuesIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type UpdateStorePageSectionCollectionsInput = {
  collectionsIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type UpdateStorePageSectionCommonInput = {
  background?: InputMaybe<BackgroundInput>;
  displayAs?: InputMaybe<DisplayAsEnum>;
  header?: InputMaybe<Scalars["String"]["input"]>;
  itemsPerRow?: InputMaybe<Scalars["Int"]["input"]>;
  showSectionHeader?: InputMaybe<Scalars["Boolean"]["input"]>;
  stretchToFullWidth?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateStorePageSectionInput = {
  BannerInput?: InputMaybe<UpdateStoreBannerSectionInput>;
  CommonInput: UpdateStorePageSectionCommonInput;
  EmbedInput?: InputMaybe<UpdateStoreEmbedSectionInput>;
  TextInput?: InputMaybe<UpdateStoreTextSectionInput>;
  VideoInput?: InputMaybe<UpdateStoreVideoSectionInput>;
};

export type UpdateStorePageSectionProductsInput = {
  fromCollectionId?: InputMaybe<Scalars["ID"]["input"]>;
  productsDisplayNumber?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UpdateStorePayload = {
  __typename?: "UpdateStorePayload";
  store: Store;
};

export type UpdateStoreStaticPageInput = {
  htmlTemplate?: InputMaybe<Scalars["String"]["input"]>;
  isArchived?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateStoreTextSectionInput = {
  content?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateStoreVideoSectionInput = {
  videosUrls: Array<Scalars["String"]["input"]>;
};

export type UpdateTaxInput = {
  image?: InputMaybe<ImageInput>;
  percentageOff: Scalars["Float"]["input"];
};

export type UpdateTemplateInput = {
  htmlTemplate?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateWuiltShipmentStoreDetailsInput = {
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  storeLocation: StoreBranchLocationInput;
};

export type User = {
  __typename?: "User";
  createdAt: Scalars["DateTime"]["output"];
  demoCompleted?: Maybe<Scalars["Boolean"]["output"]>;
  demoCompletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  domains: Array<RegisteredDomain>;
  email: Scalars["String"]["output"];
  flags: Scalars["JSONObject"]["output"];
  id: Scalars["ID"]["output"];
  isPartner?: Maybe<Scalars["Boolean"]["output"]>;
  isVerified: Scalars["Boolean"]["output"];
  locale?: Maybe<Locale>;
  name: Scalars["String"]["output"];
  permissions?: Maybe<Array<Maybe<Permission>>>;
  phone?: Maybe<Scalars["String"]["output"]>;
  questionnaireCompleted?: Maybe<Scalars["Boolean"]["output"]>;
  requireDemo?: Maybe<Scalars["Boolean"]["output"]>;
  roles?: Maybe<Scalars["String"]["output"]>;
  subscriptions: Array<PlanSubscription>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export enum UserAccessType {
  App = "APP",
  User = "USER",
}

export type UserFlagInput = {
  key: Scalars["String"]["input"];
  value: Scalars["Boolean"]["input"];
};

export type UserInfo = {
  __typename?: "UserInfo";
  email?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type UserInfoInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  flags?: InputMaybe<Scalars["JSONObject"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserRequestError = {
  __typename?: "UserRequestError";
  code?: Maybe<ErrorCode>;
  params?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type UserRequestStatus = {
  __typename?: "UserRequestStatus";
  errors: Array<Maybe<UserRequestError>>;
  requestId: Scalars["ID"]["output"];
  requestType: RequestType;
  status: RequestStatus;
};

export type UserRequestWarning = {
  __typename?: "UserRequestWarning";
  code?: Maybe<Scalars["String"]["output"]>;
  params?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type UserStorePermissionInput = {
  permissions?: InputMaybe<Array<StoreUserPermissionInput>>;
  role: PermissionRole;
  storeId: Scalars["ID"]["input"];
};

export type UserVendor = {
  __typename?: "UserVendor";
  isExist?: Maybe<Scalars["Boolean"]["output"]>;
  user: User;
};

export type ValidateAdminCartInput = {
  customerContactInfo?: InputMaybe<CreateContactInfoInput>;
  items?: InputMaybe<Array<SimpleItemInput>>;
  promoCode?: InputMaybe<Scalars["String"]["input"]>;
  shipping?: InputMaybe<ValidateShippingInfoInput>;
};

export type ValidateShippingInfoInput = {
  provider?: InputMaybe<WuiltShipmentProvider>;
  shippingDetails: ShippingDetailsInput;
  shippingRateId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ValidatedAdminCart = {
  __typename?: "ValidatedAdminCart";
  cartErrors?: Maybe<Array<CartError>>;
  contactInfo?: Maybe<CartContactInfo>;
  customer?: Maybe<CartCustomer>;
  items: Array<CartSimpleItem>;
  promoCode?: Maybe<PromoCode>;
  provider?: Maybe<WuiltShipmentProvider>;
  receipt: CartReceipt;
  shippingDetails?: Maybe<CartShippingDetails>;
  shippingRate?: Maybe<CartShippingRate>;
};

export type VariantPreview = {
  __typename?: "VariantPreview";
  price?: Maybe<Scalars["Float"]["output"]>;
  sku?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type Wallet = Node & {
  __typename?: "Wallet";
  balance: Scalars["Float"]["output"];
  currencyCode: CurrencyCode;
  id: Scalars["ID"]["output"];
  payoutSettings?: Maybe<PayoutSettings>;
  payouts?: Maybe<Array<Payout>>;
  pendingBalance: Scalars["Float"]["output"];
  store: Store;
  storeId: Scalars["ID"]["output"];
  transactions?: Maybe<Array<Transaction>>;
};

export type WalletActions = {
  __typename?: "WalletActions";
  addPayoutSettings: PayoutSettings;
  cancelPayoutRequest: Payout;
  create: Wallet;
  createPayoutRequest: Payout;
  deletePaymentSource: Scalars["Boolean"]["output"];
  deletePayoutSettings: Scalars["Boolean"]["output"];
  topUpCreatePaymentSetup: WalletPaymentSource;
  topUpWithCard: Transaction;
  topUpWithInstaPay: Transaction;
};

export type WalletActionsAddPayoutSettingsArgs = {
  payoutSettings: PayoutSettingsInput;
  walletId: Scalars["ID"]["input"];
};

export type WalletActionsCancelPayoutRequestArgs = {
  id: Scalars["ID"]["input"];
};

export type WalletActionsCreatePayoutRequestArgs = {
  amount: Scalars["Float"]["input"];
  payoutSettingsId: Scalars["ID"]["input"];
  walletId: Scalars["ID"]["input"];
};

export type WalletActionsDeletePaymentSourceArgs = {
  paymentSourceId: Scalars["ID"]["input"];
  walletId: Scalars["ID"]["input"];
};

export type WalletActionsDeletePayoutSettingsArgs = {
  payoutSettingsId: Scalars["ID"]["input"];
};

export type WalletActionsTopUpCreatePaymentSetupArgs = {
  input: PaymentSetupInput;
};

export type WalletActionsTopUpWithCardArgs = {
  amount: Scalars["Float"]["input"];
  cvc?: InputMaybe<Scalars["String"]["input"]>;
  paymentSourceId: Scalars["ID"]["input"];
  remember?: InputMaybe<Scalars["Boolean"]["input"]>;
  walletId: Scalars["ID"]["input"];
};

export type WalletActionsTopUpWithInstaPayArgs = {
  input: TopUpWithInstaPayInput;
  walletId: Scalars["ID"]["input"];
};

export type WalletImageInput = {
  altText?: InputMaybe<Scalars["String"]["input"]>;
  src: Scalars["String"]["input"];
};

export enum WalletPaymentMethod {
  AmazonCard = "AMAZON_CARD",
  AmazonWallet = "AMAZON_WALLET",
  Instapay = "INSTAPAY",
  Manual = "MANUAL",
  Payout = "PAYOUT",
  System = "SYSTEM",
}

export enum WalletPaymentProvider {
  PayFort = "PAY_FORT",
}

export type WalletPaymentSetup = {
  __typename?: "WalletPaymentSetup";
  access_code: Scalars["String"]["output"];
  language: Scalars["String"]["output"];
  merchant_identifier: Scalars["String"]["output"];
  merchant_reference: Scalars["String"]["output"];
  paymentUrl?: Maybe<Scalars["String"]["output"]>;
  return_url: Scalars["String"]["output"];
  service_command: Scalars["String"]["output"];
  signature: Scalars["String"]["output"];
  token_name?: Maybe<Scalars["String"]["output"]>;
};

export type WalletPaymentSource = {
  __typename?: "WalletPaymentSource";
  card?: Maybe<CardDetails>;
  id: Scalars["ID"]["output"];
  provider: WalletPaymentProvider;
  setupPayload?: Maybe<WalletPaymentSetup>;
  type: WalletPaymentSourceType;
};

export enum WalletPaymentSourceType {
  Card = "CARD",
}

export type WalletQueries = {
  __typename?: "WalletQueries";
  getPaymentSources: Array<WalletPaymentSource>;
  payout?: Maybe<Payout>;
  payouts: PayoutConnection;
  transaction?: Maybe<Transaction>;
  transactions: TransactionConnection;
};

export type WalletQueriesGetPaymentSourcesArgs = {
  walletId: Scalars["ID"]["input"];
};

export type WalletQueriesPayoutArgs = {
  id: Scalars["ID"]["input"];
};

export type WalletQueriesPayoutsArgs = {
  connection?: InputMaybe<PayoutsConnectionInput>;
  filter?: InputMaybe<PayoutsAdminFilterInput>;
};

export type WalletQueriesTransactionArgs = {
  id: Scalars["ID"]["input"];
};

export type WalletQueriesTransactionsArgs = {
  connection?: InputMaybe<TransactionsConnectionInput>;
  filter?: InputMaybe<TransactionsAdminFilterInput>;
};

export type WalletReceipt = {
  __typename?: "WalletReceipt";
  VAT?: Maybe<Money>;
  amountToCollect: Money;
  total: Money;
};

export enum WalletServiceErrorCodes {
  AlreadyProcessed = "ALREADY_PROCESSED",
  BadRequestError = "BAD_REQUEST_ERROR",
  Conflict = "CONFLICT",
  FeesCannotBeHigherThanAmount = "FEES_CANNOT_BE_HIGHER_THAN_AMOUNT",
  Forbidden = "FORBIDDEN",
  InsufficientBalance = "INSUFFICIENT_BALANCE",
  InternalServerError = "INTERNAL_SERVER_ERROR",
  Invalid = "INVALID",
  InvalidInput = "INVALID_INPUT",
  LockAcquisitionFailed = "LOCK_ACQUISITION_FAILED",
  NotFound = "NOT_FOUND",
  PaymentGatewayNotSupported = "PAYMENT_GATEWAY_NOT_SUPPORTED",
  PayoutSettingsNotVerified = "PAYOUT_SETTINGS_NOT_VERIFIED",
  TransactionNotFound = "TRANSACTION_NOT_FOUND",
  Unauthorized = "UNAUTHORIZED",
}

export type WeeklyStoreMetrics = {
  __typename?: "WeeklyStoreMetrics";
  currentWeek: StoreMetrics;
  previousWeek: StoreMetrics;
};

export type WuiltShipment = {
  __typename?: "WuiltShipment";
  activatedProviders: Array<WuiltShipmentProvider>;
  isActive: Scalars["Boolean"]["output"];
  isSuspended: Scalars["Boolean"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  reason?: Maybe<Scalars["String"]["output"]>;
  storeDetails: WuiltShipmentStoreDetails;
  updatedAt: Scalars["DateTime"]["output"];
  wizardFormStep?: Maybe<WuiltShipmentWizardStep>;
};

export type WuiltShipmentActions = {
  __typename?: "WuiltShipmentActions";
  activateProvider?: Maybe<Scalars["Boolean"]["output"]>;
  addPickUpLocation?: Maybe<Scalars["Boolean"]["output"]>;
  cancelPickUp: ShipOrderResponse;
  changeOrderProvider: Order;
  createPickUps: Array<Maybe<ShipOrderResponse>>;
  createReturnPickUp: ShipOrderResponse;
  deactivateProvider?: Maybe<Scalars["Boolean"]["output"]>;
  editPickUpLocation?: Maybe<Scalars["Boolean"]["output"]>;
  refreshShipmentStatus: RefreshShipmentStatusResponse;
  removePickUpLocation?: Maybe<WuiltShipment>;
  updateInstallation?: Maybe<WuiltShipment>;
  updateProviderStatus: WuiltShipment;
  updateWuiltShipmentStoreDetails?: Maybe<WuiltShipment>;
};

export type WuiltShipmentActionsActivateProviderArgs = {
  provider: WuiltShipmentProvider;
};

export type WuiltShipmentActionsAddPickUpLocationArgs = {
  pickupLocation: PickUpLocationInput;
};

export type WuiltShipmentActionsCancelPickUpArgs = {
  orderId: Scalars["ID"]["input"];
  type?: InputMaybe<ShipmentType>;
};

export type WuiltShipmentActionsChangeOrderProviderArgs = {
  collectSurplus: Scalars["Boolean"]["input"];
  flyer?: InputMaybe<BostaFlyer>;
  orderId: Scalars["ID"]["input"];
  provider: WuiltShipmentProvider;
};

export type WuiltShipmentActionsCreatePickUpsArgs = {
  input: CreatePickUpAdditionalInfo;
  orderIds: Array<Scalars["ID"]["input"]>;
  provider: WuiltShipmentProvider;
};

export type WuiltShipmentActionsCreateReturnPickUpArgs = {
  input: CreatePickUpAdditionalInfo;
  orderId: Scalars["ID"]["input"];
  provider: WuiltShipmentProvider;
  refundAmount: Scalars["Float"]["input"];
};

export type WuiltShipmentActionsDeactivateProviderArgs = {
  provider: WuiltShipmentProvider;
};

export type WuiltShipmentActionsEditPickUpLocationArgs = {
  id: Scalars["ID"]["input"];
  pickupLocation: UpdatePickUpLocationInput;
};

export type WuiltShipmentActionsRefreshShipmentStatusArgs = {
  orderId: Scalars["ID"]["input"];
  type?: InputMaybe<ShipmentType>;
};

export type WuiltShipmentActionsRemovePickUpLocationArgs = {
  id: Scalars["ID"]["input"];
};

export type WuiltShipmentActionsUpdateInstallationArgs = {
  update: WuiltShipmentUpdate;
};

export type WuiltShipmentActionsUpdateProviderStatusArgs = {
  provider: WuiltShipmentProvider;
  status: Scalars["Boolean"]["input"];
};

export type WuiltShipmentActionsUpdateWuiltShipmentStoreDetailsArgs = {
  input: UpdateWuiltShipmentStoreDetailsInput;
};

export type WuiltShipmentCustomerQueries = {
  __typename?: "WuiltShipmentCustomerQueries";
  rates: Array<WuiltShipmentProviderCityRate>;
};

export type WuiltShipmentCustomerQueriesRatesArgs = {
  cartId: Scalars["ID"]["input"];
  cityId: Scalars["ID"]["input"];
  stateId: Scalars["ID"]["input"];
};

export enum WuiltShipmentProvider {
  Aramex = "Aramex",
  Bosta = "Bosta",
  Mylerz = "Mylerz",
}

export type WuiltShipmentProviderCityRate = {
  __typename?: "WuiltShipmentProviderCityRate";
  city?: Maybe<City>;
  provider: WuiltShipmentProvider;
  rate: Money;
  shippingRateId: Scalars["ID"]["output"];
  state?: Maybe<State>;
};

export type WuiltShipmentQueries = {
  __typename?: "WuiltShipmentQueries";
  getAirwayBill: Scalars["String"]["output"];
  getPickUpLocations: Array<PickUpLocation>;
  getShipmentCost: Scalars["Float"]["output"];
  orderShipments?: Maybe<OrderShipmentConnection>;
};

export type WuiltShipmentQueriesGetAirwayBillArgs = {
  orderId: Scalars["ID"]["input"];
  provider: WuiltShipmentProvider;
  type?: InputMaybe<ShipmentType>;
};

export type WuiltShipmentQueriesGetShipmentCostArgs = {
  flyer?: InputMaybe<BostaFlyer>;
  orderId: Scalars["ID"]["input"];
  provider: WuiltShipmentProvider;
};

export type WuiltShipmentQueriesOrderShipmentsArgs = {
  connection?: InputMaybe<OrderShipmentsConnectionInput>;
  filter?: InputMaybe<OrderShipmentsFilterInput>;
};

export enum WuiltShipmentStatusEnum {
  ActionRequired = "ACTION_REQUIRED",
  AttemptsCompleted = "ATTEMPTS_COMPLETED",
  CancelledByShipper = "CANCELLED_BY_SHIPPER",
  ConfirmRejectedReason = "CONFIRM_REJECTED_REASON",
  Damaged = "DAMAGED",
  Delivered = "DELIVERED",
  Exception = "EXCEPTION",
  InTransit = "IN_TRANSIT",
  InTransitReceivedInHub = "IN_TRANSIT_RECEIVED_IN_HUB",
  Lost = "LOST",
  NotPickedYet = "NOT_PICKED_YET",
  OnHold = "ON_HOLD",
  OutForDelivery = "OUT_FOR_DELIVERY",
  Picked = "PICKED",
  RejectedByShipper = "REJECTED_BY_SHIPPER",
  Rescheduled = "RESCHEDULED",
  ReturnedToShipper = "RETURNED_TO_SHIPPER",
  ReAttemptDelivery = "RE_ATTEMPT_DELIVERY",
  ReAttemptDeliveryReady = "RE_ATTEMPT_DELIVERY_READY",
  ReAttemptPickup = "RE_ATTEMPT_PICKUP",
  SentTo = "SENT_TO",
  ShipmentScanned = "SHIPMENT_SCANNED",
}

export type WuiltShipmentStoreDetails = {
  __typename?: "WuiltShipmentStoreDetails";
  email: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
  phoneNumber: Scalars["String"]["output"];
  pickUpLocations: Array<PickUpLocation>;
  storeLocation?: Maybe<StoreBranchLocation>;
  storeName?: Maybe<Scalars["String"]["output"]>;
};

export type WuiltShipmentStoreDetailsInput = {
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  pickUpLocation: PickUpLocationInput;
  storeLocation?: InputMaybe<StoreBranchLocationInput>;
  storeName: Scalars["String"]["input"];
};

export type WuiltShipmentUpdate = {
  formContent?: InputMaybe<WuiltShipmentWizardInput>;
  step: WuiltShipmentWizardStep;
};

export type WuiltShipmentWizardInput = {
  selectedShippingCompanies?: InputMaybe<Array<WuiltShipmentProvider>>;
  storeDetails?: InputMaybe<WuiltShipmentStoreDetailsInput>;
};

export enum WuiltShipmentWizardStep {
  ActivateShipmentCompanies = "ACTIVATE_SHIPMENT_COMPANIES",
  FillStoreDetails = "FILL_STORE_DETAILS",
  PayoutSettings = "PAYOUT_SETTINGS",
  TermsAndConditions = "TERMS_AND_CONDITIONS",
}

export type ZoneCity = {
  __typename?: "ZoneCity";
  code?: Maybe<Scalars["String"]["output"]>;
  countryId: Scalars["ID"]["output"];
  id: Scalars["ID"]["output"];
  inAnotherState?: Maybe<Scalars["Boolean"]["output"]>;
  isPredefined?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
  region?: Maybe<ZoneRegion>;
  regions?: Maybe<ZoneRegionConnection>;
  shippingRate?: Maybe<ShippingRate>;
  shippingRates?: Maybe<Array<Maybe<ShippingRate>>>;
  stateId: Scalars["ID"]["output"];
  storeId?: Maybe<Scalars["ID"]["output"]>;
  zoneId?: Maybe<Scalars["ID"]["output"]>;
};

export type ZoneCityRegionArgs = {
  id: Scalars["ID"]["input"];
};

export type ZoneCityRegionsArgs = {
  zoneId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ZoneCityShippingRateArgs = {
  id: Scalars["ID"]["input"];
};

export type ZoneCityConnection = {
  __typename?: "ZoneCityConnection";
  edges?: Maybe<Array<Maybe<ZoneCityEdge>>>;
  nodes?: Maybe<Array<Maybe<ZoneCity>>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ZoneCityEdge = {
  __typename?: "ZoneCityEdge";
  cursor: Scalars["ConnectionCursor"]["output"];
  node?: Maybe<ZoneCity>;
};

export type ZoneCityInput = {
  cityId: Scalars["ID"]["input"];
  regions?: InputMaybe<Array<InputMaybe<ZoneRegionInput>>>;
};

export type ZoneCityRegionsInput = {
  cityId?: InputMaybe<Scalars["ID"]["input"]>;
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
  zonedId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ZoneCountry = ICountry & {
  __typename?: "ZoneCountry";
  code2: Scalars["String"]["output"];
  code3: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isPredefined?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
  state?: Maybe<ZoneState>;
  states?: Maybe<ZoneStateConnection>;
  storeId?: Maybe<Scalars["ID"]["output"]>;
  zoneId?: Maybe<Scalars["ID"]["output"]>;
};

export type ZoneCountryStateArgs = {
  id: Scalars["ID"]["input"];
};

export type ZoneCountryConnection = {
  __typename?: "ZoneCountryConnection";
  edges?: Maybe<Array<Maybe<ZoneCountryEdge>>>;
  nodes?: Maybe<Array<Maybe<ZoneCountry>>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ZoneCountryEdge = {
  __typename?: "ZoneCountryEdge";
  cursor: Scalars["ConnectionCursor"]["output"];
  node?: Maybe<ZoneCountry>;
};

export type ZoneCountryInput = {
  countryId: Scalars["ID"]["input"];
  states?: InputMaybe<Array<InputMaybe<ZoneStateInput>>>;
};

export type ZoneCountryStateInput = {
  countryId?: InputMaybe<Scalars["ID"]["input"]>;
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
  zoneId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ZoneRegion = IRegion & {
  __typename?: "ZoneRegion";
  cityId: Scalars["ID"]["output"];
  code?: Maybe<Scalars["String"]["output"]>;
  countryId: Scalars["ID"]["output"];
  id: Scalars["ID"]["output"];
  isPredefined?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
  shippingRate?: Maybe<ShippingRate>;
  shippingRates?: Maybe<Array<Maybe<ShippingRate>>>;
  stateId: Scalars["ID"]["output"];
  storeId?: Maybe<Scalars["ID"]["output"]>;
};

export type ZoneRegionShippingRateArgs = {
  id: Scalars["ID"]["input"];
};

export type ZoneRegionConnection = {
  __typename?: "ZoneRegionConnection";
  edges?: Maybe<Array<Maybe<ZoneRegionEdge>>>;
  nodes?: Maybe<Array<Maybe<ZoneRegion>>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ZoneRegionEdge = {
  __typename?: "ZoneRegionEdge";
  cursor: Scalars["ConnectionCursor"]["output"];
  node?: Maybe<ZoneRegion>;
};

export type ZoneRegionInput = {
  regionId: Scalars["ID"]["input"];
};

export type ZoneState = {
  __typename?: "ZoneState";
  cities?: Maybe<ZoneCityConnection>;
  city?: Maybe<ZoneCity>;
  code?: Maybe<Scalars["String"]["output"]>;
  countryId?: Maybe<Scalars["ID"]["output"]>;
  id: Scalars["ID"]["output"];
  isPredefined?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
  numOfCities?: Maybe<Scalars["Int"]["output"]>;
  shippingRate?: Maybe<ShippingRate>;
  shippingRates?: Maybe<Array<Maybe<ShippingRate>>>;
  storeId?: Maybe<Scalars["ID"]["output"]>;
  zoneId?: Maybe<Scalars["ID"]["output"]>;
};

export type ZoneStateCityArgs = {
  id: Scalars["ID"]["input"];
};

export type ZoneStateShippingRateArgs = {
  id: Scalars["ID"]["input"];
};

export type ZoneStateCitiesInput = {
  stateId?: InputMaybe<Scalars["ID"]["input"]>;
  storeId?: InputMaybe<Scalars["ID"]["input"]>;
  zonedId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ZoneStateConnection = {
  __typename?: "ZoneStateConnection";
  edges?: Maybe<Array<Maybe<ZoneStateEdge>>>;
  nodes?: Maybe<Array<Maybe<ZoneState>>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ZoneStateEdge = {
  __typename?: "ZoneStateEdge";
  cursor: Scalars["ConnectionCursor"]["output"];
  node?: Maybe<ZoneState>;
};

export type ZoneStateInput = {
  cities?: InputMaybe<Array<InputMaybe<ZoneCityInput>>>;
  stateId: Scalars["ID"]["input"];
};

export type AdjustItemsQuantityInput = {
  items: Array<InputMaybe<ItemsQuantityInput>>;
  orderId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type ArchiveShippingRateInput = {
  shippingRateId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type ArchiveShippingZoneInput = {
  shippingZoneId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type AttributeValueSelector = {
  attributeId: Scalars["ID"]["input"];
  attributeValueId: Scalars["ID"]["input"];
};

export type BackgroundInput = {
  color: Scalars["String"]["input"];
  type: SectionBackgroundTypeEnum;
};

export type CustomerUserInput = {
  addresses?: InputMaybe<Array<AddCustomerAddressInput>>;
  email: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
};

export type CustomerUserWithoutPasswordInput = {
  addresses?: InputMaybe<Array<AddCustomerAddressInput>>;
  email: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
};

export type DeleteShippingRateInput = {
  shippingRateIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
  storeId: Scalars["ID"]["input"];
};

export type DeleteShippingZoneInput = {
  shippingZoneIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
  storeId: Scalars["ID"]["input"];
};

export enum DomainType {
  External = "external",
  Internal = "internal",
}

export type ListCustomerReviewsInput = {
  connection?: InputMaybe<ReviewsConnectionInput>;
  customerId: Scalars["ID"]["input"];
};

export type MaterialOrderStoreDetails = {
  __typename?: "materialOrderStoreDetails";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type OptionValueSelector = {
  optionId: Scalars["ID"]["input"];
  optionValueId: Scalars["ID"]["input"];
};

export enum PromoCodesSortByField {
  CreatedAt = "createdAt",
  Id = "id",
  UpdatedAt = "updatedAt",
}

export type ShipPayload = {
  __typename?: "shipPayload";
  dcStatus?: Maybe<Scalars["String"]["output"]>;
  deliveryCompany?: Maybe<Scalars["String"]["output"]>;
  driverId?: Maybe<Scalars["String"]["output"]>;
  driverName?: Maybe<Scalars["String"]["output"]>;
  driverPhone?: Maybe<Scalars["String"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  orderId?: Maybe<Scalars["String"]["output"]>;
  orderSerial?: Maybe<Scalars["String"]["output"]>;
  printAWBURL?: Maybe<Scalars["String"]["output"]>;
  status: Scalars["String"]["output"];
  timestamp?: Maybe<Scalars["String"]["output"]>;
  trackingNumber?: Maybe<Scalars["String"]["output"]>;
  trackingURL?: Maybe<Scalars["String"]["output"]>;
};

export type ShippingRatesFilterInput = {
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ShippingZonesFilterInput = {
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StoreCitiesConnectionInput = {
  after?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  before?: InputMaybe<Scalars["ConnectionCursor"]["input"]>;
  first?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  last?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<StoreCitiesByField>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type UpdateCustomerUserAdminInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateCustomerUserInput = {
  currentPassword: Scalars["String"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateShippingRateInput = {
  cost: MoneyInput;
  name: Scalars["String"]["input"];
  shippingRateId: Scalars["ID"]["input"];
  shippingZoneId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type UpdateShippingZoneInput = {
  countries?: InputMaybe<Array<InputMaybe<ZoneCountryInput>>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  settings?: InputMaybe<ShippingZoneSettingsInput>;
  shippingZoneId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type UpdateStoreCustomCitiesInput = {
  cities: Array<InputMaybe<CustomCityInput>>;
  stateId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
};

export type UpdateStoreCustomCountriesInput = {
  countries: Array<InputMaybe<CustomCountryInput>>;
  storeId: Scalars["ID"]["input"];
};

export type UpdateStoreCustomRegionsInput = {
  cityId: Scalars["ID"]["input"];
  regions: Array<InputMaybe<CustomRegionInput>>;
  storeId: Scalars["ID"]["input"];
};

export type UpdateStoreCustomStatesInput = {
  countryId: Scalars["ID"]["input"];
  states: Array<InputMaybe<CustomStateInput>>;
  storeId: Scalars["ID"]["input"];
};

export type SocialIntegrationProductFragment = {
  __typename?: "Product";
  id: string;
  title: string;
  descriptionHtml?: any | null;
  shortDescription?: string | null;
  isInStock: boolean;
  handle: string;
  updatedAt: any;
  options?: Array<{
    __typename: "ProductOption";
    id: string;
    name: string;
    handle: string;
    position: number;
    locale: string;
    values: Array<{
      __typename: "ProductOptionValue";
      id: string;
      name?: string | null;
      handle?: string | null;
      locale?: string | null;
      translations?: Array<{
        __typename?: "ProductOptionValueTranslation";
        name?: string | null;
      } | null> | null;
    }>;
  } | null> | null;
  collections: {
    __typename?: "ProductCollectionConnection";
    nodes?: Array<{
      __typename?: "ProductCollection";
      id: string;
      title?: string | null;
      description?: string | null;
      handle: string;
    } | null> | null;
  };
  variants?: {
    __typename?: "ProductVariantConnection";
    nodes: Array<{
      __typename?: "ProductVariant";
      id: string;
      title?: string | null;
      quantity?: number | null;
      updatedAt?: any | null;
      compareAtPrice?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
      selectedOptions: Array<{
        __typename?: "SelectedOption";
        option: {
          __typename?: "ProductOption";
          id: string;
          name: string;
          handle: string;
        };
        value: {
          __typename?: "ProductOptionValue";
          id: string;
          name?: string | null;
        };
      }>;
      price: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      };
      image?: { __typename?: "Image"; src?: string | null } | null;
    }>;
  } | null;
  images: Array<{ __typename?: "Image"; src?: string | null } | null>;
};

export type SocialStoreDetailsFragment = {
  __typename?: "Store";
  id: string;
  name: string;
  updatedAt?: any | null;
  supportedLocales: Array<string>;
  description?: string | null;
  shippingZones?: {
    __typename?: "ShippingZoneConnection";
    nodes?: Array<{
      __typename?: "ShippingZone";
      id: string;
      shippingRates?: Array<{
        __typename?: "ShippingRate";
        name: string;
        cost: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
      } | null> | null;
      countries?: {
        __typename?: "ZoneCountryConnection";
        nodes?: Array<{
          __typename?: "ZoneCountry";
          id: string;
          name: string;
          code2: string;
          code3: string;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
  staticPages?: Array<{
    __typename?: "StoreStaticPage";
    handle: string;
  } | null> | null;
  collections?: {
    __typename?: "ProductCollectionConnection";
    nodes?: Array<{
      __typename?: "ProductCollection";
      handle: string;
      updatedAt: any;
      isVisible?: boolean | null;
    } | null> | null;
  } | null;
};

export type SocialStoreDetailsByHandleQueryVariables = Exact<{
  storeHandle: Scalars["String"]["input"];
}>;

export type SocialStoreDetailsByHandleQuery = {
  __typename?: "Query";
  store?: {
    __typename?: "Store";
    id: string;
    name: string;
    updatedAt?: any | null;
    supportedLocales: Array<string>;
    description?: string | null;
    shippingZones?: {
      __typename?: "ShippingZoneConnection";
      nodes?: Array<{
        __typename?: "ShippingZone";
        id: string;
        shippingRates?: Array<{
          __typename?: "ShippingRate";
          name: string;
          cost: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        } | null> | null;
        countries?: {
          __typename?: "ZoneCountryConnection";
          nodes?: Array<{
            __typename?: "ZoneCountry";
            id: string;
            name: string;
            code2: string;
            code3: string;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
    staticPages?: Array<{
      __typename?: "StoreStaticPage";
      handle: string;
    } | null> | null;
    collections?: {
      __typename?: "ProductCollectionConnection";
      nodes?: Array<{
        __typename?: "ProductCollection";
        handle: string;
        updatedAt: any;
        isVisible?: boolean | null;
      } | null> | null;
    } | null;
  } | null;
};

export type SocialStoreDetailsByDomainQueryVariables = Exact<{
  storeDomain: Scalars["String"]["input"];
}>;

export type SocialStoreDetailsByDomainQuery = {
  __typename?: "Query";
  store?: {
    __typename?: "Store";
    id: string;
    name: string;
    updatedAt?: any | null;
    supportedLocales: Array<string>;
    description?: string | null;
    shippingZones?: {
      __typename?: "ShippingZoneConnection";
      nodes?: Array<{
        __typename?: "ShippingZone";
        id: string;
        shippingRates?: Array<{
          __typename?: "ShippingRate";
          name: string;
          cost: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        } | null> | null;
        countries?: {
          __typename?: "ZoneCountryConnection";
          nodes?: Array<{
            __typename?: "ZoneCountry";
            id: string;
            name: string;
            code2: string;
            code3: string;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
    staticPages?: Array<{
      __typename?: "StoreStaticPage";
      handle: string;
    } | null> | null;
    collections?: {
      __typename?: "ProductCollectionConnection";
      nodes?: Array<{
        __typename?: "ProductCollection";
        handle: string;
        updatedAt: any;
        isVisible?: boolean | null;
      } | null> | null;
    } | null;
  } | null;
};

export type SocialIntegrationProductsQueryVariables = Exact<{
  filter?: InputMaybe<ProductsFilterInput>;
  connection?: InputMaybe<ProductsConnectionInput>;
}>;

export type SocialIntegrationProductsQuery = {
  __typename?: "Query";
  products: {
    __typename?: "ProductConnection";
    nodes: Array<{
      __typename?: "Product";
      id: string;
      title: string;
      descriptionHtml?: any | null;
      shortDescription?: string | null;
      isInStock: boolean;
      handle: string;
      updatedAt: any;
      options?: Array<{
        __typename: "ProductOption";
        id: string;
        name: string;
        handle: string;
        position: number;
        locale: string;
        values: Array<{
          __typename: "ProductOptionValue";
          id: string;
          name?: string | null;
          handle?: string | null;
          locale?: string | null;
          translations?: Array<{
            __typename?: "ProductOptionValueTranslation";
            name?: string | null;
          } | null> | null;
        }>;
      } | null> | null;
      collections: {
        __typename?: "ProductCollectionConnection";
        nodes?: Array<{
          __typename?: "ProductCollection";
          id: string;
          title?: string | null;
          description?: string | null;
          handle: string;
        } | null> | null;
      };
      variants?: {
        __typename?: "ProductVariantConnection";
        nodes: Array<{
          __typename?: "ProductVariant";
          id: string;
          title?: string | null;
          quantity?: number | null;
          updatedAt?: any | null;
          compareAtPrice?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          selectedOptions: Array<{
            __typename?: "SelectedOption";
            option: {
              __typename?: "ProductOption";
              id: string;
              name: string;
              handle: string;
            };
            value: {
              __typename?: "ProductOptionValue";
              id: string;
              name?: string | null;
            };
          }>;
          price: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          image?: { __typename?: "Image"; src?: string | null } | null;
        }>;
      } | null;
      images: Array<{ __typename?: "Image"; src?: string | null } | null>;
    }>;
  };
};

export type ValidateSocialIntegrationInstallationQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  provider: ProviderName;
  authHeader: Scalars["String"]["input"];
}>;

export type ValidateSocialIntegrationInstallationQuery = {
  __typename?: "Query";
  validateSocialIntegrationInstallation?: boolean | null;
};

export type StoreBasicInfoFragment = {
  __typename?: "Store";
  id: string;
  name: string;
  description?: string | null;
  currency: string;
  supportedLocales: Array<string>;
  fallbackLocale: string;
  favIcon?: { __typename?: "Image"; id: string; src?: string | null } | null;
  logo?: {
    __typename?: "StoreLogo";
    size?: LogoSize | null;
    image?: { __typename?: "Image"; id: string; src?: string | null } | null;
  } | null;
  seo?: {
    __typename?: "StoreSEO";
    title?: string | null;
    description?: string | null;
  } | null;
  appearance?: {
    __typename?: "StoreAppearance";
    template?: { __typename?: "Template"; handle?: string | null } | null;
  } | null;
  maintenanceMode?: {
    __typename?: "MaintenanceMode";
    isEnabled: boolean;
    message?: string | null;
  } | null;
  location?: { __typename?: "Location"; address: string } | null;
  contactInfo?: {
    __typename?: "ContactInfo";
    phone?: string | null;
    email?: string | null;
  } | null;
  redirects?: Array<{
    __typename?: "StoreRedirect";
    id: string;
    newUrl: string;
    oldUrl: string;
  }> | null;
};

export type StoreDetailsFragment = {
  __typename?: "Store";
  id: string;
  name: string;
  description?: string | null;
  currency: string;
  defaultLocale: string;
  locales: Array<string>;
  locale: string;
  fallbackLocale: string;
  supportedLocales: Array<string>;
  userId: string;
  isExpired?: boolean | null;
  areReviewsActivated: boolean;
  wuiltShipment?: {
    __typename?: "WuiltShipment";
    isActive: boolean;
    activatedProviders: Array<WuiltShipmentProvider>;
  } | null;
  adminContactInfo?: {
    __typename?: "ContactInfo";
    name?: string | null;
    email?: string | null;
  } | null;
  location?: { __typename?: "Location"; address: string } | null;
  socialMedia?: Array<{
    __typename?: "SocialLinkType";
    name?: string | null;
    link?: string | null;
  } | null> | null;
  seo?: {
    __typename?: "StoreSEO";
    title?: string | null;
    description?: string | null;
  } | null;
  legalPages?: Array<{
    __typename?: "StoreLegalPage";
    id: string;
    name?: string | null;
    title?: string | null;
    handle: string;
  } | null> | null;
  appearance?: {
    __typename?: "StoreAppearance";
    template?: { __typename?: "Template"; handle?: string | null } | null;
    colors?: {
      __typename?: "ThemeColors";
      primary?: string | null;
      inkNormal?: string | null;
      inkSubdued?: string | null;
    } | null;
    fonts?: Array<{
      __typename?: "FontType";
      type?: FontTypeEnum | null;
      fontFamily?: string | null;
      category?: string | null;
      typeFace?: string | null;
      weight?: string | null;
      isItalic?: boolean | null;
      url?: string | null;
      locale?: string | null;
      locales?: Array<string | null> | null;
    } | null> | null;
    footer?: {
      __typename?: "FooterType";
      activeFooter?: ActiveFooter | null;
      startPart?: string | null;
      endPart?: string | null;
      customPart?: string | null;
      locale?: string | null;
      locales?: Array<string | null> | null;
    } | null;
    menus?: Array<{
      __typename?: "StoreMenu";
      id: string;
      title: string;
      handle?: string | null;
      items?: Array<{
        __typename?: "StoreMenuItem";
        id: string;
        parentId?: string | null;
        title: string;
        type: MenuItemType;
        locale?: string | null;
        locales?: Array<string | null> | null;
        handle?: string | null;
        icon?: string | null;
        link?: {
          __typename?: "MenuLink";
          type: MenuItemType;
          url?: string | null;
          resourceId?: string | null;
          openInNewTab?: boolean | null;
          staticPageType?: StaticPageEnum | null;
          resource?:
            | { __typename: "Product"; handle: string }
            | { __typename: "ProductCollection"; handle: string }
            | { __typename: "StoreLegalPage"; handle: string }
            | { __typename: "StorePage"; handle: string }
            | { __typename: "StoreStaticPage" }
            | null;
        } | null;
      } | null> | null;
    } | null> | null;
    infoBar?: {
      __typename?: "InfoBar";
      text: any;
      background: string;
      locales?: Array<string | null> | null;
      locale?: string | null;
      isActive?: boolean | null;
      isDismissible?: boolean | null;
      translations?: Array<{
        __typename?: "InfoBarTranslation";
        text: any;
        locale: string;
      } | null> | null;
    } | null;
    productDisplay?: {
      __typename?: "ProductDisplaySettings";
      productImageZoomBehavior?: ProductImageZoomBehavior | null;
      buyNowButton?: {
        __typename?: "BuyNowButtonSettings";
        display?: BuyNowButtonDisplay | null;
        backgroundColor?: string | null;
        fontColor?: string | null;
        borderColor?: string | null;
      } | null;
    } | null;
  } | null;
  contactInfo?: {
    __typename?: "ContactInfo";
    email?: string | null;
    phone?: string | null;
  } | null;
  maintenanceMode?: {
    __typename?: "MaintenanceMode";
    isEnabled: boolean;
    message?: string | null;
  } | null;
  logo?: {
    __typename?: "StoreLogo";
    size?: LogoSize | null;
    image?: {
      __typename?: "Image";
      id: string;
      altText?: string | null;
      src?: string | null;
      width?: number | null;
      height?: number | null;
      format?: string | null;
    } | null;
  } | null;
  owner?: { __typename?: "User"; createdAt: any } | null;
  subscription?: {
    __typename?: "PlanSubscription";
    createdAt: any;
    status: SubscriptionStatus;
  } | null;
  favIcon?: { __typename?: "Image"; id: string; src?: string | null } | null;
  analyticalAccounts?: Array<{
    __typename?: "AnalyticalAccount";
    name: string;
    id: string;
    isActive: boolean;
  } | null> | null;
  automaticDiscounts?: Array<{
    __typename?: "Discount";
    id: string;
    title?: string | null;
    appliedOn?: DiscountAppliedOnType | null;
    percentage?: number | null;
    status?: DiscountStatus | null;
    customerBuys?: {
      __typename?: "CustomerBuys";
      value?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
    } | null;
    amount?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
    BuyXGetYDiscount?: {
      __typename?: "BuyXGetYDiscount";
      customerBuys: {
        __typename?: "CustomerBuys";
        quantity?: number | null;
        itemsType?: DiscountItemsType | null;
        value?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
        items?: {
          __typename?: "CustomerBuysItems";
          productVariantIds?: Array<string> | null;
          collectionIds?: Array<string> | null;
        } | null;
      };
      customerGets: {
        __typename?: "CustomerGets";
        quantity?: number | null;
        percentage: number;
        itemsType: DiscountItemsType;
        items: {
          __typename?: "CustomerGetsItems";
          productVariants?: Array<{
            __typename?: "ProductVariant";
            id: string;
          }> | null;
          collections?: Array<{
            __typename?: "ProductCollection";
            id: string;
          }> | null;
        };
      };
    } | null;
    amountOff?: {
      __typename?: "AmountOff";
      customerBuys: {
        __typename?: "CustomerBuysAmountOff";
        itemsType?: DiscountItemsType | null;
        items?: {
          __typename?: "CustomerBuysItems";
          productVariantIds?: Array<string> | null;
          collectionIds?: Array<string> | null;
          collections?: Array<{
            __typename?: "ProductCollection";
            id: string;
          }> | null;
          productVariants?: Array<{
            __typename?: "ProductVariant";
            id: string;
          }> | null;
        } | null;
      };
      discount: {
        __typename?: "AmountOffDiscounted";
        amount?: number | null;
        percentage?: number | null;
      };
    } | null;
    translations?: Array<{
      __typename?: "DiscountTranslation";
      title?: string | null;
      locale?: string | null;
    } | null> | null;
  } | null> | null;
  storeReviewSettings?: {
    __typename?: "StoreReviewsSettings";
    id: string;
    enableGuestReviews: boolean;
    showCustomerAvatar: boolean;
    showOverAllRating: boolean;
    showReviewDate: boolean;
    showReviewsCount: boolean;
    showStars: boolean;
    starsColor: string;
  } | null;
  redirects?: Array<{
    __typename?: "StoreRedirect";
    id: string;
    newUrl: string;
    oldUrl: string;
  }> | null;
  customCheckoutSetting?: {
    __typename?: "CustomCheckoutSetting";
    id: string;
    identifier: CustomerIdentifier;
    name: NameInputOptions;
    secondaryPhone: CheckoutFieldOptions;
    postalCode: CheckoutFieldOptions;
    notesToSeller: CheckoutFieldOptions;
    checkoutNote: {
      __typename?: "CheckoutNote";
      placement: CheckoutNotePlacement;
      enabled: boolean;
      content: string;
    };
  } | null;
  domain?: { __typename?: "Domain"; domainName: string } | null;
  integrationScriptControls?: Array<
    | {
        __typename?: "GameballScriptControls";
        provider: ProviderName;
        apiKey: string;
      }
    | {
        __typename?: "KlaviyoScriptControls";
        provider: ProviderName;
        publicKey: string;
      }
    | {
        __typename?: "MailchimpScriptControls";
        provider: ProviderName;
        apiKey: string;
      }
    | { __typename?: "NoScriptControls"; provider: ProviderName }
    | {
        __typename?: "OptimonkScriptControls";
        provider: ProviderName;
        accountId: string;
      }
  > | null;
};

export type StoreMenuItemFragment = {
  __typename?: "StoreMenuItem";
  id: string;
  parentId?: string | null;
  title: string;
  type: MenuItemType;
  locale?: string | null;
  locales?: Array<string | null> | null;
  handle?: string | null;
  icon?: string | null;
  link?: {
    __typename?: "MenuLink";
    type: MenuItemType;
    url?: string | null;
    resourceId?: string | null;
    openInNewTab?: boolean | null;
    staticPageType?: StaticPageEnum | null;
    resource?:
      | { __typename: "Product"; handle: string }
      | { __typename: "ProductCollection"; handle: string }
      | { __typename: "StoreLegalPage"; handle: string }
      | { __typename: "StorePage"; handle: string }
      | { __typename: "StoreStaticPage" }
      | null;
  } | null;
};

export type MenuInfoFragment = {
  __typename?: "StoreMenu";
  id: string;
  title: string;
  handle?: string | null;
  items?: Array<{
    __typename?: "StoreMenuItem";
    id: string;
    parentId?: string | null;
    title: string;
    type: MenuItemType;
    locale?: string | null;
    locales?: Array<string | null> | null;
    handle?: string | null;
    icon?: string | null;
    link?: {
      __typename?: "MenuLink";
      type: MenuItemType;
      url?: string | null;
      resourceId?: string | null;
      openInNewTab?: boolean | null;
      staticPageType?: StaticPageEnum | null;
      resource?:
        | { __typename: "Product"; handle: string }
        | { __typename: "ProductCollection"; handle: string }
        | { __typename: "StoreLegalPage"; handle: string }
        | { __typename: "StorePage"; handle: string }
        | { __typename: "StoreStaticPage" }
        | null;
    } | null;
  } | null> | null;
};

export type CollectionInfoFragment = {
  __typename?: "ProductCollection";
  id: string;
  locale: string;
  title?: string | null;
  handle: string;
  shortDescription?: string | null;
  productsCount: number;
  image?: {
    __typename?: "Image";
    id: string;
    altText?: string | null;
    src?: string | null;
    width?: number | null;
    height?: number | null;
    format?: string | null;
  } | null;
  seo?: {
    __typename?: "ProductCollectionSEO";
    title?: string | null;
    description?: string | null;
  } | null;
};

export type MoneyFragment = {
  __typename?: "Money";
  amount: number;
  currencyCode: CurrencyCode;
};

export type ImageFragment = {
  __typename?: "Image";
  id: string;
  altText?: string | null;
  src?: string | null;
  width?: number | null;
  height?: number | null;
  format?: string | null;
};

export type OrderItemDiscountInfoFragment = {
  __typename?: "OrderItemDiscountDetails";
  discountSource: DiscountSource;
  quantity: number;
  data?: {
    __typename?: "Discount";
    id: string;
    title?: string | null;
    translations?: Array<{
      __typename?: "DiscountTranslation";
      title?: string | null;
      locale?: string | null;
    } | null> | null;
  } | null;
  info: {
    __typename?: "OrderItemDiscountDetailsInfo";
    percentage?: number | null;
    fixed?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
  };
  perItem: { __typename?: "Money"; amount: number; currencyCode: CurrencyCode };
  total: { __typename?: "Money"; amount: number; currencyCode: CurrencyCode };
};

export type VariantInfoFragment = {
  __typename?: "ProductVariant";
  id: string;
  title?: string | null;
  quantity?: number | null;
  trackQuantity?: boolean | null;
  sku?: string | null;
  cartLimitsEnabled?: boolean | null;
  minPerCart?: number | null;
  maxPerCart?: number | null;
  product: {
    __typename?: "Product";
    id: string;
    title: string;
    status: ProductStatus;
    taxable?: boolean | null;
    productTax?: number | null;
    images: Array<{
      __typename?: "Image";
      id: string;
      altText?: string | null;
      src?: string | null;
      width?: number | null;
      height?: number | null;
      format?: string | null;
    } | null>;
  };
  price: { __typename?: "Money"; amount: number; currencyCode: CurrencyCode };
  compareAtPrice?: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  } | null;
  image?: {
    __typename?: "Image";
    id: string;
    altText?: string | null;
    src?: string | null;
    width?: number | null;
    height?: number | null;
    format?: string | null;
  } | null;
  selectedOptions: Array<{
    __typename?: "SelectedOption";
    option: { __typename?: "ProductOption"; id: string; name: string };
    value: {
      __typename?: "ProductOptionValue";
      id: string;
      name?: string | null;
    };
  }>;
};

export type SimpleVariantInfoFragment = {
  __typename?: "ProductVariant";
  id: string;
  title?: string | null;
  quantity?: number | null;
  trackQuantity?: boolean | null;
  sku?: string | null;
  cartLimitsEnabled?: boolean | null;
  minPerCart?: number | null;
  maxPerCart?: number | null;
  price: { __typename?: "Money"; amount: number; currencyCode: CurrencyCode };
  compareAtPrice?: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  } | null;
  image?: {
    __typename?: "Image";
    id: string;
    altText?: string | null;
    src?: string | null;
    width?: number | null;
    height?: number | null;
    format?: string | null;
  } | null;
  selectedOptions: Array<{
    __typename?: "SelectedOption";
    option: { __typename?: "ProductOption"; id: string; name: string };
    value: {
      __typename?: "ProductOptionValue";
      id: string;
      name?: string | null;
    };
  }>;
};

export type PaymentIntentInfoFragment = {
  __typename?: "PaymentIntent";
  id: string;
  status?: PaymentIntentStatusEnum | null;
  embedUrl?: string | null;
  paymentMethod?: PaymentMethodEnum | null;
  paymentMethods?: Array<PaymentMethodEnum | null> | null;
};

export type TaxFragment = { __typename?: "Tax"; percentageOff: number };

export type OrderReceiptFragment = {
  __typename?: "OrderReceipt";
  subtotal?: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  } | null;
  automaticDiscount?: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  } | null;
  discount?: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  } | null;
  tax?: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  } | null;
  shipping?: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  } | null;
  total?: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  } | null;
};

export type OrderVariantSnapshotFragment = {
  __typename?: "ProductVariantSnapshot";
  id: string;
  sku?: string | null;
  quantity?: number | null;
  productId: string;
  productTitle?: string | null;
  image?: {
    __typename?: "Image";
    id: string;
    altText?: string | null;
    src?: string | null;
    width?: number | null;
    height?: number | null;
    format?: string | null;
  } | null;
  productImages?: Array<{
    __typename?: "Image";
    id: string;
    altText?: string | null;
    src?: string | null;
    width?: number | null;
    height?: number | null;
    format?: string | null;
  } | null> | null;
};

export type OrderCategoryInfoFragment = {
  __typename?: "CustomBuildCategory";
  category: {
    __typename?: "CustomProductCategory";
    id: string;
    categoryType: CategoryType;
    name: string;
    isRequired: boolean;
    image?: {
      __typename?: "Image";
      id: string;
      altText?: string | null;
      src?: string | null;
      width?: number | null;
      height?: number | null;
      format?: string | null;
    } | null;
    variants?: Array<{
      __typename?: "CustomProductCategoryVariant";
      preselected?: boolean | null;
      variant?: {
        __typename?: "ProductVariant";
        id: string;
        title?: string | null;
        quantity?: number | null;
        trackQuantity?: boolean | null;
        sku?: string | null;
        cartLimitsEnabled?: boolean | null;
        minPerCart?: number | null;
        maxPerCart?: number | null;
        product: {
          __typename?: "Product";
          id: string;
          title: string;
          status: ProductStatus;
          taxable?: boolean | null;
          productTax?: number | null;
          images: Array<{
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null>;
        };
        price: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        compareAtPrice?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
        image?: {
          __typename?: "Image";
          id: string;
          altText?: string | null;
          src?: string | null;
          width?: number | null;
          height?: number | null;
          format?: string | null;
        } | null;
        selectedOptions: Array<{
          __typename?: "SelectedOption";
          option: { __typename?: "ProductOption"; id: string; name: string };
          value: {
            __typename?: "ProductOptionValue";
            id: string;
            name?: string | null;
          };
        }>;
      } | null;
    } | null> | null;
    settings?: {
      __typename?: "CustomProductCategorySettings";
      maxSelect?: number | null;
      maxQuantity?: number | null;
    } | null;
  };
  selectedVariants: Array<{
    __typename?: "SelectedVariant";
    quantity: number;
    variantSnapshot?: {
      __typename?: "ProductVariantSnapshot";
      id: string;
      sku?: string | null;
      quantity?: number | null;
      productId: string;
      productTitle?: string | null;
      image?: {
        __typename?: "Image";
        id: string;
        altText?: string | null;
        src?: string | null;
        width?: number | null;
        height?: number | null;
        format?: string | null;
      } | null;
      productImages?: Array<{
        __typename?: "Image";
        id: string;
        altText?: string | null;
        src?: string | null;
        width?: number | null;
        height?: number | null;
        format?: string | null;
      } | null> | null;
    } | null;
    price?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
    selectedOptions: Array<{
      __typename?: "SelectedVariantOption";
      value: string;
      name: string;
    }>;
  }>;
};

export type CategoryInfoFragment = {
  __typename?: "CustomProductCategory";
  id: string;
  categoryType: CategoryType;
  name: string;
  isRequired: boolean;
  image?: {
    __typename?: "Image";
    id: string;
    altText?: string | null;
    src?: string | null;
    width?: number | null;
    height?: number | null;
    format?: string | null;
  } | null;
  variants?: Array<{
    __typename?: "CustomProductCategoryVariant";
    preselected?: boolean | null;
    variant?: {
      __typename?: "ProductVariant";
      id: string;
      title?: string | null;
      quantity?: number | null;
      trackQuantity?: boolean | null;
      sku?: string | null;
      cartLimitsEnabled?: boolean | null;
      minPerCart?: number | null;
      maxPerCart?: number | null;
      product: {
        __typename?: "Product";
        id: string;
        title: string;
        status: ProductStatus;
        taxable?: boolean | null;
        productTax?: number | null;
        images: Array<{
          __typename?: "Image";
          id: string;
          altText?: string | null;
          src?: string | null;
          width?: number | null;
          height?: number | null;
          format?: string | null;
        } | null>;
      };
      price: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      };
      compareAtPrice?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
      image?: {
        __typename?: "Image";
        id: string;
        altText?: string | null;
        src?: string | null;
        width?: number | null;
        height?: number | null;
        format?: string | null;
      } | null;
      selectedOptions: Array<{
        __typename?: "SelectedOption";
        option: { __typename?: "ProductOption"; id: string; name: string };
        value: {
          __typename?: "ProductOptionValue";
          id: string;
          name?: string | null;
        };
      }>;
    } | null;
  } | null> | null;
  settings?: {
    __typename?: "CustomProductCategorySettings";
    maxSelect?: number | null;
    maxQuantity?: number | null;
  } | null;
};

export type OrderInfoFragment = {
  __typename?: "Order";
  id: string;
  orderSerial?: string | null;
  status?: OrderStatusEnum | null;
  paymentMethod?: PaymentMethodEnum | null;
  createdAt: any;
  paymentStatus?: OrderPaymentStatusEnum | null;
  fulfillmentStatus?: FulfillStatusEnum | null;
  externalActionMessage?: Array<string | null> | null;
  notes?: string | null;
  wuiltShipmentProvider?: WuiltShipmentProvider | null;
  shippingRateName?: string | null;
  customer: {
    __typename?: "Customer";
    name?: string | null;
    email?: string | null;
    phone?: string | null;
    isSubscribedToNewsLetter?: boolean | null;
  };
  shipmentDetails?: {
    __typename?: "ShipmentDetails";
    trackingURL?: string | null;
    orderTrackingNumber?: string | null;
  } | null;
  shippingAddress: {
    __typename?: "Address";
    phone?: string | null;
    addressLine1: string;
    addressLine2?: string | null;
    postalCode?: string | null;
    areaSnapshot?: {
      __typename?: "AreaSnapshot";
      countryName?: string | null;
      stateName?: string | null;
      cityName?: string | null;
      regionName?: string | null;
    } | null;
  };
  billingAddress: {
    __typename?: "Address";
    phone?: string | null;
    addressLine1: string;
    addressLine2?: string | null;
    postalCode?: string | null;
    areaSnapshot?: {
      __typename?: "AreaSnapshot";
      countryName?: string | null;
      stateName?: string | null;
      cityName?: string | null;
      regionName?: string | null;
    } | null;
  };
  items: Array<
    | {
        __typename?: "CustomItem";
        id: string;
        quantity: number;
        title?: string | null;
        categories: Array<{
          __typename?: "CustomBuildCategory";
          category: {
            __typename?: "CustomProductCategory";
            id: string;
            categoryType: CategoryType;
            name: string;
            isRequired: boolean;
            image?: {
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null;
            variants?: Array<{
              __typename?: "CustomProductCategoryVariant";
              preselected?: boolean | null;
              variant?: {
                __typename?: "ProductVariant";
                id: string;
                title?: string | null;
                quantity?: number | null;
                trackQuantity?: boolean | null;
                sku?: string | null;
                cartLimitsEnabled?: boolean | null;
                minPerCart?: number | null;
                maxPerCart?: number | null;
                product: {
                  __typename?: "Product";
                  id: string;
                  title: string;
                  status: ProductStatus;
                  taxable?: boolean | null;
                  productTax?: number | null;
                  images: Array<{
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null>;
                };
                price: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                compareAtPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                selectedOptions: Array<{
                  __typename?: "SelectedOption";
                  option: {
                    __typename?: "ProductOption";
                    id: string;
                    name: string;
                  };
                  value: {
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
            } | null> | null;
            settings?: {
              __typename?: "CustomProductCategorySettings";
              maxSelect?: number | null;
              maxQuantity?: number | null;
            } | null;
          };
          selectedVariants: Array<{
            __typename?: "SelectedVariant";
            quantity: number;
            variantSnapshot?: {
              __typename?: "ProductVariantSnapshot";
              id: string;
              sku?: string | null;
              quantity?: number | null;
              productId: string;
              productTitle?: string | null;
              image?: {
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null;
              productImages?: Array<{
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null> | null;
            } | null;
            price?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
            selectedOptions: Array<{
              __typename?: "SelectedVariantOption";
              value: string;
              name: string;
            }>;
          }>;
        }>;
        subtotal?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
        productSnapshot?: {
          __typename?: "ProductSnapshot";
          id: string;
          title: string;
          handle: string;
          collectionIds?: Array<string | null> | null;
          integrationProvider?: {
            __typename?: "IntegrationProviderDetailsSnapshot";
            provider?: string | null;
          } | null;
          images: Array<{
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null>;
        } | null;
        price?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
        discount?: {
          __typename?: "OrderItemDiscountDetails";
          discountSource: DiscountSource;
          quantity: number;
          data?: {
            __typename?: "Discount";
            id: string;
            title?: string | null;
            translations?: Array<{
              __typename?: "DiscountTranslation";
              title?: string | null;
              locale?: string | null;
            } | null> | null;
          } | null;
          info: {
            __typename?: "OrderItemDiscountDetailsInfo";
            percentage?: number | null;
            fixed?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          };
          perItem: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        } | null;
        discounts?: Array<{
          __typename?: "OrderItemDiscountDetails";
          discountSource: DiscountSource;
          quantity: number;
          data?: {
            __typename?: "Discount";
            id: string;
            title?: string | null;
            translations?: Array<{
              __typename?: "DiscountTranslation";
              title?: string | null;
              locale?: string | null;
            } | null> | null;
          } | null;
          info: {
            __typename?: "OrderItemDiscountDetailsInfo";
            percentage?: number | null;
            fixed?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          };
          perItem: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        }> | null;
      }
    | {
        __typename?: "SimpleItem";
        id: string;
        quantity: number;
        title?: string | null;
        productSnapshot?: {
          __typename?: "ProductSnapshot";
          id: string;
          title: string;
          handle: string;
          collectionIds?: Array<string | null> | null;
          integrationProvider?: {
            __typename?: "IntegrationProviderDetailsSnapshot";
            provider?: string | null;
          } | null;
          images: Array<{
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null>;
        } | null;
        selectedOptions: Array<{
          __typename?: "SelectedVariantOption";
          value: string;
          name: string;
        }>;
        variantSnapshot?: {
          __typename?: "ProductVariantSnapshot";
          id: string;
          sku?: string | null;
          quantity?: number | null;
          productId: string;
          productTitle?: string | null;
          image?: {
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null;
          productImages?: Array<{
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null> | null;
        } | null;
        subtotal?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
        price?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
        discount?: {
          __typename?: "OrderItemDiscountDetails";
          discountSource: DiscountSource;
          quantity: number;
          data?: {
            __typename?: "Discount";
            id: string;
            title?: string | null;
            translations?: Array<{
              __typename?: "DiscountTranslation";
              title?: string | null;
              locale?: string | null;
            } | null> | null;
          } | null;
          info: {
            __typename?: "OrderItemDiscountDetailsInfo";
            percentage?: number | null;
            fixed?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          };
          perItem: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        } | null;
        discounts?: Array<{
          __typename?: "OrderItemDiscountDetails";
          discountSource: DiscountSource;
          quantity: number;
          data?: {
            __typename?: "Discount";
            id: string;
            title?: string | null;
            translations?: Array<{
              __typename?: "DiscountTranslation";
              title?: string | null;
              locale?: string | null;
            } | null> | null;
          } | null;
          info: {
            __typename?: "OrderItemDiscountDetailsInfo";
            percentage?: number | null;
            fixed?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          };
          perItem: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        }> | null;
      }
    | null
  >;
  shippingRateCost: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  };
  promoCodeSnapshot?: {
    __typename?: "PromoCodeSnapshot";
    code: string;
    percentageOff?: number | null;
    fixedAmount?: number | null;
    type?: PromoCodeTypeEnum | null;
  } | null;
  receipt?: {
    __typename?: "OrderReceipt";
    subtotal?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
    automaticDiscount?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
    discount?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
    tax?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
    shipping?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
    total?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
  } | null;
  discounts?: Array<{
    __typename?: "DiscountSnapshot";
    id?: string | null;
    type?: DiscountType | null;
    title?: string | null;
    appliedOn?: DiscountAppliedOnType | null;
    percentage?: number | null;
    amount?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
  } | null> | null;
  paymentIntent?: {
    __typename?: "PaymentIntent";
    id: string;
    status?: PaymentIntentStatusEnum | null;
    embedUrl?: string | null;
    paymentMethod?: PaymentMethodEnum | null;
    paymentMethods?: Array<PaymentMethodEnum | null> | null;
  } | null;
  reviews: Array<{ __typename?: "ProductReview"; productId: string }>;
  errors?: Array<{
    __typename?: "CheckoutError";
    code: ErrorCode;
    resourceId?: string | null;
    params?: {
      __typename?: "Params";
      requestedQuantity?: number | null;
      availableQuantity?: number | null;
      requestedPrice?: number | null;
      availablePrice?: number | null;
      requestedCurrency?: string | null;
      availableCurrency?: string | null;
      message?: string | null;
    } | null;
  } | null> | null;
};

export type ProductInfoFragment = {
  __typename?: "Product";
  id: string;
  type?: ProductType | null;
  locale: string;
  title: string;
  descriptionHtml?: any | null;
  shortDescription?: string | null;
  handle: string;
  isInStock: boolean;
  taxable?: boolean | null;
  productTax?: number | null;
  status: ProductStatus;
  integrationProvider?: {
    __typename?: "IntegrationProviderDetails";
    provider?: string | null;
  } | null;
  collections: {
    __typename?: "ProductCollectionConnection";
    totalCount: number;
    nodes?: Array<{
      __typename?: "ProductCollection";
      id: string;
      locale: string;
      title?: string | null;
      handle: string;
      shortDescription?: string | null;
      productsCount: number;
      image?: {
        __typename?: "Image";
        id: string;
        altText?: string | null;
        src?: string | null;
        width?: number | null;
        height?: number | null;
        format?: string | null;
      } | null;
      seo?: {
        __typename?: "ProductCollectionSEO";
        title?: string | null;
        description?: string | null;
      } | null;
    } | null> | null;
  };
  initialPrice?: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  } | null;
  images: Array<{
    __typename?: "Image";
    id: string;
    altText?: string | null;
    src?: string | null;
    width?: number | null;
    height?: number | null;
    format?: string | null;
  } | null>;
  categories?: Array<{
    __typename?: "CustomProductCategory";
    id: string;
    categoryType: CategoryType;
    name: string;
    isRequired: boolean;
    image?: {
      __typename?: "Image";
      id: string;
      altText?: string | null;
      src?: string | null;
      width?: number | null;
      height?: number | null;
      format?: string | null;
    } | null;
    variants?: Array<{
      __typename?: "CustomProductCategoryVariant";
      preselected?: boolean | null;
      variant?: {
        __typename?: "ProductVariant";
        id: string;
        title?: string | null;
        quantity?: number | null;
        trackQuantity?: boolean | null;
        sku?: string | null;
        cartLimitsEnabled?: boolean | null;
        minPerCart?: number | null;
        maxPerCart?: number | null;
        product: {
          __typename?: "Product";
          id: string;
          title: string;
          status: ProductStatus;
          taxable?: boolean | null;
          productTax?: number | null;
          images: Array<{
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null>;
        };
        price: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        compareAtPrice?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
        image?: {
          __typename?: "Image";
          id: string;
          altText?: string | null;
          src?: string | null;
          width?: number | null;
          height?: number | null;
          format?: string | null;
        } | null;
        selectedOptions: Array<{
          __typename?: "SelectedOption";
          option: { __typename?: "ProductOption"; id: string; name: string };
          value: {
            __typename?: "ProductOptionValue";
            id: string;
            name?: string | null;
          };
        }>;
      } | null;
    } | null> | null;
    settings?: {
      __typename?: "CustomProductCategorySettings";
      maxSelect?: number | null;
      maxQuantity?: number | null;
    } | null;
  } | null> | null;
  variants?: {
    __typename?: "ProductVariantConnection";
    nodes: Array<{
      __typename?: "ProductVariant";
      id: string;
      title?: string | null;
      quantity?: number | null;
      trackQuantity?: boolean | null;
      sku?: string | null;
      cartLimitsEnabled?: boolean | null;
      minPerCart?: number | null;
      maxPerCart?: number | null;
      product: {
        __typename?: "Product";
        id: string;
        title: string;
        status: ProductStatus;
        taxable?: boolean | null;
        productTax?: number | null;
        images: Array<{
          __typename?: "Image";
          id: string;
          altText?: string | null;
          src?: string | null;
          width?: number | null;
          height?: number | null;
          format?: string | null;
        } | null>;
      };
      price: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      };
      compareAtPrice?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
      image?: {
        __typename?: "Image";
        id: string;
        altText?: string | null;
        src?: string | null;
        width?: number | null;
        height?: number | null;
        format?: string | null;
      } | null;
      selectedOptions: Array<{
        __typename?: "SelectedOption";
        option: { __typename?: "ProductOption"; id: string; name: string };
        value: {
          __typename?: "ProductOptionValue";
          id: string;
          name?: string | null;
        };
      }>;
    }>;
  } | null;
  discount?: {
    __typename?: "CustomProductDiscount";
    percent?: number | null;
    fixed?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
  } | null;
  attributes?: Array<{
    __typename?: "ProductAttribute";
    id: string;
    name: string;
    values: Array<{
      __typename?: "ProductAttributeValue";
      id: string;
      name?: string | null;
    }>;
  } | null> | null;
  options?: Array<{
    __typename?: "ProductOption";
    name: string;
    values: Array<{
      __typename?: "ProductOptionValue";
      id: string;
      name?: string | null;
    }>;
  } | null> | null;
  reviewsStatistics: {
    __typename?: "ProductReviewsStatistics";
    average: number;
    total: number;
  };
};

export type CartProductInfoFragment = {
  __typename?: "Product";
  id: string;
  type?: ProductType | null;
  locale: string;
  title: string;
  descriptionHtml?: any | null;
  shortDescription?: string | null;
  handle: string;
  isInStock: boolean;
  taxable?: boolean | null;
  productTax?: number | null;
  status: ProductStatus;
  integrationProvider?: {
    __typename?: "IntegrationProviderDetails";
    provider?: string | null;
  } | null;
  collections: {
    __typename?: "ProductCollectionConnection";
    totalCount: number;
    nodes?: Array<{
      __typename?: "ProductCollection";
      title?: string | null;
    } | null> | null;
  };
  initialPrice?: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  } | null;
  images: Array<{
    __typename?: "Image";
    id: string;
    altText?: string | null;
    src?: string | null;
    width?: number | null;
    height?: number | null;
    format?: string | null;
  } | null>;
  categories?: Array<{
    __typename?: "CustomProductCategory";
    id: string;
    categoryType: CategoryType;
    name: string;
    isRequired: boolean;
    image?: {
      __typename?: "Image";
      id: string;
      altText?: string | null;
      src?: string | null;
      width?: number | null;
      height?: number | null;
      format?: string | null;
    } | null;
    variants?: Array<{
      __typename?: "CustomProductCategoryVariant";
      preselected?: boolean | null;
      variant?: {
        __typename?: "ProductVariant";
        id: string;
        title?: string | null;
        quantity?: number | null;
        trackQuantity?: boolean | null;
        sku?: string | null;
        cartLimitsEnabled?: boolean | null;
        minPerCart?: number | null;
        maxPerCart?: number | null;
        product: {
          __typename?: "Product";
          id: string;
          title: string;
          status: ProductStatus;
          taxable?: boolean | null;
          productTax?: number | null;
          images: Array<{
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null>;
        };
        price: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        compareAtPrice?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
        image?: {
          __typename?: "Image";
          id: string;
          altText?: string | null;
          src?: string | null;
          width?: number | null;
          height?: number | null;
          format?: string | null;
        } | null;
        selectedOptions: Array<{
          __typename?: "SelectedOption";
          option: { __typename?: "ProductOption"; id: string; name: string };
          value: {
            __typename?: "ProductOptionValue";
            id: string;
            name?: string | null;
          };
        }>;
      } | null;
    } | null> | null;
    settings?: {
      __typename?: "CustomProductCategorySettings";
      maxSelect?: number | null;
      maxQuantity?: number | null;
    } | null;
  } | null> | null;
  options?: Array<{
    __typename?: "ProductOption";
    name: string;
    values: Array<{
      __typename?: "ProductOptionValue";
      id: string;
      name?: string | null;
    }>;
  } | null> | null;
};

export type ProductAttributesInfoFragment = {
  __typename?: "ProductAttribute";
  id: string;
  name: string;
  values: Array<{
    __typename?: "ProductAttributeValue";
    id: string;
    name?: string | null;
  }>;
};

export type ProductOptionsInfoFragment = {
  __typename?: "ProductOption";
  name: string;
  values: Array<{
    __typename?: "ProductOptionValue";
    id: string;
    name?: string | null;
  }>;
};

export type AddressInfoFragment = {
  __typename?: "CustomerAddress";
  id: string;
  addressLine1: string;
  addressLine2?: string | null;
  secondPhone?: string | null;
  isDefault: boolean;
  postalCode?: string | null;
  notes?: string | null;
  state?: { __typename?: "State"; name: string; id: string } | null;
  country?: { __typename?: "Country"; name: string; id: string } | null;
  city?: { __typename?: "City"; name: string; id: string } | null;
  region?: { __typename?: "Region"; name: string; id: string } | null;
};

export type UserOrderInfoFragment = {
  __typename?: "Order";
  id: string;
  orderSerial?: string | null;
  createdAt: any;
  paymentStatus?: OrderPaymentStatusEnum | null;
  fulfillmentStatus?: FulfillStatusEnum | null;
  wuiltShipmentProvider?: WuiltShipmentProvider | null;
  shipmentDetails?: {
    __typename?: "ShipmentDetails";
    trackingURL?: string | null;
    orderTrackingNumber?: string | null;
  } | null;
  totalPrice?: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  } | null;
};

export type DiscountFragment = {
  __typename?: "CustomProductDiscount";
  percent?: number | null;
  fixed?: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  } | null;
};

export type ProductBasicInfoFragment = {
  __typename?: "Product";
  id: string;
  title: string;
  handle: string;
  type?: ProductType | null;
  isInStock: boolean;
  taxable?: boolean | null;
  productTax?: number | null;
  source?: SourceType | null;
  integrationProvider?: {
    __typename?: "IntegrationProviderDetails";
    provider?: string | null;
  } | null;
  discount?: {
    __typename?: "CustomProductDiscount";
    percent?: number | null;
    fixed?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
  } | null;
  initialPrice?: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  } | null;
  images: Array<{
    __typename?: "Image";
    id: string;
    altText?: string | null;
    src?: string | null;
    width?: number | null;
    height?: number | null;
    format?: string | null;
  } | null>;
  variants?: {
    __typename?: "ProductVariantConnection";
    nodes: Array<{
      __typename?: "ProductVariant";
      id: string;
      title?: string | null;
      quantity?: number | null;
      trackQuantity?: boolean | null;
      sku?: string | null;
      cartLimitsEnabled?: boolean | null;
      minPerCart?: number | null;
      maxPerCart?: number | null;
      product: {
        __typename?: "Product";
        id: string;
        title: string;
        status: ProductStatus;
        taxable?: boolean | null;
        productTax?: number | null;
        images: Array<{
          __typename?: "Image";
          id: string;
          altText?: string | null;
          src?: string | null;
          width?: number | null;
          height?: number | null;
          format?: string | null;
        } | null>;
      };
      price: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      };
      compareAtPrice?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
      image?: {
        __typename?: "Image";
        id: string;
        altText?: string | null;
        src?: string | null;
        width?: number | null;
        height?: number | null;
        format?: string | null;
      } | null;
      selectedOptions: Array<{
        __typename?: "SelectedOption";
        option: { __typename?: "ProductOption"; id: string; name: string };
        value: {
          __typename?: "ProductOptionValue";
          id: string;
          name?: string | null;
        };
      }>;
    }>;
  } | null;
  collections: {
    __typename?: "ProductCollectionConnection";
    totalCount: number;
    nodes?: Array<{
      __typename?: "ProductCollection";
      id: string;
      title?: string | null;
      handle: string;
    } | null> | null;
  };
  reviewsStatistics: {
    __typename?: "ProductReviewsStatistics";
    average: number;
    total: number;
  };
};

export type CustomerInfoFragment = {
  __typename?: "CustomerUser";
  storeId?: string | null;
  id: string;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  updatedAt?: any | null;
  createdAt?: any | null;
  isGuest?: boolean | null;
  totalSpending?: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  } | null;
};

export type HeroSliderFragment = {
  __typename?: "StoreHomeHero";
  id?: string | null;
  align?: string | null;
  headingText?: string | null;
  subHeadingColor?: string | null;
  subHeadingSize?: number | null;
  subHeadingText?: string | null;
  image?: {
    __typename?: "Image";
    id: string;
    altText?: string | null;
    src?: string | null;
    width?: number | null;
    height?: number | null;
    format?: string | null;
  } | null;
  mobileImage?: {
    __typename?: "Image";
    id: string;
    altText?: string | null;
    src?: string | null;
    width?: number | null;
    height?: number | null;
    format?: string | null;
  } | null;
  buttons?: Array<{
    __typename?: "Button";
    text?: string | null;
    link?: {
      __typename?: "Link";
      type: LinkType;
      url?: string | null;
      resourceId?: string | null;
      resource?:
        | { __typename: "Product"; handle: string }
        | { __typename: "ProductCollection"; handle: string }
        | { __typename: "StoreLegalPage" }
        | { __typename: "StorePage"; handle: string }
        | { __typename: "StoreStaticPage" }
        | null;
    } | null;
  } | null> | null;
};

export type InfoBarInfoFragment = {
  __typename?: "InfoBar";
  text: any;
  background: string;
  locales?: Array<string | null> | null;
  locale?: string | null;
  isActive?: boolean | null;
  isDismissible?: boolean | null;
  translations?: Array<{
    __typename?: "InfoBarTranslation";
    text: any;
    locale: string;
  } | null> | null;
};

export type StoreAboutFragment = {
  __typename?: "AboutType";
  title?: string | null;
  content?: string | null;
  isActive?: boolean | null;
};

export type StoreHomeAppearanceFragment = {
  __typename?: "StoreAppearance";
  heroSlider?: Array<{
    __typename?: "StoreHomeHero";
    id?: string | null;
    align?: string | null;
    headingText?: string | null;
    subHeadingColor?: string | null;
    subHeadingSize?: number | null;
    subHeadingText?: string | null;
    image?: {
      __typename?: "Image";
      id: string;
      altText?: string | null;
      src?: string | null;
      width?: number | null;
      height?: number | null;
      format?: string | null;
    } | null;
    mobileImage?: {
      __typename?: "Image";
      id: string;
      altText?: string | null;
      src?: string | null;
      width?: number | null;
      height?: number | null;
      format?: string | null;
    } | null;
    buttons?: Array<{
      __typename?: "Button";
      text?: string | null;
      link?: {
        __typename?: "Link";
        type: LinkType;
        url?: string | null;
        resourceId?: string | null;
        resource?:
          | { __typename: "Product"; handle: string }
          | { __typename: "ProductCollection"; handle: string }
          | { __typename: "StoreLegalPage" }
          | { __typename: "StorePage"; handle: string }
          | { __typename: "StoreStaticPage" }
          | null;
      } | null;
    } | null> | null;
  } | null> | null;
  infoBar?: {
    __typename?: "InfoBar";
    text: any;
    background: string;
    locales?: Array<string | null> | null;
    locale?: string | null;
    isActive?: boolean | null;
    isDismissible?: boolean | null;
    translations?: Array<{
      __typename?: "InfoBarTranslation";
      text: any;
      locale: string;
    } | null> | null;
  } | null;
  about?: {
    __typename?: "AboutType";
    title?: string | null;
    content?: string | null;
    isActive?: boolean | null;
  } | null;
};

export type SelectedOptionsFragment = {
  __typename?: "SelectedOption";
  option: { __typename?: "ProductOption"; id: string; name: string };
  value: {
    __typename?: "ProductOptionValue";
    id: string;
    name?: string | null;
  };
};

export type DiscountVariantFragment = {
  __typename?: "ProductVariant";
  id: string;
};

export type DiscountCollectionFragment = {
  __typename?: "ProductCollection";
  id: string;
};

export type BuyXGetYDiscountFragment = {
  __typename?: "BuyXGetYDiscount";
  customerBuys: {
    __typename?: "CustomerBuys";
    quantity?: number | null;
    itemsType?: DiscountItemsType | null;
    value?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
    items?: {
      __typename?: "CustomerBuysItems";
      productVariantIds?: Array<string> | null;
      collectionIds?: Array<string> | null;
    } | null;
  };
  customerGets: {
    __typename?: "CustomerGets";
    quantity?: number | null;
    percentage: number;
    itemsType: DiscountItemsType;
    items: {
      __typename?: "CustomerGetsItems";
      productVariants?: Array<{
        __typename?: "ProductVariant";
        id: string;
      }> | null;
      collections?: Array<{
        __typename?: "ProductCollection";
        id: string;
      }> | null;
    };
  };
};

export type AmountOffDiscountFragment = {
  __typename?: "AmountOff";
  customerBuys: {
    __typename?: "CustomerBuysAmountOff";
    itemsType?: DiscountItemsType | null;
    items?: {
      __typename?: "CustomerBuysItems";
      productVariantIds?: Array<string> | null;
      collectionIds?: Array<string> | null;
      collections?: Array<{
        __typename?: "ProductCollection";
        id: string;
      }> | null;
      productVariants?: Array<{
        __typename?: "ProductVariant";
        id: string;
      }> | null;
    } | null;
  };
  discount: {
    __typename?: "AmountOffDiscounted";
    amount?: number | null;
    percentage?: number | null;
  };
};

export type AutomaticDiscountFragment = {
  __typename?: "Discount";
  id: string;
  title?: string | null;
  appliedOn?: DiscountAppliedOnType | null;
  percentage?: number | null;
  status?: DiscountStatus | null;
  customerBuys?: {
    __typename?: "CustomerBuys";
    value?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
  } | null;
  amount?: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  } | null;
  BuyXGetYDiscount?: {
    __typename?: "BuyXGetYDiscount";
    customerBuys: {
      __typename?: "CustomerBuys";
      quantity?: number | null;
      itemsType?: DiscountItemsType | null;
      value?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
      items?: {
        __typename?: "CustomerBuysItems";
        productVariantIds?: Array<string> | null;
        collectionIds?: Array<string> | null;
      } | null;
    };
    customerGets: {
      __typename?: "CustomerGets";
      quantity?: number | null;
      percentage: number;
      itemsType: DiscountItemsType;
      items: {
        __typename?: "CustomerGetsItems";
        productVariants?: Array<{
          __typename?: "ProductVariant";
          id: string;
        }> | null;
        collections?: Array<{
          __typename?: "ProductCollection";
          id: string;
        }> | null;
      };
    };
  } | null;
  amountOff?: {
    __typename?: "AmountOff";
    customerBuys: {
      __typename?: "CustomerBuysAmountOff";
      itemsType?: DiscountItemsType | null;
      items?: {
        __typename?: "CustomerBuysItems";
        productVariantIds?: Array<string> | null;
        collectionIds?: Array<string> | null;
        collections?: Array<{
          __typename?: "ProductCollection";
          id: string;
        }> | null;
        productVariants?: Array<{
          __typename?: "ProductVariant";
          id: string;
        }> | null;
      } | null;
    };
    discount: {
      __typename?: "AmountOffDiscounted";
      amount?: number | null;
      percentage?: number | null;
    };
  } | null;
  translations?: Array<{
    __typename?: "DiscountTranslation";
    title?: string | null;
    locale?: string | null;
  } | null> | null;
};

export type CheckoutErrorsFragment = {
  __typename?: "CheckoutError";
  code: ErrorCode;
  resourceId?: string | null;
  params?: {
    __typename?: "Params";
    requestedQuantity?: number | null;
    availableQuantity?: number | null;
    requestedPrice?: number | null;
    availablePrice?: number | null;
    requestedCurrency?: string | null;
    availableCurrency?: string | null;
    message?: string | null;
  } | null;
};

export type StorePageSectionsFragment = {
  __typename?: "StorePageSection";
  id: string;
  storeId: string;
  header?: string | null;
  displayAs?: DisplayAsEnum | null;
  locale?: Locale | null;
  showSectionHeader?: boolean | null;
  stretchToFullWidth?: boolean | null;
  itemsPerRow?: number | null;
  translations?: Array<{
    __typename?: "SectionTranslations";
    header?: string | null;
    content?: string | null;
    locale?: Locale | null;
  } | null> | null;
  background?: {
    __typename?: "SectionBackground";
    type?: SectionBackgroundTypeEnum | null;
    color?: string | null;
  } | null;
  body:
    | {
        __typename?: "SectionAttributesRow";
        id: string;
        storeId: string;
        type: SectionTypeEnum;
        header: string;
        displayAs: DisplayAsEnum;
        locale: string;
        showSectionHeader: boolean;
        stretchToFullWidth: boolean;
        showItemName: boolean;
        itemsPerRow: number;
        translations: Array<{
          __typename?: "SectionAttributesTranslation";
          header?: string | null;
          locale: string;
        }>;
        background?: {
          __typename?: "SectionBackground";
          type?: SectionBackgroundTypeEnum | null;
          color?: string | null;
        } | null;
        attributeValues: Array<{
          __typename?: "ProductAttributeValue";
          id: string;
          handle?: string | null;
          name?: string | null;
          image?: {
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null;
          attribute?: { __typename?: "ProductAttribute"; id: string } | null;
        }>;
      }
    | {
        __typename?: "SectionBanners";
        type: SectionTypeEnum;
        banners?: Array<{
          __typename?: "Banner";
          id?: string | null;
          mobileImage?: {
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null;
          image?: {
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null;
          bannerLink?: {
            __typename?: "Link";
            type: LinkType;
            resourceId?: string | null;
            url?: string | null;
            resource?:
              | {
                  __typename?: "Product";
                  id: string;
                  title: string;
                  handle: string;
                  images: Array<{
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null>;
                }
              | {
                  __typename?: "ProductCollection";
                  id: string;
                  handle: string;
                  collectionTitle?: string | null;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                }
              | { __typename?: "StoreLegalPage" }
              | { __typename?: "StorePage"; handle: string }
              | { __typename?: "StoreStaticPage" }
              | null;
          } | null;
        }> | null;
      }
    | {
        __typename?: "SectionCollectionsRow";
        id: string;
        storeId: string;
        type: SectionTypeEnum;
        header: string;
        displayAs: DisplayAsEnum;
        locale: string;
        showSectionHeader: boolean;
        stretchToFullWidth: boolean;
        showItemName: boolean;
        itemsPerRow: number;
        translations: Array<{
          __typename?: "StorePageCollectionsTranslation";
          header?: string | null;
          locale: string;
        }>;
        background?: {
          __typename?: "SectionBackground";
          type?: SectionBackgroundTypeEnum | null;
          color?: string | null;
        } | null;
        collections: Array<{
          __typename?: "ProductCollection";
          id: string;
          handle: string;
          title?: string | null;
          productsCount: number;
          shortDescription?: string | null;
          seo?: {
            __typename?: "ProductCollectionSEO";
            description?: string | null;
          } | null;
          image?: {
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null;
        }>;
      }
    | { __typename?: "SectionContent"; type: SectionTypeEnum; content: string }
    | {
        __typename?: "SectionProductsRow";
        id: string;
        storeId: string;
        type: SectionTypeEnum;
        header: string;
        displayAs: DisplayAsEnum;
        locale: string;
        showSectionHeader: boolean;
        stretchToFullWidth: boolean;
        itemsPerRow: number;
        productsDisplayNumber: number;
        translations: Array<{
          __typename?: "StorePageProductsTranslation";
          header?: string | null;
          locale: string;
        }>;
        background?: {
          __typename?: "SectionBackground";
          type?: SectionBackgroundTypeEnum | null;
          color?: string | null;
        } | null;
        fromCollection?: {
          __typename?: "ProductCollection";
          id: string;
          handle: string;
        } | null;
        products: Array<{
          __typename?: "Product";
          id: string;
          title: string;
          handle: string;
          type?: ProductType | null;
          isInStock: boolean;
          taxable?: boolean | null;
          productTax?: number | null;
          source?: SourceType | null;
          integrationProvider?: {
            __typename?: "IntegrationProviderDetails";
            provider?: string | null;
          } | null;
          discount?: {
            __typename?: "CustomProductDiscount";
            percent?: number | null;
            fixed?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          } | null;
          initialPrice?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          images: Array<{
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null>;
          variants?: {
            __typename?: "ProductVariantConnection";
            nodes: Array<{
              __typename?: "ProductVariant";
              id: string;
              title?: string | null;
              quantity?: number | null;
              trackQuantity?: boolean | null;
              sku?: string | null;
              cartLimitsEnabled?: boolean | null;
              minPerCart?: number | null;
              maxPerCart?: number | null;
              product: {
                __typename?: "Product";
                id: string;
                title: string;
                status: ProductStatus;
                taxable?: boolean | null;
                productTax?: number | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              compareAtPrice?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              image?: {
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null;
              selectedOptions: Array<{
                __typename?: "SelectedOption";
                option: {
                  __typename?: "ProductOption";
                  id: string;
                  name: string;
                };
                value: {
                  __typename?: "ProductOptionValue";
                  id: string;
                  name?: string | null;
                };
              }>;
            }>;
          } | null;
          collections: {
            __typename?: "ProductCollectionConnection";
            totalCount: number;
            nodes?: Array<{
              __typename?: "ProductCollection";
              id: string;
              title?: string | null;
              handle: string;
            } | null> | null;
          };
          reviewsStatistics: {
            __typename?: "ProductReviewsStatistics";
            average: number;
            total: number;
          };
        } | null>;
      }
    | {
        __typename?: "SectionVideos";
        type: SectionTypeEnum;
        videosUrls?: Array<string> | null;
      };
};

export type CartFragment = {
  __typename?: "Cart";
  id: string;
  isReserved?: boolean | null;
  recoveryId: string;
  reservedAt?: any | null;
  createdAt: any;
  updatedAt: any;
  sessionId: string;
  status: CartStatusEnum;
  cartStep: CartStepEnum;
  lastStep: CartStepEnum;
  refCode?: string | null;
  paymentIntentId?: string | null;
  promoCode?: {
    __typename?: "PromoCode";
    id: string;
    type?: PromoCodeTypeEnum | null;
    code: string;
    status: PromoCodeStatusEnum;
    percentageOff?: number | null;
    fixedAmount?: number | null;
    isArchived: boolean;
    minimumOrderAmountToApply?: number | null;
    schedule?: {
      __typename?: "DiscountSchedule";
      start: any;
      end?: any | null;
    } | null;
  } | null;
  receipt: {
    __typename?: "CartReceipt";
    subtotal: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    };
    automaticDiscount: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    };
    discount: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    };
    tax: { __typename?: "Money"; amount: number; currencyCode: CurrencyCode };
    shipping: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    };
    total: { __typename?: "Money"; amount: number; currencyCode: CurrencyCode };
  };
  shippingDetails?: {
    __typename?: "CartShippingDetails";
    addressLine1?: string | null;
    addressLine2?: string | null;
    id?: string | null;
    secondPhone?: string | null;
    postalCode?: string | null;
    notes?: string | null;
    area?: {
      __typename?: "CartShippingDetailsArea";
      countryId?: string | null;
      stateId?: string | null;
      cityId?: string | null;
      regionId?: string | null;
      country?: { __typename?: "Country"; name: string } | null;
      state?: { __typename?: "State"; name: string } | null;
      city?: { __typename?: "City"; name: string } | null;
      region?: { __typename?: "Region"; name: string } | null;
    } | null;
  } | null;
  shippingDiscount?: {
    __typename?: "Discount";
    id: string;
    title?: string | null;
    appliedOn?: DiscountAppliedOnType | null;
    percentage?: number | null;
    status?: DiscountStatus | null;
    customerBuys?: {
      __typename?: "CustomerBuys";
      value?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
    } | null;
    amount?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
    BuyXGetYDiscount?: {
      __typename?: "BuyXGetYDiscount";
      customerBuys: {
        __typename?: "CustomerBuys";
        quantity?: number | null;
        itemsType?: DiscountItemsType | null;
        value?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
        items?: {
          __typename?: "CustomerBuysItems";
          productVariantIds?: Array<string> | null;
          collectionIds?: Array<string> | null;
        } | null;
      };
      customerGets: {
        __typename?: "CustomerGets";
        quantity?: number | null;
        percentage: number;
        itemsType: DiscountItemsType;
        items: {
          __typename?: "CustomerGetsItems";
          productVariants?: Array<{
            __typename?: "ProductVariant";
            id: string;
          }> | null;
          collections?: Array<{
            __typename?: "ProductCollection";
            id: string;
          }> | null;
        };
      };
    } | null;
    amountOff?: {
      __typename?: "AmountOff";
      customerBuys: {
        __typename?: "CustomerBuysAmountOff";
        itemsType?: DiscountItemsType | null;
        items?: {
          __typename?: "CustomerBuysItems";
          productVariantIds?: Array<string> | null;
          collectionIds?: Array<string> | null;
          collections?: Array<{
            __typename?: "ProductCollection";
            id: string;
          }> | null;
          productVariants?: Array<{
            __typename?: "ProductVariant";
            id: string;
          }> | null;
        } | null;
      };
      discount: {
        __typename?: "AmountOffDiscounted";
        amount?: number | null;
        percentage?: number | null;
      };
    } | null;
    translations?: Array<{
      __typename?: "DiscountTranslation";
      title?: string | null;
      locale?: string | null;
    } | null> | null;
  } | null;
  contactInfo?: {
    __typename?: "CartContactInfo";
    name?: string | null;
    email?: string | null;
    phone?: string | null;
  } | null;
  customer?: {
    __typename?: "CustomerUser";
    id: string;
    name?: string | null;
    email?: string | null;
    phone?: string | null;
    isGuest?: boolean | null;
    addresses?: Array<{
      __typename?: "CustomerAddress";
      addressLine1: string;
      addressLine2?: string | null;
      id: string;
      phone?: string | null;
      secondPhone?: string | null;
      postalCode?: string | null;
      notes?: string | null;
      isDefault: boolean;
    }> | null;
  } | null;
  cartErrors?: Array<{
    __typename?: "CartError";
    code: CheckoutServiceErrorCodes;
    params?: {
      __typename?: "CartErrorParam";
      resourceId?: string | null;
      requestedQuantity?: number | null;
      availableQuantity?: number | null;
      requestedPrice?: number | null;
      availablePrice?: number | null;
      requestedCurrency?: string | null;
      availableCurrency?: string | null;
      message?: string | null;
    } | null;
  }> | null;
  shippingRate?: {
    __typename?: "CartShippingRate";
    cost: { __typename?: "Money"; amount: number; currencyCode: CurrencyCode };
    details: {
      __typename?: "ShippingRate";
      id: string;
      storeId: string;
      name: string;
      isArchived: boolean;
      locale: string;
      locales: Array<string>;
      translations?: Array<{
        __typename?: "ShippingRateTranslation";
        name?: string | null;
        locale: string;
      }> | null;
    };
  } | null;
  paymentIntent?: {
    __typename?: "PaymentIntent";
    id: string;
    status?: PaymentIntentStatusEnum | null;
    embedUrl?: string | null;
    paymentMethod?: PaymentMethodEnum | null;
    paymentMethods?: Array<PaymentMethodEnum | null> | null;
    key?: string | null;
  } | null;
  items: Array<
    | {
        __typename?: "CartCustomItem";
        id: string;
        quantity: number;
        product?: {
          __typename?: "Product";
          id: string;
          type?: ProductType | null;
          locale: string;
          title: string;
          descriptionHtml?: any | null;
          shortDescription?: string | null;
          handle: string;
          isInStock: boolean;
          taxable?: boolean | null;
          productTax?: number | null;
          status: ProductStatus;
          integrationProvider?: {
            __typename?: "IntegrationProviderDetails";
            provider?: string | null;
          } | null;
          collections: {
            __typename?: "ProductCollectionConnection";
            totalCount: number;
            nodes?: Array<{
              __typename?: "ProductCollection";
              title?: string | null;
              id: string;
              locale: string;
              handle: string;
              shortDescription?: string | null;
              productsCount: number;
              image?: {
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null;
              seo?: {
                __typename?: "ProductCollectionSEO";
                title?: string | null;
                description?: string | null;
              } | null;
            } | null> | null;
          };
          initialPrice?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          images: Array<{
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null>;
          categories?: Array<{
            __typename?: "CustomProductCategory";
            id: string;
            categoryType: CategoryType;
            name: string;
            isRequired: boolean;
            image?: {
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null;
            variants?: Array<{
              __typename?: "CustomProductCategoryVariant";
              preselected?: boolean | null;
              variant?: {
                __typename?: "ProductVariant";
                id: string;
                title?: string | null;
                quantity?: number | null;
                trackQuantity?: boolean | null;
                sku?: string | null;
                cartLimitsEnabled?: boolean | null;
                minPerCart?: number | null;
                maxPerCart?: number | null;
                product: {
                  __typename?: "Product";
                  id: string;
                  title: string;
                  status: ProductStatus;
                  taxable?: boolean | null;
                  productTax?: number | null;
                  images: Array<{
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null>;
                };
                price: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                compareAtPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                selectedOptions: Array<{
                  __typename?: "SelectedOption";
                  option: {
                    __typename?: "ProductOption";
                    id: string;
                    name: string;
                  };
                  value: {
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
            } | null> | null;
            settings?: {
              __typename?: "CustomProductCategorySettings";
              maxSelect?: number | null;
              maxQuantity?: number | null;
            } | null;
          } | null> | null;
          options?: Array<{
            __typename?: "ProductOption";
            name: string;
            values: Array<{
              __typename?: "ProductOptionValue";
              id: string;
              name?: string | null;
            }>;
          } | null> | null;
          variants?: {
            __typename?: "ProductVariantConnection";
            nodes: Array<{
              __typename?: "ProductVariant";
              id: string;
              title?: string | null;
              quantity?: number | null;
              trackQuantity?: boolean | null;
              sku?: string | null;
              cartLimitsEnabled?: boolean | null;
              minPerCart?: number | null;
              maxPerCart?: number | null;
              product: {
                __typename?: "Product";
                id: string;
                title: string;
                status: ProductStatus;
                taxable?: boolean | null;
                productTax?: number | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              compareAtPrice?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              image?: {
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null;
              selectedOptions: Array<{
                __typename?: "SelectedOption";
                option: {
                  __typename?: "ProductOption";
                  id: string;
                  name: string;
                };
                value: {
                  __typename?: "ProductOptionValue";
                  id: string;
                  name?: string | null;
                };
              }>;
            }>;
          } | null;
          discount?: {
            __typename?: "CustomProductDiscount";
            percent?: number | null;
            fixed?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          } | null;
          attributes?: Array<{
            __typename?: "ProductAttribute";
            id: string;
            name: string;
            values: Array<{
              __typename?: "ProductAttributeValue";
              id: string;
              name?: string | null;
            }>;
          } | null> | null;
          reviewsStatistics: {
            __typename?: "ProductReviewsStatistics";
            average: number;
            total: number;
          };
        } | null;
        subtotal: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        totalPrice: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        price: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        discount?: {
          __typename?: "CustomItemDiscountDetails";
          discountSource: DiscountSource;
          perItem: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          info: {
            __typename?: "OrderItemDiscountDetailsInfo";
            percentage?: number | null;
            fixed?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          };
        } | null;
        categories: Array<{
          __typename?: "CartItemCustomBuildCategory";
          category: {
            __typename?: "CartItemCustomProductCategory";
            id: string;
            categoryType: CategoryType;
            name: string;
            isRequired?: boolean | null;
            maxSelect?: number | null;
            maxQuantity?: number | null;
            image?: {
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null;
          };
          selectedVariants: Array<{
            __typename?: "CartItemSelectedVariant";
            id: string;
            quantity: number;
            price: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
            subtotal?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
            variant?: {
              __typename?: "ProductVariant";
              id: string;
              title?: string | null;
              quantity?: number | null;
              trackQuantity?: boolean | null;
              sku?: string | null;
              cartLimitsEnabled?: boolean | null;
              minPerCart?: number | null;
              maxPerCart?: number | null;
              product: {
                __typename?: "Product";
                id: string;
                title: string;
                status: ProductStatus;
                taxable?: boolean | null;
                productTax?: number | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              compareAtPrice?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              image?: {
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null;
              selectedOptions: Array<{
                __typename?: "SelectedOption";
                option: {
                  __typename?: "ProductOption";
                  id: string;
                  name: string;
                };
                value: {
                  __typename?: "ProductOptionValue";
                  id: string;
                  name?: string | null;
                };
              }>;
            } | null;
          }>;
        }>;
      }
    | {
        __typename?: "CartSimpleItem";
        id: string;
        quantity: number;
        product?: {
          __typename?: "Product";
          id: string;
          type?: ProductType | null;
          locale: string;
          title: string;
          descriptionHtml?: any | null;
          shortDescription?: string | null;
          handle: string;
          isInStock: boolean;
          taxable?: boolean | null;
          productTax?: number | null;
          status: ProductStatus;
          integrationProvider?: {
            __typename?: "IntegrationProviderDetails";
            provider?: string | null;
          } | null;
          collections: {
            __typename?: "ProductCollectionConnection";
            totalCount: number;
            nodes?: Array<{
              __typename?: "ProductCollection";
              title?: string | null;
            } | null> | null;
          };
          initialPrice?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          images: Array<{
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null>;
          categories?: Array<{
            __typename?: "CustomProductCategory";
            id: string;
            categoryType: CategoryType;
            name: string;
            isRequired: boolean;
            image?: {
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null;
            variants?: Array<{
              __typename?: "CustomProductCategoryVariant";
              preselected?: boolean | null;
              variant?: {
                __typename?: "ProductVariant";
                id: string;
                title?: string | null;
                quantity?: number | null;
                trackQuantity?: boolean | null;
                sku?: string | null;
                cartLimitsEnabled?: boolean | null;
                minPerCart?: number | null;
                maxPerCart?: number | null;
                product: {
                  __typename?: "Product";
                  id: string;
                  title: string;
                  status: ProductStatus;
                  taxable?: boolean | null;
                  productTax?: number | null;
                  images: Array<{
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null>;
                };
                price: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                compareAtPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                selectedOptions: Array<{
                  __typename?: "SelectedOption";
                  option: {
                    __typename?: "ProductOption";
                    id: string;
                    name: string;
                  };
                  value: {
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
            } | null> | null;
            settings?: {
              __typename?: "CustomProductCategorySettings";
              maxSelect?: number | null;
              maxQuantity?: number | null;
            } | null;
          } | null> | null;
          options?: Array<{
            __typename?: "ProductOption";
            name: string;
            values: Array<{
              __typename?: "ProductOptionValue";
              id: string;
              name?: string | null;
            }>;
          } | null> | null;
        } | null;
        subtotal: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        price: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        totalPrice: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        variant?: {
          __typename?: "ProductVariant";
          id: string;
          title?: string | null;
          quantity?: number | null;
          trackQuantity?: boolean | null;
          sku?: string | null;
          cartLimitsEnabled?: boolean | null;
          minPerCart?: number | null;
          maxPerCart?: number | null;
          price: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          compareAtPrice?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          image?: {
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null;
          selectedOptions: Array<{
            __typename?: "SelectedOption";
            option: { __typename?: "ProductOption"; id: string; name: string };
            value: {
              __typename?: "ProductOptionValue";
              id: string;
              name?: string | null;
            };
          }>;
        } | null;
        discount?: {
          __typename?: "OrderItemDiscountDetails";
          discountSource: DiscountSource;
          quantity: number;
          data?: {
            __typename?: "Discount";
            id: string;
            title?: string | null;
            translations?: Array<{
              __typename?: "DiscountTranslation";
              title?: string | null;
              locale?: string | null;
            } | null> | null;
          } | null;
          info: {
            __typename?: "OrderItemDiscountDetailsInfo";
            percentage?: number | null;
            fixed?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          };
          perItem: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        } | null;
        discounts?: Array<{
          __typename?: "OrderItemDiscountDetails";
          discountSource: DiscountSource;
          quantity: number;
          data?: {
            __typename?: "Discount";
            id: string;
            title?: string | null;
            translations?: Array<{
              __typename?: "DiscountTranslation";
              title?: string | null;
              locale?: string | null;
            } | null> | null;
          } | null;
          info: {
            __typename?: "OrderItemDiscountDetailsInfo";
            percentage?: number | null;
            fixed?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          };
          perItem: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        }> | null;
      }
  >;
};

export type CartReceiptFragment = {
  __typename?: "CartReceipt";
  subtotal: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  };
  automaticDiscount: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  };
  discount: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  };
  tax: { __typename?: "Money"; amount: number; currencyCode: CurrencyCode };
  shipping: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  };
  total: { __typename?: "Money"; amount: number; currencyCode: CurrencyCode };
};

export type CartShippingDetailsFragment = {
  __typename?: "CartShippingDetails";
  addressLine1?: string | null;
  addressLine2?: string | null;
  id?: string | null;
  secondPhone?: string | null;
  postalCode?: string | null;
  notes?: string | null;
  area?: {
    __typename?: "CartShippingDetailsArea";
    countryId?: string | null;
    stateId?: string | null;
    cityId?: string | null;
    regionId?: string | null;
    country?: { __typename?: "Country"; name: string } | null;
    state?: { __typename?: "State"; name: string } | null;
    city?: { __typename?: "City"; name: string } | null;
    region?: { __typename?: "Region"; name: string } | null;
  } | null;
};

export type CartShippingDetailsAreaFragment = {
  __typename?: "CartShippingDetailsArea";
  countryId?: string | null;
  stateId?: string | null;
  cityId?: string | null;
  regionId?: string | null;
  country?: { __typename?: "Country"; name: string } | null;
  state?: { __typename?: "State"; name: string } | null;
  city?: { __typename?: "City"; name: string } | null;
  region?: { __typename?: "Region"; name: string } | null;
};

export type CartItemsFragment = {
  __typename?: "Cart";
  items: Array<
    | {
        __typename?: "CartCustomItem";
        id: string;
        quantity: number;
        product?: {
          __typename?: "Product";
          id: string;
          type?: ProductType | null;
          locale: string;
          title: string;
          descriptionHtml?: any | null;
          shortDescription?: string | null;
          handle: string;
          isInStock: boolean;
          taxable?: boolean | null;
          productTax?: number | null;
          status: ProductStatus;
          integrationProvider?: {
            __typename?: "IntegrationProviderDetails";
            provider?: string | null;
          } | null;
          collections: {
            __typename?: "ProductCollectionConnection";
            totalCount: number;
            nodes?: Array<{
              __typename?: "ProductCollection";
              title?: string | null;
              id: string;
              locale: string;
              handle: string;
              shortDescription?: string | null;
              productsCount: number;
              image?: {
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null;
              seo?: {
                __typename?: "ProductCollectionSEO";
                title?: string | null;
                description?: string | null;
              } | null;
            } | null> | null;
          };
          initialPrice?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          images: Array<{
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null>;
          categories?: Array<{
            __typename?: "CustomProductCategory";
            id: string;
            categoryType: CategoryType;
            name: string;
            isRequired: boolean;
            image?: {
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null;
            variants?: Array<{
              __typename?: "CustomProductCategoryVariant";
              preselected?: boolean | null;
              variant?: {
                __typename?: "ProductVariant";
                id: string;
                title?: string | null;
                quantity?: number | null;
                trackQuantity?: boolean | null;
                sku?: string | null;
                cartLimitsEnabled?: boolean | null;
                minPerCart?: number | null;
                maxPerCart?: number | null;
                product: {
                  __typename?: "Product";
                  id: string;
                  title: string;
                  status: ProductStatus;
                  taxable?: boolean | null;
                  productTax?: number | null;
                  images: Array<{
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null>;
                };
                price: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                compareAtPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                selectedOptions: Array<{
                  __typename?: "SelectedOption";
                  option: {
                    __typename?: "ProductOption";
                    id: string;
                    name: string;
                  };
                  value: {
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
            } | null> | null;
            settings?: {
              __typename?: "CustomProductCategorySettings";
              maxSelect?: number | null;
              maxQuantity?: number | null;
            } | null;
          } | null> | null;
          options?: Array<{
            __typename?: "ProductOption";
            name: string;
            values: Array<{
              __typename?: "ProductOptionValue";
              id: string;
              name?: string | null;
            }>;
          } | null> | null;
          variants?: {
            __typename?: "ProductVariantConnection";
            nodes: Array<{
              __typename?: "ProductVariant";
              id: string;
              title?: string | null;
              quantity?: number | null;
              trackQuantity?: boolean | null;
              sku?: string | null;
              cartLimitsEnabled?: boolean | null;
              minPerCart?: number | null;
              maxPerCart?: number | null;
              product: {
                __typename?: "Product";
                id: string;
                title: string;
                status: ProductStatus;
                taxable?: boolean | null;
                productTax?: number | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              compareAtPrice?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              image?: {
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null;
              selectedOptions: Array<{
                __typename?: "SelectedOption";
                option: {
                  __typename?: "ProductOption";
                  id: string;
                  name: string;
                };
                value: {
                  __typename?: "ProductOptionValue";
                  id: string;
                  name?: string | null;
                };
              }>;
            }>;
          } | null;
          discount?: {
            __typename?: "CustomProductDiscount";
            percent?: number | null;
            fixed?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          } | null;
          attributes?: Array<{
            __typename?: "ProductAttribute";
            id: string;
            name: string;
            values: Array<{
              __typename?: "ProductAttributeValue";
              id: string;
              name?: string | null;
            }>;
          } | null> | null;
          reviewsStatistics: {
            __typename?: "ProductReviewsStatistics";
            average: number;
            total: number;
          };
        } | null;
        subtotal: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        totalPrice: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        price: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        discount?: {
          __typename?: "CustomItemDiscountDetails";
          discountSource: DiscountSource;
          perItem: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          info: {
            __typename?: "OrderItemDiscountDetailsInfo";
            percentage?: number | null;
            fixed?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          };
        } | null;
        categories: Array<{
          __typename?: "CartItemCustomBuildCategory";
          category: {
            __typename?: "CartItemCustomProductCategory";
            id: string;
            categoryType: CategoryType;
            name: string;
            isRequired?: boolean | null;
            maxSelect?: number | null;
            maxQuantity?: number | null;
            image?: {
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null;
          };
          selectedVariants: Array<{
            __typename?: "CartItemSelectedVariant";
            id: string;
            quantity: number;
            price: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
            subtotal?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
            variant?: {
              __typename?: "ProductVariant";
              id: string;
              title?: string | null;
              quantity?: number | null;
              trackQuantity?: boolean | null;
              sku?: string | null;
              cartLimitsEnabled?: boolean | null;
              minPerCart?: number | null;
              maxPerCart?: number | null;
              product: {
                __typename?: "Product";
                id: string;
                title: string;
                status: ProductStatus;
                taxable?: boolean | null;
                productTax?: number | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              compareAtPrice?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              image?: {
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null;
              selectedOptions: Array<{
                __typename?: "SelectedOption";
                option: {
                  __typename?: "ProductOption";
                  id: string;
                  name: string;
                };
                value: {
                  __typename?: "ProductOptionValue";
                  id: string;
                  name?: string | null;
                };
              }>;
            } | null;
          }>;
        }>;
      }
    | {
        __typename?: "CartSimpleItem";
        id: string;
        quantity: number;
        product?: {
          __typename?: "Product";
          id: string;
          type?: ProductType | null;
          locale: string;
          title: string;
          descriptionHtml?: any | null;
          shortDescription?: string | null;
          handle: string;
          isInStock: boolean;
          taxable?: boolean | null;
          productTax?: number | null;
          status: ProductStatus;
          integrationProvider?: {
            __typename?: "IntegrationProviderDetails";
            provider?: string | null;
          } | null;
          collections: {
            __typename?: "ProductCollectionConnection";
            totalCount: number;
            nodes?: Array<{
              __typename?: "ProductCollection";
              title?: string | null;
            } | null> | null;
          };
          initialPrice?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          images: Array<{
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null>;
          categories?: Array<{
            __typename?: "CustomProductCategory";
            id: string;
            categoryType: CategoryType;
            name: string;
            isRequired: boolean;
            image?: {
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null;
            variants?: Array<{
              __typename?: "CustomProductCategoryVariant";
              preselected?: boolean | null;
              variant?: {
                __typename?: "ProductVariant";
                id: string;
                title?: string | null;
                quantity?: number | null;
                trackQuantity?: boolean | null;
                sku?: string | null;
                cartLimitsEnabled?: boolean | null;
                minPerCart?: number | null;
                maxPerCart?: number | null;
                product: {
                  __typename?: "Product";
                  id: string;
                  title: string;
                  status: ProductStatus;
                  taxable?: boolean | null;
                  productTax?: number | null;
                  images: Array<{
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null>;
                };
                price: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                compareAtPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                selectedOptions: Array<{
                  __typename?: "SelectedOption";
                  option: {
                    __typename?: "ProductOption";
                    id: string;
                    name: string;
                  };
                  value: {
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
            } | null> | null;
            settings?: {
              __typename?: "CustomProductCategorySettings";
              maxSelect?: number | null;
              maxQuantity?: number | null;
            } | null;
          } | null> | null;
          options?: Array<{
            __typename?: "ProductOption";
            name: string;
            values: Array<{
              __typename?: "ProductOptionValue";
              id: string;
              name?: string | null;
            }>;
          } | null> | null;
        } | null;
        subtotal: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        price: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        totalPrice: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        variant?: {
          __typename?: "ProductVariant";
          id: string;
          title?: string | null;
          quantity?: number | null;
          trackQuantity?: boolean | null;
          sku?: string | null;
          cartLimitsEnabled?: boolean | null;
          minPerCart?: number | null;
          maxPerCart?: number | null;
          price: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          compareAtPrice?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          image?: {
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null;
          selectedOptions: Array<{
            __typename?: "SelectedOption";
            option: { __typename?: "ProductOption"; id: string; name: string };
            value: {
              __typename?: "ProductOptionValue";
              id: string;
              name?: string | null;
            };
          }>;
        } | null;
        discount?: {
          __typename?: "OrderItemDiscountDetails";
          discountSource: DiscountSource;
          quantity: number;
          data?: {
            __typename?: "Discount";
            id: string;
            title?: string | null;
            translations?: Array<{
              __typename?: "DiscountTranslation";
              title?: string | null;
              locale?: string | null;
            } | null> | null;
          } | null;
          info: {
            __typename?: "OrderItemDiscountDetailsInfo";
            percentage?: number | null;
            fixed?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          };
          perItem: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        } | null;
        discounts?: Array<{
          __typename?: "OrderItemDiscountDetails";
          discountSource: DiscountSource;
          quantity: number;
          data?: {
            __typename?: "Discount";
            id: string;
            title?: string | null;
            translations?: Array<{
              __typename?: "DiscountTranslation";
              title?: string | null;
              locale?: string | null;
            } | null> | null;
          } | null;
          info: {
            __typename?: "OrderItemDiscountDetailsInfo";
            percentage?: number | null;
            fixed?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          };
          perItem: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        }> | null;
      }
  >;
};

export type CartCustomerFragment = {
  __typename?: "CustomerUser";
  id: string;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  isGuest?: boolean | null;
  addresses?: Array<{
    __typename?: "CustomerAddress";
    addressLine1: string;
    addressLine2?: string | null;
    id: string;
    phone?: string | null;
    secondPhone?: string | null;
    postalCode?: string | null;
    notes?: string | null;
    isDefault: boolean;
  }> | null;
};

export type CartContactInfoFragment = {
  __typename?: "CartContactInfo";
  name?: string | null;
  email?: string | null;
  phone?: string | null;
};

export type CartErrorsFragment = {
  __typename?: "CartError";
  code: CheckoutServiceErrorCodes;
  params?: {
    __typename?: "CartErrorParam";
    resourceId?: string | null;
    requestedQuantity?: number | null;
    availableQuantity?: number | null;
    requestedPrice?: number | null;
    availablePrice?: number | null;
    requestedCurrency?: string | null;
    availableCurrency?: string | null;
    message?: string | null;
  } | null;
};

export type PromoCodeFragment = {
  __typename?: "PromoCode";
  id: string;
  type?: PromoCodeTypeEnum | null;
  code: string;
  status: PromoCodeStatusEnum;
  percentageOff?: number | null;
  fixedAmount?: number | null;
  isArchived: boolean;
  minimumOrderAmountToApply?: number | null;
  schedule?: {
    __typename?: "DiscountSchedule";
    start: any;
    end?: any | null;
  } | null;
};

export type CartShippingRateFragment = {
  __typename?: "CartShippingRate";
  cost: { __typename?: "Money"; amount: number; currencyCode: CurrencyCode };
  details: {
    __typename?: "ShippingRate";
    id: string;
    storeId: string;
    name: string;
    isArchived: boolean;
    locale: string;
    locales: Array<string>;
    translations?: Array<{
      __typename?: "ShippingRateTranslation";
      name?: string | null;
      locale: string;
    }> | null;
  };
};

export type PaymentDetailsFragment = {
  __typename?: "Cart";
  paymentIntentId?: string | null;
  paymentIntent?: {
    __typename?: "PaymentIntent";
    id: string;
    status?: PaymentIntentStatusEnum | null;
    embedUrl?: string | null;
    paymentMethod?: PaymentMethodEnum | null;
    paymentMethods?: Array<PaymentMethodEnum | null> | null;
    key?: string | null;
  } | null;
};

export type PaymentIntentDetailsFragment = {
  __typename?: "PaymentIntent";
  id: string;
  status?: PaymentIntentStatusEnum | null;
  embedUrl?: string | null;
  paymentMethod?: PaymentMethodEnum | null;
  paymentMethods?: Array<PaymentMethodEnum | null> | null;
  key?: string | null;
  cartId?: string | null;
};

export type OrderByCartIdFragment = {
  __typename?: "Order";
  orderSerial?: string | null;
  paymentMethod?: PaymentMethodEnum | null;
  status?: OrderStatusEnum | null;
  id: string;
  wuiltShipmentProvider?: WuiltShipmentProvider | null;
  externalActionMessage?: Array<string | null> | null;
  createdAt: any;
  shippingRateName?: string | null;
  billingAddress: {
    __typename?: "Address";
    addressLine1: string;
    addressLine2?: string | null;
    phone?: string | null;
    secondPhone?: string | null;
    postalCode?: string | null;
    notes?: string | null;
    areaSnapshot?: {
      __typename?: "AreaSnapshot";
      cityName?: string | null;
      regionName?: string | null;
      countryName?: string | null;
      stateName?: string | null;
    } | null;
  };
  shippingAddress: {
    __typename?: "Address";
    addressLine1: string;
    addressLine2?: string | null;
    phone?: string | null;
    secondPhone?: string | null;
    postalCode?: string | null;
    notes?: string | null;
    areaSnapshot?: {
      __typename?: "AreaSnapshot";
      cityName?: string | null;
      regionName?: string | null;
      countryName?: string | null;
      stateName?: string | null;
    } | null;
  };
  totalPrice?: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  } | null;
  customer: {
    __typename?: "Customer";
    name?: string | null;
    email?: string | null;
    phone?: string | null;
    isSubscribedToNewsLetter?: boolean | null;
  };
  receipt?: {
    __typename?: "OrderReceipt";
    subtotal?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
    discount?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
    tax?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
    shipping?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
    total?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
    automaticDiscount?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
  } | null;
  promoCodeSnapshot?: {
    __typename?: "PromoCodeSnapshot";
    code: string;
    percentageOff?: number | null;
    fixedAmount?: number | null;
    type?: PromoCodeTypeEnum | null;
  } | null;
  shippingRateCost: {
    __typename?: "Money";
    amount: number;
    currencyCode: CurrencyCode;
  };
  items: Array<
    | {
        __typename?: "CustomItem";
        id: string;
        quantity: number;
        title?: string | null;
        categories: Array<{
          __typename?: "CustomBuildCategory";
          category: {
            __typename?: "CustomProductCategory";
            id: string;
            categoryType: CategoryType;
            name: string;
            isRequired: boolean;
            image?: {
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null;
            variants?: Array<{
              __typename?: "CustomProductCategoryVariant";
              preselected?: boolean | null;
              variant?: {
                __typename?: "ProductVariant";
                id: string;
                title?: string | null;
                quantity?: number | null;
                trackQuantity?: boolean | null;
                sku?: string | null;
                cartLimitsEnabled?: boolean | null;
                minPerCart?: number | null;
                maxPerCart?: number | null;
                product: {
                  __typename?: "Product";
                  id: string;
                  title: string;
                  status: ProductStatus;
                  taxable?: boolean | null;
                  productTax?: number | null;
                  images: Array<{
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null>;
                };
                price: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                compareAtPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                selectedOptions: Array<{
                  __typename?: "SelectedOption";
                  option: {
                    __typename?: "ProductOption";
                    id: string;
                    name: string;
                  };
                  value: {
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
            } | null> | null;
            settings?: {
              __typename?: "CustomProductCategorySettings";
              maxSelect?: number | null;
              maxQuantity?: number | null;
            } | null;
          };
          selectedVariants: Array<{
            __typename?: "SelectedVariant";
            quantity: number;
            variantSnapshot?: {
              __typename?: "ProductVariantSnapshot";
              id: string;
              sku?: string | null;
              quantity?: number | null;
              productId: string;
              productTitle?: string | null;
              image?: {
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null;
              productImages?: Array<{
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null> | null;
            } | null;
            price?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
            selectedOptions: Array<{
              __typename?: "SelectedVariantOption";
              value: string;
              name: string;
            }>;
          }>;
        }>;
        subtotal?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
        productSnapshot?: {
          __typename?: "ProductSnapshot";
          id: string;
          title: string;
          handle: string;
          collectionIds?: Array<string | null> | null;
          integrationProvider?: {
            __typename?: "IntegrationProviderDetailsSnapshot";
            provider?: string | null;
          } | null;
          images: Array<{
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null>;
        } | null;
        price?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
        discount?: {
          __typename?: "OrderItemDiscountDetails";
          discountSource: DiscountSource;
          quantity: number;
          data?: {
            __typename?: "Discount";
            id: string;
            title?: string | null;
            translations?: Array<{
              __typename?: "DiscountTranslation";
              title?: string | null;
              locale?: string | null;
            } | null> | null;
          } | null;
          info: {
            __typename?: "OrderItemDiscountDetailsInfo";
            percentage?: number | null;
            fixed?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          };
          perItem: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        } | null;
        discounts?: Array<{
          __typename?: "OrderItemDiscountDetails";
          discountSource: DiscountSource;
          quantity: number;
          data?: {
            __typename?: "Discount";
            id: string;
            title?: string | null;
            translations?: Array<{
              __typename?: "DiscountTranslation";
              title?: string | null;
              locale?: string | null;
            } | null> | null;
          } | null;
          info: {
            __typename?: "OrderItemDiscountDetailsInfo";
            percentage?: number | null;
            fixed?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          };
          perItem: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        }> | null;
      }
    | {
        __typename?: "SimpleItem";
        id: string;
        quantity: number;
        title?: string | null;
        productSnapshot?: {
          __typename?: "ProductSnapshot";
          id: string;
          title: string;
          handle: string;
          collectionIds?: Array<string | null> | null;
          integrationProvider?: {
            __typename?: "IntegrationProviderDetailsSnapshot";
            provider?: string | null;
          } | null;
          images: Array<{
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null>;
        } | null;
        selectedOptions: Array<{
          __typename?: "SelectedVariantOption";
          value: string;
          name: string;
        }>;
        variantSnapshot?: {
          __typename?: "ProductVariantSnapshot";
          id: string;
          sku?: string | null;
          quantity?: number | null;
          productId: string;
          productTitle?: string | null;
          image?: {
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null;
          productImages?: Array<{
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null> | null;
        } | null;
        subtotal?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
        price?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
        discount?: {
          __typename?: "OrderItemDiscountDetails";
          discountSource: DiscountSource;
          quantity: number;
          data?: {
            __typename?: "Discount";
            id: string;
            title?: string | null;
            translations?: Array<{
              __typename?: "DiscountTranslation";
              title?: string | null;
              locale?: string | null;
            } | null> | null;
          } | null;
          info: {
            __typename?: "OrderItemDiscountDetailsInfo";
            percentage?: number | null;
            fixed?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          };
          perItem: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        } | null;
        discounts?: Array<{
          __typename?: "OrderItemDiscountDetails";
          discountSource: DiscountSource;
          quantity: number;
          data?: {
            __typename?: "Discount";
            id: string;
            title?: string | null;
            translations?: Array<{
              __typename?: "DiscountTranslation";
              title?: string | null;
              locale?: string | null;
            } | null> | null;
          } | null;
          info: {
            __typename?: "OrderItemDiscountDetailsInfo";
            percentage?: number | null;
            fixed?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          };
          perItem: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        }> | null;
      }
    | null
  >;
  discounts?: Array<{
    __typename?: "DiscountSnapshot";
    id?: string | null;
    type?: DiscountType | null;
    title?: string | null;
    appliedOn?: DiscountAppliedOnType | null;
    percentage?: number | null;
    amount?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
  } | null> | null;
};

export type SubmitStoreContactFormMutationVariables = Exact<{
  input: SubmitStoreContactFormInput;
}>;

export type SubmitStoreContactFormMutation = {
  __typename?: "Mutation";
  submitStoreContactForm: {
    __typename?: "SubmitStoreContactFormPayload";
    statusCode: number;
    message: string;
  };
};

export type UpdateOrderPaymentMethodMutationVariables = Exact<{
  orderId: Scalars["ID"]["input"];
  paymentMethod?: InputMaybe<PaymentMethodEnum>;
  storeId: Scalars["ID"]["input"];
}>;

export type UpdateOrderPaymentMethodMutation = {
  __typename?: "Mutation";
  updateOrderPaymentMethod?: {
    __typename?: "PaymentIntent";
    id: string;
    status?: PaymentIntentStatusEnum | null;
    embedUrl?: string | null;
    paymentMethod?: PaymentMethodEnum | null;
    paymentMethods?: Array<PaymentMethodEnum | null> | null;
  } | null;
};

export type CancelPaymentMutationVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  cartId: Scalars["ID"]["input"];
}>;

export type CancelPaymentMutation = {
  __typename?: "Mutation";
  cancelPayment?: {
    __typename?: "PaymentIntent";
    id: string;
    status?: PaymentIntentStatusEnum | null;
    embedUrl?: string | null;
    paymentMethod?: PaymentMethodEnum | null;
    paymentMethods?: Array<PaymentMethodEnum | null> | null;
    key?: string | null;
  } | null;
};

export type ConfirmIntentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
}>;

export type ConfirmIntentMutation = {
  __typename?: "Mutation";
  confirmPaymentIntent?: {
    __typename?: "PaymentIntent";
    id: string;
    status?: PaymentIntentStatusEnum | null;
    embedUrl?: string | null;
    paymentMethod?: PaymentMethodEnum | null;
    paymentMethods?: Array<PaymentMethodEnum | null> | null;
  } | null;
};

export type AddCustomerAddressMutationVariables = Exact<{
  customerId: Scalars["ID"]["input"];
  input: AddCustomerAddressInput;
}>;

export type AddCustomerAddressMutation = {
  __typename?: "Mutation";
  addCustomerAddress: {
    __typename?: "CustomerAddress";
    id: string;
    addressLine1: string;
    addressLine2?: string | null;
    secondPhone?: string | null;
    isDefault: boolean;
    postalCode?: string | null;
    notes?: string | null;
    state?: { __typename?: "State"; name: string; id: string } | null;
    country?: { __typename?: "Country"; name: string; id: string } | null;
    city?: { __typename?: "City"; name: string; id: string } | null;
    region?: { __typename?: "Region"; name: string; id: string } | null;
  };
};

export type UpdateCustomerAddressMutationVariables = Exact<{
  customerId: Scalars["ID"]["input"];
  addressId: Scalars["ID"]["input"];
  input: UpdateCustomerAddressInput;
}>;

export type UpdateCustomerAddressMutation = {
  __typename?: "Mutation";
  updateCustomerAddress: {
    __typename?: "CustomerAddress";
    id: string;
    addressLine1: string;
    addressLine2?: string | null;
    secondPhone?: string | null;
    isDefault: boolean;
    postalCode?: string | null;
    notes?: string | null;
    state?: { __typename?: "State"; name: string; id: string } | null;
    country?: { __typename?: "Country"; name: string; id: string } | null;
    city?: { __typename?: "City"; name: string; id: string } | null;
    region?: { __typename?: "Region"; name: string; id: string } | null;
  };
};

export type DeleteCustomerAddressMutationVariables = Exact<{
  customerId: Scalars["ID"]["input"];
  addressId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
}>;

export type DeleteCustomerAddressMutation = {
  __typename?: "Mutation";
  deleteCustomerAddress: boolean;
};

export type MakeDefaultAddressMutationVariables = Exact<{
  customerId: Scalars["ID"]["input"];
  addressId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
}>;

export type MakeDefaultAddressMutation = {
  __typename?: "Mutation";
  makeDefaultAddress: {
    __typename?: "CustomerAddress";
    id: string;
    addressLine1: string;
    addressLine2?: string | null;
    secondPhone?: string | null;
    isDefault: boolean;
    postalCode?: string | null;
    notes?: string | null;
    state?: { __typename?: "State"; name: string; id: string } | null;
    country?: { __typename?: "Country"; name: string; id: string } | null;
    city?: { __typename?: "City"; name: string; id: string } | null;
    region?: { __typename?: "Region"; name: string; id: string } | null;
  };
};

export type UpdateCustomerInfoMutationVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  customerId: Scalars["ID"]["input"];
  input?: InputMaybe<UpdateCustomerUserInput>;
}>;

export type UpdateCustomerInfoMutation = {
  __typename?: "Mutation";
  updateCustomer?: {
    __typename?: "CustomerUser";
    id: string;
    name?: string | null;
    email?: string | null;
    phone?: string | null;
  } | null;
};

export type SubmitReviewMutationVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  review: CreateProductReviewInput;
}>;

export type SubmitReviewMutation = {
  __typename?: "Mutation";
  submitReview: { __typename?: "ProductReview"; id: string };
};

export type DeleteReviewMutationVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  reviewId: Scalars["ID"]["input"];
}>;

export type DeleteReviewMutation = {
  __typename?: "Mutation";
  deleteReview: boolean;
};

export type EditReviewMutationVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  reviewId: Scalars["ID"]["input"];
  review: UpdateProductReviewInput;
}>;

export type EditReviewMutation = {
  __typename?: "Mutation";
  editReview: { __typename?: "ProductReview"; id: string };
};

export type DeleteAccountMutationVariables = Exact<{
  password: Scalars["String"]["input"];
}>;

export type DeleteAccountMutation = {
  __typename?: "Mutation";
  deleteAccount: boolean;
};

export type AddCustomItemMutationVariables = Exact<{
  item: CustomItemInput;
  storeId: Scalars["ID"]["input"];
}>;

export type AddCustomItemMutation = {
  __typename?: "Mutation";
  customerActions?: {
    __typename?: "CustomerActions";
    cart?: {
      __typename?: "CartActions";
      addCustomItem?: {
        __typename?: "Cart";
        id: string;
        isReserved?: boolean | null;
        recoveryId: string;
        reservedAt?: any | null;
        createdAt: any;
        updatedAt: any;
        sessionId: string;
        status: CartStatusEnum;
        cartStep: CartStepEnum;
        lastStep: CartStepEnum;
        refCode?: string | null;
        paymentIntentId?: string | null;
        promoCode?: {
          __typename?: "PromoCode";
          id: string;
          type?: PromoCodeTypeEnum | null;
          code: string;
          status: PromoCodeStatusEnum;
          percentageOff?: number | null;
          fixedAmount?: number | null;
          isArchived: boolean;
          minimumOrderAmountToApply?: number | null;
          schedule?: {
            __typename?: "DiscountSchedule";
            start: any;
            end?: any | null;
          } | null;
        } | null;
        receipt: {
          __typename?: "CartReceipt";
          subtotal: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          automaticDiscount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          discount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          tax: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          shipping: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        };
        shippingDetails?: {
          __typename?: "CartShippingDetails";
          addressLine1?: string | null;
          addressLine2?: string | null;
          id?: string | null;
          secondPhone?: string | null;
          postalCode?: string | null;
          notes?: string | null;
          area?: {
            __typename?: "CartShippingDetailsArea";
            countryId?: string | null;
            stateId?: string | null;
            cityId?: string | null;
            regionId?: string | null;
            country?: { __typename?: "Country"; name: string } | null;
            state?: { __typename?: "State"; name: string } | null;
            city?: { __typename?: "City"; name: string } | null;
            region?: { __typename?: "Region"; name: string } | null;
          } | null;
        } | null;
        shippingDiscount?: {
          __typename?: "Discount";
          id: string;
          title?: string | null;
          appliedOn?: DiscountAppliedOnType | null;
          percentage?: number | null;
          status?: DiscountStatus | null;
          customerBuys?: {
            __typename?: "CustomerBuys";
            value?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          } | null;
          amount?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          BuyXGetYDiscount?: {
            __typename?: "BuyXGetYDiscount";
            customerBuys: {
              __typename?: "CustomerBuys";
              quantity?: number | null;
              itemsType?: DiscountItemsType | null;
              value?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
              } | null;
            };
            customerGets: {
              __typename?: "CustomerGets";
              quantity?: number | null;
              percentage: number;
              itemsType: DiscountItemsType;
              items: {
                __typename?: "CustomerGetsItems";
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
              };
            };
          } | null;
          amountOff?: {
            __typename?: "AmountOff";
            customerBuys: {
              __typename?: "CustomerBuysAmountOff";
              itemsType?: DiscountItemsType | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
              } | null;
            };
            discount: {
              __typename?: "AmountOffDiscounted";
              amount?: number | null;
              percentage?: number | null;
            };
          } | null;
          translations?: Array<{
            __typename?: "DiscountTranslation";
            title?: string | null;
            locale?: string | null;
          } | null> | null;
        } | null;
        contactInfo?: {
          __typename?: "CartContactInfo";
          name?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        customer?: {
          __typename?: "CustomerUser";
          id: string;
          name?: string | null;
          email?: string | null;
          phone?: string | null;
          isGuest?: boolean | null;
          addresses?: Array<{
            __typename?: "CustomerAddress";
            addressLine1: string;
            addressLine2?: string | null;
            id: string;
            phone?: string | null;
            secondPhone?: string | null;
            postalCode?: string | null;
            notes?: string | null;
            isDefault: boolean;
          }> | null;
        } | null;
        cartErrors?: Array<{
          __typename?: "CartError";
          code: CheckoutServiceErrorCodes;
          params?: {
            __typename?: "CartErrorParam";
            resourceId?: string | null;
            requestedQuantity?: number | null;
            availableQuantity?: number | null;
            requestedPrice?: number | null;
            availablePrice?: number | null;
            requestedCurrency?: string | null;
            availableCurrency?: string | null;
            message?: string | null;
          } | null;
        }> | null;
        shippingRate?: {
          __typename?: "CartShippingRate";
          cost: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          details: {
            __typename?: "ShippingRate";
            id: string;
            storeId: string;
            name: string;
            isArchived: boolean;
            locale: string;
            locales: Array<string>;
            translations?: Array<{
              __typename?: "ShippingRateTranslation";
              name?: string | null;
              locale: string;
            }> | null;
          };
        } | null;
        paymentIntent?: {
          __typename?: "PaymentIntent";
          id: string;
          status?: PaymentIntentStatusEnum | null;
          embedUrl?: string | null;
          paymentMethod?: PaymentMethodEnum | null;
          paymentMethods?: Array<PaymentMethodEnum | null> | null;
          key?: string | null;
        } | null;
        items: Array<
          | {
              __typename?: "CartCustomItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                    id: string;
                    locale: string;
                    handle: string;
                    shortDescription?: string | null;
                    productsCount: number;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    seo?: {
                      __typename?: "ProductCollectionSEO";
                      title?: string | null;
                      description?: string | null;
                    } | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                variants?: {
                  __typename?: "ProductVariantConnection";
                  nodes: Array<{
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  }>;
                } | null;
                discount?: {
                  __typename?: "CustomProductDiscount";
                  percent?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                } | null;
                attributes?: Array<{
                  __typename?: "ProductAttribute";
                  id: string;
                  name: string;
                  values: Array<{
                    __typename?: "ProductAttributeValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                reviewsStatistics: {
                  __typename?: "ProductReviewsStatistics";
                  average: number;
                  total: number;
                };
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              discount?: {
                __typename?: "CustomItemDiscountDetails";
                discountSource: DiscountSource;
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
              } | null;
              categories: Array<{
                __typename?: "CartItemCustomBuildCategory";
                category: {
                  __typename?: "CartItemCustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired?: boolean | null;
                  maxSelect?: number | null;
                  maxQuantity?: number | null;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                };
                selectedVariants: Array<{
                  __typename?: "CartItemSelectedVariant";
                  id: string;
                  quantity: number;
                  price: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  };
                  subtotal?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                  variant?: {
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  } | null;
                }>;
              }>;
            }
          | {
              __typename?: "CartSimpleItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              variant?: {
                __typename?: "ProductVariant";
                id: string;
                title?: string | null;
                quantity?: number | null;
                trackQuantity?: boolean | null;
                sku?: string | null;
                cartLimitsEnabled?: boolean | null;
                minPerCart?: number | null;
                maxPerCart?: number | null;
                price: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                compareAtPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                selectedOptions: Array<{
                  __typename?: "SelectedOption";
                  option: {
                    __typename?: "ProductOption";
                    id: string;
                    name: string;
                  };
                  value: {
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
              discount?: {
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              } | null;
              discounts?: Array<{
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              }> | null;
            }
        >;
      } | null;
    } | null;
  } | null;
};

export type AddSimpleItemMutationVariables = Exact<{
  item: SimpleItemInput;
  storeId: Scalars["ID"]["input"];
}>;

export type AddSimpleItemMutation = {
  __typename?: "Mutation";
  customerActions?: {
    __typename?: "CustomerActions";
    cart?: {
      __typename?: "CartActions";
      addSimpleItem?: {
        __typename?: "Cart";
        id: string;
        isReserved?: boolean | null;
        recoveryId: string;
        reservedAt?: any | null;
        createdAt: any;
        updatedAt: any;
        sessionId: string;
        status: CartStatusEnum;
        cartStep: CartStepEnum;
        lastStep: CartStepEnum;
        refCode?: string | null;
        paymentIntentId?: string | null;
        promoCode?: {
          __typename?: "PromoCode";
          id: string;
          type?: PromoCodeTypeEnum | null;
          code: string;
          status: PromoCodeStatusEnum;
          percentageOff?: number | null;
          fixedAmount?: number | null;
          isArchived: boolean;
          minimumOrderAmountToApply?: number | null;
          schedule?: {
            __typename?: "DiscountSchedule";
            start: any;
            end?: any | null;
          } | null;
        } | null;
        receipt: {
          __typename?: "CartReceipt";
          subtotal: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          automaticDiscount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          discount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          tax: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          shipping: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        };
        shippingDetails?: {
          __typename?: "CartShippingDetails";
          addressLine1?: string | null;
          addressLine2?: string | null;
          id?: string | null;
          secondPhone?: string | null;
          postalCode?: string | null;
          notes?: string | null;
          area?: {
            __typename?: "CartShippingDetailsArea";
            countryId?: string | null;
            stateId?: string | null;
            cityId?: string | null;
            regionId?: string | null;
            country?: { __typename?: "Country"; name: string } | null;
            state?: { __typename?: "State"; name: string } | null;
            city?: { __typename?: "City"; name: string } | null;
            region?: { __typename?: "Region"; name: string } | null;
          } | null;
        } | null;
        shippingDiscount?: {
          __typename?: "Discount";
          id: string;
          title?: string | null;
          appliedOn?: DiscountAppliedOnType | null;
          percentage?: number | null;
          status?: DiscountStatus | null;
          customerBuys?: {
            __typename?: "CustomerBuys";
            value?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          } | null;
          amount?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          BuyXGetYDiscount?: {
            __typename?: "BuyXGetYDiscount";
            customerBuys: {
              __typename?: "CustomerBuys";
              quantity?: number | null;
              itemsType?: DiscountItemsType | null;
              value?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
              } | null;
            };
            customerGets: {
              __typename?: "CustomerGets";
              quantity?: number | null;
              percentage: number;
              itemsType: DiscountItemsType;
              items: {
                __typename?: "CustomerGetsItems";
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
              };
            };
          } | null;
          amountOff?: {
            __typename?: "AmountOff";
            customerBuys: {
              __typename?: "CustomerBuysAmountOff";
              itemsType?: DiscountItemsType | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
              } | null;
            };
            discount: {
              __typename?: "AmountOffDiscounted";
              amount?: number | null;
              percentage?: number | null;
            };
          } | null;
          translations?: Array<{
            __typename?: "DiscountTranslation";
            title?: string | null;
            locale?: string | null;
          } | null> | null;
        } | null;
        contactInfo?: {
          __typename?: "CartContactInfo";
          name?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        customer?: {
          __typename?: "CustomerUser";
          id: string;
          name?: string | null;
          email?: string | null;
          phone?: string | null;
          isGuest?: boolean | null;
          addresses?: Array<{
            __typename?: "CustomerAddress";
            addressLine1: string;
            addressLine2?: string | null;
            id: string;
            phone?: string | null;
            secondPhone?: string | null;
            postalCode?: string | null;
            notes?: string | null;
            isDefault: boolean;
          }> | null;
        } | null;
        cartErrors?: Array<{
          __typename?: "CartError";
          code: CheckoutServiceErrorCodes;
          params?: {
            __typename?: "CartErrorParam";
            resourceId?: string | null;
            requestedQuantity?: number | null;
            availableQuantity?: number | null;
            requestedPrice?: number | null;
            availablePrice?: number | null;
            requestedCurrency?: string | null;
            availableCurrency?: string | null;
            message?: string | null;
          } | null;
        }> | null;
        shippingRate?: {
          __typename?: "CartShippingRate";
          cost: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          details: {
            __typename?: "ShippingRate";
            id: string;
            storeId: string;
            name: string;
            isArchived: boolean;
            locale: string;
            locales: Array<string>;
            translations?: Array<{
              __typename?: "ShippingRateTranslation";
              name?: string | null;
              locale: string;
            }> | null;
          };
        } | null;
        paymentIntent?: {
          __typename?: "PaymentIntent";
          id: string;
          status?: PaymentIntentStatusEnum | null;
          embedUrl?: string | null;
          paymentMethod?: PaymentMethodEnum | null;
          paymentMethods?: Array<PaymentMethodEnum | null> | null;
          key?: string | null;
        } | null;
        items: Array<
          | {
              __typename?: "CartCustomItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                    id: string;
                    locale: string;
                    handle: string;
                    shortDescription?: string | null;
                    productsCount: number;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    seo?: {
                      __typename?: "ProductCollectionSEO";
                      title?: string | null;
                      description?: string | null;
                    } | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                variants?: {
                  __typename?: "ProductVariantConnection";
                  nodes: Array<{
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  }>;
                } | null;
                discount?: {
                  __typename?: "CustomProductDiscount";
                  percent?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                } | null;
                attributes?: Array<{
                  __typename?: "ProductAttribute";
                  id: string;
                  name: string;
                  values: Array<{
                    __typename?: "ProductAttributeValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                reviewsStatistics: {
                  __typename?: "ProductReviewsStatistics";
                  average: number;
                  total: number;
                };
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              discount?: {
                __typename?: "CustomItemDiscountDetails";
                discountSource: DiscountSource;
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
              } | null;
              categories: Array<{
                __typename?: "CartItemCustomBuildCategory";
                category: {
                  __typename?: "CartItemCustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired?: boolean | null;
                  maxSelect?: number | null;
                  maxQuantity?: number | null;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                };
                selectedVariants: Array<{
                  __typename?: "CartItemSelectedVariant";
                  id: string;
                  quantity: number;
                  price: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  };
                  subtotal?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                  variant?: {
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  } | null;
                }>;
              }>;
            }
          | {
              __typename?: "CartSimpleItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              variant?: {
                __typename?: "ProductVariant";
                id: string;
                title?: string | null;
                quantity?: number | null;
                trackQuantity?: boolean | null;
                sku?: string | null;
                cartLimitsEnabled?: boolean | null;
                minPerCart?: number | null;
                maxPerCart?: number | null;
                price: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                compareAtPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                selectedOptions: Array<{
                  __typename?: "SelectedOption";
                  option: {
                    __typename?: "ProductOption";
                    id: string;
                    name: string;
                  };
                  value: {
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
              discount?: {
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              } | null;
              discounts?: Array<{
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              }> | null;
            }
        >;
      } | null;
    } | null;
  } | null;
};

export type UpdateContactInfoMutationVariables = Exact<{
  cartId: Scalars["ID"]["input"];
  info: CreateContactInfoInput;
  storeId: Scalars["ID"]["input"];
}>;

export type UpdateContactInfoMutation = {
  __typename?: "Mutation";
  customerActions?: {
    __typename?: "CustomerActions";
    cart?: {
      __typename?: "CartActions";
      updateContactInfo?: {
        __typename?: "Cart";
        id: string;
        isReserved?: boolean | null;
        recoveryId: string;
        reservedAt?: any | null;
        createdAt: any;
        updatedAt: any;
        sessionId: string;
        status: CartStatusEnum;
        cartStep: CartStepEnum;
        lastStep: CartStepEnum;
        refCode?: string | null;
        paymentIntentId?: string | null;
        promoCode?: {
          __typename?: "PromoCode";
          id: string;
          type?: PromoCodeTypeEnum | null;
          code: string;
          status: PromoCodeStatusEnum;
          percentageOff?: number | null;
          fixedAmount?: number | null;
          isArchived: boolean;
          minimumOrderAmountToApply?: number | null;
          schedule?: {
            __typename?: "DiscountSchedule";
            start: any;
            end?: any | null;
          } | null;
        } | null;
        receipt: {
          __typename?: "CartReceipt";
          subtotal: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          automaticDiscount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          discount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          tax: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          shipping: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        };
        shippingDetails?: {
          __typename?: "CartShippingDetails";
          addressLine1?: string | null;
          addressLine2?: string | null;
          id?: string | null;
          secondPhone?: string | null;
          postalCode?: string | null;
          notes?: string | null;
          area?: {
            __typename?: "CartShippingDetailsArea";
            countryId?: string | null;
            stateId?: string | null;
            cityId?: string | null;
            regionId?: string | null;
            country?: { __typename?: "Country"; name: string } | null;
            state?: { __typename?: "State"; name: string } | null;
            city?: { __typename?: "City"; name: string } | null;
            region?: { __typename?: "Region"; name: string } | null;
          } | null;
        } | null;
        shippingDiscount?: {
          __typename?: "Discount";
          id: string;
          title?: string | null;
          appliedOn?: DiscountAppliedOnType | null;
          percentage?: number | null;
          status?: DiscountStatus | null;
          customerBuys?: {
            __typename?: "CustomerBuys";
            value?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          } | null;
          amount?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          BuyXGetYDiscount?: {
            __typename?: "BuyXGetYDiscount";
            customerBuys: {
              __typename?: "CustomerBuys";
              quantity?: number | null;
              itemsType?: DiscountItemsType | null;
              value?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
              } | null;
            };
            customerGets: {
              __typename?: "CustomerGets";
              quantity?: number | null;
              percentage: number;
              itemsType: DiscountItemsType;
              items: {
                __typename?: "CustomerGetsItems";
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
              };
            };
          } | null;
          amountOff?: {
            __typename?: "AmountOff";
            customerBuys: {
              __typename?: "CustomerBuysAmountOff";
              itemsType?: DiscountItemsType | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
              } | null;
            };
            discount: {
              __typename?: "AmountOffDiscounted";
              amount?: number | null;
              percentage?: number | null;
            };
          } | null;
          translations?: Array<{
            __typename?: "DiscountTranslation";
            title?: string | null;
            locale?: string | null;
          } | null> | null;
        } | null;
        contactInfo?: {
          __typename?: "CartContactInfo";
          name?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        customer?: {
          __typename?: "CustomerUser";
          id: string;
          name?: string | null;
          email?: string | null;
          phone?: string | null;
          isGuest?: boolean | null;
          addresses?: Array<{
            __typename?: "CustomerAddress";
            addressLine1: string;
            addressLine2?: string | null;
            id: string;
            phone?: string | null;
            secondPhone?: string | null;
            postalCode?: string | null;
            notes?: string | null;
            isDefault: boolean;
          }> | null;
        } | null;
        cartErrors?: Array<{
          __typename?: "CartError";
          code: CheckoutServiceErrorCodes;
          params?: {
            __typename?: "CartErrorParam";
            resourceId?: string | null;
            requestedQuantity?: number | null;
            availableQuantity?: number | null;
            requestedPrice?: number | null;
            availablePrice?: number | null;
            requestedCurrency?: string | null;
            availableCurrency?: string | null;
            message?: string | null;
          } | null;
        }> | null;
        shippingRate?: {
          __typename?: "CartShippingRate";
          cost: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          details: {
            __typename?: "ShippingRate";
            id: string;
            storeId: string;
            name: string;
            isArchived: boolean;
            locale: string;
            locales: Array<string>;
            translations?: Array<{
              __typename?: "ShippingRateTranslation";
              name?: string | null;
              locale: string;
            }> | null;
          };
        } | null;
        paymentIntent?: {
          __typename?: "PaymentIntent";
          id: string;
          status?: PaymentIntentStatusEnum | null;
          embedUrl?: string | null;
          paymentMethod?: PaymentMethodEnum | null;
          paymentMethods?: Array<PaymentMethodEnum | null> | null;
          key?: string | null;
        } | null;
        items: Array<
          | {
              __typename?: "CartCustomItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                    id: string;
                    locale: string;
                    handle: string;
                    shortDescription?: string | null;
                    productsCount: number;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    seo?: {
                      __typename?: "ProductCollectionSEO";
                      title?: string | null;
                      description?: string | null;
                    } | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                variants?: {
                  __typename?: "ProductVariantConnection";
                  nodes: Array<{
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  }>;
                } | null;
                discount?: {
                  __typename?: "CustomProductDiscount";
                  percent?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                } | null;
                attributes?: Array<{
                  __typename?: "ProductAttribute";
                  id: string;
                  name: string;
                  values: Array<{
                    __typename?: "ProductAttributeValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                reviewsStatistics: {
                  __typename?: "ProductReviewsStatistics";
                  average: number;
                  total: number;
                };
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              discount?: {
                __typename?: "CustomItemDiscountDetails";
                discountSource: DiscountSource;
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
              } | null;
              categories: Array<{
                __typename?: "CartItemCustomBuildCategory";
                category: {
                  __typename?: "CartItemCustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired?: boolean | null;
                  maxSelect?: number | null;
                  maxQuantity?: number | null;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                };
                selectedVariants: Array<{
                  __typename?: "CartItemSelectedVariant";
                  id: string;
                  quantity: number;
                  price: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  };
                  subtotal?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                  variant?: {
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  } | null;
                }>;
              }>;
            }
          | {
              __typename?: "CartSimpleItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              variant?: {
                __typename?: "ProductVariant";
                id: string;
                title?: string | null;
                quantity?: number | null;
                trackQuantity?: boolean | null;
                sku?: string | null;
                cartLimitsEnabled?: boolean | null;
                minPerCart?: number | null;
                maxPerCart?: number | null;
                price: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                compareAtPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                selectedOptions: Array<{
                  __typename?: "SelectedOption";
                  option: {
                    __typename?: "ProductOption";
                    id: string;
                    name: string;
                  };
                  value: {
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
              discount?: {
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              } | null;
              discounts?: Array<{
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              }> | null;
            }
        >;
      } | null;
    } | null;
  } | null;
};

export type UpdateCustomItemMutationVariables = Exact<{
  item: CustomItemInput;
  storeId: Scalars["ID"]["input"];
}>;

export type UpdateCustomItemMutation = {
  __typename?: "Mutation";
  customerActions?: {
    __typename?: "CustomerActions";
    cart?: {
      __typename?: "CartActions";
      updateCustomItem?: {
        __typename?: "Cart";
        id: string;
        isReserved?: boolean | null;
        recoveryId: string;
        reservedAt?: any | null;
        createdAt: any;
        updatedAt: any;
        sessionId: string;
        status: CartStatusEnum;
        cartStep: CartStepEnum;
        lastStep: CartStepEnum;
        refCode?: string | null;
        paymentIntentId?: string | null;
        promoCode?: {
          __typename?: "PromoCode";
          id: string;
          type?: PromoCodeTypeEnum | null;
          code: string;
          status: PromoCodeStatusEnum;
          percentageOff?: number | null;
          fixedAmount?: number | null;
          isArchived: boolean;
          minimumOrderAmountToApply?: number | null;
          schedule?: {
            __typename?: "DiscountSchedule";
            start: any;
            end?: any | null;
          } | null;
        } | null;
        receipt: {
          __typename?: "CartReceipt";
          subtotal: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          automaticDiscount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          discount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          tax: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          shipping: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        };
        shippingDetails?: {
          __typename?: "CartShippingDetails";
          addressLine1?: string | null;
          addressLine2?: string | null;
          id?: string | null;
          secondPhone?: string | null;
          postalCode?: string | null;
          notes?: string | null;
          area?: {
            __typename?: "CartShippingDetailsArea";
            countryId?: string | null;
            stateId?: string | null;
            cityId?: string | null;
            regionId?: string | null;
            country?: { __typename?: "Country"; name: string } | null;
            state?: { __typename?: "State"; name: string } | null;
            city?: { __typename?: "City"; name: string } | null;
            region?: { __typename?: "Region"; name: string } | null;
          } | null;
        } | null;
        shippingDiscount?: {
          __typename?: "Discount";
          id: string;
          title?: string | null;
          appliedOn?: DiscountAppliedOnType | null;
          percentage?: number | null;
          status?: DiscountStatus | null;
          customerBuys?: {
            __typename?: "CustomerBuys";
            value?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          } | null;
          amount?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          BuyXGetYDiscount?: {
            __typename?: "BuyXGetYDiscount";
            customerBuys: {
              __typename?: "CustomerBuys";
              quantity?: number | null;
              itemsType?: DiscountItemsType | null;
              value?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
              } | null;
            };
            customerGets: {
              __typename?: "CustomerGets";
              quantity?: number | null;
              percentage: number;
              itemsType: DiscountItemsType;
              items: {
                __typename?: "CustomerGetsItems";
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
              };
            };
          } | null;
          amountOff?: {
            __typename?: "AmountOff";
            customerBuys: {
              __typename?: "CustomerBuysAmountOff";
              itemsType?: DiscountItemsType | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
              } | null;
            };
            discount: {
              __typename?: "AmountOffDiscounted";
              amount?: number | null;
              percentage?: number | null;
            };
          } | null;
          translations?: Array<{
            __typename?: "DiscountTranslation";
            title?: string | null;
            locale?: string | null;
          } | null> | null;
        } | null;
        contactInfo?: {
          __typename?: "CartContactInfo";
          name?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        customer?: {
          __typename?: "CustomerUser";
          id: string;
          name?: string | null;
          email?: string | null;
          phone?: string | null;
          isGuest?: boolean | null;
          addresses?: Array<{
            __typename?: "CustomerAddress";
            addressLine1: string;
            addressLine2?: string | null;
            id: string;
            phone?: string | null;
            secondPhone?: string | null;
            postalCode?: string | null;
            notes?: string | null;
            isDefault: boolean;
          }> | null;
        } | null;
        cartErrors?: Array<{
          __typename?: "CartError";
          code: CheckoutServiceErrorCodes;
          params?: {
            __typename?: "CartErrorParam";
            resourceId?: string | null;
            requestedQuantity?: number | null;
            availableQuantity?: number | null;
            requestedPrice?: number | null;
            availablePrice?: number | null;
            requestedCurrency?: string | null;
            availableCurrency?: string | null;
            message?: string | null;
          } | null;
        }> | null;
        shippingRate?: {
          __typename?: "CartShippingRate";
          cost: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          details: {
            __typename?: "ShippingRate";
            id: string;
            storeId: string;
            name: string;
            isArchived: boolean;
            locale: string;
            locales: Array<string>;
            translations?: Array<{
              __typename?: "ShippingRateTranslation";
              name?: string | null;
              locale: string;
            }> | null;
          };
        } | null;
        paymentIntent?: {
          __typename?: "PaymentIntent";
          id: string;
          status?: PaymentIntentStatusEnum | null;
          embedUrl?: string | null;
          paymentMethod?: PaymentMethodEnum | null;
          paymentMethods?: Array<PaymentMethodEnum | null> | null;
          key?: string | null;
        } | null;
        items: Array<
          | {
              __typename?: "CartCustomItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                    id: string;
                    locale: string;
                    handle: string;
                    shortDescription?: string | null;
                    productsCount: number;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    seo?: {
                      __typename?: "ProductCollectionSEO";
                      title?: string | null;
                      description?: string | null;
                    } | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                variants?: {
                  __typename?: "ProductVariantConnection";
                  nodes: Array<{
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  }>;
                } | null;
                discount?: {
                  __typename?: "CustomProductDiscount";
                  percent?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                } | null;
                attributes?: Array<{
                  __typename?: "ProductAttribute";
                  id: string;
                  name: string;
                  values: Array<{
                    __typename?: "ProductAttributeValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                reviewsStatistics: {
                  __typename?: "ProductReviewsStatistics";
                  average: number;
                  total: number;
                };
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              discount?: {
                __typename?: "CustomItemDiscountDetails";
                discountSource: DiscountSource;
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
              } | null;
              categories: Array<{
                __typename?: "CartItemCustomBuildCategory";
                category: {
                  __typename?: "CartItemCustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired?: boolean | null;
                  maxSelect?: number | null;
                  maxQuantity?: number | null;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                };
                selectedVariants: Array<{
                  __typename?: "CartItemSelectedVariant";
                  id: string;
                  quantity: number;
                  price: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  };
                  subtotal?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                  variant?: {
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  } | null;
                }>;
              }>;
            }
          | {
              __typename?: "CartSimpleItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              variant?: {
                __typename?: "ProductVariant";
                id: string;
                title?: string | null;
                quantity?: number | null;
                trackQuantity?: boolean | null;
                sku?: string | null;
                cartLimitsEnabled?: boolean | null;
                minPerCart?: number | null;
                maxPerCart?: number | null;
                price: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                compareAtPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                selectedOptions: Array<{
                  __typename?: "SelectedOption";
                  option: {
                    __typename?: "ProductOption";
                    id: string;
                    name: string;
                  };
                  value: {
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
              discount?: {
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              } | null;
              discounts?: Array<{
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              }> | null;
            }
        >;
      } | null;
    } | null;
  } | null;
};

export type UpdateSimpleItemMutationVariables = Exact<{
  item: SimpleItemInput;
  storeId: Scalars["ID"]["input"];
}>;

export type UpdateSimpleItemMutation = {
  __typename?: "Mutation";
  customerActions?: {
    __typename?: "CustomerActions";
    cart?: {
      __typename?: "CartActions";
      updateSimpleItem?: {
        __typename?: "Cart";
        id: string;
        isReserved?: boolean | null;
        recoveryId: string;
        reservedAt?: any | null;
        createdAt: any;
        updatedAt: any;
        sessionId: string;
        status: CartStatusEnum;
        cartStep: CartStepEnum;
        lastStep: CartStepEnum;
        refCode?: string | null;
        paymentIntentId?: string | null;
        promoCode?: {
          __typename?: "PromoCode";
          id: string;
          type?: PromoCodeTypeEnum | null;
          code: string;
          status: PromoCodeStatusEnum;
          percentageOff?: number | null;
          fixedAmount?: number | null;
          isArchived: boolean;
          minimumOrderAmountToApply?: number | null;
          schedule?: {
            __typename?: "DiscountSchedule";
            start: any;
            end?: any | null;
          } | null;
        } | null;
        receipt: {
          __typename?: "CartReceipt";
          subtotal: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          automaticDiscount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          discount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          tax: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          shipping: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        };
        shippingDetails?: {
          __typename?: "CartShippingDetails";
          addressLine1?: string | null;
          addressLine2?: string | null;
          id?: string | null;
          secondPhone?: string | null;
          postalCode?: string | null;
          notes?: string | null;
          area?: {
            __typename?: "CartShippingDetailsArea";
            countryId?: string | null;
            stateId?: string | null;
            cityId?: string | null;
            regionId?: string | null;
            country?: { __typename?: "Country"; name: string } | null;
            state?: { __typename?: "State"; name: string } | null;
            city?: { __typename?: "City"; name: string } | null;
            region?: { __typename?: "Region"; name: string } | null;
          } | null;
        } | null;
        shippingDiscount?: {
          __typename?: "Discount";
          id: string;
          title?: string | null;
          appliedOn?: DiscountAppliedOnType | null;
          percentage?: number | null;
          status?: DiscountStatus | null;
          customerBuys?: {
            __typename?: "CustomerBuys";
            value?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          } | null;
          amount?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          BuyXGetYDiscount?: {
            __typename?: "BuyXGetYDiscount";
            customerBuys: {
              __typename?: "CustomerBuys";
              quantity?: number | null;
              itemsType?: DiscountItemsType | null;
              value?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
              } | null;
            };
            customerGets: {
              __typename?: "CustomerGets";
              quantity?: number | null;
              percentage: number;
              itemsType: DiscountItemsType;
              items: {
                __typename?: "CustomerGetsItems";
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
              };
            };
          } | null;
          amountOff?: {
            __typename?: "AmountOff";
            customerBuys: {
              __typename?: "CustomerBuysAmountOff";
              itemsType?: DiscountItemsType | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
              } | null;
            };
            discount: {
              __typename?: "AmountOffDiscounted";
              amount?: number | null;
              percentage?: number | null;
            };
          } | null;
          translations?: Array<{
            __typename?: "DiscountTranslation";
            title?: string | null;
            locale?: string | null;
          } | null> | null;
        } | null;
        contactInfo?: {
          __typename?: "CartContactInfo";
          name?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        customer?: {
          __typename?: "CustomerUser";
          id: string;
          name?: string | null;
          email?: string | null;
          phone?: string | null;
          isGuest?: boolean | null;
          addresses?: Array<{
            __typename?: "CustomerAddress";
            addressLine1: string;
            addressLine2?: string | null;
            id: string;
            phone?: string | null;
            secondPhone?: string | null;
            postalCode?: string | null;
            notes?: string | null;
            isDefault: boolean;
          }> | null;
        } | null;
        cartErrors?: Array<{
          __typename?: "CartError";
          code: CheckoutServiceErrorCodes;
          params?: {
            __typename?: "CartErrorParam";
            resourceId?: string | null;
            requestedQuantity?: number | null;
            availableQuantity?: number | null;
            requestedPrice?: number | null;
            availablePrice?: number | null;
            requestedCurrency?: string | null;
            availableCurrency?: string | null;
            message?: string | null;
          } | null;
        }> | null;
        shippingRate?: {
          __typename?: "CartShippingRate";
          cost: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          details: {
            __typename?: "ShippingRate";
            id: string;
            storeId: string;
            name: string;
            isArchived: boolean;
            locale: string;
            locales: Array<string>;
            translations?: Array<{
              __typename?: "ShippingRateTranslation";
              name?: string | null;
              locale: string;
            }> | null;
          };
        } | null;
        paymentIntent?: {
          __typename?: "PaymentIntent";
          id: string;
          status?: PaymentIntentStatusEnum | null;
          embedUrl?: string | null;
          paymentMethod?: PaymentMethodEnum | null;
          paymentMethods?: Array<PaymentMethodEnum | null> | null;
          key?: string | null;
        } | null;
        items: Array<
          | {
              __typename?: "CartCustomItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                    id: string;
                    locale: string;
                    handle: string;
                    shortDescription?: string | null;
                    productsCount: number;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    seo?: {
                      __typename?: "ProductCollectionSEO";
                      title?: string | null;
                      description?: string | null;
                    } | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                variants?: {
                  __typename?: "ProductVariantConnection";
                  nodes: Array<{
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  }>;
                } | null;
                discount?: {
                  __typename?: "CustomProductDiscount";
                  percent?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                } | null;
                attributes?: Array<{
                  __typename?: "ProductAttribute";
                  id: string;
                  name: string;
                  values: Array<{
                    __typename?: "ProductAttributeValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                reviewsStatistics: {
                  __typename?: "ProductReviewsStatistics";
                  average: number;
                  total: number;
                };
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              discount?: {
                __typename?: "CustomItemDiscountDetails";
                discountSource: DiscountSource;
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
              } | null;
              categories: Array<{
                __typename?: "CartItemCustomBuildCategory";
                category: {
                  __typename?: "CartItemCustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired?: boolean | null;
                  maxSelect?: number | null;
                  maxQuantity?: number | null;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                };
                selectedVariants: Array<{
                  __typename?: "CartItemSelectedVariant";
                  id: string;
                  quantity: number;
                  price: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  };
                  subtotal?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                  variant?: {
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  } | null;
                }>;
              }>;
            }
          | {
              __typename?: "CartSimpleItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              variant?: {
                __typename?: "ProductVariant";
                id: string;
                title?: string | null;
                quantity?: number | null;
                trackQuantity?: boolean | null;
                sku?: string | null;
                cartLimitsEnabled?: boolean | null;
                minPerCart?: number | null;
                maxPerCart?: number | null;
                price: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                compareAtPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                selectedOptions: Array<{
                  __typename?: "SelectedOption";
                  option: {
                    __typename?: "ProductOption";
                    id: string;
                    name: string;
                  };
                  value: {
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
              discount?: {
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              } | null;
              discounts?: Array<{
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              }> | null;
            }
        >;
      } | null;
    } | null;
  } | null;
};

export type SetCartShippingDetailsMutationVariables = Exact<{
  cartId: Scalars["ID"]["input"];
  shippingDetails: ShippingDetailsInput;
  storeId: Scalars["ID"]["input"];
}>;

export type SetCartShippingDetailsMutation = {
  __typename?: "Mutation";
  customerActions?: {
    __typename?: "CustomerActions";
    cart?: {
      __typename?: "CartActions";
      setCartShippingDetails?: {
        __typename?: "Cart";
        id: string;
        isReserved?: boolean | null;
        recoveryId: string;
        reservedAt?: any | null;
        createdAt: any;
        updatedAt: any;
        sessionId: string;
        status: CartStatusEnum;
        cartStep: CartStepEnum;
        lastStep: CartStepEnum;
        refCode?: string | null;
        paymentIntentId?: string | null;
        promoCode?: {
          __typename?: "PromoCode";
          id: string;
          type?: PromoCodeTypeEnum | null;
          code: string;
          status: PromoCodeStatusEnum;
          percentageOff?: number | null;
          fixedAmount?: number | null;
          isArchived: boolean;
          minimumOrderAmountToApply?: number | null;
          schedule?: {
            __typename?: "DiscountSchedule";
            start: any;
            end?: any | null;
          } | null;
        } | null;
        receipt: {
          __typename?: "CartReceipt";
          subtotal: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          automaticDiscount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          discount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          tax: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          shipping: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        };
        shippingDetails?: {
          __typename?: "CartShippingDetails";
          addressLine1?: string | null;
          addressLine2?: string | null;
          id?: string | null;
          secondPhone?: string | null;
          postalCode?: string | null;
          notes?: string | null;
          area?: {
            __typename?: "CartShippingDetailsArea";
            countryId?: string | null;
            stateId?: string | null;
            cityId?: string | null;
            regionId?: string | null;
            country?: { __typename?: "Country"; name: string } | null;
            state?: { __typename?: "State"; name: string } | null;
            city?: { __typename?: "City"; name: string } | null;
            region?: { __typename?: "Region"; name: string } | null;
          } | null;
        } | null;
        shippingDiscount?: {
          __typename?: "Discount";
          id: string;
          title?: string | null;
          appliedOn?: DiscountAppliedOnType | null;
          percentage?: number | null;
          status?: DiscountStatus | null;
          customerBuys?: {
            __typename?: "CustomerBuys";
            value?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          } | null;
          amount?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          BuyXGetYDiscount?: {
            __typename?: "BuyXGetYDiscount";
            customerBuys: {
              __typename?: "CustomerBuys";
              quantity?: number | null;
              itemsType?: DiscountItemsType | null;
              value?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
              } | null;
            };
            customerGets: {
              __typename?: "CustomerGets";
              quantity?: number | null;
              percentage: number;
              itemsType: DiscountItemsType;
              items: {
                __typename?: "CustomerGetsItems";
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
              };
            };
          } | null;
          amountOff?: {
            __typename?: "AmountOff";
            customerBuys: {
              __typename?: "CustomerBuysAmountOff";
              itemsType?: DiscountItemsType | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
              } | null;
            };
            discount: {
              __typename?: "AmountOffDiscounted";
              amount?: number | null;
              percentage?: number | null;
            };
          } | null;
          translations?: Array<{
            __typename?: "DiscountTranslation";
            title?: string | null;
            locale?: string | null;
          } | null> | null;
        } | null;
        contactInfo?: {
          __typename?: "CartContactInfo";
          name?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        customer?: {
          __typename?: "CustomerUser";
          id: string;
          name?: string | null;
          email?: string | null;
          phone?: string | null;
          isGuest?: boolean | null;
          addresses?: Array<{
            __typename?: "CustomerAddress";
            addressLine1: string;
            addressLine2?: string | null;
            id: string;
            phone?: string | null;
            secondPhone?: string | null;
            postalCode?: string | null;
            notes?: string | null;
            isDefault: boolean;
          }> | null;
        } | null;
        cartErrors?: Array<{
          __typename?: "CartError";
          code: CheckoutServiceErrorCodes;
          params?: {
            __typename?: "CartErrorParam";
            resourceId?: string | null;
            requestedQuantity?: number | null;
            availableQuantity?: number | null;
            requestedPrice?: number | null;
            availablePrice?: number | null;
            requestedCurrency?: string | null;
            availableCurrency?: string | null;
            message?: string | null;
          } | null;
        }> | null;
        shippingRate?: {
          __typename?: "CartShippingRate";
          cost: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          details: {
            __typename?: "ShippingRate";
            id: string;
            storeId: string;
            name: string;
            isArchived: boolean;
            locale: string;
            locales: Array<string>;
            translations?: Array<{
              __typename?: "ShippingRateTranslation";
              name?: string | null;
              locale: string;
            }> | null;
          };
        } | null;
        paymentIntent?: {
          __typename?: "PaymentIntent";
          id: string;
          status?: PaymentIntentStatusEnum | null;
          embedUrl?: string | null;
          paymentMethod?: PaymentMethodEnum | null;
          paymentMethods?: Array<PaymentMethodEnum | null> | null;
          key?: string | null;
        } | null;
        items: Array<
          | {
              __typename?: "CartCustomItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                    id: string;
                    locale: string;
                    handle: string;
                    shortDescription?: string | null;
                    productsCount: number;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    seo?: {
                      __typename?: "ProductCollectionSEO";
                      title?: string | null;
                      description?: string | null;
                    } | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                variants?: {
                  __typename?: "ProductVariantConnection";
                  nodes: Array<{
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  }>;
                } | null;
                discount?: {
                  __typename?: "CustomProductDiscount";
                  percent?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                } | null;
                attributes?: Array<{
                  __typename?: "ProductAttribute";
                  id: string;
                  name: string;
                  values: Array<{
                    __typename?: "ProductAttributeValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                reviewsStatistics: {
                  __typename?: "ProductReviewsStatistics";
                  average: number;
                  total: number;
                };
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              discount?: {
                __typename?: "CustomItemDiscountDetails";
                discountSource: DiscountSource;
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
              } | null;
              categories: Array<{
                __typename?: "CartItemCustomBuildCategory";
                category: {
                  __typename?: "CartItemCustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired?: boolean | null;
                  maxSelect?: number | null;
                  maxQuantity?: number | null;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                };
                selectedVariants: Array<{
                  __typename?: "CartItemSelectedVariant";
                  id: string;
                  quantity: number;
                  price: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  };
                  subtotal?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                  variant?: {
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  } | null;
                }>;
              }>;
            }
          | {
              __typename?: "CartSimpleItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              variant?: {
                __typename?: "ProductVariant";
                id: string;
                title?: string | null;
                quantity?: number | null;
                trackQuantity?: boolean | null;
                sku?: string | null;
                cartLimitsEnabled?: boolean | null;
                minPerCart?: number | null;
                maxPerCart?: number | null;
                price: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                compareAtPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                selectedOptions: Array<{
                  __typename?: "SelectedOption";
                  option: {
                    __typename?: "ProductOption";
                    id: string;
                    name: string;
                  };
                  value: {
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
              discount?: {
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              } | null;
              discounts?: Array<{
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              }> | null;
            }
        >;
      } | null;
    } | null;
  } | null;
};

export type SetCartShippingRateMutationVariables = Exact<{
  cartId: Scalars["ID"]["input"];
  shippingRateId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
  provider?: InputMaybe<WuiltShipmentProvider>;
}>;

export type SetCartShippingRateMutation = {
  __typename?: "Mutation";
  customerActions?: {
    __typename?: "CustomerActions";
    cart?: {
      __typename?: "CartActions";
      setCartShippingRate?: {
        __typename?: "Cart";
        id: string;
        isReserved?: boolean | null;
        recoveryId: string;
        reservedAt?: any | null;
        createdAt: any;
        updatedAt: any;
        sessionId: string;
        status: CartStatusEnum;
        cartStep: CartStepEnum;
        lastStep: CartStepEnum;
        refCode?: string | null;
        paymentIntentId?: string | null;
        promoCode?: {
          __typename?: "PromoCode";
          id: string;
          type?: PromoCodeTypeEnum | null;
          code: string;
          status: PromoCodeStatusEnum;
          percentageOff?: number | null;
          fixedAmount?: number | null;
          isArchived: boolean;
          minimumOrderAmountToApply?: number | null;
          schedule?: {
            __typename?: "DiscountSchedule";
            start: any;
            end?: any | null;
          } | null;
        } | null;
        receipt: {
          __typename?: "CartReceipt";
          subtotal: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          automaticDiscount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          discount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          tax: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          shipping: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        };
        shippingDetails?: {
          __typename?: "CartShippingDetails";
          addressLine1?: string | null;
          addressLine2?: string | null;
          id?: string | null;
          secondPhone?: string | null;
          postalCode?: string | null;
          notes?: string | null;
          area?: {
            __typename?: "CartShippingDetailsArea";
            countryId?: string | null;
            stateId?: string | null;
            cityId?: string | null;
            regionId?: string | null;
            country?: { __typename?: "Country"; name: string } | null;
            state?: { __typename?: "State"; name: string } | null;
            city?: { __typename?: "City"; name: string } | null;
            region?: { __typename?: "Region"; name: string } | null;
          } | null;
        } | null;
        shippingDiscount?: {
          __typename?: "Discount";
          id: string;
          title?: string | null;
          appliedOn?: DiscountAppliedOnType | null;
          percentage?: number | null;
          status?: DiscountStatus | null;
          customerBuys?: {
            __typename?: "CustomerBuys";
            value?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          } | null;
          amount?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          BuyXGetYDiscount?: {
            __typename?: "BuyXGetYDiscount";
            customerBuys: {
              __typename?: "CustomerBuys";
              quantity?: number | null;
              itemsType?: DiscountItemsType | null;
              value?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
              } | null;
            };
            customerGets: {
              __typename?: "CustomerGets";
              quantity?: number | null;
              percentage: number;
              itemsType: DiscountItemsType;
              items: {
                __typename?: "CustomerGetsItems";
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
              };
            };
          } | null;
          amountOff?: {
            __typename?: "AmountOff";
            customerBuys: {
              __typename?: "CustomerBuysAmountOff";
              itemsType?: DiscountItemsType | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
              } | null;
            };
            discount: {
              __typename?: "AmountOffDiscounted";
              amount?: number | null;
              percentage?: number | null;
            };
          } | null;
          translations?: Array<{
            __typename?: "DiscountTranslation";
            title?: string | null;
            locale?: string | null;
          } | null> | null;
        } | null;
        contactInfo?: {
          __typename?: "CartContactInfo";
          name?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        customer?: {
          __typename?: "CustomerUser";
          id: string;
          name?: string | null;
          email?: string | null;
          phone?: string | null;
          isGuest?: boolean | null;
          addresses?: Array<{
            __typename?: "CustomerAddress";
            addressLine1: string;
            addressLine2?: string | null;
            id: string;
            phone?: string | null;
            secondPhone?: string | null;
            postalCode?: string | null;
            notes?: string | null;
            isDefault: boolean;
          }> | null;
        } | null;
        cartErrors?: Array<{
          __typename?: "CartError";
          code: CheckoutServiceErrorCodes;
          params?: {
            __typename?: "CartErrorParam";
            resourceId?: string | null;
            requestedQuantity?: number | null;
            availableQuantity?: number | null;
            requestedPrice?: number | null;
            availablePrice?: number | null;
            requestedCurrency?: string | null;
            availableCurrency?: string | null;
            message?: string | null;
          } | null;
        }> | null;
        shippingRate?: {
          __typename?: "CartShippingRate";
          cost: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          details: {
            __typename?: "ShippingRate";
            id: string;
            storeId: string;
            name: string;
            isArchived: boolean;
            locale: string;
            locales: Array<string>;
            translations?: Array<{
              __typename?: "ShippingRateTranslation";
              name?: string | null;
              locale: string;
            }> | null;
          };
        } | null;
        paymentIntent?: {
          __typename?: "PaymentIntent";
          id: string;
          status?: PaymentIntentStatusEnum | null;
          embedUrl?: string | null;
          paymentMethod?: PaymentMethodEnum | null;
          paymentMethods?: Array<PaymentMethodEnum | null> | null;
          key?: string | null;
        } | null;
        items: Array<
          | {
              __typename?: "CartCustomItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                    id: string;
                    locale: string;
                    handle: string;
                    shortDescription?: string | null;
                    productsCount: number;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    seo?: {
                      __typename?: "ProductCollectionSEO";
                      title?: string | null;
                      description?: string | null;
                    } | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                variants?: {
                  __typename?: "ProductVariantConnection";
                  nodes: Array<{
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  }>;
                } | null;
                discount?: {
                  __typename?: "CustomProductDiscount";
                  percent?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                } | null;
                attributes?: Array<{
                  __typename?: "ProductAttribute";
                  id: string;
                  name: string;
                  values: Array<{
                    __typename?: "ProductAttributeValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                reviewsStatistics: {
                  __typename?: "ProductReviewsStatistics";
                  average: number;
                  total: number;
                };
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              discount?: {
                __typename?: "CustomItemDiscountDetails";
                discountSource: DiscountSource;
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
              } | null;
              categories: Array<{
                __typename?: "CartItemCustomBuildCategory";
                category: {
                  __typename?: "CartItemCustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired?: boolean | null;
                  maxSelect?: number | null;
                  maxQuantity?: number | null;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                };
                selectedVariants: Array<{
                  __typename?: "CartItemSelectedVariant";
                  id: string;
                  quantity: number;
                  price: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  };
                  subtotal?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                  variant?: {
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  } | null;
                }>;
              }>;
            }
          | {
              __typename?: "CartSimpleItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              variant?: {
                __typename?: "ProductVariant";
                id: string;
                title?: string | null;
                quantity?: number | null;
                trackQuantity?: boolean | null;
                sku?: string | null;
                cartLimitsEnabled?: boolean | null;
                minPerCart?: number | null;
                maxPerCart?: number | null;
                price: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                compareAtPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                selectedOptions: Array<{
                  __typename?: "SelectedOption";
                  option: {
                    __typename?: "ProductOption";
                    id: string;
                    name: string;
                  };
                  value: {
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
              discount?: {
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              } | null;
              discounts?: Array<{
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              }> | null;
            }
        >;
      } | null;
    } | null;
  } | null;
};

export type ApplyPromoCodeMutationVariables = Exact<{
  cartId: Scalars["ID"]["input"];
  code: Scalars["String"]["input"];
  storeId: Scalars["ID"]["input"];
}>;

export type ApplyPromoCodeMutation = {
  __typename?: "Mutation";
  customerActions?: {
    __typename?: "CustomerActions";
    cart?: {
      __typename?: "CartActions";
      applyPromoCode?: {
        __typename?: "Cart";
        id: string;
        isReserved?: boolean | null;
        recoveryId: string;
        reservedAt?: any | null;
        createdAt: any;
        updatedAt: any;
        sessionId: string;
        status: CartStatusEnum;
        cartStep: CartStepEnum;
        lastStep: CartStepEnum;
        refCode?: string | null;
        paymentIntentId?: string | null;
        promoCode?: {
          __typename?: "PromoCode";
          id: string;
          type?: PromoCodeTypeEnum | null;
          code: string;
          status: PromoCodeStatusEnum;
          percentageOff?: number | null;
          fixedAmount?: number | null;
          isArchived: boolean;
          minimumOrderAmountToApply?: number | null;
          schedule?: {
            __typename?: "DiscountSchedule";
            start: any;
            end?: any | null;
          } | null;
        } | null;
        receipt: {
          __typename?: "CartReceipt";
          subtotal: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          automaticDiscount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          discount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          tax: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          shipping: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        };
        shippingDetails?: {
          __typename?: "CartShippingDetails";
          addressLine1?: string | null;
          addressLine2?: string | null;
          id?: string | null;
          secondPhone?: string | null;
          postalCode?: string | null;
          notes?: string | null;
          area?: {
            __typename?: "CartShippingDetailsArea";
            countryId?: string | null;
            stateId?: string | null;
            cityId?: string | null;
            regionId?: string | null;
            country?: { __typename?: "Country"; name: string } | null;
            state?: { __typename?: "State"; name: string } | null;
            city?: { __typename?: "City"; name: string } | null;
            region?: { __typename?: "Region"; name: string } | null;
          } | null;
        } | null;
        shippingDiscount?: {
          __typename?: "Discount";
          id: string;
          title?: string | null;
          appliedOn?: DiscountAppliedOnType | null;
          percentage?: number | null;
          status?: DiscountStatus | null;
          customerBuys?: {
            __typename?: "CustomerBuys";
            value?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          } | null;
          amount?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          BuyXGetYDiscount?: {
            __typename?: "BuyXGetYDiscount";
            customerBuys: {
              __typename?: "CustomerBuys";
              quantity?: number | null;
              itemsType?: DiscountItemsType | null;
              value?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
              } | null;
            };
            customerGets: {
              __typename?: "CustomerGets";
              quantity?: number | null;
              percentage: number;
              itemsType: DiscountItemsType;
              items: {
                __typename?: "CustomerGetsItems";
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
              };
            };
          } | null;
          amountOff?: {
            __typename?: "AmountOff";
            customerBuys: {
              __typename?: "CustomerBuysAmountOff";
              itemsType?: DiscountItemsType | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
              } | null;
            };
            discount: {
              __typename?: "AmountOffDiscounted";
              amount?: number | null;
              percentage?: number | null;
            };
          } | null;
          translations?: Array<{
            __typename?: "DiscountTranslation";
            title?: string | null;
            locale?: string | null;
          } | null> | null;
        } | null;
        contactInfo?: {
          __typename?: "CartContactInfo";
          name?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        customer?: {
          __typename?: "CustomerUser";
          id: string;
          name?: string | null;
          email?: string | null;
          phone?: string | null;
          isGuest?: boolean | null;
          addresses?: Array<{
            __typename?: "CustomerAddress";
            addressLine1: string;
            addressLine2?: string | null;
            id: string;
            phone?: string | null;
            secondPhone?: string | null;
            postalCode?: string | null;
            notes?: string | null;
            isDefault: boolean;
          }> | null;
        } | null;
        cartErrors?: Array<{
          __typename?: "CartError";
          code: CheckoutServiceErrorCodes;
          params?: {
            __typename?: "CartErrorParam";
            resourceId?: string | null;
            requestedQuantity?: number | null;
            availableQuantity?: number | null;
            requestedPrice?: number | null;
            availablePrice?: number | null;
            requestedCurrency?: string | null;
            availableCurrency?: string | null;
            message?: string | null;
          } | null;
        }> | null;
        shippingRate?: {
          __typename?: "CartShippingRate";
          cost: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          details: {
            __typename?: "ShippingRate";
            id: string;
            storeId: string;
            name: string;
            isArchived: boolean;
            locale: string;
            locales: Array<string>;
            translations?: Array<{
              __typename?: "ShippingRateTranslation";
              name?: string | null;
              locale: string;
            }> | null;
          };
        } | null;
        paymentIntent?: {
          __typename?: "PaymentIntent";
          id: string;
          status?: PaymentIntentStatusEnum | null;
          embedUrl?: string | null;
          paymentMethod?: PaymentMethodEnum | null;
          paymentMethods?: Array<PaymentMethodEnum | null> | null;
          key?: string | null;
        } | null;
        items: Array<
          | {
              __typename?: "CartCustomItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                    id: string;
                    locale: string;
                    handle: string;
                    shortDescription?: string | null;
                    productsCount: number;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    seo?: {
                      __typename?: "ProductCollectionSEO";
                      title?: string | null;
                      description?: string | null;
                    } | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                variants?: {
                  __typename?: "ProductVariantConnection";
                  nodes: Array<{
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  }>;
                } | null;
                discount?: {
                  __typename?: "CustomProductDiscount";
                  percent?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                } | null;
                attributes?: Array<{
                  __typename?: "ProductAttribute";
                  id: string;
                  name: string;
                  values: Array<{
                    __typename?: "ProductAttributeValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                reviewsStatistics: {
                  __typename?: "ProductReviewsStatistics";
                  average: number;
                  total: number;
                };
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              discount?: {
                __typename?: "CustomItemDiscountDetails";
                discountSource: DiscountSource;
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
              } | null;
              categories: Array<{
                __typename?: "CartItemCustomBuildCategory";
                category: {
                  __typename?: "CartItemCustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired?: boolean | null;
                  maxSelect?: number | null;
                  maxQuantity?: number | null;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                };
                selectedVariants: Array<{
                  __typename?: "CartItemSelectedVariant";
                  id: string;
                  quantity: number;
                  price: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  };
                  subtotal?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                  variant?: {
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  } | null;
                }>;
              }>;
            }
          | {
              __typename?: "CartSimpleItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              variant?: {
                __typename?: "ProductVariant";
                id: string;
                title?: string | null;
                quantity?: number | null;
                trackQuantity?: boolean | null;
                sku?: string | null;
                cartLimitsEnabled?: boolean | null;
                minPerCart?: number | null;
                maxPerCart?: number | null;
                price: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                compareAtPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                selectedOptions: Array<{
                  __typename?: "SelectedOption";
                  option: {
                    __typename?: "ProductOption";
                    id: string;
                    name: string;
                  };
                  value: {
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
              discount?: {
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              } | null;
              discounts?: Array<{
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              }> | null;
            }
        >;
      } | null;
    } | null;
  } | null;
};

export type RemovePromoCodeMutationVariables = Exact<{
  cartId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
}>;

export type RemovePromoCodeMutation = {
  __typename?: "Mutation";
  customerActions?: {
    __typename?: "CustomerActions";
    cart?: {
      __typename?: "CartActions";
      removePromoCode?: {
        __typename?: "Cart";
        id: string;
        isReserved?: boolean | null;
        recoveryId: string;
        reservedAt?: any | null;
        createdAt: any;
        updatedAt: any;
        sessionId: string;
        status: CartStatusEnum;
        cartStep: CartStepEnum;
        lastStep: CartStepEnum;
        refCode?: string | null;
        paymentIntentId?: string | null;
        promoCode?: {
          __typename?: "PromoCode";
          id: string;
          type?: PromoCodeTypeEnum | null;
          code: string;
          status: PromoCodeStatusEnum;
          percentageOff?: number | null;
          fixedAmount?: number | null;
          isArchived: boolean;
          minimumOrderAmountToApply?: number | null;
          schedule?: {
            __typename?: "DiscountSchedule";
            start: any;
            end?: any | null;
          } | null;
        } | null;
        receipt: {
          __typename?: "CartReceipt";
          subtotal: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          automaticDiscount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          discount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          tax: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          shipping: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        };
        shippingDetails?: {
          __typename?: "CartShippingDetails";
          addressLine1?: string | null;
          addressLine2?: string | null;
          id?: string | null;
          secondPhone?: string | null;
          postalCode?: string | null;
          notes?: string | null;
          area?: {
            __typename?: "CartShippingDetailsArea";
            countryId?: string | null;
            stateId?: string | null;
            cityId?: string | null;
            regionId?: string | null;
            country?: { __typename?: "Country"; name: string } | null;
            state?: { __typename?: "State"; name: string } | null;
            city?: { __typename?: "City"; name: string } | null;
            region?: { __typename?: "Region"; name: string } | null;
          } | null;
        } | null;
        shippingDiscount?: {
          __typename?: "Discount";
          id: string;
          title?: string | null;
          appliedOn?: DiscountAppliedOnType | null;
          percentage?: number | null;
          status?: DiscountStatus | null;
          customerBuys?: {
            __typename?: "CustomerBuys";
            value?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          } | null;
          amount?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          BuyXGetYDiscount?: {
            __typename?: "BuyXGetYDiscount";
            customerBuys: {
              __typename?: "CustomerBuys";
              quantity?: number | null;
              itemsType?: DiscountItemsType | null;
              value?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
              } | null;
            };
            customerGets: {
              __typename?: "CustomerGets";
              quantity?: number | null;
              percentage: number;
              itemsType: DiscountItemsType;
              items: {
                __typename?: "CustomerGetsItems";
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
              };
            };
          } | null;
          amountOff?: {
            __typename?: "AmountOff";
            customerBuys: {
              __typename?: "CustomerBuysAmountOff";
              itemsType?: DiscountItemsType | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
              } | null;
            };
            discount: {
              __typename?: "AmountOffDiscounted";
              amount?: number | null;
              percentage?: number | null;
            };
          } | null;
          translations?: Array<{
            __typename?: "DiscountTranslation";
            title?: string | null;
            locale?: string | null;
          } | null> | null;
        } | null;
        contactInfo?: {
          __typename?: "CartContactInfo";
          name?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        customer?: {
          __typename?: "CustomerUser";
          id: string;
          name?: string | null;
          email?: string | null;
          phone?: string | null;
          isGuest?: boolean | null;
          addresses?: Array<{
            __typename?: "CustomerAddress";
            addressLine1: string;
            addressLine2?: string | null;
            id: string;
            phone?: string | null;
            secondPhone?: string | null;
            postalCode?: string | null;
            notes?: string | null;
            isDefault: boolean;
          }> | null;
        } | null;
        cartErrors?: Array<{
          __typename?: "CartError";
          code: CheckoutServiceErrorCodes;
          params?: {
            __typename?: "CartErrorParam";
            resourceId?: string | null;
            requestedQuantity?: number | null;
            availableQuantity?: number | null;
            requestedPrice?: number | null;
            availablePrice?: number | null;
            requestedCurrency?: string | null;
            availableCurrency?: string | null;
            message?: string | null;
          } | null;
        }> | null;
        shippingRate?: {
          __typename?: "CartShippingRate";
          cost: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          details: {
            __typename?: "ShippingRate";
            id: string;
            storeId: string;
            name: string;
            isArchived: boolean;
            locale: string;
            locales: Array<string>;
            translations?: Array<{
              __typename?: "ShippingRateTranslation";
              name?: string | null;
              locale: string;
            }> | null;
          };
        } | null;
        paymentIntent?: {
          __typename?: "PaymentIntent";
          id: string;
          status?: PaymentIntentStatusEnum | null;
          embedUrl?: string | null;
          paymentMethod?: PaymentMethodEnum | null;
          paymentMethods?: Array<PaymentMethodEnum | null> | null;
          key?: string | null;
        } | null;
        items: Array<
          | {
              __typename?: "CartCustomItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                    id: string;
                    locale: string;
                    handle: string;
                    shortDescription?: string | null;
                    productsCount: number;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    seo?: {
                      __typename?: "ProductCollectionSEO";
                      title?: string | null;
                      description?: string | null;
                    } | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                variants?: {
                  __typename?: "ProductVariantConnection";
                  nodes: Array<{
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  }>;
                } | null;
                discount?: {
                  __typename?: "CustomProductDiscount";
                  percent?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                } | null;
                attributes?: Array<{
                  __typename?: "ProductAttribute";
                  id: string;
                  name: string;
                  values: Array<{
                    __typename?: "ProductAttributeValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                reviewsStatistics: {
                  __typename?: "ProductReviewsStatistics";
                  average: number;
                  total: number;
                };
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              discount?: {
                __typename?: "CustomItemDiscountDetails";
                discountSource: DiscountSource;
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
              } | null;
              categories: Array<{
                __typename?: "CartItemCustomBuildCategory";
                category: {
                  __typename?: "CartItemCustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired?: boolean | null;
                  maxSelect?: number | null;
                  maxQuantity?: number | null;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                };
                selectedVariants: Array<{
                  __typename?: "CartItemSelectedVariant";
                  id: string;
                  quantity: number;
                  price: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  };
                  subtotal?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                  variant?: {
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  } | null;
                }>;
              }>;
            }
          | {
              __typename?: "CartSimpleItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              variant?: {
                __typename?: "ProductVariant";
                id: string;
                title?: string | null;
                quantity?: number | null;
                trackQuantity?: boolean | null;
                sku?: string | null;
                cartLimitsEnabled?: boolean | null;
                minPerCart?: number | null;
                maxPerCart?: number | null;
                price: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                compareAtPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                selectedOptions: Array<{
                  __typename?: "SelectedOption";
                  option: {
                    __typename?: "ProductOption";
                    id: string;
                    name: string;
                  };
                  value: {
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
              discount?: {
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              } | null;
              discounts?: Array<{
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              }> | null;
            }
        >;
      } | null;
    } | null;
  } | null;
};

export type ReplaceCartItemsWithSimpleItemMutationVariables = Exact<{
  item: SimpleItemInput;
  storeId: Scalars["ID"]["input"];
}>;

export type ReplaceCartItemsWithSimpleItemMutation = {
  __typename?: "Mutation";
  customerActions?: {
    __typename?: "CustomerActions";
    cart?: {
      __typename?: "CartActions";
      replaceCartItemsWithSimpleItem?: {
        __typename?: "Cart";
        id: string;
        isReserved?: boolean | null;
        recoveryId: string;
        reservedAt?: any | null;
        createdAt: any;
        updatedAt: any;
        sessionId: string;
        status: CartStatusEnum;
        cartStep: CartStepEnum;
        lastStep: CartStepEnum;
        refCode?: string | null;
        paymentIntentId?: string | null;
        promoCode?: {
          __typename?: "PromoCode";
          id: string;
          type?: PromoCodeTypeEnum | null;
          code: string;
          status: PromoCodeStatusEnum;
          percentageOff?: number | null;
          fixedAmount?: number | null;
          isArchived: boolean;
          minimumOrderAmountToApply?: number | null;
          schedule?: {
            __typename?: "DiscountSchedule";
            start: any;
            end?: any | null;
          } | null;
        } | null;
        receipt: {
          __typename?: "CartReceipt";
          subtotal: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          automaticDiscount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          discount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          tax: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          shipping: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        };
        shippingDetails?: {
          __typename?: "CartShippingDetails";
          addressLine1?: string | null;
          addressLine2?: string | null;
          id?: string | null;
          secondPhone?: string | null;
          postalCode?: string | null;
          notes?: string | null;
          area?: {
            __typename?: "CartShippingDetailsArea";
            countryId?: string | null;
            stateId?: string | null;
            cityId?: string | null;
            regionId?: string | null;
            country?: { __typename?: "Country"; name: string } | null;
            state?: { __typename?: "State"; name: string } | null;
            city?: { __typename?: "City"; name: string } | null;
            region?: { __typename?: "Region"; name: string } | null;
          } | null;
        } | null;
        shippingDiscount?: {
          __typename?: "Discount";
          id: string;
          title?: string | null;
          appliedOn?: DiscountAppliedOnType | null;
          percentage?: number | null;
          status?: DiscountStatus | null;
          customerBuys?: {
            __typename?: "CustomerBuys";
            value?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          } | null;
          amount?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          BuyXGetYDiscount?: {
            __typename?: "BuyXGetYDiscount";
            customerBuys: {
              __typename?: "CustomerBuys";
              quantity?: number | null;
              itemsType?: DiscountItemsType | null;
              value?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
              } | null;
            };
            customerGets: {
              __typename?: "CustomerGets";
              quantity?: number | null;
              percentage: number;
              itemsType: DiscountItemsType;
              items: {
                __typename?: "CustomerGetsItems";
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
              };
            };
          } | null;
          amountOff?: {
            __typename?: "AmountOff";
            customerBuys: {
              __typename?: "CustomerBuysAmountOff";
              itemsType?: DiscountItemsType | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
              } | null;
            };
            discount: {
              __typename?: "AmountOffDiscounted";
              amount?: number | null;
              percentage?: number | null;
            };
          } | null;
          translations?: Array<{
            __typename?: "DiscountTranslation";
            title?: string | null;
            locale?: string | null;
          } | null> | null;
        } | null;
        contactInfo?: {
          __typename?: "CartContactInfo";
          name?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        customer?: {
          __typename?: "CustomerUser";
          id: string;
          name?: string | null;
          email?: string | null;
          phone?: string | null;
          isGuest?: boolean | null;
          addresses?: Array<{
            __typename?: "CustomerAddress";
            addressLine1: string;
            addressLine2?: string | null;
            id: string;
            phone?: string | null;
            secondPhone?: string | null;
            postalCode?: string | null;
            notes?: string | null;
            isDefault: boolean;
          }> | null;
        } | null;
        cartErrors?: Array<{
          __typename?: "CartError";
          code: CheckoutServiceErrorCodes;
          params?: {
            __typename?: "CartErrorParam";
            resourceId?: string | null;
            requestedQuantity?: number | null;
            availableQuantity?: number | null;
            requestedPrice?: number | null;
            availablePrice?: number | null;
            requestedCurrency?: string | null;
            availableCurrency?: string | null;
            message?: string | null;
          } | null;
        }> | null;
        shippingRate?: {
          __typename?: "CartShippingRate";
          cost: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          details: {
            __typename?: "ShippingRate";
            id: string;
            storeId: string;
            name: string;
            isArchived: boolean;
            locale: string;
            locales: Array<string>;
            translations?: Array<{
              __typename?: "ShippingRateTranslation";
              name?: string | null;
              locale: string;
            }> | null;
          };
        } | null;
        paymentIntent?: {
          __typename?: "PaymentIntent";
          id: string;
          status?: PaymentIntentStatusEnum | null;
          embedUrl?: string | null;
          paymentMethod?: PaymentMethodEnum | null;
          paymentMethods?: Array<PaymentMethodEnum | null> | null;
          key?: string | null;
        } | null;
        items: Array<
          | {
              __typename?: "CartCustomItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                    id: string;
                    locale: string;
                    handle: string;
                    shortDescription?: string | null;
                    productsCount: number;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    seo?: {
                      __typename?: "ProductCollectionSEO";
                      title?: string | null;
                      description?: string | null;
                    } | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                variants?: {
                  __typename?: "ProductVariantConnection";
                  nodes: Array<{
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  }>;
                } | null;
                discount?: {
                  __typename?: "CustomProductDiscount";
                  percent?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                } | null;
                attributes?: Array<{
                  __typename?: "ProductAttribute";
                  id: string;
                  name: string;
                  values: Array<{
                    __typename?: "ProductAttributeValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                reviewsStatistics: {
                  __typename?: "ProductReviewsStatistics";
                  average: number;
                  total: number;
                };
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              discount?: {
                __typename?: "CustomItemDiscountDetails";
                discountSource: DiscountSource;
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
              } | null;
              categories: Array<{
                __typename?: "CartItemCustomBuildCategory";
                category: {
                  __typename?: "CartItemCustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired?: boolean | null;
                  maxSelect?: number | null;
                  maxQuantity?: number | null;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                };
                selectedVariants: Array<{
                  __typename?: "CartItemSelectedVariant";
                  id: string;
                  quantity: number;
                  price: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  };
                  subtotal?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                  variant?: {
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  } | null;
                }>;
              }>;
            }
          | {
              __typename?: "CartSimpleItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              variant?: {
                __typename?: "ProductVariant";
                id: string;
                title?: string | null;
                quantity?: number | null;
                trackQuantity?: boolean | null;
                sku?: string | null;
                cartLimitsEnabled?: boolean | null;
                minPerCart?: number | null;
                maxPerCart?: number | null;
                price: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                compareAtPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                selectedOptions: Array<{
                  __typename?: "SelectedOption";
                  option: {
                    __typename?: "ProductOption";
                    id: string;
                    name: string;
                  };
                  value: {
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
              discount?: {
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              } | null;
              discounts?: Array<{
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              }> | null;
            }
        >;
      } | null;
    } | null;
  } | null;
};

export type ReplaceCartItemsWithCustomItemMutationVariables = Exact<{
  item: CustomItemInput;
  storeId: Scalars["ID"]["input"];
}>;

export type ReplaceCartItemsWithCustomItemMutation = {
  __typename?: "Mutation";
  customerActions?: {
    __typename?: "CustomerActions";
    cart?: {
      __typename?: "CartActions";
      replaceCartItemsWithCustomItem?: {
        __typename?: "Cart";
        id: string;
        isReserved?: boolean | null;
        recoveryId: string;
        reservedAt?: any | null;
        createdAt: any;
        updatedAt: any;
        sessionId: string;
        status: CartStatusEnum;
        cartStep: CartStepEnum;
        lastStep: CartStepEnum;
        refCode?: string | null;
        paymentIntentId?: string | null;
        promoCode?: {
          __typename?: "PromoCode";
          id: string;
          type?: PromoCodeTypeEnum | null;
          code: string;
          status: PromoCodeStatusEnum;
          percentageOff?: number | null;
          fixedAmount?: number | null;
          isArchived: boolean;
          minimumOrderAmountToApply?: number | null;
          schedule?: {
            __typename?: "DiscountSchedule";
            start: any;
            end?: any | null;
          } | null;
        } | null;
        receipt: {
          __typename?: "CartReceipt";
          subtotal: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          automaticDiscount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          discount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          tax: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          shipping: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        };
        shippingDetails?: {
          __typename?: "CartShippingDetails";
          addressLine1?: string | null;
          addressLine2?: string | null;
          id?: string | null;
          secondPhone?: string | null;
          postalCode?: string | null;
          notes?: string | null;
          area?: {
            __typename?: "CartShippingDetailsArea";
            countryId?: string | null;
            stateId?: string | null;
            cityId?: string | null;
            regionId?: string | null;
            country?: { __typename?: "Country"; name: string } | null;
            state?: { __typename?: "State"; name: string } | null;
            city?: { __typename?: "City"; name: string } | null;
            region?: { __typename?: "Region"; name: string } | null;
          } | null;
        } | null;
        shippingDiscount?: {
          __typename?: "Discount";
          id: string;
          title?: string | null;
          appliedOn?: DiscountAppliedOnType | null;
          percentage?: number | null;
          status?: DiscountStatus | null;
          customerBuys?: {
            __typename?: "CustomerBuys";
            value?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          } | null;
          amount?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          BuyXGetYDiscount?: {
            __typename?: "BuyXGetYDiscount";
            customerBuys: {
              __typename?: "CustomerBuys";
              quantity?: number | null;
              itemsType?: DiscountItemsType | null;
              value?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
              } | null;
            };
            customerGets: {
              __typename?: "CustomerGets";
              quantity?: number | null;
              percentage: number;
              itemsType: DiscountItemsType;
              items: {
                __typename?: "CustomerGetsItems";
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
              };
            };
          } | null;
          amountOff?: {
            __typename?: "AmountOff";
            customerBuys: {
              __typename?: "CustomerBuysAmountOff";
              itemsType?: DiscountItemsType | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
              } | null;
            };
            discount: {
              __typename?: "AmountOffDiscounted";
              amount?: number | null;
              percentage?: number | null;
            };
          } | null;
          translations?: Array<{
            __typename?: "DiscountTranslation";
            title?: string | null;
            locale?: string | null;
          } | null> | null;
        } | null;
        contactInfo?: {
          __typename?: "CartContactInfo";
          name?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        customer?: {
          __typename?: "CustomerUser";
          id: string;
          name?: string | null;
          email?: string | null;
          phone?: string | null;
          isGuest?: boolean | null;
          addresses?: Array<{
            __typename?: "CustomerAddress";
            addressLine1: string;
            addressLine2?: string | null;
            id: string;
            phone?: string | null;
            secondPhone?: string | null;
            postalCode?: string | null;
            notes?: string | null;
            isDefault: boolean;
          }> | null;
        } | null;
        cartErrors?: Array<{
          __typename?: "CartError";
          code: CheckoutServiceErrorCodes;
          params?: {
            __typename?: "CartErrorParam";
            resourceId?: string | null;
            requestedQuantity?: number | null;
            availableQuantity?: number | null;
            requestedPrice?: number | null;
            availablePrice?: number | null;
            requestedCurrency?: string | null;
            availableCurrency?: string | null;
            message?: string | null;
          } | null;
        }> | null;
        shippingRate?: {
          __typename?: "CartShippingRate";
          cost: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          details: {
            __typename?: "ShippingRate";
            id: string;
            storeId: string;
            name: string;
            isArchived: boolean;
            locale: string;
            locales: Array<string>;
            translations?: Array<{
              __typename?: "ShippingRateTranslation";
              name?: string | null;
              locale: string;
            }> | null;
          };
        } | null;
        paymentIntent?: {
          __typename?: "PaymentIntent";
          id: string;
          status?: PaymentIntentStatusEnum | null;
          embedUrl?: string | null;
          paymentMethod?: PaymentMethodEnum | null;
          paymentMethods?: Array<PaymentMethodEnum | null> | null;
          key?: string | null;
        } | null;
        items: Array<
          | {
              __typename?: "CartCustomItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                    id: string;
                    locale: string;
                    handle: string;
                    shortDescription?: string | null;
                    productsCount: number;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    seo?: {
                      __typename?: "ProductCollectionSEO";
                      title?: string | null;
                      description?: string | null;
                    } | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                variants?: {
                  __typename?: "ProductVariantConnection";
                  nodes: Array<{
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  }>;
                } | null;
                discount?: {
                  __typename?: "CustomProductDiscount";
                  percent?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                } | null;
                attributes?: Array<{
                  __typename?: "ProductAttribute";
                  id: string;
                  name: string;
                  values: Array<{
                    __typename?: "ProductAttributeValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                reviewsStatistics: {
                  __typename?: "ProductReviewsStatistics";
                  average: number;
                  total: number;
                };
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              discount?: {
                __typename?: "CustomItemDiscountDetails";
                discountSource: DiscountSource;
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
              } | null;
              categories: Array<{
                __typename?: "CartItemCustomBuildCategory";
                category: {
                  __typename?: "CartItemCustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired?: boolean | null;
                  maxSelect?: number | null;
                  maxQuantity?: number | null;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                };
                selectedVariants: Array<{
                  __typename?: "CartItemSelectedVariant";
                  id: string;
                  quantity: number;
                  price: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  };
                  subtotal?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                  variant?: {
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  } | null;
                }>;
              }>;
            }
          | {
              __typename?: "CartSimpleItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              variant?: {
                __typename?: "ProductVariant";
                id: string;
                title?: string | null;
                quantity?: number | null;
                trackQuantity?: boolean | null;
                sku?: string | null;
                cartLimitsEnabled?: boolean | null;
                minPerCart?: number | null;
                maxPerCart?: number | null;
                price: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                compareAtPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                selectedOptions: Array<{
                  __typename?: "SelectedOption";
                  option: {
                    __typename?: "ProductOption";
                    id: string;
                    name: string;
                  };
                  value: {
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
              discount?: {
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              } | null;
              discounts?: Array<{
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              }> | null;
            }
        >;
      } | null;
    } | null;
  } | null;
};

export type SetCartRefCodeMutationVariables = Exact<{
  cartId: Scalars["ID"]["input"];
  refCode: Scalars["String"]["input"];
  storeId: Scalars["ID"]["input"];
}>;

export type SetCartRefCodeMutation = {
  __typename?: "Mutation";
  customerActions?: {
    __typename?: "CustomerActions";
    cart?: {
      __typename?: "CartActions";
      setCartRefCode?: {
        __typename?: "Cart";
        id: string;
        isReserved?: boolean | null;
        recoveryId: string;
        reservedAt?: any | null;
        createdAt: any;
        updatedAt: any;
        sessionId: string;
        status: CartStatusEnum;
        cartStep: CartStepEnum;
        lastStep: CartStepEnum;
        refCode?: string | null;
        paymentIntentId?: string | null;
        promoCode?: {
          __typename?: "PromoCode";
          id: string;
          type?: PromoCodeTypeEnum | null;
          code: string;
          status: PromoCodeStatusEnum;
          percentageOff?: number | null;
          fixedAmount?: number | null;
          isArchived: boolean;
          minimumOrderAmountToApply?: number | null;
          schedule?: {
            __typename?: "DiscountSchedule";
            start: any;
            end?: any | null;
          } | null;
        } | null;
        receipt: {
          __typename?: "CartReceipt";
          subtotal: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          automaticDiscount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          discount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          tax: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          shipping: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        };
        shippingDetails?: {
          __typename?: "CartShippingDetails";
          addressLine1?: string | null;
          addressLine2?: string | null;
          id?: string | null;
          secondPhone?: string | null;
          postalCode?: string | null;
          notes?: string | null;
          area?: {
            __typename?: "CartShippingDetailsArea";
            countryId?: string | null;
            stateId?: string | null;
            cityId?: string | null;
            regionId?: string | null;
            country?: { __typename?: "Country"; name: string } | null;
            state?: { __typename?: "State"; name: string } | null;
            city?: { __typename?: "City"; name: string } | null;
            region?: { __typename?: "Region"; name: string } | null;
          } | null;
        } | null;
        shippingDiscount?: {
          __typename?: "Discount";
          id: string;
          title?: string | null;
          appliedOn?: DiscountAppliedOnType | null;
          percentage?: number | null;
          status?: DiscountStatus | null;
          customerBuys?: {
            __typename?: "CustomerBuys";
            value?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          } | null;
          amount?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          BuyXGetYDiscount?: {
            __typename?: "BuyXGetYDiscount";
            customerBuys: {
              __typename?: "CustomerBuys";
              quantity?: number | null;
              itemsType?: DiscountItemsType | null;
              value?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
              } | null;
            };
            customerGets: {
              __typename?: "CustomerGets";
              quantity?: number | null;
              percentage: number;
              itemsType: DiscountItemsType;
              items: {
                __typename?: "CustomerGetsItems";
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
              };
            };
          } | null;
          amountOff?: {
            __typename?: "AmountOff";
            customerBuys: {
              __typename?: "CustomerBuysAmountOff";
              itemsType?: DiscountItemsType | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
              } | null;
            };
            discount: {
              __typename?: "AmountOffDiscounted";
              amount?: number | null;
              percentage?: number | null;
            };
          } | null;
          translations?: Array<{
            __typename?: "DiscountTranslation";
            title?: string | null;
            locale?: string | null;
          } | null> | null;
        } | null;
        contactInfo?: {
          __typename?: "CartContactInfo";
          name?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        customer?: {
          __typename?: "CustomerUser";
          id: string;
          name?: string | null;
          email?: string | null;
          phone?: string | null;
          isGuest?: boolean | null;
          addresses?: Array<{
            __typename?: "CustomerAddress";
            addressLine1: string;
            addressLine2?: string | null;
            id: string;
            phone?: string | null;
            secondPhone?: string | null;
            postalCode?: string | null;
            notes?: string | null;
            isDefault: boolean;
          }> | null;
        } | null;
        cartErrors?: Array<{
          __typename?: "CartError";
          code: CheckoutServiceErrorCodes;
          params?: {
            __typename?: "CartErrorParam";
            resourceId?: string | null;
            requestedQuantity?: number | null;
            availableQuantity?: number | null;
            requestedPrice?: number | null;
            availablePrice?: number | null;
            requestedCurrency?: string | null;
            availableCurrency?: string | null;
            message?: string | null;
          } | null;
        }> | null;
        shippingRate?: {
          __typename?: "CartShippingRate";
          cost: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          details: {
            __typename?: "ShippingRate";
            id: string;
            storeId: string;
            name: string;
            isArchived: boolean;
            locale: string;
            locales: Array<string>;
            translations?: Array<{
              __typename?: "ShippingRateTranslation";
              name?: string | null;
              locale: string;
            }> | null;
          };
        } | null;
        paymentIntent?: {
          __typename?: "PaymentIntent";
          id: string;
          status?: PaymentIntentStatusEnum | null;
          embedUrl?: string | null;
          paymentMethod?: PaymentMethodEnum | null;
          paymentMethods?: Array<PaymentMethodEnum | null> | null;
          key?: string | null;
        } | null;
        items: Array<
          | {
              __typename?: "CartCustomItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                    id: string;
                    locale: string;
                    handle: string;
                    shortDescription?: string | null;
                    productsCount: number;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    seo?: {
                      __typename?: "ProductCollectionSEO";
                      title?: string | null;
                      description?: string | null;
                    } | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                variants?: {
                  __typename?: "ProductVariantConnection";
                  nodes: Array<{
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  }>;
                } | null;
                discount?: {
                  __typename?: "CustomProductDiscount";
                  percent?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                } | null;
                attributes?: Array<{
                  __typename?: "ProductAttribute";
                  id: string;
                  name: string;
                  values: Array<{
                    __typename?: "ProductAttributeValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                reviewsStatistics: {
                  __typename?: "ProductReviewsStatistics";
                  average: number;
                  total: number;
                };
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              discount?: {
                __typename?: "CustomItemDiscountDetails";
                discountSource: DiscountSource;
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
              } | null;
              categories: Array<{
                __typename?: "CartItemCustomBuildCategory";
                category: {
                  __typename?: "CartItemCustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired?: boolean | null;
                  maxSelect?: number | null;
                  maxQuantity?: number | null;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                };
                selectedVariants: Array<{
                  __typename?: "CartItemSelectedVariant";
                  id: string;
                  quantity: number;
                  price: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  };
                  subtotal?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                  variant?: {
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  } | null;
                }>;
              }>;
            }
          | {
              __typename?: "CartSimpleItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              variant?: {
                __typename?: "ProductVariant";
                id: string;
                title?: string | null;
                quantity?: number | null;
                trackQuantity?: boolean | null;
                sku?: string | null;
                cartLimitsEnabled?: boolean | null;
                minPerCart?: number | null;
                maxPerCart?: number | null;
                price: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                compareAtPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                selectedOptions: Array<{
                  __typename?: "SelectedOption";
                  option: {
                    __typename?: "ProductOption";
                    id: string;
                    name: string;
                  };
                  value: {
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
              discount?: {
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              } | null;
              discounts?: Array<{
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              }> | null;
            }
        >;
      } | null;
    } | null;
  } | null;
};

export type CheckoutCartMutationVariables = Exact<{
  cartId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
}>;

export type CheckoutCartMutation = {
  __typename?: "Mutation";
  customerActions?: {
    __typename?: "CustomerActions";
    cart?: {
      __typename?: "CartActions";
      checkoutCart?: {
        __typename?: "Cart";
        id: string;
        isReserved?: boolean | null;
        recoveryId: string;
        reservedAt?: any | null;
        createdAt: any;
        updatedAt: any;
        sessionId: string;
        status: CartStatusEnum;
        cartStep: CartStepEnum;
        lastStep: CartStepEnum;
        refCode?: string | null;
        paymentIntentId?: string | null;
        promoCode?: {
          __typename?: "PromoCode";
          id: string;
          type?: PromoCodeTypeEnum | null;
          code: string;
          status: PromoCodeStatusEnum;
          percentageOff?: number | null;
          fixedAmount?: number | null;
          isArchived: boolean;
          minimumOrderAmountToApply?: number | null;
          schedule?: {
            __typename?: "DiscountSchedule";
            start: any;
            end?: any | null;
          } | null;
        } | null;
        receipt: {
          __typename?: "CartReceipt";
          subtotal: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          automaticDiscount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          discount: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          tax: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          shipping: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          total: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        };
        shippingDetails?: {
          __typename?: "CartShippingDetails";
          addressLine1?: string | null;
          addressLine2?: string | null;
          id?: string | null;
          secondPhone?: string | null;
          postalCode?: string | null;
          notes?: string | null;
          area?: {
            __typename?: "CartShippingDetailsArea";
            countryId?: string | null;
            stateId?: string | null;
            cityId?: string | null;
            regionId?: string | null;
            country?: { __typename?: "Country"; name: string } | null;
            state?: { __typename?: "State"; name: string } | null;
            city?: { __typename?: "City"; name: string } | null;
            region?: { __typename?: "Region"; name: string } | null;
          } | null;
        } | null;
        shippingDiscount?: {
          __typename?: "Discount";
          id: string;
          title?: string | null;
          appliedOn?: DiscountAppliedOnType | null;
          percentage?: number | null;
          status?: DiscountStatus | null;
          customerBuys?: {
            __typename?: "CustomerBuys";
            value?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
          } | null;
          amount?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          BuyXGetYDiscount?: {
            __typename?: "BuyXGetYDiscount";
            customerBuys: {
              __typename?: "CustomerBuys";
              quantity?: number | null;
              itemsType?: DiscountItemsType | null;
              value?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
              } | null;
            };
            customerGets: {
              __typename?: "CustomerGets";
              quantity?: number | null;
              percentage: number;
              itemsType: DiscountItemsType;
              items: {
                __typename?: "CustomerGetsItems";
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
              };
            };
          } | null;
          amountOff?: {
            __typename?: "AmountOff";
            customerBuys: {
              __typename?: "CustomerBuysAmountOff";
              itemsType?: DiscountItemsType | null;
              items?: {
                __typename?: "CustomerBuysItems";
                productVariantIds?: Array<string> | null;
                collectionIds?: Array<string> | null;
                collections?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                }> | null;
                productVariants?: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                }> | null;
              } | null;
            };
            discount: {
              __typename?: "AmountOffDiscounted";
              amount?: number | null;
              percentage?: number | null;
            };
          } | null;
          translations?: Array<{
            __typename?: "DiscountTranslation";
            title?: string | null;
            locale?: string | null;
          } | null> | null;
        } | null;
        contactInfo?: {
          __typename?: "CartContactInfo";
          name?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        customer?: {
          __typename?: "CustomerUser";
          id: string;
          name?: string | null;
          email?: string | null;
          phone?: string | null;
          isGuest?: boolean | null;
          addresses?: Array<{
            __typename?: "CustomerAddress";
            addressLine1: string;
            addressLine2?: string | null;
            id: string;
            phone?: string | null;
            secondPhone?: string | null;
            postalCode?: string | null;
            notes?: string | null;
            isDefault: boolean;
          }> | null;
        } | null;
        cartErrors?: Array<{
          __typename?: "CartError";
          code: CheckoutServiceErrorCodes;
          params?: {
            __typename?: "CartErrorParam";
            resourceId?: string | null;
            requestedQuantity?: number | null;
            availableQuantity?: number | null;
            requestedPrice?: number | null;
            availablePrice?: number | null;
            requestedCurrency?: string | null;
            availableCurrency?: string | null;
            message?: string | null;
          } | null;
        }> | null;
        shippingRate?: {
          __typename?: "CartShippingRate";
          cost: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          details: {
            __typename?: "ShippingRate";
            id: string;
            storeId: string;
            name: string;
            isArchived: boolean;
            locale: string;
            locales: Array<string>;
            translations?: Array<{
              __typename?: "ShippingRateTranslation";
              name?: string | null;
              locale: string;
            }> | null;
          };
        } | null;
        paymentIntent?: {
          __typename?: "PaymentIntent";
          id: string;
          status?: PaymentIntentStatusEnum | null;
          embedUrl?: string | null;
          paymentMethod?: PaymentMethodEnum | null;
          paymentMethods?: Array<PaymentMethodEnum | null> | null;
          key?: string | null;
        } | null;
        items: Array<
          | {
              __typename?: "CartCustomItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                    id: string;
                    locale: string;
                    handle: string;
                    shortDescription?: string | null;
                    productsCount: number;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    seo?: {
                      __typename?: "ProductCollectionSEO";
                      title?: string | null;
                      description?: string | null;
                    } | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                variants?: {
                  __typename?: "ProductVariantConnection";
                  nodes: Array<{
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  }>;
                } | null;
                discount?: {
                  __typename?: "CustomProductDiscount";
                  percent?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                } | null;
                attributes?: Array<{
                  __typename?: "ProductAttribute";
                  id: string;
                  name: string;
                  values: Array<{
                    __typename?: "ProductAttributeValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
                reviewsStatistics: {
                  __typename?: "ProductReviewsStatistics";
                  average: number;
                  total: number;
                };
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              discount?: {
                __typename?: "CustomItemDiscountDetails";
                discountSource: DiscountSource;
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
              } | null;
              categories: Array<{
                __typename?: "CartItemCustomBuildCategory";
                category: {
                  __typename?: "CartItemCustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired?: boolean | null;
                  maxSelect?: number | null;
                  maxQuantity?: number | null;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                };
                selectedVariants: Array<{
                  __typename?: "CartItemSelectedVariant";
                  id: string;
                  quantity: number;
                  price: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  };
                  subtotal?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                  variant?: {
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  } | null;
                }>;
              }>;
            }
          | {
              __typename?: "CartSimpleItem";
              id: string;
              quantity: number;
              product?: {
                __typename?: "Product";
                id: string;
                type?: ProductType | null;
                locale: string;
                title: string;
                descriptionHtml?: any | null;
                shortDescription?: string | null;
                handle: string;
                isInStock: boolean;
                taxable?: boolean | null;
                productTax?: number | null;
                status: ProductStatus;
                integrationProvider?: {
                  __typename?: "IntegrationProviderDetails";
                  provider?: string | null;
                } | null;
                collections: {
                  __typename?: "ProductCollectionConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ProductCollection";
                    title?: string | null;
                  } | null> | null;
                };
                initialPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                images: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null>;
                categories?: Array<{
                  __typename?: "CustomProductCategory";
                  id: string;
                  categoryType: CategoryType;
                  name: string;
                  isRequired: boolean;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  variants?: Array<{
                    __typename?: "CustomProductCategoryVariant";
                    preselected?: boolean | null;
                    variant?: {
                      __typename?: "ProductVariant";
                      id: string;
                      title?: string | null;
                      quantity?: number | null;
                      trackQuantity?: boolean | null;
                      sku?: string | null;
                      cartLimitsEnabled?: boolean | null;
                      minPerCart?: number | null;
                      maxPerCart?: number | null;
                      product: {
                        __typename?: "Product";
                        id: string;
                        title: string;
                        status: ProductStatus;
                        taxable?: boolean | null;
                        productTax?: number | null;
                        images: Array<{
                          __typename?: "Image";
                          id: string;
                          altText?: string | null;
                          src?: string | null;
                          width?: number | null;
                          height?: number | null;
                          format?: string | null;
                        } | null>;
                      };
                      price: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      };
                      compareAtPrice?: {
                        __typename?: "Money";
                        amount: number;
                        currencyCode: CurrencyCode;
                      } | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                      selectedOptions: Array<{
                        __typename?: "SelectedOption";
                        option: {
                          __typename?: "ProductOption";
                          id: string;
                          name: string;
                        };
                        value: {
                          __typename?: "ProductOptionValue";
                          id: string;
                          name?: string | null;
                        };
                      }>;
                    } | null;
                  } | null> | null;
                  settings?: {
                    __typename?: "CustomProductCategorySettings";
                    maxSelect?: number | null;
                    maxQuantity?: number | null;
                  } | null;
                } | null> | null;
                options?: Array<{
                  __typename?: "ProductOption";
                  name: string;
                  values: Array<{
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  }>;
                } | null> | null;
              } | null;
              subtotal: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              totalPrice: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              variant?: {
                __typename?: "ProductVariant";
                id: string;
                title?: string | null;
                quantity?: number | null;
                trackQuantity?: boolean | null;
                sku?: string | null;
                cartLimitsEnabled?: boolean | null;
                minPerCart?: number | null;
                maxPerCart?: number | null;
                price: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                compareAtPrice?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                selectedOptions: Array<{
                  __typename?: "SelectedOption";
                  option: {
                    __typename?: "ProductOption";
                    id: string;
                    name: string;
                  };
                  value: {
                    __typename?: "ProductOptionValue";
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
              discount?: {
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              } | null;
              discounts?: Array<{
                __typename?: "OrderItemDiscountDetails";
                discountSource: DiscountSource;
                quantity: number;
                data?: {
                  __typename?: "Discount";
                  id: string;
                  title?: string | null;
                  translations?: Array<{
                    __typename?: "DiscountTranslation";
                    title?: string | null;
                    locale?: string | null;
                  } | null> | null;
                } | null;
                info: {
                  __typename?: "OrderItemDiscountDetailsInfo";
                  percentage?: number | null;
                  fixed?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                };
                perItem: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                total: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
              }> | null;
            }
        >;
      } | null;
    } | null;
  } | null;
};

export type StoreHomepageQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
}>;

export type StoreHomepageQuery = {
  __typename?: "Query";
  store?: {
    __typename?: "Store";
    id: string;
    products: {
      __typename?: "ProductConnection";
      nodes: Array<{
        __typename?: "Product";
        id: string;
        title: string;
        handle: string;
        type?: ProductType | null;
        isInStock: boolean;
        taxable?: boolean | null;
        productTax?: number | null;
        source?: SourceType | null;
        integrationProvider?: {
          __typename?: "IntegrationProviderDetails";
          provider?: string | null;
        } | null;
        discount?: {
          __typename?: "CustomProductDiscount";
          percent?: number | null;
          fixed?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
        } | null;
        initialPrice?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
        images: Array<{
          __typename?: "Image";
          id: string;
          altText?: string | null;
          src?: string | null;
          width?: number | null;
          height?: number | null;
          format?: string | null;
        } | null>;
        variants?: {
          __typename?: "ProductVariantConnection";
          nodes: Array<{
            __typename?: "ProductVariant";
            id: string;
            title?: string | null;
            quantity?: number | null;
            trackQuantity?: boolean | null;
            sku?: string | null;
            cartLimitsEnabled?: boolean | null;
            minPerCart?: number | null;
            maxPerCart?: number | null;
            product: {
              __typename?: "Product";
              id: string;
              title: string;
              status: ProductStatus;
              taxable?: boolean | null;
              productTax?: number | null;
              images: Array<{
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null>;
            };
            price: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
            compareAtPrice?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
            image?: {
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null;
            selectedOptions: Array<{
              __typename?: "SelectedOption";
              option: {
                __typename?: "ProductOption";
                id: string;
                name: string;
              };
              value: {
                __typename?: "ProductOptionValue";
                id: string;
                name?: string | null;
              };
            }>;
          }>;
        } | null;
        collections: {
          __typename?: "ProductCollectionConnection";
          totalCount: number;
          nodes?: Array<{
            __typename?: "ProductCollection";
            id: string;
            title?: string | null;
            handle: string;
          } | null> | null;
        };
        reviewsStatistics: {
          __typename?: "ProductReviewsStatistics";
          average: number;
          total: number;
        };
      }>;
    };
    StoreHomePageSections?: Array<{
      __typename?: "StorePageSection";
      id: string;
      storeId: string;
      header?: string | null;
      displayAs?: DisplayAsEnum | null;
      locale?: Locale | null;
      showSectionHeader?: boolean | null;
      stretchToFullWidth?: boolean | null;
      itemsPerRow?: number | null;
      translations?: Array<{
        __typename?: "SectionTranslations";
        header?: string | null;
        content?: string | null;
        locale?: Locale | null;
      } | null> | null;
      background?: {
        __typename?: "SectionBackground";
        type?: SectionBackgroundTypeEnum | null;
        color?: string | null;
      } | null;
      body:
        | {
            __typename?: "SectionAttributesRow";
            id: string;
            storeId: string;
            type: SectionTypeEnum;
            header: string;
            displayAs: DisplayAsEnum;
            locale: string;
            showSectionHeader: boolean;
            stretchToFullWidth: boolean;
            showItemName: boolean;
            itemsPerRow: number;
            translations: Array<{
              __typename?: "SectionAttributesTranslation";
              header?: string | null;
              locale: string;
            }>;
            background?: {
              __typename?: "SectionBackground";
              type?: SectionBackgroundTypeEnum | null;
              color?: string | null;
            } | null;
            attributeValues: Array<{
              __typename?: "ProductAttributeValue";
              id: string;
              handle?: string | null;
              name?: string | null;
              image?: {
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null;
              attribute?: {
                __typename?: "ProductAttribute";
                id: string;
              } | null;
            }>;
          }
        | {
            __typename?: "SectionBanners";
            type: SectionTypeEnum;
            banners?: Array<{
              __typename?: "Banner";
              id?: string | null;
              mobileImage?: {
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null;
              image?: {
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null;
              bannerLink?: {
                __typename?: "Link";
                type: LinkType;
                resourceId?: string | null;
                url?: string | null;
                resource?:
                  | {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      handle: string;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    }
                  | {
                      __typename?: "ProductCollection";
                      id: string;
                      handle: string;
                      collectionTitle?: string | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                    }
                  | { __typename?: "StoreLegalPage" }
                  | { __typename?: "StorePage"; handle: string }
                  | { __typename?: "StoreStaticPage" }
                  | null;
              } | null;
            }> | null;
          }
        | {
            __typename?: "SectionCollectionsRow";
            id: string;
            storeId: string;
            type: SectionTypeEnum;
            header: string;
            displayAs: DisplayAsEnum;
            locale: string;
            showSectionHeader: boolean;
            stretchToFullWidth: boolean;
            showItemName: boolean;
            itemsPerRow: number;
            translations: Array<{
              __typename?: "StorePageCollectionsTranslation";
              header?: string | null;
              locale: string;
            }>;
            background?: {
              __typename?: "SectionBackground";
              type?: SectionBackgroundTypeEnum | null;
              color?: string | null;
            } | null;
            collections: Array<{
              __typename?: "ProductCollection";
              id: string;
              handle: string;
              title?: string | null;
              productsCount: number;
              shortDescription?: string | null;
              seo?: {
                __typename?: "ProductCollectionSEO";
                description?: string | null;
              } | null;
              image?: {
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null;
            }>;
          }
        | {
            __typename?: "SectionContent";
            type: SectionTypeEnum;
            content: string;
          }
        | {
            __typename?: "SectionProductsRow";
            id: string;
            storeId: string;
            type: SectionTypeEnum;
            header: string;
            displayAs: DisplayAsEnum;
            locale: string;
            showSectionHeader: boolean;
            stretchToFullWidth: boolean;
            itemsPerRow: number;
            productsDisplayNumber: number;
            translations: Array<{
              __typename?: "StorePageProductsTranslation";
              header?: string | null;
              locale: string;
            }>;
            background?: {
              __typename?: "SectionBackground";
              type?: SectionBackgroundTypeEnum | null;
              color?: string | null;
            } | null;
            fromCollection?: {
              __typename?: "ProductCollection";
              id: string;
              handle: string;
            } | null;
            products: Array<{
              __typename?: "Product";
              id: string;
              title: string;
              handle: string;
              type?: ProductType | null;
              isInStock: boolean;
              taxable?: boolean | null;
              productTax?: number | null;
              source?: SourceType | null;
              integrationProvider?: {
                __typename?: "IntegrationProviderDetails";
                provider?: string | null;
              } | null;
              discount?: {
                __typename?: "CustomProductDiscount";
                percent?: number | null;
                fixed?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
              } | null;
              initialPrice?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              images: Array<{
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null>;
              variants?: {
                __typename?: "ProductVariantConnection";
                nodes: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                  title?: string | null;
                  quantity?: number | null;
                  trackQuantity?: boolean | null;
                  sku?: string | null;
                  cartLimitsEnabled?: boolean | null;
                  minPerCart?: number | null;
                  maxPerCart?: number | null;
                  product: {
                    __typename?: "Product";
                    id: string;
                    title: string;
                    status: ProductStatus;
                    taxable?: boolean | null;
                    productTax?: number | null;
                    images: Array<{
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null>;
                  };
                  price: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  };
                  compareAtPrice?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  selectedOptions: Array<{
                    __typename?: "SelectedOption";
                    option: {
                      __typename?: "ProductOption";
                      id: string;
                      name: string;
                    };
                    value: {
                      __typename?: "ProductOptionValue";
                      id: string;
                      name?: string | null;
                    };
                  }>;
                }>;
              } | null;
              collections: {
                __typename?: "ProductCollectionConnection";
                totalCount: number;
                nodes?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                  title?: string | null;
                  handle: string;
                } | null> | null;
              };
              reviewsStatistics: {
                __typename?: "ProductReviewsStatistics";
                average: number;
                total: number;
              };
            } | null>;
          }
        | {
            __typename?: "SectionVideos";
            type: SectionTypeEnum;
            videosUrls?: Array<string> | null;
          };
    } | null> | null;
    appearance?: {
      __typename?: "StoreAppearance";
      heroSlider?: Array<{
        __typename?: "StoreHomeHero";
        id?: string | null;
        align?: string | null;
        headingText?: string | null;
        subHeadingColor?: string | null;
        subHeadingSize?: number | null;
        subHeadingText?: string | null;
        image?: {
          __typename?: "Image";
          id: string;
          altText?: string | null;
          src?: string | null;
          width?: number | null;
          height?: number | null;
          format?: string | null;
        } | null;
        mobileImage?: {
          __typename?: "Image";
          id: string;
          altText?: string | null;
          src?: string | null;
          width?: number | null;
          height?: number | null;
          format?: string | null;
        } | null;
        buttons?: Array<{
          __typename?: "Button";
          text?: string | null;
          link?: {
            __typename?: "Link";
            type: LinkType;
            url?: string | null;
            resourceId?: string | null;
            resource?:
              | { __typename: "Product"; handle: string }
              | { __typename: "ProductCollection"; handle: string }
              | { __typename: "StoreLegalPage" }
              | { __typename: "StorePage"; handle: string }
              | { __typename: "StoreStaticPage" }
              | null;
          } | null;
        } | null> | null;
      } | null> | null;
      infoBar?: {
        __typename?: "InfoBar";
        text: any;
        background: string;
        locales?: Array<string | null> | null;
        locale?: string | null;
        isActive?: boolean | null;
        isDismissible?: boolean | null;
        translations?: Array<{
          __typename?: "InfoBarTranslation";
          text: any;
          locale: string;
        } | null> | null;
      } | null;
      about?: {
        __typename?: "AboutType";
        title?: string | null;
        content?: string | null;
        isActive?: boolean | null;
      } | null;
    } | null;
  } | null;
  storePaymentMethods?: Array<{
    __typename?: "StorePaymentMethod";
    id?: string | null;
    type: StorePaymentMethods;
    enabled: boolean;
  } | null> | null;
};

export type CollectionsQueryVariables = Exact<{
  ids?: InputMaybe<
    | Array<InputMaybe<Scalars["ID"]["input"]>>
    | InputMaybe<Scalars["ID"]["input"]>
  >;
}>;

export type CollectionsQuery = {
  __typename?: "Query";
  collections?: {
    __typename?: "ProductCollectionConnection";
    nodes?: Array<{
      __typename?: "ProductCollection";
      id: string;
      locale: string;
      title?: string | null;
      handle: string;
      shortDescription?: string | null;
      productsCount: number;
      image?: {
        __typename?: "Image";
        id: string;
        altText?: string | null;
        src?: string | null;
        width?: number | null;
        height?: number | null;
        format?: string | null;
      } | null;
      seo?: {
        __typename?: "ProductCollectionSEO";
        title?: string | null;
        description?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ProductDetailsQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  slug?: InputMaybe<Scalars["String"]["input"]>;
  collectionHandle?: Scalars["String"]["input"];
}>;

export type ProductDetailsQuery = {
  __typename?: "Query";
  collection?: {
    __typename?: "ProductCollection";
    id: string;
    locale: string;
    title?: string | null;
    handle: string;
    shortDescription?: string | null;
    productsCount: number;
    image?: {
      __typename?: "Image";
      id: string;
      altText?: string | null;
      src?: string | null;
      width?: number | null;
      height?: number | null;
      format?: string | null;
    } | null;
    seo?: {
      __typename?: "ProductCollectionSEO";
      title?: string | null;
      description?: string | null;
    } | null;
  } | null;
  product?: {
    __typename?: "Product";
    id: string;
    type?: ProductType | null;
    locale: string;
    title: string;
    descriptionHtml?: any | null;
    shortDescription?: string | null;
    handle: string;
    isInStock: boolean;
    taxable?: boolean | null;
    productTax?: number | null;
    status: ProductStatus;
    options?: Array<{
      __typename?: "ProductOption";
      name: string;
      values: Array<{
        __typename?: "ProductOptionValue";
        id: string;
        name?: string | null;
      }>;
    } | null> | null;
    seo?: {
      __typename?: "ProductSEO";
      title?: string | null;
      description?: string | null;
    } | null;
    attributes?: Array<{
      __typename?: "ProductAttribute";
      id: string;
      name: string;
      values: Array<{
        __typename?: "ProductAttributeValue";
        id: string;
        name?: string | null;
      }>;
    } | null> | null;
    moreProducts: {
      __typename?: "MoreProducts";
      isRandom: boolean;
      products: Array<{
        __typename?: "Product";
        id: string;
        title: string;
        handle: string;
        type?: ProductType | null;
        isInStock: boolean;
        taxable?: boolean | null;
        productTax?: number | null;
        source?: SourceType | null;
        integrationProvider?: {
          __typename?: "IntegrationProviderDetails";
          provider?: string | null;
        } | null;
        discount?: {
          __typename?: "CustomProductDiscount";
          percent?: number | null;
          fixed?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
        } | null;
        initialPrice?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
        images: Array<{
          __typename?: "Image";
          id: string;
          altText?: string | null;
          src?: string | null;
          width?: number | null;
          height?: number | null;
          format?: string | null;
        } | null>;
        variants?: {
          __typename?: "ProductVariantConnection";
          nodes: Array<{
            __typename?: "ProductVariant";
            id: string;
            title?: string | null;
            quantity?: number | null;
            trackQuantity?: boolean | null;
            sku?: string | null;
            cartLimitsEnabled?: boolean | null;
            minPerCart?: number | null;
            maxPerCart?: number | null;
            product: {
              __typename?: "Product";
              id: string;
              title: string;
              status: ProductStatus;
              taxable?: boolean | null;
              productTax?: number | null;
              images: Array<{
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null>;
            };
            price: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
            compareAtPrice?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
            image?: {
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null;
            selectedOptions: Array<{
              __typename?: "SelectedOption";
              option: {
                __typename?: "ProductOption";
                id: string;
                name: string;
              };
              value: {
                __typename?: "ProductOptionValue";
                id: string;
                name?: string | null;
              };
            }>;
          }>;
        } | null;
        collections: {
          __typename?: "ProductCollectionConnection";
          totalCount: number;
          nodes?: Array<{
            __typename?: "ProductCollection";
            id: string;
            title?: string | null;
            handle: string;
          } | null> | null;
        };
        reviewsStatistics: {
          __typename?: "ProductReviewsStatistics";
          average: number;
          total: number;
        };
      }>;
    };
    integrationProvider?: {
      __typename?: "IntegrationProviderDetails";
      provider?: string | null;
    } | null;
    collections: {
      __typename?: "ProductCollectionConnection";
      totalCount: number;
      nodes?: Array<{
        __typename?: "ProductCollection";
        id: string;
        locale: string;
        title?: string | null;
        handle: string;
        shortDescription?: string | null;
        productsCount: number;
        image?: {
          __typename?: "Image";
          id: string;
          altText?: string | null;
          src?: string | null;
          width?: number | null;
          height?: number | null;
          format?: string | null;
        } | null;
        seo?: {
          __typename?: "ProductCollectionSEO";
          title?: string | null;
          description?: string | null;
        } | null;
      } | null> | null;
    };
    initialPrice?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
    images: Array<{
      __typename?: "Image";
      id: string;
      altText?: string | null;
      src?: string | null;
      width?: number | null;
      height?: number | null;
      format?: string | null;
    } | null>;
    categories?: Array<{
      __typename?: "CustomProductCategory";
      id: string;
      categoryType: CategoryType;
      name: string;
      isRequired: boolean;
      image?: {
        __typename?: "Image";
        id: string;
        altText?: string | null;
        src?: string | null;
        width?: number | null;
        height?: number | null;
        format?: string | null;
      } | null;
      variants?: Array<{
        __typename?: "CustomProductCategoryVariant";
        preselected?: boolean | null;
        variant?: {
          __typename?: "ProductVariant";
          id: string;
          title?: string | null;
          quantity?: number | null;
          trackQuantity?: boolean | null;
          sku?: string | null;
          cartLimitsEnabled?: boolean | null;
          minPerCart?: number | null;
          maxPerCart?: number | null;
          product: {
            __typename?: "Product";
            id: string;
            title: string;
            status: ProductStatus;
            taxable?: boolean | null;
            productTax?: number | null;
            images: Array<{
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null>;
          };
          price: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          compareAtPrice?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          image?: {
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null;
          selectedOptions: Array<{
            __typename?: "SelectedOption";
            option: { __typename?: "ProductOption"; id: string; name: string };
            value: {
              __typename?: "ProductOptionValue";
              id: string;
              name?: string | null;
            };
          }>;
        } | null;
      } | null> | null;
      settings?: {
        __typename?: "CustomProductCategorySettings";
        maxSelect?: number | null;
        maxQuantity?: number | null;
      } | null;
    } | null> | null;
    variants?: {
      __typename?: "ProductVariantConnection";
      nodes: Array<{
        __typename?: "ProductVariant";
        id: string;
        title?: string | null;
        quantity?: number | null;
        trackQuantity?: boolean | null;
        sku?: string | null;
        cartLimitsEnabled?: boolean | null;
        minPerCart?: number | null;
        maxPerCart?: number | null;
        product: {
          __typename?: "Product";
          id: string;
          title: string;
          status: ProductStatus;
          taxable?: boolean | null;
          productTax?: number | null;
          images: Array<{
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null>;
        };
        price: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        compareAtPrice?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
        image?: {
          __typename?: "Image";
          id: string;
          altText?: string | null;
          src?: string | null;
          width?: number | null;
          height?: number | null;
          format?: string | null;
        } | null;
        selectedOptions: Array<{
          __typename?: "SelectedOption";
          option: { __typename?: "ProductOption"; id: string; name: string };
          value: {
            __typename?: "ProductOptionValue";
            id: string;
            name?: string | null;
          };
        }>;
      }>;
    } | null;
    discount?: {
      __typename?: "CustomProductDiscount";
      percent?: number | null;
      fixed?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
    } | null;
    reviewsStatistics: {
      __typename?: "ProductReviewsStatistics";
      average: number;
      total: number;
    };
  } | null;
};

export type StorePageByHandleQueryVariables = Exact<{
  handle: Scalars["String"]["input"];
  storeId: Scalars["ID"]["input"];
}>;

export type StorePageByHandleQuery = {
  __typename?: "Query";
  storePageByHandle?: {
    __typename?: "StorePage";
    id: string;
    name: string;
    handle: string;
    status: PageStatusEnum;
    locale?: Locale | null;
    pageType: StorePageTypeEnum;
    seo?: {
      __typename?: "PageSeo";
      title?: string | null;
      description?: string | null;
    } | null;
    translations?: Array<{
      __typename?: "StorePageTranslation";
      name?: string | null;
    } | null> | null;
    sections: Array<{
      __typename?: "StorePageSection";
      id: string;
      storeId: string;
      header?: string | null;
      displayAs?: DisplayAsEnum | null;
      locale?: Locale | null;
      showSectionHeader?: boolean | null;
      stretchToFullWidth?: boolean | null;
      itemsPerRow?: number | null;
      translations?: Array<{
        __typename?: "SectionTranslations";
        header?: string | null;
        content?: string | null;
        locale?: Locale | null;
      } | null> | null;
      background?: {
        __typename?: "SectionBackground";
        type?: SectionBackgroundTypeEnum | null;
        color?: string | null;
      } | null;
      body:
        | {
            __typename?: "SectionAttributesRow";
            id: string;
            storeId: string;
            type: SectionTypeEnum;
            header: string;
            displayAs: DisplayAsEnum;
            locale: string;
            showSectionHeader: boolean;
            stretchToFullWidth: boolean;
            showItemName: boolean;
            itemsPerRow: number;
            translations: Array<{
              __typename?: "SectionAttributesTranslation";
              header?: string | null;
              locale: string;
            }>;
            background?: {
              __typename?: "SectionBackground";
              type?: SectionBackgroundTypeEnum | null;
              color?: string | null;
            } | null;
            attributeValues: Array<{
              __typename?: "ProductAttributeValue";
              id: string;
              handle?: string | null;
              name?: string | null;
              image?: {
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null;
              attribute?: {
                __typename?: "ProductAttribute";
                id: string;
              } | null;
            }>;
          }
        | {
            __typename?: "SectionBanners";
            type: SectionTypeEnum;
            banners?: Array<{
              __typename?: "Banner";
              id?: string | null;
              mobileImage?: {
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null;
              image?: {
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null;
              bannerLink?: {
                __typename?: "Link";
                type: LinkType;
                resourceId?: string | null;
                url?: string | null;
                resource?:
                  | {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      handle: string;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    }
                  | {
                      __typename?: "ProductCollection";
                      id: string;
                      handle: string;
                      collectionTitle?: string | null;
                      image?: {
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null;
                    }
                  | { __typename?: "StoreLegalPage" }
                  | { __typename?: "StorePage"; handle: string }
                  | { __typename?: "StoreStaticPage" }
                  | null;
              } | null;
            }> | null;
          }
        | {
            __typename?: "SectionCollectionsRow";
            id: string;
            storeId: string;
            type: SectionTypeEnum;
            header: string;
            displayAs: DisplayAsEnum;
            locale: string;
            showSectionHeader: boolean;
            stretchToFullWidth: boolean;
            showItemName: boolean;
            itemsPerRow: number;
            translations: Array<{
              __typename?: "StorePageCollectionsTranslation";
              header?: string | null;
              locale: string;
            }>;
            background?: {
              __typename?: "SectionBackground";
              type?: SectionBackgroundTypeEnum | null;
              color?: string | null;
            } | null;
            collections: Array<{
              __typename?: "ProductCollection";
              id: string;
              handle: string;
              title?: string | null;
              productsCount: number;
              shortDescription?: string | null;
              seo?: {
                __typename?: "ProductCollectionSEO";
                description?: string | null;
              } | null;
              image?: {
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null;
            }>;
          }
        | {
            __typename?: "SectionContent";
            type: SectionTypeEnum;
            content: string;
          }
        | {
            __typename?: "SectionProductsRow";
            id: string;
            storeId: string;
            type: SectionTypeEnum;
            header: string;
            displayAs: DisplayAsEnum;
            locale: string;
            showSectionHeader: boolean;
            stretchToFullWidth: boolean;
            itemsPerRow: number;
            productsDisplayNumber: number;
            translations: Array<{
              __typename?: "StorePageProductsTranslation";
              header?: string | null;
              locale: string;
            }>;
            background?: {
              __typename?: "SectionBackground";
              type?: SectionBackgroundTypeEnum | null;
              color?: string | null;
            } | null;
            fromCollection?: {
              __typename?: "ProductCollection";
              id: string;
              handle: string;
            } | null;
            products: Array<{
              __typename?: "Product";
              id: string;
              title: string;
              handle: string;
              type?: ProductType | null;
              isInStock: boolean;
              taxable?: boolean | null;
              productTax?: number | null;
              source?: SourceType | null;
              integrationProvider?: {
                __typename?: "IntegrationProviderDetails";
                provider?: string | null;
              } | null;
              discount?: {
                __typename?: "CustomProductDiscount";
                percent?: number | null;
                fixed?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
              } | null;
              initialPrice?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              images: Array<{
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null>;
              variants?: {
                __typename?: "ProductVariantConnection";
                nodes: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                  title?: string | null;
                  quantity?: number | null;
                  trackQuantity?: boolean | null;
                  sku?: string | null;
                  cartLimitsEnabled?: boolean | null;
                  minPerCart?: number | null;
                  maxPerCart?: number | null;
                  product: {
                    __typename?: "Product";
                    id: string;
                    title: string;
                    status: ProductStatus;
                    taxable?: boolean | null;
                    productTax?: number | null;
                    images: Array<{
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null>;
                  };
                  price: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  };
                  compareAtPrice?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  selectedOptions: Array<{
                    __typename?: "SelectedOption";
                    option: {
                      __typename?: "ProductOption";
                      id: string;
                      name: string;
                    };
                    value: {
                      __typename?: "ProductOptionValue";
                      id: string;
                      name?: string | null;
                    };
                  }>;
                }>;
              } | null;
              collections: {
                __typename?: "ProductCollectionConnection";
                totalCount: number;
                nodes?: Array<{
                  __typename?: "ProductCollection";
                  id: string;
                  title?: string | null;
                  handle: string;
                } | null> | null;
              };
              reviewsStatistics: {
                __typename?: "ProductReviewsStatistics";
                average: number;
                total: number;
              };
            } | null>;
          }
        | {
            __typename?: "SectionVideos";
            type: SectionTypeEnum;
            videosUrls?: Array<string> | null;
          };
    } | null>;
  } | null;
};

export type ReviewsQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  productId: Scalars["ID"]["input"];
  connection: ReviewsConnectionInput;
}>;

export type ReviewsQuery = {
  __typename?: "Query";
  product?: {
    __typename?: "Product";
    reviewsStatistics: {
      __typename?: "ProductReviewsStatistics";
      average: number;
      total: number;
      starsCount: {
        __typename?: "ProductStarsCount";
        five: number;
        four: number;
        one: number;
        three: number;
        two: number;
      };
    };
    reviews?: Array<{
      __typename?: "ProductReview";
      id: string;
      content?: string | null;
      createdAt: any;
      customerId: string;
      headline: string;
      orderId?: string | null;
      rating: number;
      showName: boolean;
      customer?: { __typename?: "CustomerUser"; name?: string | null } | null;
      reply?: {
        __typename?: "StoreAdminReply";
        content?: string | null;
      } | null;
    }> | null;
  } | null;
};

export type StoreCollectionsQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  connection?: InputMaybe<ProductCollectionConnectionInput>;
}>;

export type StoreCollectionsQuery = {
  __typename?: "Query";
  collections?: {
    __typename?: "ProductCollectionConnection";
    totalCount: number;
    nodes?: Array<{
      __typename?: "ProductCollection";
      id: string;
      locale: string;
      title?: string | null;
      handle: string;
      shortDescription?: string | null;
      productsCount: number;
      image?: {
        __typename?: "Image";
        id: string;
        altText?: string | null;
        src?: string | null;
        width?: number | null;
        height?: number | null;
        format?: string | null;
      } | null;
      seo?: {
        __typename?: "ProductCollectionSEO";
        title?: string | null;
        description?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type StoreProductsQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  priceFrom?: InputMaybe<Scalars["Float"]["input"]>;
  priceTo?: InputMaybe<Scalars["Float"]["input"]>;
  sortOrder?: InputMaybe<SortOrder>;
  sortBy?: InputMaybe<ProductSortByField>;
}>;

export type StoreProductsQuery = {
  __typename?: "Query";
  products: {
    __typename?: "ProductConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      startCursor?: any | null;
      endCursor?: any | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    nodes: Array<{
      __typename?: "Product";
      id: string;
      title: string;
      handle: string;
      type?: ProductType | null;
      isInStock: boolean;
      taxable?: boolean | null;
      productTax?: number | null;
      source?: SourceType | null;
      integrationProvider?: {
        __typename?: "IntegrationProviderDetails";
        provider?: string | null;
      } | null;
      discount?: {
        __typename?: "CustomProductDiscount";
        percent?: number | null;
        fixed?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
      } | null;
      initialPrice?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
      images: Array<{
        __typename?: "Image";
        id: string;
        altText?: string | null;
        src?: string | null;
        width?: number | null;
        height?: number | null;
        format?: string | null;
      } | null>;
      variants?: {
        __typename?: "ProductVariantConnection";
        nodes: Array<{
          __typename?: "ProductVariant";
          id: string;
          title?: string | null;
          quantity?: number | null;
          trackQuantity?: boolean | null;
          sku?: string | null;
          cartLimitsEnabled?: boolean | null;
          minPerCart?: number | null;
          maxPerCart?: number | null;
          product: {
            __typename?: "Product";
            id: string;
            title: string;
            status: ProductStatus;
            taxable?: boolean | null;
            productTax?: number | null;
            images: Array<{
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null>;
          };
          price: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          compareAtPrice?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          image?: {
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null;
          selectedOptions: Array<{
            __typename?: "SelectedOption";
            option: { __typename?: "ProductOption"; id: string; name: string };
            value: {
              __typename?: "ProductOptionValue";
              id: string;
              name?: string | null;
            };
          }>;
        }>;
      } | null;
      collections: {
        __typename?: "ProductCollectionConnection";
        totalCount: number;
        nodes?: Array<{
          __typename?: "ProductCollection";
          id: string;
          title?: string | null;
          handle: string;
        } | null> | null;
      };
      reviewsStatistics: {
        __typename?: "ProductReviewsStatistics";
        average: number;
        total: number;
      };
    }>;
  };
};

export type SearchProductsQueryVariables = Exact<{
  connection?: InputMaybe<ProductsConnectionInput>;
  filter?: InputMaybe<ProductsFilterInput>;
}>;

export type SearchProductsQuery = {
  __typename?: "Query";
  products: {
    __typename?: "ProductConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Product";
      id: string;
      title: string;
      handle: string;
      type?: ProductType | null;
      initialPrice?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
      images: Array<{
        __typename?: "Image";
        id: string;
        altText?: string | null;
        src?: string | null;
        width?: number | null;
        height?: number | null;
        format?: string | null;
      } | null>;
      collections: {
        __typename?: "ProductCollectionConnection";
        nodes?: Array<{
          __typename?: "ProductCollection";
          id: string;
          handle: string;
        } | null> | null;
      };
      variants?: {
        __typename?: "ProductVariantConnection";
        nodes: Array<{
          __typename?: "ProductVariant";
          id: string;
          price: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
        }>;
      } | null;
    }>;
  };
};

export type SearchAndFiltersQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  connectionSettings?: InputMaybe<ProductsConnectionInput>;
  filters?: InputMaybe<CatalogSearchFilters>;
}>;

export type SearchAndFiltersQuery = {
  __typename?: "Query";
  searchCatalog?: {
    __typename?: "CatalogSearchResults";
    products?: Array<{
      __typename?: "Product";
      id: string;
      title: string;
      handle: string;
      type?: ProductType | null;
      isInStock: boolean;
      taxable?: boolean | null;
      productTax?: number | null;
      source?: SourceType | null;
      integrationProvider?: {
        __typename?: "IntegrationProviderDetails";
        provider?: string | null;
      } | null;
      discount?: {
        __typename?: "CustomProductDiscount";
        percent?: number | null;
        fixed?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
      } | null;
      initialPrice?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
      images: Array<{
        __typename?: "Image";
        id: string;
        altText?: string | null;
        src?: string | null;
        width?: number | null;
        height?: number | null;
        format?: string | null;
      } | null>;
      variants?: {
        __typename?: "ProductVariantConnection";
        nodes: Array<{
          __typename?: "ProductVariant";
          id: string;
          title?: string | null;
          quantity?: number | null;
          trackQuantity?: boolean | null;
          sku?: string | null;
          cartLimitsEnabled?: boolean | null;
          minPerCart?: number | null;
          maxPerCart?: number | null;
          product: {
            __typename?: "Product";
            id: string;
            title: string;
            status: ProductStatus;
            taxable?: boolean | null;
            productTax?: number | null;
            images: Array<{
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null>;
          };
          price: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          compareAtPrice?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          image?: {
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null;
          selectedOptions: Array<{
            __typename?: "SelectedOption";
            option: { __typename?: "ProductOption"; id: string; name: string };
            value: {
              __typename?: "ProductOptionValue";
              id: string;
              name?: string | null;
            };
          }>;
        }>;
      } | null;
      collections: {
        __typename?: "ProductCollectionConnection";
        totalCount: number;
        nodes?: Array<{
          __typename?: "ProductCollection";
          id: string;
          title?: string | null;
          handle: string;
        } | null> | null;
      };
      reviewsStatistics: {
        __typename?: "ProductReviewsStatistics";
        average: number;
        total: number;
      };
    } | null> | null;
    metaData?: {
      __typename?: "CatalogSearchMetaData";
      totalCount?: number | null;
      collectionsProductsDistribution?: Array<{
        __typename?: "CollectionProductsCount";
        collectionId?: string | null;
        count?: number | null;
        collection?: {
          __typename?: "ProductCollection";
          isDeleted?: boolean | null;
          isArchived?: boolean | null;
          isVisible?: boolean | null;
          id: string;
          title?: string | null;
        } | null;
      } | null> | null;
      attributesProductsDistribution?: Array<{
        __typename?: "AttributeProductsCount";
        count?: number | null;
        attributeValueId?: string | null;
        attributeValue?: {
          __typename?: "ProductAttributeValue";
          id: string;
          name?: string | null;
          attribute?: {
            __typename?: "ProductAttribute";
            id: string;
            name: string;
          } | null;
        } | null;
      } | null> | null;
      optionsProductsDistribution?: Array<{
        __typename?: "OptionProductsCount";
        optionValueId?: string | null;
        count?: number | null;
        optionValue?: {
          __typename?: "ProductOptionValue";
          id: string;
          name?: string | null;
          option?: {
            __typename?: "ProductOption";
            id: string;
            name: string;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CheckVariantsStatusQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  variantIds: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
}>;

export type CheckVariantsStatusQuery = {
  __typename?: "Query";
  checkVariantsStatus: Array<{
    __typename?: "ProductVariantStatus";
    id: string;
    status: ProductStatus;
    trackQuantity?: boolean | null;
    quantity?: number | null;
  } | null>;
};

export type GetFiltersQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
}>;

export type GetFiltersQuery = {
  __typename?: "Query";
  getApplicableFilters?: {
    __typename?: "CatalogFilters";
    collections?: Array<{
      __typename?: "ProductCollection";
      title?: string | null;
      id: string;
      isDeleted?: boolean | null;
      isArchived?: boolean | null;
      isVisible?: boolean | null;
    } | null> | null;
    optionValues?: Array<{
      __typename?: "ProductOptionValue";
      name?: string | null;
      id: string;
      option?: {
        __typename?: "ProductOption";
        name: string;
        id: string;
      } | null;
    } | null> | null;
    attributeValues?: Array<{
      __typename?: "ProductAttributeValue";
      name?: string | null;
      id: string;
      attribute?: {
        __typename?: "ProductAttribute";
        name: string;
        id: string;
      } | null;
    } | null> | null;
  } | null;
};

export type FullTextSearchAndFilterQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  connectionSettings?: InputMaybe<ProductsConnectionInput>;
  filters?: InputMaybe<CatalogSearchFilters>;
}>;

export type FullTextSearchAndFilterQuery = {
  __typename?: "Query";
  fullTextSearchAndFilter?: {
    __typename?: "SearchAndFilterResults";
    totalCount?: number | null;
    products?: Array<{
      __typename?: "Product";
      id: string;
      title: string;
      handle: string;
      type?: ProductType | null;
      isInStock: boolean;
      taxable?: boolean | null;
      productTax?: number | null;
      source?: SourceType | null;
      integrationProvider?: {
        __typename?: "IntegrationProviderDetails";
        provider?: string | null;
      } | null;
      discount?: {
        __typename?: "CustomProductDiscount";
        percent?: number | null;
        fixed?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
      } | null;
      initialPrice?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
      images: Array<{
        __typename?: "Image";
        id: string;
        altText?: string | null;
        src?: string | null;
        width?: number | null;
        height?: number | null;
        format?: string | null;
      } | null>;
      variants?: {
        __typename?: "ProductVariantConnection";
        nodes: Array<{
          __typename?: "ProductVariant";
          id: string;
          title?: string | null;
          quantity?: number | null;
          trackQuantity?: boolean | null;
          sku?: string | null;
          cartLimitsEnabled?: boolean | null;
          minPerCart?: number | null;
          maxPerCart?: number | null;
          product: {
            __typename?: "Product";
            id: string;
            title: string;
            status: ProductStatus;
            taxable?: boolean | null;
            productTax?: number | null;
            images: Array<{
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null>;
          };
          price: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          };
          compareAtPrice?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          image?: {
            __typename?: "Image";
            id: string;
            altText?: string | null;
            src?: string | null;
            width?: number | null;
            height?: number | null;
            format?: string | null;
          } | null;
          selectedOptions: Array<{
            __typename?: "SelectedOption";
            option: { __typename?: "ProductOption"; id: string; name: string };
            value: {
              __typename?: "ProductOptionValue";
              id: string;
              name?: string | null;
            };
          }>;
        }>;
      } | null;
      collections: {
        __typename?: "ProductCollectionConnection";
        totalCount: number;
        nodes?: Array<{
          __typename?: "ProductCollection";
          id: string;
          title?: string | null;
          handle: string;
        } | null> | null;
      };
      reviewsStatistics: {
        __typename?: "ProductReviewsStatistics";
        average: number;
        total: number;
      };
    } | null> | null;
  } | null;
};

export type CollectionProductsQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  handle: Scalars["String"]["input"];
  first?: InputMaybe<Scalars["ConnectionLimitInt"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  priceFrom?: InputMaybe<Scalars["Float"]["input"]>;
  priceTo?: InputMaybe<Scalars["Float"]["input"]>;
  sortOrder?: InputMaybe<SortOrder>;
  sortBy?: InputMaybe<CollectionProductsSortBy>;
}>;

export type CollectionProductsQuery = {
  __typename?: "Query";
  collection?: {
    __typename?: "ProductCollection";
    id: string;
    locale: string;
    title?: string | null;
    handle: string;
    shortDescription?: string | null;
    productsCount: number;
    products: {
      __typename?: "ProductConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "Product";
        id: string;
        title: string;
        handle: string;
        type?: ProductType | null;
        isInStock: boolean;
        taxable?: boolean | null;
        productTax?: number | null;
        source?: SourceType | null;
        integrationProvider?: {
          __typename?: "IntegrationProviderDetails";
          provider?: string | null;
        } | null;
        discount?: {
          __typename?: "CustomProductDiscount";
          percent?: number | null;
          fixed?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
        } | null;
        initialPrice?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
        images: Array<{
          __typename?: "Image";
          id: string;
          altText?: string | null;
          src?: string | null;
          width?: number | null;
          height?: number | null;
          format?: string | null;
        } | null>;
        variants?: {
          __typename?: "ProductVariantConnection";
          nodes: Array<{
            __typename?: "ProductVariant";
            id: string;
            title?: string | null;
            quantity?: number | null;
            trackQuantity?: boolean | null;
            sku?: string | null;
            cartLimitsEnabled?: boolean | null;
            minPerCart?: number | null;
            maxPerCart?: number | null;
            product: {
              __typename?: "Product";
              id: string;
              title: string;
              status: ProductStatus;
              taxable?: boolean | null;
              productTax?: number | null;
              images: Array<{
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null>;
            };
            price: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
            compareAtPrice?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
            image?: {
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null;
            selectedOptions: Array<{
              __typename?: "SelectedOption";
              option: {
                __typename?: "ProductOption";
                id: string;
                name: string;
              };
              value: {
                __typename?: "ProductOptionValue";
                id: string;
                name?: string | null;
              };
            }>;
          }>;
        } | null;
        collections: {
          __typename?: "ProductCollectionConnection";
          totalCount: number;
          nodes?: Array<{
            __typename?: "ProductCollection";
            id: string;
            title?: string | null;
            handle: string;
          } | null> | null;
        };
        reviewsStatistics: {
          __typename?: "ProductReviewsStatistics";
          average: number;
          total: number;
        };
      }>;
    };
    image?: {
      __typename?: "Image";
      id: string;
      altText?: string | null;
      src?: string | null;
      width?: number | null;
      height?: number | null;
      format?: string | null;
    } | null;
    seo?: {
      __typename?: "ProductCollectionSEO";
      title?: string | null;
      description?: string | null;
    } | null;
  } | null;
};

export type StoreDetailsByHandleQueryVariables = Exact<{
  storeHandle: Scalars["String"]["input"];
}>;

export type StoreDetailsByHandleQuery = {
  __typename?: "Query";
  store?: {
    __typename?: "Store";
    id: string;
    name: string;
    description?: string | null;
    currency: string;
    defaultLocale: string;
    locales: Array<string>;
    locale: string;
    fallbackLocale: string;
    supportedLocales: Array<string>;
    userId: string;
    isExpired?: boolean | null;
    areReviewsActivated: boolean;
    wuiltShipment?: {
      __typename?: "WuiltShipment";
      isActive: boolean;
      activatedProviders: Array<WuiltShipmentProvider>;
    } | null;
    adminContactInfo?: {
      __typename?: "ContactInfo";
      name?: string | null;
      email?: string | null;
    } | null;
    location?: { __typename?: "Location"; address: string } | null;
    socialMedia?: Array<{
      __typename?: "SocialLinkType";
      name?: string | null;
      link?: string | null;
    } | null> | null;
    seo?: {
      __typename?: "StoreSEO";
      title?: string | null;
      description?: string | null;
    } | null;
    legalPages?: Array<{
      __typename?: "StoreLegalPage";
      id: string;
      name?: string | null;
      title?: string | null;
      handle: string;
    } | null> | null;
    appearance?: {
      __typename?: "StoreAppearance";
      template?: { __typename?: "Template"; handle?: string | null } | null;
      colors?: {
        __typename?: "ThemeColors";
        primary?: string | null;
        inkNormal?: string | null;
        inkSubdued?: string | null;
      } | null;
      fonts?: Array<{
        __typename?: "FontType";
        type?: FontTypeEnum | null;
        fontFamily?: string | null;
        category?: string | null;
        typeFace?: string | null;
        weight?: string | null;
        isItalic?: boolean | null;
        url?: string | null;
        locale?: string | null;
        locales?: Array<string | null> | null;
      } | null> | null;
      footer?: {
        __typename?: "FooterType";
        activeFooter?: ActiveFooter | null;
        startPart?: string | null;
        endPart?: string | null;
        customPart?: string | null;
        locale?: string | null;
        locales?: Array<string | null> | null;
      } | null;
      menus?: Array<{
        __typename?: "StoreMenu";
        id: string;
        title: string;
        handle?: string | null;
        items?: Array<{
          __typename?: "StoreMenuItem";
          id: string;
          parentId?: string | null;
          title: string;
          type: MenuItemType;
          locale?: string | null;
          locales?: Array<string | null> | null;
          handle?: string | null;
          icon?: string | null;
          link?: {
            __typename?: "MenuLink";
            type: MenuItemType;
            url?: string | null;
            resourceId?: string | null;
            openInNewTab?: boolean | null;
            staticPageType?: StaticPageEnum | null;
            resource?:
              | { __typename: "Product"; handle: string }
              | { __typename: "ProductCollection"; handle: string }
              | { __typename: "StoreLegalPage"; handle: string }
              | { __typename: "StorePage"; handle: string }
              | { __typename: "StoreStaticPage" }
              | null;
          } | null;
        } | null> | null;
      } | null> | null;
      infoBar?: {
        __typename?: "InfoBar";
        text: any;
        background: string;
        locales?: Array<string | null> | null;
        locale?: string | null;
        isActive?: boolean | null;
        isDismissible?: boolean | null;
        translations?: Array<{
          __typename?: "InfoBarTranslation";
          text: any;
          locale: string;
        } | null> | null;
      } | null;
      productDisplay?: {
        __typename?: "ProductDisplaySettings";
        productImageZoomBehavior?: ProductImageZoomBehavior | null;
        buyNowButton?: {
          __typename?: "BuyNowButtonSettings";
          display?: BuyNowButtonDisplay | null;
          backgroundColor?: string | null;
          fontColor?: string | null;
          borderColor?: string | null;
        } | null;
      } | null;
    } | null;
    contactInfo?: {
      __typename?: "ContactInfo";
      email?: string | null;
      phone?: string | null;
    } | null;
    maintenanceMode?: {
      __typename?: "MaintenanceMode";
      isEnabled: boolean;
      message?: string | null;
    } | null;
    logo?: {
      __typename?: "StoreLogo";
      size?: LogoSize | null;
      image?: {
        __typename?: "Image";
        id: string;
        altText?: string | null;
        src?: string | null;
        width?: number | null;
        height?: number | null;
        format?: string | null;
      } | null;
    } | null;
    owner?: { __typename?: "User"; createdAt: any } | null;
    subscription?: {
      __typename?: "PlanSubscription";
      createdAt: any;
      status: SubscriptionStatus;
    } | null;
    favIcon?: { __typename?: "Image"; id: string; src?: string | null } | null;
    analyticalAccounts?: Array<{
      __typename?: "AnalyticalAccount";
      name: string;
      id: string;
      isActive: boolean;
    } | null> | null;
    automaticDiscounts?: Array<{
      __typename?: "Discount";
      id: string;
      title?: string | null;
      appliedOn?: DiscountAppliedOnType | null;
      percentage?: number | null;
      status?: DiscountStatus | null;
      customerBuys?: {
        __typename?: "CustomerBuys";
        value?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
      } | null;
      amount?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
      BuyXGetYDiscount?: {
        __typename?: "BuyXGetYDiscount";
        customerBuys: {
          __typename?: "CustomerBuys";
          quantity?: number | null;
          itemsType?: DiscountItemsType | null;
          value?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          items?: {
            __typename?: "CustomerBuysItems";
            productVariantIds?: Array<string> | null;
            collectionIds?: Array<string> | null;
          } | null;
        };
        customerGets: {
          __typename?: "CustomerGets";
          quantity?: number | null;
          percentage: number;
          itemsType: DiscountItemsType;
          items: {
            __typename?: "CustomerGetsItems";
            productVariants?: Array<{
              __typename?: "ProductVariant";
              id: string;
            }> | null;
            collections?: Array<{
              __typename?: "ProductCollection";
              id: string;
            }> | null;
          };
        };
      } | null;
      amountOff?: {
        __typename?: "AmountOff";
        customerBuys: {
          __typename?: "CustomerBuysAmountOff";
          itemsType?: DiscountItemsType | null;
          items?: {
            __typename?: "CustomerBuysItems";
            productVariantIds?: Array<string> | null;
            collectionIds?: Array<string> | null;
            collections?: Array<{
              __typename?: "ProductCollection";
              id: string;
            }> | null;
            productVariants?: Array<{
              __typename?: "ProductVariant";
              id: string;
            }> | null;
          } | null;
        };
        discount: {
          __typename?: "AmountOffDiscounted";
          amount?: number | null;
          percentage?: number | null;
        };
      } | null;
      translations?: Array<{
        __typename?: "DiscountTranslation";
        title?: string | null;
        locale?: string | null;
      } | null> | null;
    } | null> | null;
    storeReviewSettings?: {
      __typename?: "StoreReviewsSettings";
      id: string;
      enableGuestReviews: boolean;
      showCustomerAvatar: boolean;
      showOverAllRating: boolean;
      showReviewDate: boolean;
      showReviewsCount: boolean;
      showStars: boolean;
      starsColor: string;
    } | null;
    redirects?: Array<{
      __typename?: "StoreRedirect";
      id: string;
      newUrl: string;
      oldUrl: string;
    }> | null;
    customCheckoutSetting?: {
      __typename?: "CustomCheckoutSetting";
      id: string;
      identifier: CustomerIdentifier;
      name: NameInputOptions;
      secondaryPhone: CheckoutFieldOptions;
      postalCode: CheckoutFieldOptions;
      notesToSeller: CheckoutFieldOptions;
      checkoutNote: {
        __typename?: "CheckoutNote";
        placement: CheckoutNotePlacement;
        enabled: boolean;
        content: string;
      };
    } | null;
    domain?: { __typename?: "Domain"; domainName: string } | null;
    integrationScriptControls?: Array<
      | {
          __typename?: "GameballScriptControls";
          provider: ProviderName;
          apiKey: string;
        }
      | {
          __typename?: "KlaviyoScriptControls";
          provider: ProviderName;
          publicKey: string;
        }
      | {
          __typename?: "MailchimpScriptControls";
          provider: ProviderName;
          apiKey: string;
        }
      | { __typename?: "NoScriptControls"; provider: ProviderName }
      | {
          __typename?: "OptimonkScriptControls";
          provider: ProviderName;
          accountId: string;
        }
    > | null;
  } | null;
};

export type StoreDetailsByDomainQueryVariables = Exact<{
  storeDomain: Scalars["String"]["input"];
}>;

export type StoreDetailsByDomainQuery = {
  __typename?: "Query";
  store?: {
    __typename?: "Store";
    id: string;
    name: string;
    description?: string | null;
    currency: string;
    defaultLocale: string;
    locales: Array<string>;
    locale: string;
    fallbackLocale: string;
    supportedLocales: Array<string>;
    userId: string;
    isExpired?: boolean | null;
    areReviewsActivated: boolean;
    wuiltShipment?: {
      __typename?: "WuiltShipment";
      isActive: boolean;
      activatedProviders: Array<WuiltShipmentProvider>;
    } | null;
    adminContactInfo?: {
      __typename?: "ContactInfo";
      name?: string | null;
      email?: string | null;
    } | null;
    location?: { __typename?: "Location"; address: string } | null;
    socialMedia?: Array<{
      __typename?: "SocialLinkType";
      name?: string | null;
      link?: string | null;
    } | null> | null;
    seo?: {
      __typename?: "StoreSEO";
      title?: string | null;
      description?: string | null;
    } | null;
    legalPages?: Array<{
      __typename?: "StoreLegalPage";
      id: string;
      name?: string | null;
      title?: string | null;
      handle: string;
    } | null> | null;
    appearance?: {
      __typename?: "StoreAppearance";
      template?: { __typename?: "Template"; handle?: string | null } | null;
      colors?: {
        __typename?: "ThemeColors";
        primary?: string | null;
        inkNormal?: string | null;
        inkSubdued?: string | null;
      } | null;
      fonts?: Array<{
        __typename?: "FontType";
        type?: FontTypeEnum | null;
        fontFamily?: string | null;
        category?: string | null;
        typeFace?: string | null;
        weight?: string | null;
        isItalic?: boolean | null;
        url?: string | null;
        locale?: string | null;
        locales?: Array<string | null> | null;
      } | null> | null;
      footer?: {
        __typename?: "FooterType";
        activeFooter?: ActiveFooter | null;
        startPart?: string | null;
        endPart?: string | null;
        customPart?: string | null;
        locale?: string | null;
        locales?: Array<string | null> | null;
      } | null;
      menus?: Array<{
        __typename?: "StoreMenu";
        id: string;
        title: string;
        handle?: string | null;
        items?: Array<{
          __typename?: "StoreMenuItem";
          id: string;
          parentId?: string | null;
          title: string;
          type: MenuItemType;
          locale?: string | null;
          locales?: Array<string | null> | null;
          handle?: string | null;
          icon?: string | null;
          link?: {
            __typename?: "MenuLink";
            type: MenuItemType;
            url?: string | null;
            resourceId?: string | null;
            openInNewTab?: boolean | null;
            staticPageType?: StaticPageEnum | null;
            resource?:
              | { __typename: "Product"; handle: string }
              | { __typename: "ProductCollection"; handle: string }
              | { __typename: "StoreLegalPage"; handle: string }
              | { __typename: "StorePage"; handle: string }
              | { __typename: "StoreStaticPage" }
              | null;
          } | null;
        } | null> | null;
      } | null> | null;
      infoBar?: {
        __typename?: "InfoBar";
        text: any;
        background: string;
        locales?: Array<string | null> | null;
        locale?: string | null;
        isActive?: boolean | null;
        isDismissible?: boolean | null;
        translations?: Array<{
          __typename?: "InfoBarTranslation";
          text: any;
          locale: string;
        } | null> | null;
      } | null;
      productDisplay?: {
        __typename?: "ProductDisplaySettings";
        productImageZoomBehavior?: ProductImageZoomBehavior | null;
        buyNowButton?: {
          __typename?: "BuyNowButtonSettings";
          display?: BuyNowButtonDisplay | null;
          backgroundColor?: string | null;
          fontColor?: string | null;
          borderColor?: string | null;
        } | null;
      } | null;
    } | null;
    contactInfo?: {
      __typename?: "ContactInfo";
      email?: string | null;
      phone?: string | null;
    } | null;
    maintenanceMode?: {
      __typename?: "MaintenanceMode";
      isEnabled: boolean;
      message?: string | null;
    } | null;
    logo?: {
      __typename?: "StoreLogo";
      size?: LogoSize | null;
      image?: {
        __typename?: "Image";
        id: string;
        altText?: string | null;
        src?: string | null;
        width?: number | null;
        height?: number | null;
        format?: string | null;
      } | null;
    } | null;
    owner?: { __typename?: "User"; createdAt: any } | null;
    subscription?: {
      __typename?: "PlanSubscription";
      createdAt: any;
      status: SubscriptionStatus;
    } | null;
    favIcon?: { __typename?: "Image"; id: string; src?: string | null } | null;
    analyticalAccounts?: Array<{
      __typename?: "AnalyticalAccount";
      name: string;
      id: string;
      isActive: boolean;
    } | null> | null;
    automaticDiscounts?: Array<{
      __typename?: "Discount";
      id: string;
      title?: string | null;
      appliedOn?: DiscountAppliedOnType | null;
      percentage?: number | null;
      status?: DiscountStatus | null;
      customerBuys?: {
        __typename?: "CustomerBuys";
        value?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
      } | null;
      amount?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
      BuyXGetYDiscount?: {
        __typename?: "BuyXGetYDiscount";
        customerBuys: {
          __typename?: "CustomerBuys";
          quantity?: number | null;
          itemsType?: DiscountItemsType | null;
          value?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          items?: {
            __typename?: "CustomerBuysItems";
            productVariantIds?: Array<string> | null;
            collectionIds?: Array<string> | null;
          } | null;
        };
        customerGets: {
          __typename?: "CustomerGets";
          quantity?: number | null;
          percentage: number;
          itemsType: DiscountItemsType;
          items: {
            __typename?: "CustomerGetsItems";
            productVariants?: Array<{
              __typename?: "ProductVariant";
              id: string;
            }> | null;
            collections?: Array<{
              __typename?: "ProductCollection";
              id: string;
            }> | null;
          };
        };
      } | null;
      amountOff?: {
        __typename?: "AmountOff";
        customerBuys: {
          __typename?: "CustomerBuysAmountOff";
          itemsType?: DiscountItemsType | null;
          items?: {
            __typename?: "CustomerBuysItems";
            productVariantIds?: Array<string> | null;
            collectionIds?: Array<string> | null;
            collections?: Array<{
              __typename?: "ProductCollection";
              id: string;
            }> | null;
            productVariants?: Array<{
              __typename?: "ProductVariant";
              id: string;
            }> | null;
          } | null;
        };
        discount: {
          __typename?: "AmountOffDiscounted";
          amount?: number | null;
          percentage?: number | null;
        };
      } | null;
      translations?: Array<{
        __typename?: "DiscountTranslation";
        title?: string | null;
        locale?: string | null;
      } | null> | null;
    } | null> | null;
    storeReviewSettings?: {
      __typename?: "StoreReviewsSettings";
      id: string;
      enableGuestReviews: boolean;
      showCustomerAvatar: boolean;
      showOverAllRating: boolean;
      showReviewDate: boolean;
      showReviewsCount: boolean;
      showStars: boolean;
      starsColor: string;
    } | null;
    redirects?: Array<{
      __typename?: "StoreRedirect";
      id: string;
      newUrl: string;
      oldUrl: string;
    }> | null;
    customCheckoutSetting?: {
      __typename?: "CustomCheckoutSetting";
      id: string;
      identifier: CustomerIdentifier;
      name: NameInputOptions;
      secondaryPhone: CheckoutFieldOptions;
      postalCode: CheckoutFieldOptions;
      notesToSeller: CheckoutFieldOptions;
      checkoutNote: {
        __typename?: "CheckoutNote";
        placement: CheckoutNotePlacement;
        enabled: boolean;
        content: string;
      };
    } | null;
    domain?: { __typename?: "Domain"; domainName: string } | null;
    integrationScriptControls?: Array<
      | {
          __typename?: "GameballScriptControls";
          provider: ProviderName;
          apiKey: string;
        }
      | {
          __typename?: "KlaviyoScriptControls";
          provider: ProviderName;
          publicKey: string;
        }
      | {
          __typename?: "MailchimpScriptControls";
          provider: ProviderName;
          apiKey: string;
        }
      | { __typename?: "NoScriptControls"; provider: ProviderName }
      | {
          __typename?: "OptimonkScriptControls";
          provider: ProviderName;
          accountId: string;
        }
    > | null;
  } | null;
};

export type StoreInfoByHandleQueryVariables = Exact<{
  storeHandle: Scalars["String"]["input"];
}>;

export type StoreInfoByHandleQuery = {
  __typename?: "Query";
  store?: {
    __typename?: "Store";
    id: string;
    name: string;
    description?: string | null;
    currency: string;
    supportedLocales: Array<string>;
    fallbackLocale: string;
    favIcon?: { __typename?: "Image"; id: string; src?: string | null } | null;
    logo?: {
      __typename?: "StoreLogo";
      size?: LogoSize | null;
      image?: { __typename?: "Image"; id: string; src?: string | null } | null;
    } | null;
    seo?: {
      __typename?: "StoreSEO";
      title?: string | null;
      description?: string | null;
    } | null;
    appearance?: {
      __typename?: "StoreAppearance";
      template?: { __typename?: "Template"; handle?: string | null } | null;
    } | null;
    maintenanceMode?: {
      __typename?: "MaintenanceMode";
      isEnabled: boolean;
      message?: string | null;
    } | null;
    location?: { __typename?: "Location"; address: string } | null;
    contactInfo?: {
      __typename?: "ContactInfo";
      phone?: string | null;
      email?: string | null;
    } | null;
    redirects?: Array<{
      __typename?: "StoreRedirect";
      id: string;
      newUrl: string;
      oldUrl: string;
    }> | null;
  } | null;
};

export type StoreInfoByDomainQueryVariables = Exact<{
  storeDomain: Scalars["String"]["input"];
}>;

export type StoreInfoByDomainQuery = {
  __typename?: "Query";
  store?: {
    __typename?: "Store";
    id: string;
    name: string;
    description?: string | null;
    currency: string;
    supportedLocales: Array<string>;
    fallbackLocale: string;
    favIcon?: { __typename?: "Image"; id: string; src?: string | null } | null;
    logo?: {
      __typename?: "StoreLogo";
      size?: LogoSize | null;
      image?: { __typename?: "Image"; id: string; src?: string | null } | null;
    } | null;
    seo?: {
      __typename?: "StoreSEO";
      title?: string | null;
      description?: string | null;
    } | null;
    appearance?: {
      __typename?: "StoreAppearance";
      template?: { __typename?: "Template"; handle?: string | null } | null;
    } | null;
    maintenanceMode?: {
      __typename?: "MaintenanceMode";
      isEnabled: boolean;
      message?: string | null;
    } | null;
    location?: { __typename?: "Location"; address: string } | null;
    contactInfo?: {
      __typename?: "ContactInfo";
      phone?: string | null;
      email?: string | null;
    } | null;
    redirects?: Array<{
      __typename?: "StoreRedirect";
      id: string;
      newUrl: string;
      oldUrl: string;
    }> | null;
  } | null;
};

export type StoreBasicInfoByHandleQueryVariables = Exact<{
  storeHandle: Scalars["String"]["input"];
}>;

export type StoreBasicInfoByHandleQuery = {
  __typename?: "Query";
  store?: {
    __typename?: "Store";
    supportedLocales: Array<string>;
    defaultLocale: string;
    isExpired?: boolean | null;
    subscription?: {
      __typename?: "PlanSubscription";
      createdAt: any;
      status: SubscriptionStatus;
    } | null;
    domain?: { __typename?: "Domain"; domainName: string } | null;
  } | null;
};

export type StoreBasicInfoByDomainQueryVariables = Exact<{
  storeDomain: Scalars["String"]["input"];
}>;

export type StoreBasicInfoByDomainQuery = {
  __typename?: "Query";
  store?: {
    __typename?: "Store";
    supportedLocales: Array<string>;
    defaultLocale: string;
    isExpired?: boolean | null;
    subscription?: {
      __typename?: "PlanSubscription";
      createdAt: any;
      status: SubscriptionStatus;
    } | null;
    domain?: { __typename?: "Domain"; domainName: string } | null;
  } | null;
};

export type ShippingDestinationsQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
}>;

export type ShippingDestinationsQuery = {
  __typename?: "Query";
  store?: {
    __typename?: "Store";
    id: string;
    shippingZones?: {
      __typename?: "ShippingZoneConnection";
      totalCount: number;
      nodes?: Array<{
        __typename?: "ShippingZone";
        id: string;
        name: string;
        countries?: {
          __typename?: "ZoneCountryConnection";
          totalCount: number;
          nodes?: Array<{
            __typename?: "ZoneCountry";
            id: string;
            name: string;
            zoneId?: string | null;
            states?: {
              __typename?: "ZoneStateConnection";
              totalCount: number;
              nodes?: Array<{
                __typename?: "ZoneState";
                id: string;
                name: string;
                cities?: {
                  __typename?: "ZoneCityConnection";
                  totalCount: number;
                  nodes?: Array<{
                    __typename?: "ZoneCity";
                    id: string;
                    name: string;
                    regions?: {
                      __typename?: "ZoneRegionConnection";
                      totalCount: number;
                      nodes?: Array<{
                        __typename?: "ZoneRegion";
                        id: string;
                        name: string;
                      } | null> | null;
                    } | null;
                  } | null> | null;
                } | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ShippingRatesQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  input?: InputMaybe<ShippingRatesInput>;
}>;

export type ShippingRatesQuery = {
  __typename?: "Query";
  store?: {
    __typename?: "Store";
    id: string;
    shippingRates?: Array<{
      __typename?: "ShippingRate";
      id: string;
      name: string;
      cost: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      };
    } | null> | null;
  } | null;
};

export type StoreStaticPageByHandleQueryVariables = Exact<{
  input?: InputMaybe<StaticPageStoreByHandleInput>;
}>;

export type StoreStaticPageByHandleQuery = {
  __typename?: "Query";
  storeStaticPageByHandle?: {
    __typename?: "StoreStaticPage";
    id: string;
    handle: string;
    htmlTemplate?: string | null;
    locale?: string | null;
    name?: string | null;
    title?: string | null;
    translations?: Array<{
      __typename?: "StoreStaticPageTranslation";
      htmlTemplate?: string | null;
      locale?: string | null;
      title?: string | null;
    } | null> | null;
  } | null;
};

export type GetOrderByIdQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
}>;

export type GetOrderByIdQuery = {
  __typename?: "Query";
  order?: {
    __typename?: "Order";
    id: string;
    orderSerial?: string | null;
    status?: OrderStatusEnum | null;
    paymentMethod?: PaymentMethodEnum | null;
    createdAt: any;
    paymentStatus?: OrderPaymentStatusEnum | null;
    fulfillmentStatus?: FulfillStatusEnum | null;
    externalActionMessage?: Array<string | null> | null;
    notes?: string | null;
    wuiltShipmentProvider?: WuiltShipmentProvider | null;
    shippingRateName?: string | null;
    customer: {
      __typename?: "Customer";
      name?: string | null;
      email?: string | null;
      phone?: string | null;
      isSubscribedToNewsLetter?: boolean | null;
    };
    shipmentDetails?: {
      __typename?: "ShipmentDetails";
      trackingURL?: string | null;
      orderTrackingNumber?: string | null;
    } | null;
    shippingAddress: {
      __typename?: "Address";
      phone?: string | null;
      addressLine1: string;
      addressLine2?: string | null;
      postalCode?: string | null;
      areaSnapshot?: {
        __typename?: "AreaSnapshot";
        countryName?: string | null;
        stateName?: string | null;
        cityName?: string | null;
        regionName?: string | null;
      } | null;
    };
    billingAddress: {
      __typename?: "Address";
      phone?: string | null;
      addressLine1: string;
      addressLine2?: string | null;
      postalCode?: string | null;
      areaSnapshot?: {
        __typename?: "AreaSnapshot";
        countryName?: string | null;
        stateName?: string | null;
        cityName?: string | null;
        regionName?: string | null;
      } | null;
    };
    items: Array<
      | {
          __typename?: "CustomItem";
          id: string;
          quantity: number;
          title?: string | null;
          categories: Array<{
            __typename?: "CustomBuildCategory";
            category: {
              __typename?: "CustomProductCategory";
              id: string;
              categoryType: CategoryType;
              name: string;
              isRequired: boolean;
              image?: {
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null;
              variants?: Array<{
                __typename?: "CustomProductCategoryVariant";
                preselected?: boolean | null;
                variant?: {
                  __typename?: "ProductVariant";
                  id: string;
                  title?: string | null;
                  quantity?: number | null;
                  trackQuantity?: boolean | null;
                  sku?: string | null;
                  cartLimitsEnabled?: boolean | null;
                  minPerCart?: number | null;
                  maxPerCart?: number | null;
                  product: {
                    __typename?: "Product";
                    id: string;
                    title: string;
                    status: ProductStatus;
                    taxable?: boolean | null;
                    productTax?: number | null;
                    images: Array<{
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null>;
                  };
                  price: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  };
                  compareAtPrice?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  selectedOptions: Array<{
                    __typename?: "SelectedOption";
                    option: {
                      __typename?: "ProductOption";
                      id: string;
                      name: string;
                    };
                    value: {
                      __typename?: "ProductOptionValue";
                      id: string;
                      name?: string | null;
                    };
                  }>;
                } | null;
              } | null> | null;
              settings?: {
                __typename?: "CustomProductCategorySettings";
                maxSelect?: number | null;
                maxQuantity?: number | null;
              } | null;
            };
            selectedVariants: Array<{
              __typename?: "SelectedVariant";
              quantity: number;
              variantSnapshot?: {
                __typename?: "ProductVariantSnapshot";
                id: string;
                sku?: string | null;
                quantity?: number | null;
                productId: string;
                productTitle?: string | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                productImages?: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null> | null;
              } | null;
              price?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              selectedOptions: Array<{
                __typename?: "SelectedVariantOption";
                value: string;
                name: string;
              }>;
            }>;
          }>;
          subtotal?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          productSnapshot?: {
            __typename?: "ProductSnapshot";
            id: string;
            title: string;
            handle: string;
            collectionIds?: Array<string | null> | null;
            integrationProvider?: {
              __typename?: "IntegrationProviderDetailsSnapshot";
              provider?: string | null;
            } | null;
            images: Array<{
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null>;
          } | null;
          price?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          discount?: {
            __typename?: "OrderItemDiscountDetails";
            discountSource: DiscountSource;
            quantity: number;
            data?: {
              __typename?: "Discount";
              id: string;
              title?: string | null;
              translations?: Array<{
                __typename?: "DiscountTranslation";
                title?: string | null;
                locale?: string | null;
              } | null> | null;
            } | null;
            info: {
              __typename?: "OrderItemDiscountDetailsInfo";
              percentage?: number | null;
              fixed?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
            };
            perItem: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
            total: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
          } | null;
          discounts?: Array<{
            __typename?: "OrderItemDiscountDetails";
            discountSource: DiscountSource;
            quantity: number;
            data?: {
              __typename?: "Discount";
              id: string;
              title?: string | null;
              translations?: Array<{
                __typename?: "DiscountTranslation";
                title?: string | null;
                locale?: string | null;
              } | null> | null;
            } | null;
            info: {
              __typename?: "OrderItemDiscountDetailsInfo";
              percentage?: number | null;
              fixed?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
            };
            perItem: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
            total: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
          }> | null;
        }
      | {
          __typename?: "SimpleItem";
          id: string;
          quantity: number;
          title?: string | null;
          productSnapshot?: {
            __typename?: "ProductSnapshot";
            id: string;
            title: string;
            handle: string;
            collectionIds?: Array<string | null> | null;
            integrationProvider?: {
              __typename?: "IntegrationProviderDetailsSnapshot";
              provider?: string | null;
            } | null;
            images: Array<{
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null>;
          } | null;
          selectedOptions: Array<{
            __typename?: "SelectedVariantOption";
            value: string;
            name: string;
          }>;
          variantSnapshot?: {
            __typename?: "ProductVariantSnapshot";
            id: string;
            sku?: string | null;
            quantity?: number | null;
            productId: string;
            productTitle?: string | null;
            image?: {
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null;
            productImages?: Array<{
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null> | null;
          } | null;
          subtotal?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          price?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          discount?: {
            __typename?: "OrderItemDiscountDetails";
            discountSource: DiscountSource;
            quantity: number;
            data?: {
              __typename?: "Discount";
              id: string;
              title?: string | null;
              translations?: Array<{
                __typename?: "DiscountTranslation";
                title?: string | null;
                locale?: string | null;
              } | null> | null;
            } | null;
            info: {
              __typename?: "OrderItemDiscountDetailsInfo";
              percentage?: number | null;
              fixed?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
            };
            perItem: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
            total: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
          } | null;
          discounts?: Array<{
            __typename?: "OrderItemDiscountDetails";
            discountSource: DiscountSource;
            quantity: number;
            data?: {
              __typename?: "Discount";
              id: string;
              title?: string | null;
              translations?: Array<{
                __typename?: "DiscountTranslation";
                title?: string | null;
                locale?: string | null;
              } | null> | null;
            } | null;
            info: {
              __typename?: "OrderItemDiscountDetailsInfo";
              percentage?: number | null;
              fixed?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
            };
            perItem: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
            total: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
          }> | null;
        }
      | null
    >;
    shippingRateCost: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    };
    promoCodeSnapshot?: {
      __typename?: "PromoCodeSnapshot";
      code: string;
      percentageOff?: number | null;
      fixedAmount?: number | null;
      type?: PromoCodeTypeEnum | null;
    } | null;
    receipt?: {
      __typename?: "OrderReceipt";
      subtotal?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
      automaticDiscount?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
      discount?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
      tax?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
      shipping?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
      total?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
    } | null;
    discounts?: Array<{
      __typename?: "DiscountSnapshot";
      id?: string | null;
      type?: DiscountType | null;
      title?: string | null;
      appliedOn?: DiscountAppliedOnType | null;
      percentage?: number | null;
      amount?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
    } | null> | null;
    paymentIntent?: {
      __typename?: "PaymentIntent";
      id: string;
      status?: PaymentIntentStatusEnum | null;
      embedUrl?: string | null;
      paymentMethod?: PaymentMethodEnum | null;
      paymentMethods?: Array<PaymentMethodEnum | null> | null;
    } | null;
    reviews: Array<{ __typename?: "ProductReview"; productId: string }>;
    errors?: Array<{
      __typename?: "CheckoutError";
      code: ErrorCode;
      resourceId?: string | null;
      params?: {
        __typename?: "Params";
        requestedQuantity?: number | null;
        availableQuantity?: number | null;
        requestedPrice?: number | null;
        availablePrice?: number | null;
        requestedCurrency?: string | null;
        availableCurrency?: string | null;
        message?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetOrderStatusQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
}>;

export type GetOrderStatusQuery = {
  __typename?: "Query";
  order?: {
    __typename?: "Order";
    id: string;
    status?: OrderStatusEnum | null;
    orderSerial?: string | null;
  } | null;
};

export type GetOrderErrorsQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
}>;

export type GetOrderErrorsQuery = {
  __typename?: "Query";
  order?: {
    __typename?: "Order";
    id: string;
    errors?: Array<{
      __typename?: "CheckoutError";
      code: ErrorCode;
      resourceId?: string | null;
      params?: {
        __typename?: "Params";
        requestedQuantity?: number | null;
        availableQuantity?: number | null;
        requestedPrice?: number | null;
        availablePrice?: number | null;
        requestedCurrency?: string | null;
        availableCurrency?: string | null;
        message?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetStorePaymentMethodsQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
}>;

export type GetStorePaymentMethodsQuery = {
  __typename?: "Query";
  storePaymentMethods?: Array<{
    __typename?: "StorePaymentMethod";
    id?: string | null;
    type: StorePaymentMethods;
    enabled: boolean;
  } | null> | null;
};

export type GetStoreCheckoutPaymentMethodsQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
}>;

export type GetStoreCheckoutPaymentMethodsQuery = {
  __typename?: "Query";
  storeCheckoutPaymentMethods?: Array<PaymentMethodEnum | null> | null;
};

export type StoreCheckoutQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
}>;

export type StoreCheckoutQuery = {
  __typename?: "Query";
  store?: {
    __typename?: "Store";
    id: string;
    checkout?: {
      __typename?: "Checkout";
      successMessage?: string | null;
    } | null;
  } | null;
};

export type ListCustomerAddressesQueryVariables = Exact<{
  customerId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
}>;

export type ListCustomerAddressesQuery = {
  __typename?: "Query";
  customerAddresses: Array<{
    __typename?: "CustomerAddress";
    id: string;
    addressLine1: string;
    addressLine2?: string | null;
    secondPhone?: string | null;
    isDefault: boolean;
    postalCode?: string | null;
    notes?: string | null;
    state?: { __typename?: "State"; name: string; id: string } | null;
    country?: { __typename?: "Country"; name: string; id: string } | null;
    city?: { __typename?: "City"; name: string; id: string } | null;
    region?: { __typename?: "Region"; name: string; id: string } | null;
  }>;
};

export type ListCustomerOrdersQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  connection?: InputMaybe<OrdersConnectionInput>;
}>;

export type ListCustomerOrdersQuery = {
  __typename?: "Query";
  customerOrders?: {
    __typename?: "CustomerUser";
    id: string;
    name?: string | null;
    email?: string | null;
    phone?: string | null;
    orders?: {
      __typename?: "OrderConnection";
      totalCount: number;
      nodes?: Array<{
        __typename?: "Order";
        id: string;
        orderSerial?: string | null;
        createdAt: any;
        paymentStatus?: OrderPaymentStatusEnum | null;
        fulfillmentStatus?: FulfillStatusEnum | null;
        wuiltShipmentProvider?: WuiltShipmentProvider | null;
        shipmentDetails?: {
          __typename?: "ShipmentDetails";
          trackingURL?: string | null;
          orderTrackingNumber?: string | null;
        } | null;
        totalPrice?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CustomerDetailsQueryVariables = Exact<{
  customerId: Scalars["ID"]["input"];
  connection?: InputMaybe<OrdersConnectionInput>;
}>;

export type CustomerDetailsQuery = {
  __typename?: "Query";
  customerOrders?: {
    __typename?: "CustomerUser";
    storeId?: string | null;
    id: string;
    name?: string | null;
    email?: string | null;
    phone?: string | null;
    updatedAt?: any | null;
    createdAt?: any | null;
    isGuest?: boolean | null;
    orders?: {
      __typename?: "OrderConnection";
      totalCount: number;
      nodes?: Array<{
        __typename?: "Order";
        id: string;
        orderSerial?: string | null;
        createdAt: any;
        paymentStatus?: OrderPaymentStatusEnum | null;
        fulfillmentStatus?: FulfillStatusEnum | null;
        totalPrice?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
      } | null> | null;
    } | null;
    addresses?: Array<{
      __typename?: "CustomerAddress";
      id: string;
      addressLine1: string;
      addressLine2?: string | null;
      secondPhone?: string | null;
      isDefault: boolean;
      postalCode?: string | null;
      notes?: string | null;
      state?: { __typename?: "State"; name: string; id: string } | null;
      country?: { __typename?: "Country"; name: string; id: string } | null;
      city?: { __typename?: "City"; name: string; id: string } | null;
      region?: { __typename?: "Region"; name: string; id: string } | null;
    }> | null;
    totalSpending?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
  } | null;
};

export type ListCustomerReviewsQueryVariables = Exact<{
  input: ListCustomerReviewsInput;
}>;

export type ListCustomerReviewsQuery = {
  __typename?: "Query";
  listCustomerReviews: Array<{
    __typename?: "ProductReview";
    id: string;
    createdAt: any;
    headline: string;
    content?: string | null;
    rating: number;
    showName: boolean;
    status: ReviewStatus;
    product: {
      __typename?: "Product";
      id: string;
      title: string;
      images: Array<{
        __typename?: "Image";
        id: string;
        altText?: string | null;
        src?: string | null;
        width?: number | null;
        height?: number | null;
        format?: string | null;
      } | null>;
    };
  }>;
};

export type GetStoreRobotsTxtQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
}>;

export type GetStoreRobotsTxtQuery = {
  __typename?: "Query";
  getStoreRobotsTxt?: {
    __typename?: "RobotsTxt";
    content: string;
    id: string;
  } | null;
};

export type CalculateDiscountsQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  items: Array<CalculateItemsDiscountsInput> | CalculateItemsDiscountsInput;
}>;

export type CalculateDiscountsQuery = {
  __typename?: "Query";
  calculateDiscounts: Array<{
    __typename?: "CalculatedDiscountBaseItem";
    productId: string;
    variantId: string;
    collectionIds?: Array<string | null> | null;
    quantity: number;
    price: { __typename?: "Money"; amount: number; currencyCode: CurrencyCode };
    discount?: {
      __typename?: "OrderItemDiscountDetails";
      discountSource: DiscountSource;
      quantity: number;
      data?: {
        __typename?: "Discount";
        id: string;
        title?: string | null;
        translations?: Array<{
          __typename?: "DiscountTranslation";
          title?: string | null;
          locale?: string | null;
        } | null> | null;
      } | null;
      info: {
        __typename?: "OrderItemDiscountDetailsInfo";
        percentage?: number | null;
        fixed?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
      };
      perItem: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      };
      total: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      };
    } | null;
    discounts?: Array<{
      __typename?: "OrderItemDiscountDetails";
      discountSource: DiscountSource;
      quantity: number;
      data?: {
        __typename?: "Discount";
        id: string;
        title?: string | null;
        translations?: Array<{
          __typename?: "DiscountTranslation";
          title?: string | null;
          locale?: string | null;
        } | null> | null;
      } | null;
      info: {
        __typename?: "OrderItemDiscountDetailsInfo";
        percentage?: number | null;
        fixed?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
      };
      perItem: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      };
      total: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      };
    }> | null;
  }>;
};

export type CartQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  cartId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type CartQuery = {
  __typename?: "Query";
  customerQueries?: {
    __typename?: "CustomerQueries";
    cart?: {
      __typename?: "Cart";
      id: string;
      isReserved?: boolean | null;
      recoveryId: string;
      reservedAt?: any | null;
      createdAt: any;
      updatedAt: any;
      sessionId: string;
      status: CartStatusEnum;
      cartStep: CartStepEnum;
      lastStep: CartStepEnum;
      refCode?: string | null;
      paymentIntentId?: string | null;
      promoCode?: {
        __typename?: "PromoCode";
        id: string;
        type?: PromoCodeTypeEnum | null;
        code: string;
        status: PromoCodeStatusEnum;
        percentageOff?: number | null;
        fixedAmount?: number | null;
        isArchived: boolean;
        minimumOrderAmountToApply?: number | null;
        schedule?: {
          __typename?: "DiscountSchedule";
          start: any;
          end?: any | null;
        } | null;
      } | null;
      receipt: {
        __typename?: "CartReceipt";
        subtotal: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        automaticDiscount: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        discount: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        tax: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        shipping: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        total: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
      };
      shippingDetails?: {
        __typename?: "CartShippingDetails";
        addressLine1?: string | null;
        addressLine2?: string | null;
        id?: string | null;
        secondPhone?: string | null;
        postalCode?: string | null;
        notes?: string | null;
        area?: {
          __typename?: "CartShippingDetailsArea";
          countryId?: string | null;
          stateId?: string | null;
          cityId?: string | null;
          regionId?: string | null;
          country?: { __typename?: "Country"; name: string } | null;
          state?: { __typename?: "State"; name: string } | null;
          city?: { __typename?: "City"; name: string } | null;
          region?: { __typename?: "Region"; name: string } | null;
        } | null;
      } | null;
      shippingDiscount?: {
        __typename?: "Discount";
        id: string;
        title?: string | null;
        appliedOn?: DiscountAppliedOnType | null;
        percentage?: number | null;
        status?: DiscountStatus | null;
        customerBuys?: {
          __typename?: "CustomerBuys";
          value?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
        } | null;
        amount?: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        } | null;
        BuyXGetYDiscount?: {
          __typename?: "BuyXGetYDiscount";
          customerBuys: {
            __typename?: "CustomerBuys";
            quantity?: number | null;
            itemsType?: DiscountItemsType | null;
            value?: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            } | null;
            items?: {
              __typename?: "CustomerBuysItems";
              productVariantIds?: Array<string> | null;
              collectionIds?: Array<string> | null;
            } | null;
          };
          customerGets: {
            __typename?: "CustomerGets";
            quantity?: number | null;
            percentage: number;
            itemsType: DiscountItemsType;
            items: {
              __typename?: "CustomerGetsItems";
              productVariants?: Array<{
                __typename?: "ProductVariant";
                id: string;
              }> | null;
              collections?: Array<{
                __typename?: "ProductCollection";
                id: string;
              }> | null;
            };
          };
        } | null;
        amountOff?: {
          __typename?: "AmountOff";
          customerBuys: {
            __typename?: "CustomerBuysAmountOff";
            itemsType?: DiscountItemsType | null;
            items?: {
              __typename?: "CustomerBuysItems";
              productVariantIds?: Array<string> | null;
              collectionIds?: Array<string> | null;
              collections?: Array<{
                __typename?: "ProductCollection";
                id: string;
              }> | null;
              productVariants?: Array<{
                __typename?: "ProductVariant";
                id: string;
              }> | null;
            } | null;
          };
          discount: {
            __typename?: "AmountOffDiscounted";
            amount?: number | null;
            percentage?: number | null;
          };
        } | null;
        translations?: Array<{
          __typename?: "DiscountTranslation";
          title?: string | null;
          locale?: string | null;
        } | null> | null;
      } | null;
      contactInfo?: {
        __typename?: "CartContactInfo";
        name?: string | null;
        email?: string | null;
        phone?: string | null;
      } | null;
      customer?: {
        __typename?: "CustomerUser";
        id: string;
        name?: string | null;
        email?: string | null;
        phone?: string | null;
        isGuest?: boolean | null;
        addresses?: Array<{
          __typename?: "CustomerAddress";
          addressLine1: string;
          addressLine2?: string | null;
          id: string;
          phone?: string | null;
          secondPhone?: string | null;
          postalCode?: string | null;
          notes?: string | null;
          isDefault: boolean;
        }> | null;
      } | null;
      cartErrors?: Array<{
        __typename?: "CartError";
        code: CheckoutServiceErrorCodes;
        params?: {
          __typename?: "CartErrorParam";
          resourceId?: string | null;
          requestedQuantity?: number | null;
          availableQuantity?: number | null;
          requestedPrice?: number | null;
          availablePrice?: number | null;
          requestedCurrency?: string | null;
          availableCurrency?: string | null;
          message?: string | null;
        } | null;
      }> | null;
      shippingRate?: {
        __typename?: "CartShippingRate";
        cost: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
        details: {
          __typename?: "ShippingRate";
          id: string;
          storeId: string;
          name: string;
          isArchived: boolean;
          locale: string;
          locales: Array<string>;
          translations?: Array<{
            __typename?: "ShippingRateTranslation";
            name?: string | null;
            locale: string;
          }> | null;
        };
      } | null;
      paymentIntent?: {
        __typename?: "PaymentIntent";
        id: string;
        status?: PaymentIntentStatusEnum | null;
        embedUrl?: string | null;
        paymentMethod?: PaymentMethodEnum | null;
        paymentMethods?: Array<PaymentMethodEnum | null> | null;
        key?: string | null;
      } | null;
      items: Array<
        | {
            __typename?: "CartCustomItem";
            id: string;
            quantity: number;
            product?: {
              __typename?: "Product";
              id: string;
              type?: ProductType | null;
              locale: string;
              title: string;
              descriptionHtml?: any | null;
              shortDescription?: string | null;
              handle: string;
              isInStock: boolean;
              taxable?: boolean | null;
              productTax?: number | null;
              status: ProductStatus;
              integrationProvider?: {
                __typename?: "IntegrationProviderDetails";
                provider?: string | null;
              } | null;
              collections: {
                __typename?: "ProductCollectionConnection";
                totalCount: number;
                nodes?: Array<{
                  __typename?: "ProductCollection";
                  title?: string | null;
                  id: string;
                  locale: string;
                  handle: string;
                  shortDescription?: string | null;
                  productsCount: number;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  seo?: {
                    __typename?: "ProductCollectionSEO";
                    title?: string | null;
                    description?: string | null;
                  } | null;
                } | null> | null;
              };
              initialPrice?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              images: Array<{
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null>;
              categories?: Array<{
                __typename?: "CustomProductCategory";
                id: string;
                categoryType: CategoryType;
                name: string;
                isRequired: boolean;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                variants?: Array<{
                  __typename?: "CustomProductCategoryVariant";
                  preselected?: boolean | null;
                  variant?: {
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  } | null;
                } | null> | null;
                settings?: {
                  __typename?: "CustomProductCategorySettings";
                  maxSelect?: number | null;
                  maxQuantity?: number | null;
                } | null;
              } | null> | null;
              options?: Array<{
                __typename?: "ProductOption";
                name: string;
                values: Array<{
                  __typename?: "ProductOptionValue";
                  id: string;
                  name?: string | null;
                }>;
              } | null> | null;
              variants?: {
                __typename?: "ProductVariantConnection";
                nodes: Array<{
                  __typename?: "ProductVariant";
                  id: string;
                  title?: string | null;
                  quantity?: number | null;
                  trackQuantity?: boolean | null;
                  sku?: string | null;
                  cartLimitsEnabled?: boolean | null;
                  minPerCart?: number | null;
                  maxPerCart?: number | null;
                  product: {
                    __typename?: "Product";
                    id: string;
                    title: string;
                    status: ProductStatus;
                    taxable?: boolean | null;
                    productTax?: number | null;
                    images: Array<{
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null>;
                  };
                  price: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  };
                  compareAtPrice?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  selectedOptions: Array<{
                    __typename?: "SelectedOption";
                    option: {
                      __typename?: "ProductOption";
                      id: string;
                      name: string;
                    };
                    value: {
                      __typename?: "ProductOptionValue";
                      id: string;
                      name?: string | null;
                    };
                  }>;
                }>;
              } | null;
              discount?: {
                __typename?: "CustomProductDiscount";
                percent?: number | null;
                fixed?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
              } | null;
              attributes?: Array<{
                __typename?: "ProductAttribute";
                id: string;
                name: string;
                values: Array<{
                  __typename?: "ProductAttributeValue";
                  id: string;
                  name?: string | null;
                }>;
              } | null> | null;
              reviewsStatistics: {
                __typename?: "ProductReviewsStatistics";
                average: number;
                total: number;
              };
            } | null;
            subtotal: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
            totalPrice: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
            price: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
            discount?: {
              __typename?: "CustomItemDiscountDetails";
              discountSource: DiscountSource;
              perItem: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              total: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              info: {
                __typename?: "OrderItemDiscountDetailsInfo";
                percentage?: number | null;
                fixed?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
              };
            } | null;
            categories: Array<{
              __typename?: "CartItemCustomBuildCategory";
              category: {
                __typename?: "CartItemCustomProductCategory";
                id: string;
                categoryType: CategoryType;
                name: string;
                isRequired?: boolean | null;
                maxSelect?: number | null;
                maxQuantity?: number | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
              };
              selectedVariants: Array<{
                __typename?: "CartItemSelectedVariant";
                id: string;
                quantity: number;
                price: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                };
                subtotal?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
                variant?: {
                  __typename?: "ProductVariant";
                  id: string;
                  title?: string | null;
                  quantity?: number | null;
                  trackQuantity?: boolean | null;
                  sku?: string | null;
                  cartLimitsEnabled?: boolean | null;
                  minPerCart?: number | null;
                  maxPerCart?: number | null;
                  product: {
                    __typename?: "Product";
                    id: string;
                    title: string;
                    status: ProductStatus;
                    taxable?: boolean | null;
                    productTax?: number | null;
                    images: Array<{
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null>;
                  };
                  price: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  };
                  compareAtPrice?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  selectedOptions: Array<{
                    __typename?: "SelectedOption";
                    option: {
                      __typename?: "ProductOption";
                      id: string;
                      name: string;
                    };
                    value: {
                      __typename?: "ProductOptionValue";
                      id: string;
                      name?: string | null;
                    };
                  }>;
                } | null;
              }>;
            }>;
          }
        | {
            __typename?: "CartSimpleItem";
            id: string;
            quantity: number;
            product?: {
              __typename?: "Product";
              id: string;
              type?: ProductType | null;
              locale: string;
              title: string;
              descriptionHtml?: any | null;
              shortDescription?: string | null;
              handle: string;
              isInStock: boolean;
              taxable?: boolean | null;
              productTax?: number | null;
              status: ProductStatus;
              integrationProvider?: {
                __typename?: "IntegrationProviderDetails";
                provider?: string | null;
              } | null;
              collections: {
                __typename?: "ProductCollectionConnection";
                totalCount: number;
                nodes?: Array<{
                  __typename?: "ProductCollection";
                  title?: string | null;
                } | null> | null;
              };
              initialPrice?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              images: Array<{
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null>;
              categories?: Array<{
                __typename?: "CustomProductCategory";
                id: string;
                categoryType: CategoryType;
                name: string;
                isRequired: boolean;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                variants?: Array<{
                  __typename?: "CustomProductCategoryVariant";
                  preselected?: boolean | null;
                  variant?: {
                    __typename?: "ProductVariant";
                    id: string;
                    title?: string | null;
                    quantity?: number | null;
                    trackQuantity?: boolean | null;
                    sku?: string | null;
                    cartLimitsEnabled?: boolean | null;
                    minPerCart?: number | null;
                    maxPerCart?: number | null;
                    product: {
                      __typename?: "Product";
                      id: string;
                      title: string;
                      status: ProductStatus;
                      taxable?: boolean | null;
                      productTax?: number | null;
                      images: Array<{
                        __typename?: "Image";
                        id: string;
                        altText?: string | null;
                        src?: string | null;
                        width?: number | null;
                        height?: number | null;
                        format?: string | null;
                      } | null>;
                    };
                    price: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    };
                    compareAtPrice?: {
                      __typename?: "Money";
                      amount: number;
                      currencyCode: CurrencyCode;
                    } | null;
                    image?: {
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null;
                    selectedOptions: Array<{
                      __typename?: "SelectedOption";
                      option: {
                        __typename?: "ProductOption";
                        id: string;
                        name: string;
                      };
                      value: {
                        __typename?: "ProductOptionValue";
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  } | null;
                } | null> | null;
                settings?: {
                  __typename?: "CustomProductCategorySettings";
                  maxSelect?: number | null;
                  maxQuantity?: number | null;
                } | null;
              } | null> | null;
              options?: Array<{
                __typename?: "ProductOption";
                name: string;
                values: Array<{
                  __typename?: "ProductOptionValue";
                  id: string;
                  name?: string | null;
                }>;
              } | null> | null;
            } | null;
            subtotal: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
            price: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
            totalPrice: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
            variant?: {
              __typename?: "ProductVariant";
              id: string;
              title?: string | null;
              quantity?: number | null;
              trackQuantity?: boolean | null;
              sku?: string | null;
              cartLimitsEnabled?: boolean | null;
              minPerCart?: number | null;
              maxPerCart?: number | null;
              price: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              compareAtPrice?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              image?: {
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null;
              selectedOptions: Array<{
                __typename?: "SelectedOption";
                option: {
                  __typename?: "ProductOption";
                  id: string;
                  name: string;
                };
                value: {
                  __typename?: "ProductOptionValue";
                  id: string;
                  name?: string | null;
                };
              }>;
            } | null;
            discount?: {
              __typename?: "OrderItemDiscountDetails";
              discountSource: DiscountSource;
              quantity: number;
              data?: {
                __typename?: "Discount";
                id: string;
                title?: string | null;
                translations?: Array<{
                  __typename?: "DiscountTranslation";
                  title?: string | null;
                  locale?: string | null;
                } | null> | null;
              } | null;
              info: {
                __typename?: "OrderItemDiscountDetailsInfo";
                percentage?: number | null;
                fixed?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
              };
              perItem: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              total: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
            } | null;
            discounts?: Array<{
              __typename?: "OrderItemDiscountDetails";
              discountSource: DiscountSource;
              quantity: number;
              data?: {
                __typename?: "Discount";
                id: string;
                title?: string | null;
                translations?: Array<{
                  __typename?: "DiscountTranslation";
                  title?: string | null;
                  locale?: string | null;
                } | null> | null;
              } | null;
              info: {
                __typename?: "OrderItemDiscountDetailsInfo";
                percentage?: number | null;
                fixed?: {
                  __typename?: "Money";
                  amount: number;
                  currencyCode: CurrencyCode;
                } | null;
              };
              perItem: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
              total: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              };
            }> | null;
          }
      >;
    } | null;
  } | null;
};

export type GetPaymentStatusByIntentIdQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  paymentStatusByIntentIdId: Scalars["ID"]["input"];
}>;

export type GetPaymentStatusByIntentIdQuery = {
  __typename?: "Query";
  paymentStatusByIntentId?: PaymentIntentStatusEnum | null;
};

export type GetOrderByCartIdQueryVariables = Exact<{
  cartId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
}>;

export type GetOrderByCartIdQuery = {
  __typename?: "Query";
  orderByCart?: {
    __typename?: "Order";
    orderSerial?: string | null;
    paymentMethod?: PaymentMethodEnum | null;
    status?: OrderStatusEnum | null;
    id: string;
    wuiltShipmentProvider?: WuiltShipmentProvider | null;
    externalActionMessage?: Array<string | null> | null;
    createdAt: any;
    shippingRateName?: string | null;
    billingAddress: {
      __typename?: "Address";
      addressLine1: string;
      addressLine2?: string | null;
      phone?: string | null;
      secondPhone?: string | null;
      postalCode?: string | null;
      notes?: string | null;
      areaSnapshot?: {
        __typename?: "AreaSnapshot";
        cityName?: string | null;
        regionName?: string | null;
        countryName?: string | null;
        stateName?: string | null;
      } | null;
    };
    shippingAddress: {
      __typename?: "Address";
      addressLine1: string;
      addressLine2?: string | null;
      phone?: string | null;
      secondPhone?: string | null;
      postalCode?: string | null;
      notes?: string | null;
      areaSnapshot?: {
        __typename?: "AreaSnapshot";
        cityName?: string | null;
        regionName?: string | null;
        countryName?: string | null;
        stateName?: string | null;
      } | null;
    };
    totalPrice?: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    } | null;
    customer: {
      __typename?: "Customer";
      name?: string | null;
      email?: string | null;
      phone?: string | null;
      isSubscribedToNewsLetter?: boolean | null;
    };
    receipt?: {
      __typename?: "OrderReceipt";
      subtotal?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
      discount?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
      tax?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
      shipping?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
      total?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
      automaticDiscount?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
    } | null;
    promoCodeSnapshot?: {
      __typename?: "PromoCodeSnapshot";
      code: string;
      percentageOff?: number | null;
      fixedAmount?: number | null;
      type?: PromoCodeTypeEnum | null;
    } | null;
    shippingRateCost: {
      __typename?: "Money";
      amount: number;
      currencyCode: CurrencyCode;
    };
    items: Array<
      | {
          __typename?: "CustomItem";
          id: string;
          quantity: number;
          title?: string | null;
          categories: Array<{
            __typename?: "CustomBuildCategory";
            category: {
              __typename?: "CustomProductCategory";
              id: string;
              categoryType: CategoryType;
              name: string;
              isRequired: boolean;
              image?: {
                __typename?: "Image";
                id: string;
                altText?: string | null;
                src?: string | null;
                width?: number | null;
                height?: number | null;
                format?: string | null;
              } | null;
              variants?: Array<{
                __typename?: "CustomProductCategoryVariant";
                preselected?: boolean | null;
                variant?: {
                  __typename?: "ProductVariant";
                  id: string;
                  title?: string | null;
                  quantity?: number | null;
                  trackQuantity?: boolean | null;
                  sku?: string | null;
                  cartLimitsEnabled?: boolean | null;
                  minPerCart?: number | null;
                  maxPerCart?: number | null;
                  product: {
                    __typename?: "Product";
                    id: string;
                    title: string;
                    status: ProductStatus;
                    taxable?: boolean | null;
                    productTax?: number | null;
                    images: Array<{
                      __typename?: "Image";
                      id: string;
                      altText?: string | null;
                      src?: string | null;
                      width?: number | null;
                      height?: number | null;
                      format?: string | null;
                    } | null>;
                  };
                  price: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  };
                  compareAtPrice?: {
                    __typename?: "Money";
                    amount: number;
                    currencyCode: CurrencyCode;
                  } | null;
                  image?: {
                    __typename?: "Image";
                    id: string;
                    altText?: string | null;
                    src?: string | null;
                    width?: number | null;
                    height?: number | null;
                    format?: string | null;
                  } | null;
                  selectedOptions: Array<{
                    __typename?: "SelectedOption";
                    option: {
                      __typename?: "ProductOption";
                      id: string;
                      name: string;
                    };
                    value: {
                      __typename?: "ProductOptionValue";
                      id: string;
                      name?: string | null;
                    };
                  }>;
                } | null;
              } | null> | null;
              settings?: {
                __typename?: "CustomProductCategorySettings";
                maxSelect?: number | null;
                maxQuantity?: number | null;
              } | null;
            };
            selectedVariants: Array<{
              __typename?: "SelectedVariant";
              quantity: number;
              variantSnapshot?: {
                __typename?: "ProductVariantSnapshot";
                id: string;
                sku?: string | null;
                quantity?: number | null;
                productId: string;
                productTitle?: string | null;
                image?: {
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null;
                productImages?: Array<{
                  __typename?: "Image";
                  id: string;
                  altText?: string | null;
                  src?: string | null;
                  width?: number | null;
                  height?: number | null;
                  format?: string | null;
                } | null> | null;
              } | null;
              price?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
              selectedOptions: Array<{
                __typename?: "SelectedVariantOption";
                value: string;
                name: string;
              }>;
            }>;
          }>;
          subtotal?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          productSnapshot?: {
            __typename?: "ProductSnapshot";
            id: string;
            title: string;
            handle: string;
            collectionIds?: Array<string | null> | null;
            integrationProvider?: {
              __typename?: "IntegrationProviderDetailsSnapshot";
              provider?: string | null;
            } | null;
            images: Array<{
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null>;
          } | null;
          price?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          discount?: {
            __typename?: "OrderItemDiscountDetails";
            discountSource: DiscountSource;
            quantity: number;
            data?: {
              __typename?: "Discount";
              id: string;
              title?: string | null;
              translations?: Array<{
                __typename?: "DiscountTranslation";
                title?: string | null;
                locale?: string | null;
              } | null> | null;
            } | null;
            info: {
              __typename?: "OrderItemDiscountDetailsInfo";
              percentage?: number | null;
              fixed?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
            };
            perItem: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
            total: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
          } | null;
          discounts?: Array<{
            __typename?: "OrderItemDiscountDetails";
            discountSource: DiscountSource;
            quantity: number;
            data?: {
              __typename?: "Discount";
              id: string;
              title?: string | null;
              translations?: Array<{
                __typename?: "DiscountTranslation";
                title?: string | null;
                locale?: string | null;
              } | null> | null;
            } | null;
            info: {
              __typename?: "OrderItemDiscountDetailsInfo";
              percentage?: number | null;
              fixed?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
            };
            perItem: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
            total: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
          }> | null;
        }
      | {
          __typename?: "SimpleItem";
          id: string;
          quantity: number;
          title?: string | null;
          productSnapshot?: {
            __typename?: "ProductSnapshot";
            id: string;
            title: string;
            handle: string;
            collectionIds?: Array<string | null> | null;
            integrationProvider?: {
              __typename?: "IntegrationProviderDetailsSnapshot";
              provider?: string | null;
            } | null;
            images: Array<{
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null>;
          } | null;
          selectedOptions: Array<{
            __typename?: "SelectedVariantOption";
            value: string;
            name: string;
          }>;
          variantSnapshot?: {
            __typename?: "ProductVariantSnapshot";
            id: string;
            sku?: string | null;
            quantity?: number | null;
            productId: string;
            productTitle?: string | null;
            image?: {
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null;
            productImages?: Array<{
              __typename?: "Image";
              id: string;
              altText?: string | null;
              src?: string | null;
              width?: number | null;
              height?: number | null;
              format?: string | null;
            } | null> | null;
          } | null;
          subtotal?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          price?: {
            __typename?: "Money";
            amount: number;
            currencyCode: CurrencyCode;
          } | null;
          discount?: {
            __typename?: "OrderItemDiscountDetails";
            discountSource: DiscountSource;
            quantity: number;
            data?: {
              __typename?: "Discount";
              id: string;
              title?: string | null;
              translations?: Array<{
                __typename?: "DiscountTranslation";
                title?: string | null;
                locale?: string | null;
              } | null> | null;
            } | null;
            info: {
              __typename?: "OrderItemDiscountDetailsInfo";
              percentage?: number | null;
              fixed?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
            };
            perItem: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
            total: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
          } | null;
          discounts?: Array<{
            __typename?: "OrderItemDiscountDetails";
            discountSource: DiscountSource;
            quantity: number;
            data?: {
              __typename?: "Discount";
              id: string;
              title?: string | null;
              translations?: Array<{
                __typename?: "DiscountTranslation";
                title?: string | null;
                locale?: string | null;
              } | null> | null;
            } | null;
            info: {
              __typename?: "OrderItemDiscountDetailsInfo";
              percentage?: number | null;
              fixed?: {
                __typename?: "Money";
                amount: number;
                currencyCode: CurrencyCode;
              } | null;
            };
            perItem: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
            total: {
              __typename?: "Money";
              amount: number;
              currencyCode: CurrencyCode;
            };
          }> | null;
        }
      | null
    >;
    discounts?: Array<{
      __typename?: "DiscountSnapshot";
      id?: string | null;
      type?: DiscountType | null;
      title?: string | null;
      appliedOn?: DiscountAppliedOnType | null;
      percentage?: number | null;
      amount?: {
        __typename?: "Money";
        amount: number;
        currencyCode: CurrencyCode;
      } | null;
    } | null> | null;
  } | null;
};

export type PaymentIntentQueryVariables = Exact<{
  paymentIntentId: Scalars["ID"]["input"];
  storeId: Scalars["ID"]["input"];
}>;

export type PaymentIntentQuery = {
  __typename?: "Query";
  paymentIntent?: {
    __typename?: "PaymentIntent";
    id: string;
    status?: PaymentIntentStatusEnum | null;
    embedUrl?: string | null;
    paymentMethod?: PaymentMethodEnum | null;
    paymentMethods?: Array<PaymentMethodEnum | null> | null;
    key?: string | null;
    cartId?: string | null;
  } | null;
};

export type GetWuiltShipmentRatesQueryVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  cityId: Scalars["ID"]["input"];
  cartId: Scalars["ID"]["input"];
  stateId: Scalars["ID"]["input"];
}>;

export type GetWuiltShipmentRatesQuery = {
  __typename?: "Query";
  customerQueries?: {
    __typename?: "CustomerQueries";
    wuiltShipment: {
      __typename?: "WuiltShipmentCustomerQueries";
      rates: Array<{
        __typename?: "WuiltShipmentProviderCityRate";
        provider: WuiltShipmentProvider;
        shippingRateId: string;
        rate: {
          __typename?: "Money";
          amount: number;
          currencyCode: CurrencyCode;
        };
      }>;
    };
  } | null;
};

export type SignUpMutationVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  input: CustomerUserInput;
}>;

export type SignUpMutation = {
  __typename?: "Mutation";
  signup?: string | null;
};

export type ForgotPasswordMutationVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  storeUrl?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ForgotPasswordMutation = {
  __typename?: "Mutation";
  forgetCustomerPassword: boolean;
};

export type ResetPasswordMutationVariables = Exact<{
  storeId: Scalars["ID"]["input"];
  token?: InputMaybe<Scalars["String"]["input"]>;
  newPassword?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  resetCustomerPassword: string;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "CustomerUser";
    id: string;
    name?: string | null;
    email?: string | null;
    phone?: string | null;
  } | null;
};

export type LoginQueryVariables = Exact<{
  storeId: Scalars["String"]["input"];
  phoneOrEmail: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
}>;

export type LoginQuery = {
  __typename?: "Query";
  customerLoginV2?: string | null;
};

export const SocialIntegrationProductFragmentDoc = gql`
  fragment SocialIntegrationProduct on Product {
    id
    title
    descriptionHtml
    shortDescription
    isInStock
    handle
    options {
      __typename
      id
      name
      values {
        __typename
        id
        name
        handle
        locale
        translations {
          name
        }
      }
      handle
      position
      locale
    }
    collections {
      nodes {
        id
        title
        description
        handle
      }
    }
    variants {
      nodes {
        id
        compareAtPrice {
          amount
          currencyCode
        }
        selectedOptions {
          option {
            id
            name
            handle
          }
          value {
            id
            name
          }
        }
        price {
          amount
          currencyCode
        }
        title
        quantity
        image {
          src
        }
        updatedAt
      }
    }
    images {
      src
    }
    updatedAt
  }
`;
export const SocialStoreDetailsFragmentDoc = gql`
  fragment SocialStoreDetails on Store {
    id
    name
    updatedAt
    supportedLocales
    description
    shippingZones {
      nodes {
        shippingRates {
          name
          cost {
            amount
            currencyCode
          }
        }
        id
        countries {
          nodes {
            id
            name
            code2
            code3
          }
        }
      }
    }
    staticPages {
      handle
    }
    collections {
      nodes {
        handle
        updatedAt
        isVisible
      }
    }
  }
`;
export const StoreBasicInfoFragmentDoc = gql`
  fragment StoreBasicInfo on Store {
    id
    name
    description
    currency
    supportedLocales
    fallbackLocale
    favIcon {
      id
      src
    }
    logo {
      size
      image {
        id
        src
      }
    }
    seo {
      title
      description
    }
    appearance {
      template {
        handle
      }
    }
    maintenanceMode {
      isEnabled
      message
    }
    location {
      address
    }
    contactInfo {
      phone
      email
    }
    redirects {
      id
      newUrl
      oldUrl
    }
  }
`;
export const StoreMenuItemFragmentDoc = gql`
  fragment StoreMenuItem on StoreMenuItem {
    id
    parentId
    title
    type
    locale
    locales
    handle
    icon
    link {
      type
      url
      resourceId
      openInNewTab
      staticPageType
      resource {
        __typename
        ... on Product {
          handle
        }
        ... on ProductCollection {
          handle
        }
        ... on StoreLegalPage {
          handle
        }
        ... on StorePage {
          handle
        }
      }
    }
  }
`;
export const MenuInfoFragmentDoc = gql`
  fragment MenuInfo on StoreMenu {
    id
    title
    handle
    items {
      ...StoreMenuItem
    }
  }
  ${StoreMenuItemFragmentDoc}
`;
export const InfoBarInfoFragmentDoc = gql`
  fragment InfoBarInfo on InfoBar {
    text
    background
    translations {
      text
      locale
    }
    locales
    locale
    isActive
    isDismissible
  }
`;
export const ImageFragmentDoc = gql`
  fragment Image on Image {
    id
    altText
    src
    width
    height
    format
  }
`;
export const MoneyFragmentDoc = gql`
  fragment Money on Money {
    amount
    currencyCode
  }
`;
export const DiscountVariantFragmentDoc = gql`
  fragment DiscountVariant on ProductVariant {
    id
  }
`;
export const DiscountCollectionFragmentDoc = gql`
  fragment DiscountCollection on ProductCollection {
    id
  }
`;
export const BuyXGetYDiscountFragmentDoc = gql`
  fragment BuyXGetYDiscount on BuyXGetYDiscount {
    customerBuys {
      value {
        ...Money
      }
      quantity
      itemsType
      items {
        productVariantIds
        collectionIds
      }
    }
    customerGets {
      quantity
      percentage
      itemsType
      items {
        productVariants {
          ...DiscountVariant
        }
        collections {
          ...DiscountCollection
        }
      }
    }
  }
  ${MoneyFragmentDoc}
  ${DiscountVariantFragmentDoc}
  ${DiscountCollectionFragmentDoc}
`;
export const AmountOffDiscountFragmentDoc = gql`
  fragment AmountOffDiscount on AmountOff {
    customerBuys {
      items {
        productVariantIds
        collectionIds
        collections {
          ...DiscountCollection
        }
        productVariants {
          ...DiscountVariant
        }
      }
      itemsType
    }
    discount {
      amount
      percentage
    }
  }
  ${DiscountCollectionFragmentDoc}
  ${DiscountVariantFragmentDoc}
`;
export const AutomaticDiscountFragmentDoc = gql`
  fragment AutomaticDiscount on Discount {
    id
    title
    customerBuys {
      value {
        ...Money
      }
    }
    appliedOn
    percentage
    amount {
      ...Money
    }
    status
    BuyXGetYDiscount {
      ...BuyXGetYDiscount
    }
    amountOff {
      ...AmountOffDiscount
    }
    translations {
      title
      locale
    }
  }
  ${MoneyFragmentDoc}
  ${BuyXGetYDiscountFragmentDoc}
  ${AmountOffDiscountFragmentDoc}
`;
export const StoreDetailsFragmentDoc = gql`
  fragment StoreDetails on Store {
    id
    name
    description
    currency
    defaultLocale
    locales
    locale
    fallbackLocale
    supportedLocales
    wuiltShipment {
      isActive
      activatedProviders
    }
    userId
    adminContactInfo {
      name
      email
    }
    location {
      address
    }
    socialMedia {
      name
      link
    }
    seo {
      title
      description
    }
    legalPages {
      id
      name
      title
      handle
    }
    appearance {
      template {
        handle
      }
      colors {
        primary
        inkNormal
        inkSubdued
      }
      fonts {
        type
        fontFamily
        category
        typeFace
        weight
        isItalic
        url
        locale
        locales
      }
      footer {
        activeFooter
        startPart
        endPart
        customPart
        locale
        locales
      }
      menus {
        ...MenuInfo
      }
      infoBar {
        ...InfoBarInfo
      }
      productDisplay {
        buyNowButton {
          display
          backgroundColor
          fontColor
          borderColor
        }
        productImageZoomBehavior
      }
    }
    contactInfo {
      email
      phone
    }
    maintenanceMode {
      isEnabled
      message
    }
    logo {
      image {
        ...Image
      }
      size
    }
    owner {
      createdAt
    }
    subscription {
      createdAt
      status
    }
    favIcon {
      id
      src
    }
    isExpired
    analyticalAccounts {
      name
      id
      isActive
    }
    automaticDiscounts {
      ...AutomaticDiscount
    }
    areReviewsActivated
    storeReviewSettings {
      id
      enableGuestReviews
      showCustomerAvatar
      showOverAllRating
      showReviewDate
      showReviewsCount
      showStars
      starsColor
    }
    redirects {
      id
      newUrl
      oldUrl
    }
    customCheckoutSetting {
      id
      identifier
      name
      secondaryPhone
      postalCode
      notesToSeller
      checkoutNote {
        placement
        enabled
        content
      }
    }
    domain {
      domainName
    }
    integrationScriptControls {
      ... on GameballScriptControls {
        provider
        apiKey
      }
      ... on KlaviyoScriptControls {
        provider
        publicKey
      }
      ... on OptimonkScriptControls {
        provider
        accountId
      }
      ... on MailchimpScriptControls {
        provider
        apiKey
      }
      ... on NoScriptControls {
        provider
      }
    }
  }
  ${MenuInfoFragmentDoc}
  ${InfoBarInfoFragmentDoc}
  ${ImageFragmentDoc}
  ${AutomaticDiscountFragmentDoc}
`;
export const TaxFragmentDoc = gql`
  fragment Tax on Tax {
    percentageOff
  }
`;
export const OrderItemDiscountInfoFragmentDoc = gql`
  fragment OrderItemDiscountInfo on OrderItemDiscountDetails {
    data {
      id
      title
      translations {
        title
        locale
      }
    }
    discountSource
    quantity
    info {
      percentage
      fixed {
        ...Money
      }
    }
    perItem {
      ...Money
    }
    total {
      ...Money
    }
  }
  ${MoneyFragmentDoc}
`;
export const VariantInfoFragmentDoc = gql`
  fragment VariantInfo on ProductVariant {
    id
    title
    quantity
    trackQuantity
    sku
    cartLimitsEnabled
    minPerCart
    maxPerCart
    product {
      id
      title
      status
      images {
        ...Image
      }
      taxable
      productTax
    }
    price {
      ...Money
    }
    compareAtPrice {
      ...Money
    }
    image {
      ...Image
    }
    selectedOptions {
      option {
        id
        name
      }
      value {
        id
        name
      }
    }
  }
  ${ImageFragmentDoc}
  ${MoneyFragmentDoc}
`;
export const CategoryInfoFragmentDoc = gql`
  fragment CategoryInfo on CustomProductCategory {
    id
    categoryType
    name
    isRequired
    image {
      ...Image
    }
    variants {
      preselected
      variant {
        ...VariantInfo
      }
    }
    settings {
      maxSelect
      maxQuantity
    }
  }
  ${ImageFragmentDoc}
  ${VariantInfoFragmentDoc}
`;
export const OrderVariantSnapshotFragmentDoc = gql`
  fragment OrderVariantSnapshot on ProductVariantSnapshot {
    id
    sku
    quantity
    image {
      ...Image
    }
    productId
    productTitle
    productImages {
      ...Image
    }
  }
  ${ImageFragmentDoc}
`;
export const OrderCategoryInfoFragmentDoc = gql`
  fragment OrderCategoryInfo on CustomBuildCategory {
    category {
      ...CategoryInfo
    }
    selectedVariants {
      variantSnapshot {
        ...OrderVariantSnapshot
      }
      price {
        ...Money
      }
      quantity
      selectedOptions {
        value
        name
      }
    }
  }
  ${CategoryInfoFragmentDoc}
  ${OrderVariantSnapshotFragmentDoc}
  ${MoneyFragmentDoc}
`;
export const OrderReceiptFragmentDoc = gql`
  fragment OrderReceipt on OrderReceipt {
    subtotal {
      ...Money
    }
    automaticDiscount {
      ...Money
    }
    discount {
      ...Money
    }
    tax {
      ...Money
    }
    shipping {
      ...Money
    }
    total {
      ...Money
    }
  }
  ${MoneyFragmentDoc}
`;
export const PaymentIntentInfoFragmentDoc = gql`
  fragment PaymentIntentInfo on PaymentIntent {
    id
    status
    embedUrl
    paymentMethod
    paymentMethods
  }
`;
export const CheckoutErrorsFragmentDoc = gql`
  fragment CheckoutErrors on CheckoutError {
    code
    resourceId
    params {
      requestedQuantity
      availableQuantity
      requestedPrice
      availablePrice
      requestedCurrency
      availableCurrency
      message
    }
  }
`;
export const OrderInfoFragmentDoc = gql`
  fragment OrderInfo on Order {
    id
    orderSerial
    status
    paymentMethod
    createdAt
    paymentStatus
    fulfillmentStatus
    externalActionMessage
    notes
    wuiltShipmentProvider
    customer {
      name
      email
      phone
      isSubscribedToNewsLetter
    }
    shipmentDetails {
      trackingURL
      orderTrackingNumber
    }
    shippingAddress {
      phone
      addressLine1
      addressLine2
      postalCode
      areaSnapshot {
        countryName
        stateName
        cityName
        regionName
      }
    }
    billingAddress {
      phone
      addressLine1
      addressLine2
      postalCode
      areaSnapshot {
        countryName
        stateName
        cityName
        regionName
      }
    }
    items {
      id
      quantity
      title
      subtotal {
        ...Money
      }
      productSnapshot {
        id
        title
        handle
        collectionIds
        integrationProvider {
          provider
        }
        images {
          ...Image
        }
      }
      price {
        ...Money
      }
      discount {
        ...OrderItemDiscountInfo
      }
      discounts {
        ...OrderItemDiscountInfo
      }
      ... on CustomItem {
        categories {
          ...OrderCategoryInfo
        }
      }
      ... on SimpleItem {
        productSnapshot {
          id
        }
        selectedOptions {
          value
          name
        }
        variantSnapshot {
          ...OrderVariantSnapshot
        }
      }
    }
    shippingRateName
    shippingRateCost {
      ...Money
    }
    promoCodeSnapshot {
      code
      percentageOff
      fixedAmount
      type
    }
    receipt {
      ...OrderReceipt
    }
    discounts {
      id
      type
      title
      appliedOn
      percentage
      amount {
        ...Money
      }
    }
    paymentIntent {
      ...PaymentIntentInfo
    }
    reviews {
      productId
    }
    errors {
      ...CheckoutErrors
    }
  }
  ${MoneyFragmentDoc}
  ${ImageFragmentDoc}
  ${OrderItemDiscountInfoFragmentDoc}
  ${OrderCategoryInfoFragmentDoc}
  ${OrderVariantSnapshotFragmentDoc}
  ${OrderReceiptFragmentDoc}
  ${PaymentIntentInfoFragmentDoc}
  ${CheckoutErrorsFragmentDoc}
`;
export const AddressInfoFragmentDoc = gql`
  fragment AddressInfo on CustomerAddress {
    id
    addressLine1
    addressLine2
    secondPhone
    isDefault
    postalCode
    notes
    state {
      name
      id
    }
    country {
      name
      id
    }
    city {
      name
      id
    }
    region {
      name
      id
    }
  }
`;
export const UserOrderInfoFragmentDoc = gql`
  fragment UserOrderInfo on Order {
    id
    orderSerial
    createdAt
    paymentStatus
    fulfillmentStatus
    wuiltShipmentProvider
    shipmentDetails {
      trackingURL
      orderTrackingNumber
    }
    totalPrice {
      amount
      currencyCode
    }
  }
`;
export const CustomerInfoFragmentDoc = gql`
  fragment CustomerInfo on CustomerUser {
    storeId
    id
    name
    email
    phone
    updatedAt
    createdAt
    isGuest
    totalSpending {
      ...Money
    }
  }
  ${MoneyFragmentDoc}
`;
export const HeroSliderFragmentDoc = gql`
  fragment HeroSlider on StoreHomeHero {
    id
    image {
      ...Image
    }
    mobileImage {
      ...Image
    }
    align
    headingText
    subHeadingColor
    subHeadingSize
    subHeadingText
    buttons {
      text
      link {
        type
        url
        resourceId
        resource {
          __typename
          ... on Product {
            handle
          }
          ... on ProductCollection {
            handle
          }
          ... on StorePage {
            handle
          }
        }
      }
    }
  }
  ${ImageFragmentDoc}
`;
export const StoreAboutFragmentDoc = gql`
  fragment StoreAbout on AboutType {
    title
    content
    isActive
  }
`;
export const StoreHomeAppearanceFragmentDoc = gql`
  fragment StoreHomeAppearance on StoreAppearance {
    heroSlider {
      ...HeroSlider
    }
    infoBar {
      ...InfoBarInfo
    }
    about {
      ...StoreAbout
    }
  }
  ${HeroSliderFragmentDoc}
  ${InfoBarInfoFragmentDoc}
  ${StoreAboutFragmentDoc}
`;
export const SelectedOptionsFragmentDoc = gql`
  fragment SelectedOptions on SelectedOption {
    option {
      id
      name
    }
    value {
      id
      name
    }
  }
`;
export const DiscountFragmentDoc = gql`
  fragment Discount on CustomProductDiscount {
    percent
    fixed {
      ...Money
    }
  }
  ${MoneyFragmentDoc}
`;
export const ProductBasicInfoFragmentDoc = gql`
  fragment ProductBasicInfo on Product {
    id
    title
    handle
    type
    isInStock
    taxable
    productTax
    source
    integrationProvider {
      provider
    }
    discount {
      ...Discount
    }
    initialPrice {
      ...Money
    }
    images {
      ...Image
    }
    variants(first: 1) {
      nodes {
        ...VariantInfo
      }
    }
    collections {
      totalCount
      nodes {
        id
        title
        handle
      }
    }
    reviewsStatistics {
      average
      total
    }
  }
  ${DiscountFragmentDoc}
  ${MoneyFragmentDoc}
  ${ImageFragmentDoc}
  ${VariantInfoFragmentDoc}
`;
export const StorePageSectionsFragmentDoc = gql`
  fragment StorePageSections on StorePageSection {
    id
    storeId
    header
    displayAs
    locale
    translations {
      header
      content
      locale
    }
    showSectionHeader
    stretchToFullWidth
    itemsPerRow
    background {
      type
      color
    }
    body {
      ... on SectionBanners {
        type
        banners {
          id
          mobileImage {
            ...Image
          }
          image {
            ...Image
          }
          bannerLink {
            type
            resourceId
            resource {
              ... on Product {
                id
                title
                handle
                images {
                  ...Image
                }
              }
              ... on ProductCollection {
                id
                collectionTitle: title
                handle
                image {
                  ...Image
                }
              }
              ... on StorePage {
                handle
              }
            }
            url
          }
        }
      }
      ... on SectionContent {
        type
        content
      }
      ... on SectionVideos {
        type
        videosUrls
      }
      ... on SectionCollectionsRow {
        id
        storeId
        type
        header
        displayAs
        locale
        translations {
          header
          locale
        }
        background {
          type
          color
        }
        showSectionHeader
        stretchToFullWidth
        showItemName
        itemsPerRow
        collections {
          id
          handle
          title
          productsCount
          shortDescription
          seo {
            description
          }
          image {
            ...Image
          }
        }
      }
      ... on SectionProductsRow {
        id
        storeId
        type
        header
        displayAs
        locale
        translations {
          header
          locale
        }
        background {
          type
          color
        }
        showSectionHeader
        stretchToFullWidth
        itemsPerRow
        fromCollection {
          id
          handle
        }
        products {
          ...ProductBasicInfo
        }
        productsDisplayNumber
      }
      ... on SectionAttributesRow {
        id
        storeId
        type
        header
        displayAs
        locale
        translations {
          header
          locale
        }
        background {
          type
          color
        }
        showSectionHeader
        stretchToFullWidth
        showItemName
        itemsPerRow
        attributeValues {
          id
          handle
          name
          image {
            ...Image
          }
          attribute {
            id
          }
        }
      }
    }
  }
  ${ImageFragmentDoc}
  ${ProductBasicInfoFragmentDoc}
`;
export const ProductOptionsInfoFragmentDoc = gql`
  fragment ProductOptionsInfo on ProductOption {
    name
    values {
      id
      name
    }
  }
`;
export const CartProductInfoFragmentDoc = gql`
  fragment CartProductInfo on Product {
    id
    type
    locale
    title
    type
    descriptionHtml
    shortDescription
    handle
    isInStock
    taxable
    productTax
    integrationProvider {
      provider
    }
    collections {
      totalCount
      nodes {
        title
      }
    }
    initialPrice {
      ...Money
    }
    images {
      ...Image
    }
    categories {
      ...CategoryInfo
    }
    options {
      ...ProductOptionsInfo
    }
    status
  }
  ${MoneyFragmentDoc}
  ${ImageFragmentDoc}
  ${CategoryInfoFragmentDoc}
  ${ProductOptionsInfoFragmentDoc}
`;
export const SimpleVariantInfoFragmentDoc = gql`
  fragment SimpleVariantInfo on ProductVariant {
    id
    title
    quantity
    trackQuantity
    sku
    cartLimitsEnabled
    minPerCart
    maxPerCart
    price {
      ...Money
    }
    compareAtPrice {
      ...Money
    }
    image {
      ...Image
    }
    selectedOptions {
      option {
        id
        name
      }
      value {
        id
        name
      }
    }
  }
  ${MoneyFragmentDoc}
  ${ImageFragmentDoc}
`;
export const CollectionInfoFragmentDoc = gql`
  fragment CollectionInfo on ProductCollection {
    id
    locale
    title
    handle
    shortDescription
    productsCount
    image {
      ...Image
    }
    seo {
      title
      description
    }
  }
  ${ImageFragmentDoc}
`;
export const ProductAttributesInfoFragmentDoc = gql`
  fragment ProductAttributesInfo on ProductAttribute {
    id
    name
    values {
      id
      name
    }
  }
`;
export const ProductInfoFragmentDoc = gql`
  fragment ProductInfo on Product {
    id
    type
    locale
    title
    type
    descriptionHtml
    shortDescription
    handle
    isInStock
    taxable
    productTax
    integrationProvider {
      provider
    }
    collections {
      totalCount
      nodes {
        ...CollectionInfo
      }
    }
    initialPrice {
      ...Money
    }
    images {
      ...Image
    }
    categories {
      ...CategoryInfo
    }
    variants {
      nodes {
        ...VariantInfo
      }
    }
    discount {
      ...Discount
    }
    attributes {
      ...ProductAttributesInfo
    }
    options {
      ...ProductOptionsInfo
    }
    reviewsStatistics {
      average
      total
    }
    status
  }
  ${CollectionInfoFragmentDoc}
  ${MoneyFragmentDoc}
  ${ImageFragmentDoc}
  ${CategoryInfoFragmentDoc}
  ${VariantInfoFragmentDoc}
  ${DiscountFragmentDoc}
  ${ProductAttributesInfoFragmentDoc}
  ${ProductOptionsInfoFragmentDoc}
`;
export const CartItemsFragmentDoc = gql`
  fragment CartItems on Cart {
    items {
      ... on CartSimpleItem {
        id
        product {
          ...CartProductInfo
        }
        subtotal {
          ...Money
        }
        price {
          ...Money
        }
        totalPrice {
          ...Money
        }
        quantity
        variant {
          ...SimpleVariantInfo
        }
        discount {
          ...OrderItemDiscountInfo
        }
        discounts {
          ...OrderItemDiscountInfo
        }
      }
      ... on CartCustomItem {
        id
        quantity
        product {
          ...CartProductInfo
        }
        ... on CartCustomItem {
          id
          quantity
          product {
            ...ProductInfo
          }
          subtotal {
            ...Money
          }
          totalPrice {
            ...Money
          }
          price {
            ...Money
          }
          discount {
            discountSource
            perItem {
              ...Money
            }
            total {
              ...Money
            }
            info {
              fixed {
                ...Money
              }
              percentage
            }
          }
          categories {
            category {
              id
              categoryType
              name
              isRequired
              maxSelect
              maxQuantity
              image {
                ...Image
              }
            }
            selectedVariants {
              id
              price {
                ...Money
              }
              quantity
              subtotal {
                ...Money
              }
              variant {
                ...VariantInfo
              }
            }
          }
        }
      }
    }
  }
  ${CartProductInfoFragmentDoc}
  ${MoneyFragmentDoc}
  ${SimpleVariantInfoFragmentDoc}
  ${OrderItemDiscountInfoFragmentDoc}
  ${ProductInfoFragmentDoc}
  ${ImageFragmentDoc}
  ${VariantInfoFragmentDoc}
`;
export const PromoCodeFragmentDoc = gql`
  fragment promoCode on PromoCode {
    id
    type
    code
    status
    percentageOff
    fixedAmount
    isArchived
    minimumOrderAmountToApply
    schedule {
      start
      end
    }
  }
`;
export const CartReceiptFragmentDoc = gql`
  fragment CartReceipt on CartReceipt {
    subtotal {
      ...Money
    }
    automaticDiscount {
      ...Money
    }
    discount {
      ...Money
    }
    tax {
      ...Money
    }
    shipping {
      ...Money
    }
    total {
      ...Money
    }
  }
  ${MoneyFragmentDoc}
`;
export const CartShippingDetailsAreaFragmentDoc = gql`
  fragment CartShippingDetailsArea on CartShippingDetailsArea {
    countryId
    stateId
    cityId
    regionId
    country {
      name
    }
    state {
      name
    }
    city {
      name
    }
    region {
      name
    }
  }
`;
export const CartShippingDetailsFragmentDoc = gql`
  fragment CartShippingDetails on CartShippingDetails {
    addressLine1
    addressLine2
    id
    secondPhone
    postalCode
    notes
    area {
      ...CartShippingDetailsArea
    }
  }
  ${CartShippingDetailsAreaFragmentDoc}
`;
export const CartContactInfoFragmentDoc = gql`
  fragment CartContactInfo on CartContactInfo {
    name
    email
    phone
  }
`;
export const CartCustomerFragmentDoc = gql`
  fragment CartCustomer on CustomerUser {
    id
    name
    email
    phone
    isGuest
    addresses {
      addressLine1
      addressLine2
      id
      phone
      secondPhone
      postalCode
      notes
      isDefault
    }
  }
`;
export const CartErrorsFragmentDoc = gql`
  fragment CartErrors on CartError {
    params {
      resourceId
      requestedQuantity
      availableQuantity
      requestedPrice
      availablePrice
      requestedCurrency
      availableCurrency
      message
    }
    code
  }
`;
export const CartShippingRateFragmentDoc = gql`
  fragment CartShippingRate on CartShippingRate {
    cost {
      ...Money
    }
    details {
      id
      storeId
      name
      isArchived
      locale
      locales
      translations {
        name
        locale
      }
    }
  }
  ${MoneyFragmentDoc}
`;
export const CartFragmentDoc = gql`
  fragment Cart on Cart {
    id
    ...CartItems
    isReserved
    recoveryId
    reservedAt
    createdAt
    updatedAt
    sessionId
    status
    cartStep
    lastStep
    refCode
    paymentIntentId
    promoCode {
      ...promoCode
    }
    receipt {
      ...CartReceipt
    }
    shippingDetails {
      ...CartShippingDetails
    }
    shippingDiscount {
      ...AutomaticDiscount
    }
    contactInfo {
      ...CartContactInfo
    }
    customer {
      ...CartCustomer
    }
    cartErrors {
      ...CartErrors
    }
    shippingRate {
      ...CartShippingRate
    }
    paymentIntent {
      id
      status
      embedUrl
      paymentMethod
      paymentMethods
      key
    }
  }
  ${CartItemsFragmentDoc}
  ${PromoCodeFragmentDoc}
  ${CartReceiptFragmentDoc}
  ${CartShippingDetailsFragmentDoc}
  ${AutomaticDiscountFragmentDoc}
  ${CartContactInfoFragmentDoc}
  ${CartCustomerFragmentDoc}
  ${CartErrorsFragmentDoc}
  ${CartShippingRateFragmentDoc}
`;
export const PaymentDetailsFragmentDoc = gql`
  fragment PaymentDetails on Cart {
    paymentIntent {
      id
      status
      embedUrl
      paymentMethod
      paymentMethods
      key
    }
    paymentIntentId
  }
`;
export const PaymentIntentDetailsFragmentDoc = gql`
  fragment PaymentIntentDetails on PaymentIntent {
    id
    status
    embedUrl
    paymentMethod
    paymentMethods
    key
    cartId
  }
`;
export const OrderByCartIdFragmentDoc = gql`
  fragment OrderByCartId on Order {
    billingAddress {
      addressLine1
      addressLine2
      phone
      secondPhone
      postalCode
      notes
      areaSnapshot {
        cityName
        regionName
        countryName
        stateName
      }
    }
    shippingAddress {
      addressLine1
      addressLine2
      phone
      secondPhone
      postalCode
      notes
      areaSnapshot {
        cityName
        regionName
        countryName
        stateName
      }
    }
    orderSerial
    paymentMethod
    totalPrice {
      amount
      currencyCode
    }
    customer {
      name
      email
      phone
      isSubscribedToNewsLetter
    }
    status
    id
    wuiltShipmentProvider
    externalActionMessage
    receipt {
      subtotal {
        amount
        currencyCode
      }
      discount {
        amount
        currencyCode
      }
      tax {
        amount
        currencyCode
      }
      shipping {
        amount
        currencyCode
      }
      total {
        amount
        currencyCode
      }
      automaticDiscount {
        amount
        currencyCode
      }
    }
    promoCodeSnapshot {
      code
      percentageOff
      fixedAmount
      type
    }
    createdAt
    shippingRateName
    shippingRateCost {
      ...Money
    }
    items {
      id
      quantity
      title
      subtotal {
        ...Money
      }
      productSnapshot {
        id
        title
        handle
        collectionIds
        integrationProvider {
          provider
        }
        images {
          ...Image
        }
      }
      price {
        ...Money
      }
      discount {
        ...OrderItemDiscountInfo
      }
      discounts {
        ...OrderItemDiscountInfo
      }
      ... on CustomItem {
        categories {
          ...OrderCategoryInfo
        }
      }
      ... on SimpleItem {
        productSnapshot {
          id
        }
        selectedOptions {
          value
          name
        }
        variantSnapshot {
          ...OrderVariantSnapshot
        }
      }
    }
    discounts {
      id
      type
      title
      appliedOn
      percentage
      amount {
        ...Money
      }
    }
  }
  ${MoneyFragmentDoc}
  ${ImageFragmentDoc}
  ${OrderItemDiscountInfoFragmentDoc}
  ${OrderCategoryInfoFragmentDoc}
  ${OrderVariantSnapshotFragmentDoc}
`;
export const SocialStoreDetailsByHandleDocument = gql`
  query SocialStoreDetailsByHandle($storeHandle: String!) {
    store: storeByHandle(handle: $storeHandle) {
      ...SocialStoreDetails
    }
  }
  ${SocialStoreDetailsFragmentDoc}
`;

/**
 * __useSocialStoreDetailsByHandleQuery__
 *
 * To run a query within a React component, call `useSocialStoreDetailsByHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useSocialStoreDetailsByHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSocialStoreDetailsByHandleQuery({
 *   variables: {
 *      storeHandle: // value for 'storeHandle'
 *   },
 * });
 */
export function useSocialStoreDetailsByHandleQuery(
  baseOptions: Apollo.QueryHookOptions<
    SocialStoreDetailsByHandleQuery,
    SocialStoreDetailsByHandleQueryVariables
  > &
    (
      | { variables: SocialStoreDetailsByHandleQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SocialStoreDetailsByHandleQuery,
    SocialStoreDetailsByHandleQueryVariables
  >(SocialStoreDetailsByHandleDocument, options);
}
export function useSocialStoreDetailsByHandleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SocialStoreDetailsByHandleQuery,
    SocialStoreDetailsByHandleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SocialStoreDetailsByHandleQuery,
    SocialStoreDetailsByHandleQueryVariables
  >(SocialStoreDetailsByHandleDocument, options);
}
export function useSocialStoreDetailsByHandleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SocialStoreDetailsByHandleQuery,
    SocialStoreDetailsByHandleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SocialStoreDetailsByHandleQuery,
    SocialStoreDetailsByHandleQueryVariables
  >(SocialStoreDetailsByHandleDocument, options);
}
export type SocialStoreDetailsByHandleQueryHookResult = ReturnType<
  typeof useSocialStoreDetailsByHandleQuery
>;
export type SocialStoreDetailsByHandleLazyQueryHookResult = ReturnType<
  typeof useSocialStoreDetailsByHandleLazyQuery
>;
export type SocialStoreDetailsByHandleSuspenseQueryHookResult = ReturnType<
  typeof useSocialStoreDetailsByHandleSuspenseQuery
>;
export type SocialStoreDetailsByHandleQueryResult = Apollo.QueryResult<
  SocialStoreDetailsByHandleQuery,
  SocialStoreDetailsByHandleQueryVariables
>;
export const SocialStoreDetailsByDomainDocument = gql`
  query SocialStoreDetailsByDomain($storeDomain: String!) {
    store: storeByDomain(domainName: $storeDomain) {
      ...SocialStoreDetails
    }
  }
  ${SocialStoreDetailsFragmentDoc}
`;

/**
 * __useSocialStoreDetailsByDomainQuery__
 *
 * To run a query within a React component, call `useSocialStoreDetailsByDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useSocialStoreDetailsByDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSocialStoreDetailsByDomainQuery({
 *   variables: {
 *      storeDomain: // value for 'storeDomain'
 *   },
 * });
 */
export function useSocialStoreDetailsByDomainQuery(
  baseOptions: Apollo.QueryHookOptions<
    SocialStoreDetailsByDomainQuery,
    SocialStoreDetailsByDomainQueryVariables
  > &
    (
      | { variables: SocialStoreDetailsByDomainQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SocialStoreDetailsByDomainQuery,
    SocialStoreDetailsByDomainQueryVariables
  >(SocialStoreDetailsByDomainDocument, options);
}
export function useSocialStoreDetailsByDomainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SocialStoreDetailsByDomainQuery,
    SocialStoreDetailsByDomainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SocialStoreDetailsByDomainQuery,
    SocialStoreDetailsByDomainQueryVariables
  >(SocialStoreDetailsByDomainDocument, options);
}
export function useSocialStoreDetailsByDomainSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SocialStoreDetailsByDomainQuery,
    SocialStoreDetailsByDomainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SocialStoreDetailsByDomainQuery,
    SocialStoreDetailsByDomainQueryVariables
  >(SocialStoreDetailsByDomainDocument, options);
}
export type SocialStoreDetailsByDomainQueryHookResult = ReturnType<
  typeof useSocialStoreDetailsByDomainQuery
>;
export type SocialStoreDetailsByDomainLazyQueryHookResult = ReturnType<
  typeof useSocialStoreDetailsByDomainLazyQuery
>;
export type SocialStoreDetailsByDomainSuspenseQueryHookResult = ReturnType<
  typeof useSocialStoreDetailsByDomainSuspenseQuery
>;
export type SocialStoreDetailsByDomainQueryResult = Apollo.QueryResult<
  SocialStoreDetailsByDomainQuery,
  SocialStoreDetailsByDomainQueryVariables
>;
export const SocialIntegrationProductsDocument = gql`
  query SocialIntegrationProducts(
    $filter: ProductsFilterInput
    $connection: ProductsConnectionInput
  ) {
    products(filter: $filter, connection: $connection) {
      nodes {
        ...SocialIntegrationProduct
      }
    }
  }
  ${SocialIntegrationProductFragmentDoc}
`;

/**
 * __useSocialIntegrationProductsQuery__
 *
 * To run a query within a React component, call `useSocialIntegrationProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSocialIntegrationProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSocialIntegrationProductsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      connection: // value for 'connection'
 *   },
 * });
 */
export function useSocialIntegrationProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SocialIntegrationProductsQuery,
    SocialIntegrationProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SocialIntegrationProductsQuery,
    SocialIntegrationProductsQueryVariables
  >(SocialIntegrationProductsDocument, options);
}
export function useSocialIntegrationProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SocialIntegrationProductsQuery,
    SocialIntegrationProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SocialIntegrationProductsQuery,
    SocialIntegrationProductsQueryVariables
  >(SocialIntegrationProductsDocument, options);
}
export function useSocialIntegrationProductsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SocialIntegrationProductsQuery,
    SocialIntegrationProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SocialIntegrationProductsQuery,
    SocialIntegrationProductsQueryVariables
  >(SocialIntegrationProductsDocument, options);
}
export type SocialIntegrationProductsQueryHookResult = ReturnType<
  typeof useSocialIntegrationProductsQuery
>;
export type SocialIntegrationProductsLazyQueryHookResult = ReturnType<
  typeof useSocialIntegrationProductsLazyQuery
>;
export type SocialIntegrationProductsSuspenseQueryHookResult = ReturnType<
  typeof useSocialIntegrationProductsSuspenseQuery
>;
export type SocialIntegrationProductsQueryResult = Apollo.QueryResult<
  SocialIntegrationProductsQuery,
  SocialIntegrationProductsQueryVariables
>;
export const ValidateSocialIntegrationInstallationDocument = gql`
  query ValidateSocialIntegrationInstallation(
    $storeId: ID!
    $provider: ProviderName!
    $authHeader: String!
  ) {
    validateSocialIntegrationInstallation(
      provider: $provider
      storeId: $storeId
      authHeader: $authHeader
    )
  }
`;

/**
 * __useValidateSocialIntegrationInstallationQuery__
 *
 * To run a query within a React component, call `useValidateSocialIntegrationInstallationQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateSocialIntegrationInstallationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateSocialIntegrationInstallationQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      provider: // value for 'provider'
 *      authHeader: // value for 'authHeader'
 *   },
 * });
 */
export function useValidateSocialIntegrationInstallationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidateSocialIntegrationInstallationQuery,
    ValidateSocialIntegrationInstallationQueryVariables
  > &
    (
      | {
          variables: ValidateSocialIntegrationInstallationQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ValidateSocialIntegrationInstallationQuery,
    ValidateSocialIntegrationInstallationQueryVariables
  >(ValidateSocialIntegrationInstallationDocument, options);
}
export function useValidateSocialIntegrationInstallationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidateSocialIntegrationInstallationQuery,
    ValidateSocialIntegrationInstallationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ValidateSocialIntegrationInstallationQuery,
    ValidateSocialIntegrationInstallationQueryVariables
  >(ValidateSocialIntegrationInstallationDocument, options);
}
export function useValidateSocialIntegrationInstallationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ValidateSocialIntegrationInstallationQuery,
    ValidateSocialIntegrationInstallationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ValidateSocialIntegrationInstallationQuery,
    ValidateSocialIntegrationInstallationQueryVariables
  >(ValidateSocialIntegrationInstallationDocument, options);
}
export type ValidateSocialIntegrationInstallationQueryHookResult = ReturnType<
  typeof useValidateSocialIntegrationInstallationQuery
>;
export type ValidateSocialIntegrationInstallationLazyQueryHookResult =
  ReturnType<typeof useValidateSocialIntegrationInstallationLazyQuery>;
export type ValidateSocialIntegrationInstallationSuspenseQueryHookResult =
  ReturnType<typeof useValidateSocialIntegrationInstallationSuspenseQuery>;
export type ValidateSocialIntegrationInstallationQueryResult =
  Apollo.QueryResult<
    ValidateSocialIntegrationInstallationQuery,
    ValidateSocialIntegrationInstallationQueryVariables
  >;
export const SubmitStoreContactFormDocument = gql`
  mutation submitStoreContactForm($input: SubmitStoreContactFormInput!) {
    submitStoreContactForm(input: $input) {
      statusCode
      message
    }
  }
`;
export type SubmitStoreContactFormMutationFn = Apollo.MutationFunction<
  SubmitStoreContactFormMutation,
  SubmitStoreContactFormMutationVariables
>;

/**
 * __useSubmitStoreContactFormMutation__
 *
 * To run a mutation, you first call `useSubmitStoreContactFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitStoreContactFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitStoreContactFormMutation, { data, loading, error }] = useSubmitStoreContactFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitStoreContactFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitStoreContactFormMutation,
    SubmitStoreContactFormMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitStoreContactFormMutation,
    SubmitStoreContactFormMutationVariables
  >(SubmitStoreContactFormDocument, options);
}
export type SubmitStoreContactFormMutationHookResult = ReturnType<
  typeof useSubmitStoreContactFormMutation
>;
export type SubmitStoreContactFormMutationResult =
  Apollo.MutationResult<SubmitStoreContactFormMutation>;
export type SubmitStoreContactFormMutationOptions = Apollo.BaseMutationOptions<
  SubmitStoreContactFormMutation,
  SubmitStoreContactFormMutationVariables
>;
export const UpdateOrderPaymentMethodDocument = gql`
  mutation UpdateOrderPaymentMethod(
    $orderId: ID!
    $paymentMethod: PaymentMethodEnum
    $storeId: ID!
  ) {
    updateOrderPaymentMethod(
      orderId: $orderId
      paymentMethod: $paymentMethod
      storeId: $storeId
    ) {
      ...PaymentIntentInfo
    }
  }
  ${PaymentIntentInfoFragmentDoc}
`;
export type UpdateOrderPaymentMethodMutationFn = Apollo.MutationFunction<
  UpdateOrderPaymentMethodMutation,
  UpdateOrderPaymentMethodMutationVariables
>;

/**
 * __useUpdateOrderPaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateOrderPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderPaymentMethodMutation, { data, loading, error }] = useUpdateOrderPaymentMethodMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      paymentMethod: // value for 'paymentMethod'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useUpdateOrderPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrderPaymentMethodMutation,
    UpdateOrderPaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrderPaymentMethodMutation,
    UpdateOrderPaymentMethodMutationVariables
  >(UpdateOrderPaymentMethodDocument, options);
}
export type UpdateOrderPaymentMethodMutationHookResult = ReturnType<
  typeof useUpdateOrderPaymentMethodMutation
>;
export type UpdateOrderPaymentMethodMutationResult =
  Apollo.MutationResult<UpdateOrderPaymentMethodMutation>;
export type UpdateOrderPaymentMethodMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrderPaymentMethodMutation,
    UpdateOrderPaymentMethodMutationVariables
  >;
export const CancelPaymentDocument = gql`
  mutation CancelPayment($storeId: ID!, $cartId: ID!) {
    cancelPayment(storeId: $storeId, cartId: $cartId) {
      id
      status
      embedUrl
      paymentMethod
      paymentMethods
      key
    }
  }
`;
export type CancelPaymentMutationFn = Apollo.MutationFunction<
  CancelPaymentMutation,
  CancelPaymentMutationVariables
>;

/**
 * __useCancelPaymentMutation__
 *
 * To run a mutation, you first call `useCancelPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPaymentMutation, { data, loading, error }] = useCancelPaymentMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useCancelPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelPaymentMutation,
    CancelPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelPaymentMutation,
    CancelPaymentMutationVariables
  >(CancelPaymentDocument, options);
}
export type CancelPaymentMutationHookResult = ReturnType<
  typeof useCancelPaymentMutation
>;
export type CancelPaymentMutationResult =
  Apollo.MutationResult<CancelPaymentMutation>;
export type CancelPaymentMutationOptions = Apollo.BaseMutationOptions<
  CancelPaymentMutation,
  CancelPaymentMutationVariables
>;
export const ConfirmIntentDocument = gql`
  mutation ConfirmIntent($id: ID!, $storeId: ID!) {
    confirmPaymentIntent(id: $id, storeId: $storeId) {
      ...PaymentIntentInfo
    }
  }
  ${PaymentIntentInfoFragmentDoc}
`;
export type ConfirmIntentMutationFn = Apollo.MutationFunction<
  ConfirmIntentMutation,
  ConfirmIntentMutationVariables
>;

/**
 * __useConfirmIntentMutation__
 *
 * To run a mutation, you first call `useConfirmIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmIntentMutation, { data, loading, error }] = useConfirmIntentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useConfirmIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmIntentMutation,
    ConfirmIntentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmIntentMutation,
    ConfirmIntentMutationVariables
  >(ConfirmIntentDocument, options);
}
export type ConfirmIntentMutationHookResult = ReturnType<
  typeof useConfirmIntentMutation
>;
export type ConfirmIntentMutationResult =
  Apollo.MutationResult<ConfirmIntentMutation>;
export type ConfirmIntentMutationOptions = Apollo.BaseMutationOptions<
  ConfirmIntentMutation,
  ConfirmIntentMutationVariables
>;
export const AddCustomerAddressDocument = gql`
  mutation AddCustomerAddress(
    $customerId: ID!
    $input: AddCustomerAddressInput!
  ) {
    addCustomerAddress(customerId: $customerId, input: $input) {
      ...AddressInfo
    }
  }
  ${AddressInfoFragmentDoc}
`;
export type AddCustomerAddressMutationFn = Apollo.MutationFunction<
  AddCustomerAddressMutation,
  AddCustomerAddressMutationVariables
>;

/**
 * __useAddCustomerAddressMutation__
 *
 * To run a mutation, you first call `useAddCustomerAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomerAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomerAddressMutation, { data, loading, error }] = useAddCustomerAddressMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCustomerAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCustomerAddressMutation,
    AddCustomerAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddCustomerAddressMutation,
    AddCustomerAddressMutationVariables
  >(AddCustomerAddressDocument, options);
}
export type AddCustomerAddressMutationHookResult = ReturnType<
  typeof useAddCustomerAddressMutation
>;
export type AddCustomerAddressMutationResult =
  Apollo.MutationResult<AddCustomerAddressMutation>;
export type AddCustomerAddressMutationOptions = Apollo.BaseMutationOptions<
  AddCustomerAddressMutation,
  AddCustomerAddressMutationVariables
>;
export const UpdateCustomerAddressDocument = gql`
  mutation UpdateCustomerAddress(
    $customerId: ID!
    $addressId: ID!
    $input: UpdateCustomerAddressInput!
  ) {
    updateCustomerAddress(
      customerId: $customerId
      addressId: $addressId
      input: $input
    ) {
      ...AddressInfo
    }
  }
  ${AddressInfoFragmentDoc}
`;
export type UpdateCustomerAddressMutationFn = Apollo.MutationFunction<
  UpdateCustomerAddressMutation,
  UpdateCustomerAddressMutationVariables
>;

/**
 * __useUpdateCustomerAddressMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerAddressMutation, { data, loading, error }] = useUpdateCustomerAddressMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      addressId: // value for 'addressId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCustomerAddressMutation,
    UpdateCustomerAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCustomerAddressMutation,
    UpdateCustomerAddressMutationVariables
  >(UpdateCustomerAddressDocument, options);
}
export type UpdateCustomerAddressMutationHookResult = ReturnType<
  typeof useUpdateCustomerAddressMutation
>;
export type UpdateCustomerAddressMutationResult =
  Apollo.MutationResult<UpdateCustomerAddressMutation>;
export type UpdateCustomerAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerAddressMutation,
  UpdateCustomerAddressMutationVariables
>;
export const DeleteCustomerAddressDocument = gql`
  mutation DeleteCustomerAddress(
    $customerId: ID!
    $addressId: ID!
    $storeId: ID!
  ) {
    deleteCustomerAddress(
      customerId: $customerId
      addressId: $addressId
      storeId: $storeId
    )
  }
`;
export type DeleteCustomerAddressMutationFn = Apollo.MutationFunction<
  DeleteCustomerAddressMutation,
  DeleteCustomerAddressMutationVariables
>;

/**
 * __useDeleteCustomerAddressMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerAddressMutation, { data, loading, error }] = useDeleteCustomerAddressMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      addressId: // value for 'addressId'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useDeleteCustomerAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCustomerAddressMutation,
    DeleteCustomerAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCustomerAddressMutation,
    DeleteCustomerAddressMutationVariables
  >(DeleteCustomerAddressDocument, options);
}
export type DeleteCustomerAddressMutationHookResult = ReturnType<
  typeof useDeleteCustomerAddressMutation
>;
export type DeleteCustomerAddressMutationResult =
  Apollo.MutationResult<DeleteCustomerAddressMutation>;
export type DeleteCustomerAddressMutationOptions = Apollo.BaseMutationOptions<
  DeleteCustomerAddressMutation,
  DeleteCustomerAddressMutationVariables
>;
export const MakeDefaultAddressDocument = gql`
  mutation MakeDefaultAddress(
    $customerId: ID!
    $addressId: ID!
    $storeId: ID!
  ) {
    makeDefaultAddress(
      customerId: $customerId
      addressId: $addressId
      storeId: $storeId
    ) {
      ...AddressInfo
    }
  }
  ${AddressInfoFragmentDoc}
`;
export type MakeDefaultAddressMutationFn = Apollo.MutationFunction<
  MakeDefaultAddressMutation,
  MakeDefaultAddressMutationVariables
>;

/**
 * __useMakeDefaultAddressMutation__
 *
 * To run a mutation, you first call `useMakeDefaultAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeDefaultAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeDefaultAddressMutation, { data, loading, error }] = useMakeDefaultAddressMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      addressId: // value for 'addressId'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useMakeDefaultAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeDefaultAddressMutation,
    MakeDefaultAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MakeDefaultAddressMutation,
    MakeDefaultAddressMutationVariables
  >(MakeDefaultAddressDocument, options);
}
export type MakeDefaultAddressMutationHookResult = ReturnType<
  typeof useMakeDefaultAddressMutation
>;
export type MakeDefaultAddressMutationResult =
  Apollo.MutationResult<MakeDefaultAddressMutation>;
export type MakeDefaultAddressMutationOptions = Apollo.BaseMutationOptions<
  MakeDefaultAddressMutation,
  MakeDefaultAddressMutationVariables
>;
export const UpdateCustomerInfoDocument = gql`
  mutation updateCustomerInfo(
    $storeId: ID!
    $customerId: ID!
    $input: updateCustomerUserInput
  ) {
    updateCustomer(storeId: $storeId, customerId: $customerId, input: $input) {
      id
      name
      email
      phone
    }
  }
`;
export type UpdateCustomerInfoMutationFn = Apollo.MutationFunction<
  UpdateCustomerInfoMutation,
  UpdateCustomerInfoMutationVariables
>;

/**
 * __useUpdateCustomerInfoMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerInfoMutation, { data, loading, error }] = useUpdateCustomerInfoMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      customerId: // value for 'customerId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCustomerInfoMutation,
    UpdateCustomerInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCustomerInfoMutation,
    UpdateCustomerInfoMutationVariables
  >(UpdateCustomerInfoDocument, options);
}
export type UpdateCustomerInfoMutationHookResult = ReturnType<
  typeof useUpdateCustomerInfoMutation
>;
export type UpdateCustomerInfoMutationResult =
  Apollo.MutationResult<UpdateCustomerInfoMutation>;
export type UpdateCustomerInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerInfoMutation,
  UpdateCustomerInfoMutationVariables
>;
export const SubmitReviewDocument = gql`
  mutation SubmitReview($storeId: ID!, $review: CreateProductReviewInput!) {
    submitReview(storeId: $storeId, review: $review) {
      id
    }
  }
`;
export type SubmitReviewMutationFn = Apollo.MutationFunction<
  SubmitReviewMutation,
  SubmitReviewMutationVariables
>;

/**
 * __useSubmitReviewMutation__
 *
 * To run a mutation, you first call `useSubmitReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitReviewMutation, { data, loading, error }] = useSubmitReviewMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      review: // value for 'review'
 *   },
 * });
 */
export function useSubmitReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitReviewMutation,
    SubmitReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitReviewMutation,
    SubmitReviewMutationVariables
  >(SubmitReviewDocument, options);
}
export type SubmitReviewMutationHookResult = ReturnType<
  typeof useSubmitReviewMutation
>;
export type SubmitReviewMutationResult =
  Apollo.MutationResult<SubmitReviewMutation>;
export type SubmitReviewMutationOptions = Apollo.BaseMutationOptions<
  SubmitReviewMutation,
  SubmitReviewMutationVariables
>;
export const DeleteReviewDocument = gql`
  mutation DeleteReview($storeId: ID!, $reviewId: ID!) {
    deleteReview(storeId: $storeId, reviewId: $reviewId)
  }
`;
export type DeleteReviewMutationFn = Apollo.MutationFunction<
  DeleteReviewMutation,
  DeleteReviewMutationVariables
>;

/**
 * __useDeleteReviewMutation__
 *
 * To run a mutation, you first call `useDeleteReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReviewMutation, { data, loading, error }] = useDeleteReviewMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      reviewId: // value for 'reviewId'
 *   },
 * });
 */
export function useDeleteReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteReviewMutation,
    DeleteReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteReviewMutation,
    DeleteReviewMutationVariables
  >(DeleteReviewDocument, options);
}
export type DeleteReviewMutationHookResult = ReturnType<
  typeof useDeleteReviewMutation
>;
export type DeleteReviewMutationResult =
  Apollo.MutationResult<DeleteReviewMutation>;
export type DeleteReviewMutationOptions = Apollo.BaseMutationOptions<
  DeleteReviewMutation,
  DeleteReviewMutationVariables
>;
export const EditReviewDocument = gql`
  mutation EditReview(
    $storeId: ID!
    $reviewId: ID!
    $review: UpdateProductReviewInput!
  ) {
    editReview(storeId: $storeId, reviewId: $reviewId, review: $review) {
      id
    }
  }
`;
export type EditReviewMutationFn = Apollo.MutationFunction<
  EditReviewMutation,
  EditReviewMutationVariables
>;

/**
 * __useEditReviewMutation__
 *
 * To run a mutation, you first call `useEditReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReviewMutation, { data, loading, error }] = useEditReviewMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      reviewId: // value for 'reviewId'
 *      review: // value for 'review'
 *   },
 * });
 */
export function useEditReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditReviewMutation,
    EditReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditReviewMutation, EditReviewMutationVariables>(
    EditReviewDocument,
    options
  );
}
export type EditReviewMutationHookResult = ReturnType<
  typeof useEditReviewMutation
>;
export type EditReviewMutationResult =
  Apollo.MutationResult<EditReviewMutation>;
export type EditReviewMutationOptions = Apollo.BaseMutationOptions<
  EditReviewMutation,
  EditReviewMutationVariables
>;
export const DeleteAccountDocument = gql`
  mutation DeleteAccount($password: String!) {
    deleteAccount(password: $password)
  }
`;
export type DeleteAccountMutationFn = Apollo.MutationFunction<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
  >(DeleteAccountDocument, options);
}
export type DeleteAccountMutationHookResult = ReturnType<
  typeof useDeleteAccountMutation
>;
export type DeleteAccountMutationResult =
  Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;
export const AddCustomItemDocument = gql`
  mutation AddCustomItem($item: CustomItemInput!, $storeId: ID!) {
    customerActions(storeId: $storeId) {
      cart {
        addCustomItem(item: $item) {
          ...Cart
        }
      }
    }
  }
  ${CartFragmentDoc}
`;
export type AddCustomItemMutationFn = Apollo.MutationFunction<
  AddCustomItemMutation,
  AddCustomItemMutationVariables
>;

/**
 * __useAddCustomItemMutation__
 *
 * To run a mutation, you first call `useAddCustomItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomItemMutation, { data, loading, error }] = useAddCustomItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useAddCustomItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCustomItemMutation,
    AddCustomItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddCustomItemMutation,
    AddCustomItemMutationVariables
  >(AddCustomItemDocument, options);
}
export type AddCustomItemMutationHookResult = ReturnType<
  typeof useAddCustomItemMutation
>;
export type AddCustomItemMutationResult =
  Apollo.MutationResult<AddCustomItemMutation>;
export type AddCustomItemMutationOptions = Apollo.BaseMutationOptions<
  AddCustomItemMutation,
  AddCustomItemMutationVariables
>;
export const AddSimpleItemDocument = gql`
  mutation AddSimpleItem($item: SimpleItemInput!, $storeId: ID!) {
    customerActions(storeId: $storeId) {
      cart {
        addSimpleItem(item: $item) {
          ...Cart
        }
      }
    }
  }
  ${CartFragmentDoc}
`;
export type AddSimpleItemMutationFn = Apollo.MutationFunction<
  AddSimpleItemMutation,
  AddSimpleItemMutationVariables
>;

/**
 * __useAddSimpleItemMutation__
 *
 * To run a mutation, you first call `useAddSimpleItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSimpleItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSimpleItemMutation, { data, loading, error }] = useAddSimpleItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useAddSimpleItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSimpleItemMutation,
    AddSimpleItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddSimpleItemMutation,
    AddSimpleItemMutationVariables
  >(AddSimpleItemDocument, options);
}
export type AddSimpleItemMutationHookResult = ReturnType<
  typeof useAddSimpleItemMutation
>;
export type AddSimpleItemMutationResult =
  Apollo.MutationResult<AddSimpleItemMutation>;
export type AddSimpleItemMutationOptions = Apollo.BaseMutationOptions<
  AddSimpleItemMutation,
  AddSimpleItemMutationVariables
>;
export const UpdateContactInfoDocument = gql`
  mutation UpdateContactInfo(
    $cartId: ID!
    $info: CreateContactInfoInput!
    $storeId: ID!
  ) {
    customerActions(storeId: $storeId) {
      cart {
        updateContactInfo(cartId: $cartId, info: $info) {
          ...Cart
        }
      }
    }
  }
  ${CartFragmentDoc}
`;
export type UpdateContactInfoMutationFn = Apollo.MutationFunction<
  UpdateContactInfoMutation,
  UpdateContactInfoMutationVariables
>;

/**
 * __useUpdateContactInfoMutation__
 *
 * To run a mutation, you first call `useUpdateContactInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactInfoMutation, { data, loading, error }] = useUpdateContactInfoMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      info: // value for 'info'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useUpdateContactInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContactInfoMutation,
    UpdateContactInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContactInfoMutation,
    UpdateContactInfoMutationVariables
  >(UpdateContactInfoDocument, options);
}
export type UpdateContactInfoMutationHookResult = ReturnType<
  typeof useUpdateContactInfoMutation
>;
export type UpdateContactInfoMutationResult =
  Apollo.MutationResult<UpdateContactInfoMutation>;
export type UpdateContactInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactInfoMutation,
  UpdateContactInfoMutationVariables
>;
export const UpdateCustomItemDocument = gql`
  mutation UpdateCustomItem($item: CustomItemInput!, $storeId: ID!) {
    customerActions(storeId: $storeId) {
      cart {
        updateCustomItem(item: $item) {
          ...Cart
        }
      }
    }
  }
  ${CartFragmentDoc}
`;
export type UpdateCustomItemMutationFn = Apollo.MutationFunction<
  UpdateCustomItemMutation,
  UpdateCustomItemMutationVariables
>;

/**
 * __useUpdateCustomItemMutation__
 *
 * To run a mutation, you first call `useUpdateCustomItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomItemMutation, { data, loading, error }] = useUpdateCustomItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useUpdateCustomItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCustomItemMutation,
    UpdateCustomItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCustomItemMutation,
    UpdateCustomItemMutationVariables
  >(UpdateCustomItemDocument, options);
}
export type UpdateCustomItemMutationHookResult = ReturnType<
  typeof useUpdateCustomItemMutation
>;
export type UpdateCustomItemMutationResult =
  Apollo.MutationResult<UpdateCustomItemMutation>;
export type UpdateCustomItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomItemMutation,
  UpdateCustomItemMutationVariables
>;
export const UpdateSimpleItemDocument = gql`
  mutation UpdateSimpleItem($item: SimpleItemInput!, $storeId: ID!) {
    customerActions(storeId: $storeId) {
      cart {
        updateSimpleItem(item: $item) {
          ...Cart
        }
      }
    }
  }
  ${CartFragmentDoc}
`;
export type UpdateSimpleItemMutationFn = Apollo.MutationFunction<
  UpdateSimpleItemMutation,
  UpdateSimpleItemMutationVariables
>;

/**
 * __useUpdateSimpleItemMutation__
 *
 * To run a mutation, you first call `useUpdateSimpleItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSimpleItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSimpleItemMutation, { data, loading, error }] = useUpdateSimpleItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useUpdateSimpleItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSimpleItemMutation,
    UpdateSimpleItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSimpleItemMutation,
    UpdateSimpleItemMutationVariables
  >(UpdateSimpleItemDocument, options);
}
export type UpdateSimpleItemMutationHookResult = ReturnType<
  typeof useUpdateSimpleItemMutation
>;
export type UpdateSimpleItemMutationResult =
  Apollo.MutationResult<UpdateSimpleItemMutation>;
export type UpdateSimpleItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateSimpleItemMutation,
  UpdateSimpleItemMutationVariables
>;
export const SetCartShippingDetailsDocument = gql`
  mutation SetCartShippingDetails(
    $cartId: ID!
    $shippingDetails: ShippingDetailsInput!
    $storeId: ID!
  ) {
    customerActions(storeId: $storeId) {
      cart {
        setCartShippingDetails(
          cartId: $cartId
          shippingDetails: $shippingDetails
        ) {
          ...Cart
        }
      }
    }
  }
  ${CartFragmentDoc}
`;
export type SetCartShippingDetailsMutationFn = Apollo.MutationFunction<
  SetCartShippingDetailsMutation,
  SetCartShippingDetailsMutationVariables
>;

/**
 * __useSetCartShippingDetailsMutation__
 *
 * To run a mutation, you first call `useSetCartShippingDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCartShippingDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCartShippingDetailsMutation, { data, loading, error }] = useSetCartShippingDetailsMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      shippingDetails: // value for 'shippingDetails'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useSetCartShippingDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetCartShippingDetailsMutation,
    SetCartShippingDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetCartShippingDetailsMutation,
    SetCartShippingDetailsMutationVariables
  >(SetCartShippingDetailsDocument, options);
}
export type SetCartShippingDetailsMutationHookResult = ReturnType<
  typeof useSetCartShippingDetailsMutation
>;
export type SetCartShippingDetailsMutationResult =
  Apollo.MutationResult<SetCartShippingDetailsMutation>;
export type SetCartShippingDetailsMutationOptions = Apollo.BaseMutationOptions<
  SetCartShippingDetailsMutation,
  SetCartShippingDetailsMutationVariables
>;
export const SetCartShippingRateDocument = gql`
  mutation SetCartShippingRate(
    $cartId: ID!
    $shippingRateId: ID!
    $storeId: ID!
    $provider: WuiltShipmentProvider
  ) {
    customerActions(storeId: $storeId) {
      cart {
        setCartShippingRate(
          cartId: $cartId
          shippingRateId: $shippingRateId
          provider: $provider
        ) {
          ...Cart
        }
      }
    }
  }
  ${CartFragmentDoc}
`;
export type SetCartShippingRateMutationFn = Apollo.MutationFunction<
  SetCartShippingRateMutation,
  SetCartShippingRateMutationVariables
>;

/**
 * __useSetCartShippingRateMutation__
 *
 * To run a mutation, you first call `useSetCartShippingRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCartShippingRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCartShippingRateMutation, { data, loading, error }] = useSetCartShippingRateMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      shippingRateId: // value for 'shippingRateId'
 *      storeId: // value for 'storeId'
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useSetCartShippingRateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetCartShippingRateMutation,
    SetCartShippingRateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetCartShippingRateMutation,
    SetCartShippingRateMutationVariables
  >(SetCartShippingRateDocument, options);
}
export type SetCartShippingRateMutationHookResult = ReturnType<
  typeof useSetCartShippingRateMutation
>;
export type SetCartShippingRateMutationResult =
  Apollo.MutationResult<SetCartShippingRateMutation>;
export type SetCartShippingRateMutationOptions = Apollo.BaseMutationOptions<
  SetCartShippingRateMutation,
  SetCartShippingRateMutationVariables
>;
export const ApplyPromoCodeDocument = gql`
  mutation ApplyPromoCode($cartId: ID!, $code: String!, $storeId: ID!) {
    customerActions(storeId: $storeId) {
      cart {
        applyPromoCode(cartId: $cartId, code: $code) {
          ...Cart
        }
      }
    }
  }
  ${CartFragmentDoc}
`;
export type ApplyPromoCodeMutationFn = Apollo.MutationFunction<
  ApplyPromoCodeMutation,
  ApplyPromoCodeMutationVariables
>;

/**
 * __useApplyPromoCodeMutation__
 *
 * To run a mutation, you first call `useApplyPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyPromoCodeMutation, { data, loading, error }] = useApplyPromoCodeMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      code: // value for 'code'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useApplyPromoCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyPromoCodeMutation,
    ApplyPromoCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApplyPromoCodeMutation,
    ApplyPromoCodeMutationVariables
  >(ApplyPromoCodeDocument, options);
}
export type ApplyPromoCodeMutationHookResult = ReturnType<
  typeof useApplyPromoCodeMutation
>;
export type ApplyPromoCodeMutationResult =
  Apollo.MutationResult<ApplyPromoCodeMutation>;
export type ApplyPromoCodeMutationOptions = Apollo.BaseMutationOptions<
  ApplyPromoCodeMutation,
  ApplyPromoCodeMutationVariables
>;
export const RemovePromoCodeDocument = gql`
  mutation RemovePromoCode($cartId: ID!, $storeId: ID!) {
    customerActions(storeId: $storeId) {
      cart {
        removePromoCode(cartId: $cartId) {
          ...Cart
        }
      }
    }
  }
  ${CartFragmentDoc}
`;
export type RemovePromoCodeMutationFn = Apollo.MutationFunction<
  RemovePromoCodeMutation,
  RemovePromoCodeMutationVariables
>;

/**
 * __useRemovePromoCodeMutation__
 *
 * To run a mutation, you first call `useRemovePromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePromoCodeMutation, { data, loading, error }] = useRemovePromoCodeMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useRemovePromoCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePromoCodeMutation,
    RemovePromoCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemovePromoCodeMutation,
    RemovePromoCodeMutationVariables
  >(RemovePromoCodeDocument, options);
}
export type RemovePromoCodeMutationHookResult = ReturnType<
  typeof useRemovePromoCodeMutation
>;
export type RemovePromoCodeMutationResult =
  Apollo.MutationResult<RemovePromoCodeMutation>;
export type RemovePromoCodeMutationOptions = Apollo.BaseMutationOptions<
  RemovePromoCodeMutation,
  RemovePromoCodeMutationVariables
>;
export const ReplaceCartItemsWithSimpleItemDocument = gql`
  mutation ReplaceCartItemsWithSimpleItem(
    $item: SimpleItemInput!
    $storeId: ID!
  ) {
    customerActions(storeId: $storeId) {
      cart {
        replaceCartItemsWithSimpleItem(item: $item) {
          ...Cart
        }
      }
    }
  }
  ${CartFragmentDoc}
`;
export type ReplaceCartItemsWithSimpleItemMutationFn = Apollo.MutationFunction<
  ReplaceCartItemsWithSimpleItemMutation,
  ReplaceCartItemsWithSimpleItemMutationVariables
>;

/**
 * __useReplaceCartItemsWithSimpleItemMutation__
 *
 * To run a mutation, you first call `useReplaceCartItemsWithSimpleItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceCartItemsWithSimpleItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceCartItemsWithSimpleItemMutation, { data, loading, error }] = useReplaceCartItemsWithSimpleItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useReplaceCartItemsWithSimpleItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReplaceCartItemsWithSimpleItemMutation,
    ReplaceCartItemsWithSimpleItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReplaceCartItemsWithSimpleItemMutation,
    ReplaceCartItemsWithSimpleItemMutationVariables
  >(ReplaceCartItemsWithSimpleItemDocument, options);
}
export type ReplaceCartItemsWithSimpleItemMutationHookResult = ReturnType<
  typeof useReplaceCartItemsWithSimpleItemMutation
>;
export type ReplaceCartItemsWithSimpleItemMutationResult =
  Apollo.MutationResult<ReplaceCartItemsWithSimpleItemMutation>;
export type ReplaceCartItemsWithSimpleItemMutationOptions =
  Apollo.BaseMutationOptions<
    ReplaceCartItemsWithSimpleItemMutation,
    ReplaceCartItemsWithSimpleItemMutationVariables
  >;
export const ReplaceCartItemsWithCustomItemDocument = gql`
  mutation ReplaceCartItemsWithCustomItem(
    $item: CustomItemInput!
    $storeId: ID!
  ) {
    customerActions(storeId: $storeId) {
      cart {
        replaceCartItemsWithCustomItem(item: $item) {
          ...Cart
        }
      }
    }
  }
  ${CartFragmentDoc}
`;
export type ReplaceCartItemsWithCustomItemMutationFn = Apollo.MutationFunction<
  ReplaceCartItemsWithCustomItemMutation,
  ReplaceCartItemsWithCustomItemMutationVariables
>;

/**
 * __useReplaceCartItemsWithCustomItemMutation__
 *
 * To run a mutation, you first call `useReplaceCartItemsWithCustomItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceCartItemsWithCustomItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceCartItemsWithCustomItemMutation, { data, loading, error }] = useReplaceCartItemsWithCustomItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useReplaceCartItemsWithCustomItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReplaceCartItemsWithCustomItemMutation,
    ReplaceCartItemsWithCustomItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReplaceCartItemsWithCustomItemMutation,
    ReplaceCartItemsWithCustomItemMutationVariables
  >(ReplaceCartItemsWithCustomItemDocument, options);
}
export type ReplaceCartItemsWithCustomItemMutationHookResult = ReturnType<
  typeof useReplaceCartItemsWithCustomItemMutation
>;
export type ReplaceCartItemsWithCustomItemMutationResult =
  Apollo.MutationResult<ReplaceCartItemsWithCustomItemMutation>;
export type ReplaceCartItemsWithCustomItemMutationOptions =
  Apollo.BaseMutationOptions<
    ReplaceCartItemsWithCustomItemMutation,
    ReplaceCartItemsWithCustomItemMutationVariables
  >;
export const SetCartRefCodeDocument = gql`
  mutation SetCartRefCode($cartId: ID!, $refCode: String!, $storeId: ID!) {
    customerActions(storeId: $storeId) {
      cart {
        setCartRefCode(cartId: $cartId, refCode: $refCode) {
          ...Cart
        }
      }
    }
  }
  ${CartFragmentDoc}
`;
export type SetCartRefCodeMutationFn = Apollo.MutationFunction<
  SetCartRefCodeMutation,
  SetCartRefCodeMutationVariables
>;

/**
 * __useSetCartRefCodeMutation__
 *
 * To run a mutation, you first call `useSetCartRefCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCartRefCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCartRefCodeMutation, { data, loading, error }] = useSetCartRefCodeMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      refCode: // value for 'refCode'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useSetCartRefCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetCartRefCodeMutation,
    SetCartRefCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetCartRefCodeMutation,
    SetCartRefCodeMutationVariables
  >(SetCartRefCodeDocument, options);
}
export type SetCartRefCodeMutationHookResult = ReturnType<
  typeof useSetCartRefCodeMutation
>;
export type SetCartRefCodeMutationResult =
  Apollo.MutationResult<SetCartRefCodeMutation>;
export type SetCartRefCodeMutationOptions = Apollo.BaseMutationOptions<
  SetCartRefCodeMutation,
  SetCartRefCodeMutationVariables
>;
export const CheckoutCartDocument = gql`
  mutation CheckoutCart($cartId: ID!, $storeId: ID!) {
    customerActions(storeId: $storeId) {
      cart {
        checkoutCart(cartId: $cartId) {
          ...Cart
        }
      }
    }
  }
  ${CartFragmentDoc}
`;
export type CheckoutCartMutationFn = Apollo.MutationFunction<
  CheckoutCartMutation,
  CheckoutCartMutationVariables
>;

/**
 * __useCheckoutCartMutation__
 *
 * To run a mutation, you first call `useCheckoutCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutCartMutation, { data, loading, error }] = useCheckoutCartMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useCheckoutCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckoutCartMutation,
    CheckoutCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CheckoutCartMutation,
    CheckoutCartMutationVariables
  >(CheckoutCartDocument, options);
}
export type CheckoutCartMutationHookResult = ReturnType<
  typeof useCheckoutCartMutation
>;
export type CheckoutCartMutationResult =
  Apollo.MutationResult<CheckoutCartMutation>;
export type CheckoutCartMutationOptions = Apollo.BaseMutationOptions<
  CheckoutCartMutation,
  CheckoutCartMutationVariables
>;
export const StoreHomepageDocument = gql`
  query StoreHomepage($storeId: ID!) {
    store(id: $storeId) {
      id
      products(connection: { first: 6 }) {
        nodes {
          ...ProductBasicInfo
        }
      }
      StoreHomePageSections {
        ...StorePageSections
      }
      appearance {
        ...StoreHomeAppearance
      }
    }
    storePaymentMethods(storeId: $storeId) {
      id
      type
      enabled
    }
  }
  ${ProductBasicInfoFragmentDoc}
  ${StorePageSectionsFragmentDoc}
  ${StoreHomeAppearanceFragmentDoc}
`;

/**
 * __useStoreHomepageQuery__
 *
 * To run a query within a React component, call `useStoreHomepageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreHomepageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreHomepageQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useStoreHomepageQuery(
  baseOptions: Apollo.QueryHookOptions<
    StoreHomepageQuery,
    StoreHomepageQueryVariables
  > &
    (
      | { variables: StoreHomepageQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StoreHomepageQuery, StoreHomepageQueryVariables>(
    StoreHomepageDocument,
    options
  );
}
export function useStoreHomepageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoreHomepageQuery,
    StoreHomepageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StoreHomepageQuery, StoreHomepageQueryVariables>(
    StoreHomepageDocument,
    options
  );
}
export function useStoreHomepageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StoreHomepageQuery,
    StoreHomepageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    StoreHomepageQuery,
    StoreHomepageQueryVariables
  >(StoreHomepageDocument, options);
}
export type StoreHomepageQueryHookResult = ReturnType<
  typeof useStoreHomepageQuery
>;
export type StoreHomepageLazyQueryHookResult = ReturnType<
  typeof useStoreHomepageLazyQuery
>;
export type StoreHomepageSuspenseQueryHookResult = ReturnType<
  typeof useStoreHomepageSuspenseQuery
>;
export type StoreHomepageQueryResult = Apollo.QueryResult<
  StoreHomepageQuery,
  StoreHomepageQueryVariables
>;
export const CollectionsDocument = gql`
  query Collections($ids: [ID]) {
    collections(filter: { ids: $ids }) {
      nodes {
        ...CollectionInfo
      }
    }
  }
  ${CollectionInfoFragmentDoc}
`;

/**
 * __useCollectionsQuery__
 *
 * To run a query within a React component, call `useCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CollectionsQuery,
    CollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CollectionsQuery, CollectionsQueryVariables>(
    CollectionsDocument,
    options
  );
}
export function useCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectionsQuery,
    CollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CollectionsQuery, CollectionsQueryVariables>(
    CollectionsDocument,
    options
  );
}
export function useCollectionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CollectionsQuery,
    CollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CollectionsQuery, CollectionsQueryVariables>(
    CollectionsDocument,
    options
  );
}
export type CollectionsQueryHookResult = ReturnType<typeof useCollectionsQuery>;
export type CollectionsLazyQueryHookResult = ReturnType<
  typeof useCollectionsLazyQuery
>;
export type CollectionsSuspenseQueryHookResult = ReturnType<
  typeof useCollectionsSuspenseQuery
>;
export type CollectionsQueryResult = Apollo.QueryResult<
  CollectionsQuery,
  CollectionsQueryVariables
>;
export const ProductDetailsDocument = gql`
  query ProductDetails(
    $storeId: ID!
    $slug: String
    $collectionHandle: String! = ""
  ) {
    collection: storeCollectionByHandle(
      storeId: $storeId
      handle: $collectionHandle
    ) {
      ...CollectionInfo
    }
    product: storeProductByHandle(storeId: $storeId, handle: $slug) {
      ...ProductInfo
      options {
        ...ProductOptionsInfo
      }
      seo {
        title
        description
      }
      attributes {
        ...ProductAttributesInfo
      }
      moreProducts {
        isRandom
        products {
          ...ProductBasicInfo
        }
      }
    }
  }
  ${CollectionInfoFragmentDoc}
  ${ProductInfoFragmentDoc}
  ${ProductOptionsInfoFragmentDoc}
  ${ProductAttributesInfoFragmentDoc}
  ${ProductBasicInfoFragmentDoc}
`;

/**
 * __useProductDetailsQuery__
 *
 * To run a query within a React component, call `useProductDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDetailsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      slug: // value for 'slug'
 *      collectionHandle: // value for 'collectionHandle'
 *   },
 * });
 */
export function useProductDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductDetailsQuery,
    ProductDetailsQueryVariables
  > &
    (
      | { variables: ProductDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductDetailsQuery, ProductDetailsQueryVariables>(
    ProductDetailsDocument,
    options
  );
}
export function useProductDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductDetailsQuery,
    ProductDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductDetailsQuery, ProductDetailsQueryVariables>(
    ProductDetailsDocument,
    options
  );
}
export function useProductDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProductDetailsQuery,
    ProductDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProductDetailsQuery,
    ProductDetailsQueryVariables
  >(ProductDetailsDocument, options);
}
export type ProductDetailsQueryHookResult = ReturnType<
  typeof useProductDetailsQuery
>;
export type ProductDetailsLazyQueryHookResult = ReturnType<
  typeof useProductDetailsLazyQuery
>;
export type ProductDetailsSuspenseQueryHookResult = ReturnType<
  typeof useProductDetailsSuspenseQuery
>;
export type ProductDetailsQueryResult = Apollo.QueryResult<
  ProductDetailsQuery,
  ProductDetailsQueryVariables
>;
export const StorePageByHandleDocument = gql`
  query StorePageByHandle($handle: String!, $storeId: ID!) {
    storePageByHandle(handle: $handle, storeId: $storeId) {
      id
      name
      handle
      status
      locale
      pageType
      seo {
        title
        description
      }
      translations {
        name
      }
      sections {
        ...StorePageSections
      }
    }
  }
  ${StorePageSectionsFragmentDoc}
`;

/**
 * __useStorePageByHandleQuery__
 *
 * To run a query within a React component, call `useStorePageByHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorePageByHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorePageByHandleQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useStorePageByHandleQuery(
  baseOptions: Apollo.QueryHookOptions<
    StorePageByHandleQuery,
    StorePageByHandleQueryVariables
  > &
    (
      | { variables: StorePageByHandleQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    StorePageByHandleQuery,
    StorePageByHandleQueryVariables
  >(StorePageByHandleDocument, options);
}
export function useStorePageByHandleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StorePageByHandleQuery,
    StorePageByHandleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StorePageByHandleQuery,
    StorePageByHandleQueryVariables
  >(StorePageByHandleDocument, options);
}
export function useStorePageByHandleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StorePageByHandleQuery,
    StorePageByHandleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    StorePageByHandleQuery,
    StorePageByHandleQueryVariables
  >(StorePageByHandleDocument, options);
}
export type StorePageByHandleQueryHookResult = ReturnType<
  typeof useStorePageByHandleQuery
>;
export type StorePageByHandleLazyQueryHookResult = ReturnType<
  typeof useStorePageByHandleLazyQuery
>;
export type StorePageByHandleSuspenseQueryHookResult = ReturnType<
  typeof useStorePageByHandleSuspenseQuery
>;
export type StorePageByHandleQueryResult = Apollo.QueryResult<
  StorePageByHandleQuery,
  StorePageByHandleQueryVariables
>;
export const ReviewsDocument = gql`
  query Reviews(
    $storeId: ID!
    $productId: ID!
    $connection: ReviewsConnectionInput!
  ) {
    product(storeId: $storeId, id: $productId) {
      reviewsStatistics {
        average
        starsCount {
          five
          four
          one
          three
          two
        }
        total
      }
      reviews(connection: $connection) {
        id
        content
        createdAt
        customer {
          name
        }
        customerId
        headline
        orderId
        rating
        showName
        reply {
          content
        }
      }
    }
  }
`;

/**
 * __useReviewsQuery__
 *
 * To run a query within a React component, call `useReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      productId: // value for 'productId'
 *      connection: // value for 'connection'
 *   },
 * });
 */
export function useReviewsQuery(
  baseOptions: Apollo.QueryHookOptions<ReviewsQuery, ReviewsQueryVariables> &
    ({ variables: ReviewsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReviewsQuery, ReviewsQueryVariables>(
    ReviewsDocument,
    options
  );
}
export function useReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReviewsQuery, ReviewsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReviewsQuery, ReviewsQueryVariables>(
    ReviewsDocument,
    options
  );
}
export function useReviewsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ReviewsQuery,
    ReviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ReviewsQuery, ReviewsQueryVariables>(
    ReviewsDocument,
    options
  );
}
export type ReviewsQueryHookResult = ReturnType<typeof useReviewsQuery>;
export type ReviewsLazyQueryHookResult = ReturnType<typeof useReviewsLazyQuery>;
export type ReviewsSuspenseQueryHookResult = ReturnType<
  typeof useReviewsSuspenseQuery
>;
export type ReviewsQueryResult = Apollo.QueryResult<
  ReviewsQuery,
  ReviewsQueryVariables
>;
export const StoreCollectionsDocument = gql`
  query StoreCollections(
    $storeId: ID!
    $connection: ProductCollectionConnectionInput
  ) {
    collections(connection: $connection, filter: { storeId: $storeId }) {
      totalCount
      nodes {
        ...CollectionInfo
      }
    }
  }
  ${CollectionInfoFragmentDoc}
`;

/**
 * __useStoreCollectionsQuery__
 *
 * To run a query within a React component, call `useStoreCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreCollectionsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      connection: // value for 'connection'
 *   },
 * });
 */
export function useStoreCollectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    StoreCollectionsQuery,
    StoreCollectionsQueryVariables
  > &
    (
      | { variables: StoreCollectionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StoreCollectionsQuery, StoreCollectionsQueryVariables>(
    StoreCollectionsDocument,
    options
  );
}
export function useStoreCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoreCollectionsQuery,
    StoreCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StoreCollectionsQuery,
    StoreCollectionsQueryVariables
  >(StoreCollectionsDocument, options);
}
export function useStoreCollectionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StoreCollectionsQuery,
    StoreCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    StoreCollectionsQuery,
    StoreCollectionsQueryVariables
  >(StoreCollectionsDocument, options);
}
export type StoreCollectionsQueryHookResult = ReturnType<
  typeof useStoreCollectionsQuery
>;
export type StoreCollectionsLazyQueryHookResult = ReturnType<
  typeof useStoreCollectionsLazyQuery
>;
export type StoreCollectionsSuspenseQueryHookResult = ReturnType<
  typeof useStoreCollectionsSuspenseQuery
>;
export type StoreCollectionsQueryResult = Apollo.QueryResult<
  StoreCollectionsQuery,
  StoreCollectionsQueryVariables
>;
export const StoreProductsDocument = gql`
  query StoreProducts(
    $storeId: ID!
    $title: String
    $first: ConnectionLimitInt
    $offset: Int
    $priceFrom: Float
    $priceTo: Float
    $sortOrder: SortOrder
    $sortBy: ProductSortByField
  ) {
    products(
      connection: {
        first: $first
        offset: $offset
        sortBy: $sortBy
        sortOrder: $sortOrder
      }
      filter: {
        storeIds: [$storeId]
        title: $title
        minPrice: $priceFrom
        maxPrice: $priceTo
      }
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ...ProductBasicInfo
      }
    }
  }
  ${ProductBasicInfoFragmentDoc}
`;

/**
 * __useStoreProductsQuery__
 *
 * To run a query within a React component, call `useStoreProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreProductsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      title: // value for 'title'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      priceFrom: // value for 'priceFrom'
 *      priceTo: // value for 'priceTo'
 *      sortOrder: // value for 'sortOrder'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useStoreProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    StoreProductsQuery,
    StoreProductsQueryVariables
  > &
    (
      | { variables: StoreProductsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StoreProductsQuery, StoreProductsQueryVariables>(
    StoreProductsDocument,
    options
  );
}
export function useStoreProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoreProductsQuery,
    StoreProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StoreProductsQuery, StoreProductsQueryVariables>(
    StoreProductsDocument,
    options
  );
}
export function useStoreProductsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StoreProductsQuery,
    StoreProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    StoreProductsQuery,
    StoreProductsQueryVariables
  >(StoreProductsDocument, options);
}
export type StoreProductsQueryHookResult = ReturnType<
  typeof useStoreProductsQuery
>;
export type StoreProductsLazyQueryHookResult = ReturnType<
  typeof useStoreProductsLazyQuery
>;
export type StoreProductsSuspenseQueryHookResult = ReturnType<
  typeof useStoreProductsSuspenseQuery
>;
export type StoreProductsQueryResult = Apollo.QueryResult<
  StoreProductsQuery,
  StoreProductsQueryVariables
>;
export const SearchProductsDocument = gql`
  query SearchProducts(
    $connection: ProductsConnectionInput
    $filter: ProductsFilterInput
  ) {
    products(connection: $connection, filter: $filter) {
      totalCount
      nodes {
        id
        title
        handle
        type
        initialPrice {
          ...Money
        }
        images {
          ...Image
        }
        collections {
          nodes {
            id
            handle
          }
        }
        variants(first: 1) {
          nodes {
            id
            price {
              ...Money
            }
          }
        }
      }
    }
  }
  ${MoneyFragmentDoc}
  ${ImageFragmentDoc}
`;

/**
 * __useSearchProductsQuery__
 *
 * To run a query within a React component, call `useSearchProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductsQuery({
 *   variables: {
 *      connection: // value for 'connection'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchProductsQuery,
    SearchProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchProductsQuery, SearchProductsQueryVariables>(
    SearchProductsDocument,
    options
  );
}
export function useSearchProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchProductsQuery,
    SearchProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchProductsQuery, SearchProductsQueryVariables>(
    SearchProductsDocument,
    options
  );
}
export function useSearchProductsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SearchProductsQuery,
    SearchProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SearchProductsQuery,
    SearchProductsQueryVariables
  >(SearchProductsDocument, options);
}
export type SearchProductsQueryHookResult = ReturnType<
  typeof useSearchProductsQuery
>;
export type SearchProductsLazyQueryHookResult = ReturnType<
  typeof useSearchProductsLazyQuery
>;
export type SearchProductsSuspenseQueryHookResult = ReturnType<
  typeof useSearchProductsSuspenseQuery
>;
export type SearchProductsQueryResult = Apollo.QueryResult<
  SearchProductsQuery,
  SearchProductsQueryVariables
>;
export const SearchAndFiltersDocument = gql`
  query SearchAndFilters(
    $storeId: ID!
    $connectionSettings: ProductsConnectionInput
    $filters: CatalogSearchFilters
  ) {
    searchCatalog(
      storeId: $storeId
      connectionSettings: $connectionSettings
      filters: $filters
    ) {
      products {
        ...ProductBasicInfo
      }
      metaData {
        totalCount
        collectionsProductsDistribution {
          collectionId
          count
          collection {
            isDeleted
            isArchived
            isVisible
            id
            title
          }
        }
        attributesProductsDistribution {
          count
          attributeValueId
          attributeValue {
            id
            name
            attribute {
              id
              name
            }
          }
        }
        optionsProductsDistribution {
          optionValueId
          count
          optionValue {
            id
            name
            option {
              id
              name
            }
          }
        }
      }
    }
  }
  ${ProductBasicInfoFragmentDoc}
`;

/**
 * __useSearchAndFiltersQuery__
 *
 * To run a query within a React component, call `useSearchAndFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAndFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAndFiltersQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      connectionSettings: // value for 'connectionSettings'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSearchAndFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAndFiltersQuery,
    SearchAndFiltersQueryVariables
  > &
    (
      | { variables: SearchAndFiltersQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchAndFiltersQuery, SearchAndFiltersQueryVariables>(
    SearchAndFiltersDocument,
    options
  );
}
export function useSearchAndFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAndFiltersQuery,
    SearchAndFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchAndFiltersQuery,
    SearchAndFiltersQueryVariables
  >(SearchAndFiltersDocument, options);
}
export function useSearchAndFiltersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SearchAndFiltersQuery,
    SearchAndFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SearchAndFiltersQuery,
    SearchAndFiltersQueryVariables
  >(SearchAndFiltersDocument, options);
}
export type SearchAndFiltersQueryHookResult = ReturnType<
  typeof useSearchAndFiltersQuery
>;
export type SearchAndFiltersLazyQueryHookResult = ReturnType<
  typeof useSearchAndFiltersLazyQuery
>;
export type SearchAndFiltersSuspenseQueryHookResult = ReturnType<
  typeof useSearchAndFiltersSuspenseQuery
>;
export type SearchAndFiltersQueryResult = Apollo.QueryResult<
  SearchAndFiltersQuery,
  SearchAndFiltersQueryVariables
>;
export const CheckVariantsStatusDocument = gql`
  query CheckVariantsStatus($storeId: ID!, $variantIds: [ID!]!) {
    checkVariantsStatus(storeId: $storeId, variantIds: $variantIds) {
      id
      status
      trackQuantity
      quantity
    }
  }
`;

/**
 * __useCheckVariantsStatusQuery__
 *
 * To run a query within a React component, call `useCheckVariantsStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckVariantsStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckVariantsStatusQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      variantIds: // value for 'variantIds'
 *   },
 * });
 */
export function useCheckVariantsStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckVariantsStatusQuery,
    CheckVariantsStatusQueryVariables
  > &
    (
      | { variables: CheckVariantsStatusQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckVariantsStatusQuery,
    CheckVariantsStatusQueryVariables
  >(CheckVariantsStatusDocument, options);
}
export function useCheckVariantsStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckVariantsStatusQuery,
    CheckVariantsStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckVariantsStatusQuery,
    CheckVariantsStatusQueryVariables
  >(CheckVariantsStatusDocument, options);
}
export function useCheckVariantsStatusSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CheckVariantsStatusQuery,
    CheckVariantsStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CheckVariantsStatusQuery,
    CheckVariantsStatusQueryVariables
  >(CheckVariantsStatusDocument, options);
}
export type CheckVariantsStatusQueryHookResult = ReturnType<
  typeof useCheckVariantsStatusQuery
>;
export type CheckVariantsStatusLazyQueryHookResult = ReturnType<
  typeof useCheckVariantsStatusLazyQuery
>;
export type CheckVariantsStatusSuspenseQueryHookResult = ReturnType<
  typeof useCheckVariantsStatusSuspenseQuery
>;
export type CheckVariantsStatusQueryResult = Apollo.QueryResult<
  CheckVariantsStatusQuery,
  CheckVariantsStatusQueryVariables
>;
export const GetFiltersDocument = gql`
  query GetFilters($storeId: ID!) {
    getApplicableFilters(storeId: $storeId) {
      collections {
        title
        id
        isDeleted
        isArchived
        isVisible
      }
      optionValues {
        name
        id
        option {
          name
          id
        }
      }
      attributeValues {
        name
        id
        attribute {
          name
          id
        }
      }
    }
  }
`;

/**
 * __useGetFiltersQuery__
 *
 * To run a query within a React component, call `useGetFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFiltersQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFiltersQuery,
    GetFiltersQueryVariables
  > &
    (
      | { variables: GetFiltersQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFiltersQuery, GetFiltersQueryVariables>(
    GetFiltersDocument,
    options
  );
}
export function useGetFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFiltersQuery,
    GetFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFiltersQuery, GetFiltersQueryVariables>(
    GetFiltersDocument,
    options
  );
}
export function useGetFiltersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFiltersQuery,
    GetFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFiltersQuery, GetFiltersQueryVariables>(
    GetFiltersDocument,
    options
  );
}
export type GetFiltersQueryHookResult = ReturnType<typeof useGetFiltersQuery>;
export type GetFiltersLazyQueryHookResult = ReturnType<
  typeof useGetFiltersLazyQuery
>;
export type GetFiltersSuspenseQueryHookResult = ReturnType<
  typeof useGetFiltersSuspenseQuery
>;
export type GetFiltersQueryResult = Apollo.QueryResult<
  GetFiltersQuery,
  GetFiltersQueryVariables
>;
export const FullTextSearchAndFilterDocument = gql`
  query FullTextSearchAndFilter(
    $storeId: ID!
    $connectionSettings: ProductsConnectionInput
    $filters: CatalogSearchFilters
  ) {
    fullTextSearchAndFilter(
      storeId: $storeId
      connectionSettings: $connectionSettings
      filters: $filters
    ) {
      products {
        ...ProductBasicInfo
      }
      totalCount
    }
  }
  ${ProductBasicInfoFragmentDoc}
`;

/**
 * __useFullTextSearchAndFilterQuery__
 *
 * To run a query within a React component, call `useFullTextSearchAndFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullTextSearchAndFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullTextSearchAndFilterQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      connectionSettings: // value for 'connectionSettings'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useFullTextSearchAndFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    FullTextSearchAndFilterQuery,
    FullTextSearchAndFilterQueryVariables
  > &
    (
      | { variables: FullTextSearchAndFilterQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FullTextSearchAndFilterQuery,
    FullTextSearchAndFilterQueryVariables
  >(FullTextSearchAndFilterDocument, options);
}
export function useFullTextSearchAndFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FullTextSearchAndFilterQuery,
    FullTextSearchAndFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FullTextSearchAndFilterQuery,
    FullTextSearchAndFilterQueryVariables
  >(FullTextSearchAndFilterDocument, options);
}
export function useFullTextSearchAndFilterSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FullTextSearchAndFilterQuery,
    FullTextSearchAndFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FullTextSearchAndFilterQuery,
    FullTextSearchAndFilterQueryVariables
  >(FullTextSearchAndFilterDocument, options);
}
export type FullTextSearchAndFilterQueryHookResult = ReturnType<
  typeof useFullTextSearchAndFilterQuery
>;
export type FullTextSearchAndFilterLazyQueryHookResult = ReturnType<
  typeof useFullTextSearchAndFilterLazyQuery
>;
export type FullTextSearchAndFilterSuspenseQueryHookResult = ReturnType<
  typeof useFullTextSearchAndFilterSuspenseQuery
>;
export type FullTextSearchAndFilterQueryResult = Apollo.QueryResult<
  FullTextSearchAndFilterQuery,
  FullTextSearchAndFilterQueryVariables
>;
export const CollectionProductsDocument = gql`
  query CollectionProducts(
    $storeId: ID!
    $handle: String!
    $first: ConnectionLimitInt
    $offset: Int
    $priceFrom: Float
    $priceTo: Float
    $sortOrder: SortOrder
    $sortBy: CollectionProductsSortBy
  ) {
    collection: storeCollectionByHandle(storeId: $storeId, handle: $handle) {
      ...CollectionInfo
      products(
        connection: {
          first: $first
          offset: $offset
          sortBy: $sortBy
          sortOrder: $sortOrder
        }
        filter: { minPrice: $priceFrom, maxPrice: $priceTo }
      ) {
        nodes {
          ...ProductBasicInfo
        }
        totalCount
      }
    }
  }
  ${CollectionInfoFragmentDoc}
  ${ProductBasicInfoFragmentDoc}
`;

/**
 * __useCollectionProductsQuery__
 *
 * To run a query within a React component, call `useCollectionProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionProductsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      handle: // value for 'handle'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      priceFrom: // value for 'priceFrom'
 *      priceTo: // value for 'priceTo'
 *      sortOrder: // value for 'sortOrder'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useCollectionProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectionProductsQuery,
    CollectionProductsQueryVariables
  > &
    (
      | { variables: CollectionProductsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CollectionProductsQuery,
    CollectionProductsQueryVariables
  >(CollectionProductsDocument, options);
}
export function useCollectionProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectionProductsQuery,
    CollectionProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CollectionProductsQuery,
    CollectionProductsQueryVariables
  >(CollectionProductsDocument, options);
}
export function useCollectionProductsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CollectionProductsQuery,
    CollectionProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CollectionProductsQuery,
    CollectionProductsQueryVariables
  >(CollectionProductsDocument, options);
}
export type CollectionProductsQueryHookResult = ReturnType<
  typeof useCollectionProductsQuery
>;
export type CollectionProductsLazyQueryHookResult = ReturnType<
  typeof useCollectionProductsLazyQuery
>;
export type CollectionProductsSuspenseQueryHookResult = ReturnType<
  typeof useCollectionProductsSuspenseQuery
>;
export type CollectionProductsQueryResult = Apollo.QueryResult<
  CollectionProductsQuery,
  CollectionProductsQueryVariables
>;
export const StoreDetailsByHandleDocument = gql`
  query StoreDetailsByHandle($storeHandle: String!) {
    store: storeByHandle(handle: $storeHandle) {
      ...StoreDetails
    }
  }
  ${StoreDetailsFragmentDoc}
`;

/**
 * __useStoreDetailsByHandleQuery__
 *
 * To run a query within a React component, call `useStoreDetailsByHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreDetailsByHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreDetailsByHandleQuery({
 *   variables: {
 *      storeHandle: // value for 'storeHandle'
 *   },
 * });
 */
export function useStoreDetailsByHandleQuery(
  baseOptions: Apollo.QueryHookOptions<
    StoreDetailsByHandleQuery,
    StoreDetailsByHandleQueryVariables
  > &
    (
      | { variables: StoreDetailsByHandleQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    StoreDetailsByHandleQuery,
    StoreDetailsByHandleQueryVariables
  >(StoreDetailsByHandleDocument, options);
}
export function useStoreDetailsByHandleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoreDetailsByHandleQuery,
    StoreDetailsByHandleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StoreDetailsByHandleQuery,
    StoreDetailsByHandleQueryVariables
  >(StoreDetailsByHandleDocument, options);
}
export function useStoreDetailsByHandleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StoreDetailsByHandleQuery,
    StoreDetailsByHandleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    StoreDetailsByHandleQuery,
    StoreDetailsByHandleQueryVariables
  >(StoreDetailsByHandleDocument, options);
}
export type StoreDetailsByHandleQueryHookResult = ReturnType<
  typeof useStoreDetailsByHandleQuery
>;
export type StoreDetailsByHandleLazyQueryHookResult = ReturnType<
  typeof useStoreDetailsByHandleLazyQuery
>;
export type StoreDetailsByHandleSuspenseQueryHookResult = ReturnType<
  typeof useStoreDetailsByHandleSuspenseQuery
>;
export type StoreDetailsByHandleQueryResult = Apollo.QueryResult<
  StoreDetailsByHandleQuery,
  StoreDetailsByHandleQueryVariables
>;
export const StoreDetailsByDomainDocument = gql`
  query StoreDetailsByDomain($storeDomain: String!) {
    store: storeByDomain(domainName: $storeDomain) {
      ...StoreDetails
    }
  }
  ${StoreDetailsFragmentDoc}
`;

/**
 * __useStoreDetailsByDomainQuery__
 *
 * To run a query within a React component, call `useStoreDetailsByDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreDetailsByDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreDetailsByDomainQuery({
 *   variables: {
 *      storeDomain: // value for 'storeDomain'
 *   },
 * });
 */
export function useStoreDetailsByDomainQuery(
  baseOptions: Apollo.QueryHookOptions<
    StoreDetailsByDomainQuery,
    StoreDetailsByDomainQueryVariables
  > &
    (
      | { variables: StoreDetailsByDomainQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    StoreDetailsByDomainQuery,
    StoreDetailsByDomainQueryVariables
  >(StoreDetailsByDomainDocument, options);
}
export function useStoreDetailsByDomainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoreDetailsByDomainQuery,
    StoreDetailsByDomainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StoreDetailsByDomainQuery,
    StoreDetailsByDomainQueryVariables
  >(StoreDetailsByDomainDocument, options);
}
export function useStoreDetailsByDomainSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StoreDetailsByDomainQuery,
    StoreDetailsByDomainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    StoreDetailsByDomainQuery,
    StoreDetailsByDomainQueryVariables
  >(StoreDetailsByDomainDocument, options);
}
export type StoreDetailsByDomainQueryHookResult = ReturnType<
  typeof useStoreDetailsByDomainQuery
>;
export type StoreDetailsByDomainLazyQueryHookResult = ReturnType<
  typeof useStoreDetailsByDomainLazyQuery
>;
export type StoreDetailsByDomainSuspenseQueryHookResult = ReturnType<
  typeof useStoreDetailsByDomainSuspenseQuery
>;
export type StoreDetailsByDomainQueryResult = Apollo.QueryResult<
  StoreDetailsByDomainQuery,
  StoreDetailsByDomainQueryVariables
>;
export const StoreInfoByHandleDocument = gql`
  query StoreInfoByHandle($storeHandle: String!) {
    store: storeByHandle(handle: $storeHandle) {
      ...StoreBasicInfo
    }
  }
  ${StoreBasicInfoFragmentDoc}
`;

/**
 * __useStoreInfoByHandleQuery__
 *
 * To run a query within a React component, call `useStoreInfoByHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreInfoByHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreInfoByHandleQuery({
 *   variables: {
 *      storeHandle: // value for 'storeHandle'
 *   },
 * });
 */
export function useStoreInfoByHandleQuery(
  baseOptions: Apollo.QueryHookOptions<
    StoreInfoByHandleQuery,
    StoreInfoByHandleQueryVariables
  > &
    (
      | { variables: StoreInfoByHandleQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    StoreInfoByHandleQuery,
    StoreInfoByHandleQueryVariables
  >(StoreInfoByHandleDocument, options);
}
export function useStoreInfoByHandleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoreInfoByHandleQuery,
    StoreInfoByHandleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StoreInfoByHandleQuery,
    StoreInfoByHandleQueryVariables
  >(StoreInfoByHandleDocument, options);
}
export function useStoreInfoByHandleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StoreInfoByHandleQuery,
    StoreInfoByHandleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    StoreInfoByHandleQuery,
    StoreInfoByHandleQueryVariables
  >(StoreInfoByHandleDocument, options);
}
export type StoreInfoByHandleQueryHookResult = ReturnType<
  typeof useStoreInfoByHandleQuery
>;
export type StoreInfoByHandleLazyQueryHookResult = ReturnType<
  typeof useStoreInfoByHandleLazyQuery
>;
export type StoreInfoByHandleSuspenseQueryHookResult = ReturnType<
  typeof useStoreInfoByHandleSuspenseQuery
>;
export type StoreInfoByHandleQueryResult = Apollo.QueryResult<
  StoreInfoByHandleQuery,
  StoreInfoByHandleQueryVariables
>;
export const StoreInfoByDomainDocument = gql`
  query StoreInfoByDomain($storeDomain: String!) {
    store: storeByDomain(domainName: $storeDomain) {
      ...StoreBasicInfo
    }
  }
  ${StoreBasicInfoFragmentDoc}
`;

/**
 * __useStoreInfoByDomainQuery__
 *
 * To run a query within a React component, call `useStoreInfoByDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreInfoByDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreInfoByDomainQuery({
 *   variables: {
 *      storeDomain: // value for 'storeDomain'
 *   },
 * });
 */
export function useStoreInfoByDomainQuery(
  baseOptions: Apollo.QueryHookOptions<
    StoreInfoByDomainQuery,
    StoreInfoByDomainQueryVariables
  > &
    (
      | { variables: StoreInfoByDomainQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    StoreInfoByDomainQuery,
    StoreInfoByDomainQueryVariables
  >(StoreInfoByDomainDocument, options);
}
export function useStoreInfoByDomainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoreInfoByDomainQuery,
    StoreInfoByDomainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StoreInfoByDomainQuery,
    StoreInfoByDomainQueryVariables
  >(StoreInfoByDomainDocument, options);
}
export function useStoreInfoByDomainSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StoreInfoByDomainQuery,
    StoreInfoByDomainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    StoreInfoByDomainQuery,
    StoreInfoByDomainQueryVariables
  >(StoreInfoByDomainDocument, options);
}
export type StoreInfoByDomainQueryHookResult = ReturnType<
  typeof useStoreInfoByDomainQuery
>;
export type StoreInfoByDomainLazyQueryHookResult = ReturnType<
  typeof useStoreInfoByDomainLazyQuery
>;
export type StoreInfoByDomainSuspenseQueryHookResult = ReturnType<
  typeof useStoreInfoByDomainSuspenseQuery
>;
export type StoreInfoByDomainQueryResult = Apollo.QueryResult<
  StoreInfoByDomainQuery,
  StoreInfoByDomainQueryVariables
>;
export const StoreBasicInfoByHandleDocument = gql`
  query StoreBasicInfoByHandle($storeHandle: String!) {
    store: storeByHandle(handle: $storeHandle) {
      supportedLocales
      defaultLocale
      subscription {
        createdAt
        status
      }
      isExpired
      domain {
        domainName
      }
    }
  }
`;

/**
 * __useStoreBasicInfoByHandleQuery__
 *
 * To run a query within a React component, call `useStoreBasicInfoByHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreBasicInfoByHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreBasicInfoByHandleQuery({
 *   variables: {
 *      storeHandle: // value for 'storeHandle'
 *   },
 * });
 */
export function useStoreBasicInfoByHandleQuery(
  baseOptions: Apollo.QueryHookOptions<
    StoreBasicInfoByHandleQuery,
    StoreBasicInfoByHandleQueryVariables
  > &
    (
      | { variables: StoreBasicInfoByHandleQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    StoreBasicInfoByHandleQuery,
    StoreBasicInfoByHandleQueryVariables
  >(StoreBasicInfoByHandleDocument, options);
}
export function useStoreBasicInfoByHandleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoreBasicInfoByHandleQuery,
    StoreBasicInfoByHandleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StoreBasicInfoByHandleQuery,
    StoreBasicInfoByHandleQueryVariables
  >(StoreBasicInfoByHandleDocument, options);
}
export function useStoreBasicInfoByHandleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StoreBasicInfoByHandleQuery,
    StoreBasicInfoByHandleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    StoreBasicInfoByHandleQuery,
    StoreBasicInfoByHandleQueryVariables
  >(StoreBasicInfoByHandleDocument, options);
}
export type StoreBasicInfoByHandleQueryHookResult = ReturnType<
  typeof useStoreBasicInfoByHandleQuery
>;
export type StoreBasicInfoByHandleLazyQueryHookResult = ReturnType<
  typeof useStoreBasicInfoByHandleLazyQuery
>;
export type StoreBasicInfoByHandleSuspenseQueryHookResult = ReturnType<
  typeof useStoreBasicInfoByHandleSuspenseQuery
>;
export type StoreBasicInfoByHandleQueryResult = Apollo.QueryResult<
  StoreBasicInfoByHandleQuery,
  StoreBasicInfoByHandleQueryVariables
>;
export const StoreBasicInfoByDomainDocument = gql`
  query StoreBasicInfoByDomain($storeDomain: String!) {
    store: storeByDomain(domainName: $storeDomain) {
      supportedLocales
      defaultLocale
      subscription {
        createdAt
        status
      }
      isExpired
      domain {
        domainName
      }
    }
  }
`;

/**
 * __useStoreBasicInfoByDomainQuery__
 *
 * To run a query within a React component, call `useStoreBasicInfoByDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreBasicInfoByDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreBasicInfoByDomainQuery({
 *   variables: {
 *      storeDomain: // value for 'storeDomain'
 *   },
 * });
 */
export function useStoreBasicInfoByDomainQuery(
  baseOptions: Apollo.QueryHookOptions<
    StoreBasicInfoByDomainQuery,
    StoreBasicInfoByDomainQueryVariables
  > &
    (
      | { variables: StoreBasicInfoByDomainQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    StoreBasicInfoByDomainQuery,
    StoreBasicInfoByDomainQueryVariables
  >(StoreBasicInfoByDomainDocument, options);
}
export function useStoreBasicInfoByDomainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoreBasicInfoByDomainQuery,
    StoreBasicInfoByDomainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StoreBasicInfoByDomainQuery,
    StoreBasicInfoByDomainQueryVariables
  >(StoreBasicInfoByDomainDocument, options);
}
export function useStoreBasicInfoByDomainSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StoreBasicInfoByDomainQuery,
    StoreBasicInfoByDomainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    StoreBasicInfoByDomainQuery,
    StoreBasicInfoByDomainQueryVariables
  >(StoreBasicInfoByDomainDocument, options);
}
export type StoreBasicInfoByDomainQueryHookResult = ReturnType<
  typeof useStoreBasicInfoByDomainQuery
>;
export type StoreBasicInfoByDomainLazyQueryHookResult = ReturnType<
  typeof useStoreBasicInfoByDomainLazyQuery
>;
export type StoreBasicInfoByDomainSuspenseQueryHookResult = ReturnType<
  typeof useStoreBasicInfoByDomainSuspenseQuery
>;
export type StoreBasicInfoByDomainQueryResult = Apollo.QueryResult<
  StoreBasicInfoByDomainQuery,
  StoreBasicInfoByDomainQueryVariables
>;
export const ShippingDestinationsDocument = gql`
  query ShippingDestinations($storeId: ID!) {
    store(id: $storeId) {
      id
      shippingZones {
        totalCount
        nodes {
          id
          name
          countries {
            totalCount
            nodes {
              id
              name
              zoneId
              states {
                totalCount
                nodes {
                  id
                  name
                  cities {
                    totalCount
                    nodes {
                      id
                      name
                      regions {
                        totalCount
                        nodes {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useShippingDestinationsQuery__
 *
 * To run a query within a React component, call `useShippingDestinationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShippingDestinationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShippingDestinationsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useShippingDestinationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShippingDestinationsQuery,
    ShippingDestinationsQueryVariables
  > &
    (
      | { variables: ShippingDestinationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ShippingDestinationsQuery,
    ShippingDestinationsQueryVariables
  >(ShippingDestinationsDocument, options);
}
export function useShippingDestinationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShippingDestinationsQuery,
    ShippingDestinationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ShippingDestinationsQuery,
    ShippingDestinationsQueryVariables
  >(ShippingDestinationsDocument, options);
}
export function useShippingDestinationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ShippingDestinationsQuery,
    ShippingDestinationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ShippingDestinationsQuery,
    ShippingDestinationsQueryVariables
  >(ShippingDestinationsDocument, options);
}
export type ShippingDestinationsQueryHookResult = ReturnType<
  typeof useShippingDestinationsQuery
>;
export type ShippingDestinationsLazyQueryHookResult = ReturnType<
  typeof useShippingDestinationsLazyQuery
>;
export type ShippingDestinationsSuspenseQueryHookResult = ReturnType<
  typeof useShippingDestinationsSuspenseQuery
>;
export type ShippingDestinationsQueryResult = Apollo.QueryResult<
  ShippingDestinationsQuery,
  ShippingDestinationsQueryVariables
>;
export const ShippingRatesDocument = gql`
  query ShippingRates($storeId: ID!, $input: ShippingRatesInput) {
    store(id: $storeId) {
      id
      shippingRates(input: $input) {
        id
        name
        cost {
          ...Money
        }
      }
    }
  }
  ${MoneyFragmentDoc}
`;

/**
 * __useShippingRatesQuery__
 *
 * To run a query within a React component, call `useShippingRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useShippingRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShippingRatesQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShippingRatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShippingRatesQuery,
    ShippingRatesQueryVariables
  > &
    (
      | { variables: ShippingRatesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShippingRatesQuery, ShippingRatesQueryVariables>(
    ShippingRatesDocument,
    options
  );
}
export function useShippingRatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShippingRatesQuery,
    ShippingRatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShippingRatesQuery, ShippingRatesQueryVariables>(
    ShippingRatesDocument,
    options
  );
}
export function useShippingRatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ShippingRatesQuery,
    ShippingRatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ShippingRatesQuery,
    ShippingRatesQueryVariables
  >(ShippingRatesDocument, options);
}
export type ShippingRatesQueryHookResult = ReturnType<
  typeof useShippingRatesQuery
>;
export type ShippingRatesLazyQueryHookResult = ReturnType<
  typeof useShippingRatesLazyQuery
>;
export type ShippingRatesSuspenseQueryHookResult = ReturnType<
  typeof useShippingRatesSuspenseQuery
>;
export type ShippingRatesQueryResult = Apollo.QueryResult<
  ShippingRatesQuery,
  ShippingRatesQueryVariables
>;
export const StoreStaticPageByHandleDocument = gql`
  query StoreStaticPageByHandle($input: StaticPageStoreByHandleInput) {
    storeStaticPageByHandle(input: $input) {
      id
      handle
      htmlTemplate
      locale
      name
      title
      translations {
        htmlTemplate
        locale
        title
      }
      handle
      id
    }
  }
`;

/**
 * __useStoreStaticPageByHandleQuery__
 *
 * To run a query within a React component, call `useStoreStaticPageByHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreStaticPageByHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreStaticPageByHandleQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStoreStaticPageByHandleQuery(
  baseOptions?: Apollo.QueryHookOptions<
    StoreStaticPageByHandleQuery,
    StoreStaticPageByHandleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    StoreStaticPageByHandleQuery,
    StoreStaticPageByHandleQueryVariables
  >(StoreStaticPageByHandleDocument, options);
}
export function useStoreStaticPageByHandleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoreStaticPageByHandleQuery,
    StoreStaticPageByHandleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StoreStaticPageByHandleQuery,
    StoreStaticPageByHandleQueryVariables
  >(StoreStaticPageByHandleDocument, options);
}
export function useStoreStaticPageByHandleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StoreStaticPageByHandleQuery,
    StoreStaticPageByHandleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    StoreStaticPageByHandleQuery,
    StoreStaticPageByHandleQueryVariables
  >(StoreStaticPageByHandleDocument, options);
}
export type StoreStaticPageByHandleQueryHookResult = ReturnType<
  typeof useStoreStaticPageByHandleQuery
>;
export type StoreStaticPageByHandleLazyQueryHookResult = ReturnType<
  typeof useStoreStaticPageByHandleLazyQuery
>;
export type StoreStaticPageByHandleSuspenseQueryHookResult = ReturnType<
  typeof useStoreStaticPageByHandleSuspenseQuery
>;
export type StoreStaticPageByHandleQueryResult = Apollo.QueryResult<
  StoreStaticPageByHandleQuery,
  StoreStaticPageByHandleQueryVariables
>;
export const GetOrderByIdDocument = gql`
  query GetOrderById($storeId: ID!, $orderId: ID!) {
    order(orderId: $orderId, storeId: $storeId) {
      ...OrderInfo
    }
  }
  ${OrderInfoFragmentDoc}
`;

/**
 * __useGetOrderByIdQuery__
 *
 * To run a query within a React component, call `useGetOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderByIdQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrderByIdQuery,
    GetOrderByIdQueryVariables
  > &
    (
      | { variables: GetOrderByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(
    GetOrderByIdDocument,
    options
  );
}
export function useGetOrderByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderByIdQuery,
    GetOrderByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(
    GetOrderByIdDocument,
    options
  );
}
export function useGetOrderByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrderByIdQuery,
    GetOrderByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(
    GetOrderByIdDocument,
    options
  );
}
export type GetOrderByIdQueryHookResult = ReturnType<
  typeof useGetOrderByIdQuery
>;
export type GetOrderByIdLazyQueryHookResult = ReturnType<
  typeof useGetOrderByIdLazyQuery
>;
export type GetOrderByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetOrderByIdSuspenseQuery
>;
export type GetOrderByIdQueryResult = Apollo.QueryResult<
  GetOrderByIdQuery,
  GetOrderByIdQueryVariables
>;
export const GetOrderStatusDocument = gql`
  query GetOrderStatus($storeId: ID!, $orderId: ID!) {
    order(orderId: $orderId, storeId: $storeId) {
      id
      status
      orderSerial
    }
  }
`;

/**
 * __useGetOrderStatusQuery__
 *
 * To run a query within a React component, call `useGetOrderStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderStatusQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrderStatusQuery,
    GetOrderStatusQueryVariables
  > &
    (
      | { variables: GetOrderStatusQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderStatusQuery, GetOrderStatusQueryVariables>(
    GetOrderStatusDocument,
    options
  );
}
export function useGetOrderStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderStatusQuery,
    GetOrderStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderStatusQuery, GetOrderStatusQueryVariables>(
    GetOrderStatusDocument,
    options
  );
}
export function useGetOrderStatusSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrderStatusQuery,
    GetOrderStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOrderStatusQuery,
    GetOrderStatusQueryVariables
  >(GetOrderStatusDocument, options);
}
export type GetOrderStatusQueryHookResult = ReturnType<
  typeof useGetOrderStatusQuery
>;
export type GetOrderStatusLazyQueryHookResult = ReturnType<
  typeof useGetOrderStatusLazyQuery
>;
export type GetOrderStatusSuspenseQueryHookResult = ReturnType<
  typeof useGetOrderStatusSuspenseQuery
>;
export type GetOrderStatusQueryResult = Apollo.QueryResult<
  GetOrderStatusQuery,
  GetOrderStatusQueryVariables
>;
export const GetOrderErrorsDocument = gql`
  query GetOrderErrors($storeId: ID!, $orderId: ID!) {
    order(orderId: $orderId, storeId: $storeId) {
      id
      errors {
        ...CheckoutErrors
      }
    }
  }
  ${CheckoutErrorsFragmentDoc}
`;

/**
 * __useGetOrderErrorsQuery__
 *
 * To run a query within a React component, call `useGetOrderErrorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderErrorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderErrorsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderErrorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrderErrorsQuery,
    GetOrderErrorsQueryVariables
  > &
    (
      | { variables: GetOrderErrorsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderErrorsQuery, GetOrderErrorsQueryVariables>(
    GetOrderErrorsDocument,
    options
  );
}
export function useGetOrderErrorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderErrorsQuery,
    GetOrderErrorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderErrorsQuery, GetOrderErrorsQueryVariables>(
    GetOrderErrorsDocument,
    options
  );
}
export function useGetOrderErrorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrderErrorsQuery,
    GetOrderErrorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOrderErrorsQuery,
    GetOrderErrorsQueryVariables
  >(GetOrderErrorsDocument, options);
}
export type GetOrderErrorsQueryHookResult = ReturnType<
  typeof useGetOrderErrorsQuery
>;
export type GetOrderErrorsLazyQueryHookResult = ReturnType<
  typeof useGetOrderErrorsLazyQuery
>;
export type GetOrderErrorsSuspenseQueryHookResult = ReturnType<
  typeof useGetOrderErrorsSuspenseQuery
>;
export type GetOrderErrorsQueryResult = Apollo.QueryResult<
  GetOrderErrorsQuery,
  GetOrderErrorsQueryVariables
>;
export const GetStorePaymentMethodsDocument = gql`
  query GetStorePaymentMethods($storeId: ID!) {
    storePaymentMethods(storeId: $storeId) {
      id
      type
      enabled
    }
  }
`;

/**
 * __useGetStorePaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetStorePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStorePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStorePaymentMethodsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetStorePaymentMethodsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStorePaymentMethodsQuery,
    GetStorePaymentMethodsQueryVariables
  > &
    (
      | { variables: GetStorePaymentMethodsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStorePaymentMethodsQuery,
    GetStorePaymentMethodsQueryVariables
  >(GetStorePaymentMethodsDocument, options);
}
export function useGetStorePaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStorePaymentMethodsQuery,
    GetStorePaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStorePaymentMethodsQuery,
    GetStorePaymentMethodsQueryVariables
  >(GetStorePaymentMethodsDocument, options);
}
export function useGetStorePaymentMethodsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetStorePaymentMethodsQuery,
    GetStorePaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetStorePaymentMethodsQuery,
    GetStorePaymentMethodsQueryVariables
  >(GetStorePaymentMethodsDocument, options);
}
export type GetStorePaymentMethodsQueryHookResult = ReturnType<
  typeof useGetStorePaymentMethodsQuery
>;
export type GetStorePaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useGetStorePaymentMethodsLazyQuery
>;
export type GetStorePaymentMethodsSuspenseQueryHookResult = ReturnType<
  typeof useGetStorePaymentMethodsSuspenseQuery
>;
export type GetStorePaymentMethodsQueryResult = Apollo.QueryResult<
  GetStorePaymentMethodsQuery,
  GetStorePaymentMethodsQueryVariables
>;
export const GetStoreCheckoutPaymentMethodsDocument = gql`
  query GetStoreCheckoutPaymentMethods($storeId: ID!) {
    storeCheckoutPaymentMethods(storeId: $storeId)
  }
`;

/**
 * __useGetStoreCheckoutPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetStoreCheckoutPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreCheckoutPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreCheckoutPaymentMethodsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetStoreCheckoutPaymentMethodsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStoreCheckoutPaymentMethodsQuery,
    GetStoreCheckoutPaymentMethodsQueryVariables
  > &
    (
      | {
          variables: GetStoreCheckoutPaymentMethodsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStoreCheckoutPaymentMethodsQuery,
    GetStoreCheckoutPaymentMethodsQueryVariables
  >(GetStoreCheckoutPaymentMethodsDocument, options);
}
export function useGetStoreCheckoutPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStoreCheckoutPaymentMethodsQuery,
    GetStoreCheckoutPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStoreCheckoutPaymentMethodsQuery,
    GetStoreCheckoutPaymentMethodsQueryVariables
  >(GetStoreCheckoutPaymentMethodsDocument, options);
}
export function useGetStoreCheckoutPaymentMethodsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetStoreCheckoutPaymentMethodsQuery,
    GetStoreCheckoutPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetStoreCheckoutPaymentMethodsQuery,
    GetStoreCheckoutPaymentMethodsQueryVariables
  >(GetStoreCheckoutPaymentMethodsDocument, options);
}
export type GetStoreCheckoutPaymentMethodsQueryHookResult = ReturnType<
  typeof useGetStoreCheckoutPaymentMethodsQuery
>;
export type GetStoreCheckoutPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useGetStoreCheckoutPaymentMethodsLazyQuery
>;
export type GetStoreCheckoutPaymentMethodsSuspenseQueryHookResult = ReturnType<
  typeof useGetStoreCheckoutPaymentMethodsSuspenseQuery
>;
export type GetStoreCheckoutPaymentMethodsQueryResult = Apollo.QueryResult<
  GetStoreCheckoutPaymentMethodsQuery,
  GetStoreCheckoutPaymentMethodsQueryVariables
>;
export const StoreCheckoutDocument = gql`
  query StoreCheckout($storeId: ID!) {
    store(id: $storeId) {
      id
      checkout {
        successMessage
      }
    }
  }
`;

/**
 * __useStoreCheckoutQuery__
 *
 * To run a query within a React component, call `useStoreCheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreCheckoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreCheckoutQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useStoreCheckoutQuery(
  baseOptions: Apollo.QueryHookOptions<
    StoreCheckoutQuery,
    StoreCheckoutQueryVariables
  > &
    (
      | { variables: StoreCheckoutQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StoreCheckoutQuery, StoreCheckoutQueryVariables>(
    StoreCheckoutDocument,
    options
  );
}
export function useStoreCheckoutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoreCheckoutQuery,
    StoreCheckoutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StoreCheckoutQuery, StoreCheckoutQueryVariables>(
    StoreCheckoutDocument,
    options
  );
}
export function useStoreCheckoutSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StoreCheckoutQuery,
    StoreCheckoutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    StoreCheckoutQuery,
    StoreCheckoutQueryVariables
  >(StoreCheckoutDocument, options);
}
export type StoreCheckoutQueryHookResult = ReturnType<
  typeof useStoreCheckoutQuery
>;
export type StoreCheckoutLazyQueryHookResult = ReturnType<
  typeof useStoreCheckoutLazyQuery
>;
export type StoreCheckoutSuspenseQueryHookResult = ReturnType<
  typeof useStoreCheckoutSuspenseQuery
>;
export type StoreCheckoutQueryResult = Apollo.QueryResult<
  StoreCheckoutQuery,
  StoreCheckoutQueryVariables
>;
export const ListCustomerAddressesDocument = gql`
  query ListCustomerAddresses($customerId: ID!, $storeId: ID!) {
    customerAddresses(customerId: $customerId, storeId: $storeId) {
      ...AddressInfo
    }
  }
  ${AddressInfoFragmentDoc}
`;

/**
 * __useListCustomerAddressesQuery__
 *
 * To run a query within a React component, call `useListCustomerAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCustomerAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCustomerAddressesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useListCustomerAddressesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListCustomerAddressesQuery,
    ListCustomerAddressesQueryVariables
  > &
    (
      | { variables: ListCustomerAddressesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListCustomerAddressesQuery,
    ListCustomerAddressesQueryVariables
  >(ListCustomerAddressesDocument, options);
}
export function useListCustomerAddressesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListCustomerAddressesQuery,
    ListCustomerAddressesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListCustomerAddressesQuery,
    ListCustomerAddressesQueryVariables
  >(ListCustomerAddressesDocument, options);
}
export function useListCustomerAddressesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListCustomerAddressesQuery,
    ListCustomerAddressesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListCustomerAddressesQuery,
    ListCustomerAddressesQueryVariables
  >(ListCustomerAddressesDocument, options);
}
export type ListCustomerAddressesQueryHookResult = ReturnType<
  typeof useListCustomerAddressesQuery
>;
export type ListCustomerAddressesLazyQueryHookResult = ReturnType<
  typeof useListCustomerAddressesLazyQuery
>;
export type ListCustomerAddressesSuspenseQueryHookResult = ReturnType<
  typeof useListCustomerAddressesSuspenseQuery
>;
export type ListCustomerAddressesQueryResult = Apollo.QueryResult<
  ListCustomerAddressesQuery,
  ListCustomerAddressesQueryVariables
>;
export const ListCustomerOrdersDocument = gql`
  query ListCustomerOrders($id: ID!, $connection: OrdersConnectionInput) {
    customerOrders(id: $id, connection: $connection) {
      id
      name
      email
      phone
      orders(connection: $connection) {
        totalCount
        nodes {
          ...UserOrderInfo
        }
      }
    }
  }
  ${UserOrderInfoFragmentDoc}
`;

/**
 * __useListCustomerOrdersQuery__
 *
 * To run a query within a React component, call `useListCustomerOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCustomerOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCustomerOrdersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      connection: // value for 'connection'
 *   },
 * });
 */
export function useListCustomerOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListCustomerOrdersQuery,
    ListCustomerOrdersQueryVariables
  > &
    (
      | { variables: ListCustomerOrdersQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListCustomerOrdersQuery,
    ListCustomerOrdersQueryVariables
  >(ListCustomerOrdersDocument, options);
}
export function useListCustomerOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListCustomerOrdersQuery,
    ListCustomerOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListCustomerOrdersQuery,
    ListCustomerOrdersQueryVariables
  >(ListCustomerOrdersDocument, options);
}
export function useListCustomerOrdersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListCustomerOrdersQuery,
    ListCustomerOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListCustomerOrdersQuery,
    ListCustomerOrdersQueryVariables
  >(ListCustomerOrdersDocument, options);
}
export type ListCustomerOrdersQueryHookResult = ReturnType<
  typeof useListCustomerOrdersQuery
>;
export type ListCustomerOrdersLazyQueryHookResult = ReturnType<
  typeof useListCustomerOrdersLazyQuery
>;
export type ListCustomerOrdersSuspenseQueryHookResult = ReturnType<
  typeof useListCustomerOrdersSuspenseQuery
>;
export type ListCustomerOrdersQueryResult = Apollo.QueryResult<
  ListCustomerOrdersQuery,
  ListCustomerOrdersQueryVariables
>;
export const CustomerDetailsDocument = gql`
  query CustomerDetails($customerId: ID!, $connection: OrdersConnectionInput) {
    customerOrders(id: $customerId, connection: $connection) {
      ...CustomerInfo
      orders(connection: $connection) {
        totalCount
        nodes {
          id
          orderSerial
          createdAt
          totalPrice {
            ...Money
          }
          paymentStatus
          fulfillmentStatus
        }
      }
      addresses {
        ...AddressInfo
      }
    }
  }
  ${CustomerInfoFragmentDoc}
  ${MoneyFragmentDoc}
  ${AddressInfoFragmentDoc}
`;

/**
 * __useCustomerDetailsQuery__
 *
 * To run a query within a React component, call `useCustomerDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDetailsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      connection: // value for 'connection'
 *   },
 * });
 */
export function useCustomerDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CustomerDetailsQuery,
    CustomerDetailsQueryVariables
  > &
    (
      | { variables: CustomerDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerDetailsQuery, CustomerDetailsQueryVariables>(
    CustomerDetailsDocument,
    options
  );
}
export function useCustomerDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomerDetailsQuery,
    CustomerDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CustomerDetailsQuery,
    CustomerDetailsQueryVariables
  >(CustomerDetailsDocument, options);
}
export function useCustomerDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CustomerDetailsQuery,
    CustomerDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CustomerDetailsQuery,
    CustomerDetailsQueryVariables
  >(CustomerDetailsDocument, options);
}
export type CustomerDetailsQueryHookResult = ReturnType<
  typeof useCustomerDetailsQuery
>;
export type CustomerDetailsLazyQueryHookResult = ReturnType<
  typeof useCustomerDetailsLazyQuery
>;
export type CustomerDetailsSuspenseQueryHookResult = ReturnType<
  typeof useCustomerDetailsSuspenseQuery
>;
export type CustomerDetailsQueryResult = Apollo.QueryResult<
  CustomerDetailsQuery,
  CustomerDetailsQueryVariables
>;
export const ListCustomerReviewsDocument = gql`
  query ListCustomerReviews($input: listCustomerReviewsInput!) {
    listCustomerReviews(input: $input) {
      id
      createdAt
      headline
      content
      rating
      showName
      status
      product {
        id
        title
        images {
          ...Image
        }
      }
    }
  }
  ${ImageFragmentDoc}
`;

/**
 * __useListCustomerReviewsQuery__
 *
 * To run a query within a React component, call `useListCustomerReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCustomerReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCustomerReviewsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListCustomerReviewsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListCustomerReviewsQuery,
    ListCustomerReviewsQueryVariables
  > &
    (
      | { variables: ListCustomerReviewsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListCustomerReviewsQuery,
    ListCustomerReviewsQueryVariables
  >(ListCustomerReviewsDocument, options);
}
export function useListCustomerReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListCustomerReviewsQuery,
    ListCustomerReviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListCustomerReviewsQuery,
    ListCustomerReviewsQueryVariables
  >(ListCustomerReviewsDocument, options);
}
export function useListCustomerReviewsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListCustomerReviewsQuery,
    ListCustomerReviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListCustomerReviewsQuery,
    ListCustomerReviewsQueryVariables
  >(ListCustomerReviewsDocument, options);
}
export type ListCustomerReviewsQueryHookResult = ReturnType<
  typeof useListCustomerReviewsQuery
>;
export type ListCustomerReviewsLazyQueryHookResult = ReturnType<
  typeof useListCustomerReviewsLazyQuery
>;
export type ListCustomerReviewsSuspenseQueryHookResult = ReturnType<
  typeof useListCustomerReviewsSuspenseQuery
>;
export type ListCustomerReviewsQueryResult = Apollo.QueryResult<
  ListCustomerReviewsQuery,
  ListCustomerReviewsQueryVariables
>;
export const GetStoreRobotsTxtDocument = gql`
  query GetStoreRobotsTxt($storeId: ID!) {
    getStoreRobotsTxt(storeId: $storeId) {
      content
      id
    }
  }
`;

/**
 * __useGetStoreRobotsTxtQuery__
 *
 * To run a query within a React component, call `useGetStoreRobotsTxtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreRobotsTxtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreRobotsTxtQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetStoreRobotsTxtQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStoreRobotsTxtQuery,
    GetStoreRobotsTxtQueryVariables
  > &
    (
      | { variables: GetStoreRobotsTxtQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStoreRobotsTxtQuery,
    GetStoreRobotsTxtQueryVariables
  >(GetStoreRobotsTxtDocument, options);
}
export function useGetStoreRobotsTxtLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStoreRobotsTxtQuery,
    GetStoreRobotsTxtQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStoreRobotsTxtQuery,
    GetStoreRobotsTxtQueryVariables
  >(GetStoreRobotsTxtDocument, options);
}
export function useGetStoreRobotsTxtSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetStoreRobotsTxtQuery,
    GetStoreRobotsTxtQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetStoreRobotsTxtQuery,
    GetStoreRobotsTxtQueryVariables
  >(GetStoreRobotsTxtDocument, options);
}
export type GetStoreRobotsTxtQueryHookResult = ReturnType<
  typeof useGetStoreRobotsTxtQuery
>;
export type GetStoreRobotsTxtLazyQueryHookResult = ReturnType<
  typeof useGetStoreRobotsTxtLazyQuery
>;
export type GetStoreRobotsTxtSuspenseQueryHookResult = ReturnType<
  typeof useGetStoreRobotsTxtSuspenseQuery
>;
export type GetStoreRobotsTxtQueryResult = Apollo.QueryResult<
  GetStoreRobotsTxtQuery,
  GetStoreRobotsTxtQueryVariables
>;
export const CalculateDiscountsDocument = gql`
  query CalculateDiscounts(
    $storeId: ID!
    $items: [CalculateItemsDiscountsInput!]!
  ) {
    calculateDiscounts(storeId: $storeId, items: $items) {
      productId
      variantId
      collectionIds
      quantity
      price {
        ...Money
      }
      discount {
        ...OrderItemDiscountInfo
      }
      discounts {
        ...OrderItemDiscountInfo
      }
    }
  }
  ${MoneyFragmentDoc}
  ${OrderItemDiscountInfoFragmentDoc}
`;

/**
 * __useCalculateDiscountsQuery__
 *
 * To run a query within a React component, call `useCalculateDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateDiscountsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useCalculateDiscountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalculateDiscountsQuery,
    CalculateDiscountsQueryVariables
  > &
    (
      | { variables: CalculateDiscountsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CalculateDiscountsQuery,
    CalculateDiscountsQueryVariables
  >(CalculateDiscountsDocument, options);
}
export function useCalculateDiscountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalculateDiscountsQuery,
    CalculateDiscountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CalculateDiscountsQuery,
    CalculateDiscountsQueryVariables
  >(CalculateDiscountsDocument, options);
}
export function useCalculateDiscountsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CalculateDiscountsQuery,
    CalculateDiscountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CalculateDiscountsQuery,
    CalculateDiscountsQueryVariables
  >(CalculateDiscountsDocument, options);
}
export type CalculateDiscountsQueryHookResult = ReturnType<
  typeof useCalculateDiscountsQuery
>;
export type CalculateDiscountsLazyQueryHookResult = ReturnType<
  typeof useCalculateDiscountsLazyQuery
>;
export type CalculateDiscountsSuspenseQueryHookResult = ReturnType<
  typeof useCalculateDiscountsSuspenseQuery
>;
export type CalculateDiscountsQueryResult = Apollo.QueryResult<
  CalculateDiscountsQuery,
  CalculateDiscountsQueryVariables
>;
export const CartDocument = gql`
  query Cart($storeId: ID!, $cartId: ID) {
    customerQueries(storeId: $storeId) {
      cart(id: $cartId) {
        ...Cart
      }
    }
  }
  ${CartFragmentDoc}
`;

/**
 * __useCartQuery__
 *
 * To run a query within a React component, call `useCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useCartQuery(
  baseOptions: Apollo.QueryHookOptions<CartQuery, CartQueryVariables> &
    ({ variables: CartQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CartQuery, CartQueryVariables>(CartDocument, options);
}
export function useCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CartQuery, CartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CartQuery, CartQueryVariables>(
    CartDocument,
    options
  );
}
export function useCartSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CartQuery, CartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CartQuery, CartQueryVariables>(
    CartDocument,
    options
  );
}
export type CartQueryHookResult = ReturnType<typeof useCartQuery>;
export type CartLazyQueryHookResult = ReturnType<typeof useCartLazyQuery>;
export type CartSuspenseQueryHookResult = ReturnType<
  typeof useCartSuspenseQuery
>;
export type CartQueryResult = Apollo.QueryResult<CartQuery, CartQueryVariables>;
export const GetPaymentStatusByIntentIdDocument = gql`
  query getPaymentStatusByIntentId(
    $storeId: ID!
    $paymentStatusByIntentIdId: ID!
  ) {
    paymentStatusByIntentId(storeId: $storeId, id: $paymentStatusByIntentIdId)
  }
`;

/**
 * __useGetPaymentStatusByIntentIdQuery__
 *
 * To run a query within a React component, call `useGetPaymentStatusByIntentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentStatusByIntentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentStatusByIntentIdQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      paymentStatusByIntentIdId: // value for 'paymentStatusByIntentIdId'
 *   },
 * });
 */
export function useGetPaymentStatusByIntentIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentStatusByIntentIdQuery,
    GetPaymentStatusByIntentIdQueryVariables
  > &
    (
      | { variables: GetPaymentStatusByIntentIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPaymentStatusByIntentIdQuery,
    GetPaymentStatusByIntentIdQueryVariables
  >(GetPaymentStatusByIntentIdDocument, options);
}
export function useGetPaymentStatusByIntentIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentStatusByIntentIdQuery,
    GetPaymentStatusByIntentIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPaymentStatusByIntentIdQuery,
    GetPaymentStatusByIntentIdQueryVariables
  >(GetPaymentStatusByIntentIdDocument, options);
}
export function useGetPaymentStatusByIntentIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPaymentStatusByIntentIdQuery,
    GetPaymentStatusByIntentIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPaymentStatusByIntentIdQuery,
    GetPaymentStatusByIntentIdQueryVariables
  >(GetPaymentStatusByIntentIdDocument, options);
}
export type GetPaymentStatusByIntentIdQueryHookResult = ReturnType<
  typeof useGetPaymentStatusByIntentIdQuery
>;
export type GetPaymentStatusByIntentIdLazyQueryHookResult = ReturnType<
  typeof useGetPaymentStatusByIntentIdLazyQuery
>;
export type GetPaymentStatusByIntentIdSuspenseQueryHookResult = ReturnType<
  typeof useGetPaymentStatusByIntentIdSuspenseQuery
>;
export type GetPaymentStatusByIntentIdQueryResult = Apollo.QueryResult<
  GetPaymentStatusByIntentIdQuery,
  GetPaymentStatusByIntentIdQueryVariables
>;
export const GetOrderByCartIdDocument = gql`
  query GetOrderByCartId($cartId: ID!, $storeId: ID!) {
    orderByCart(cartId: $cartId, storeId: $storeId) {
      ...OrderByCartId
    }
  }
  ${OrderByCartIdFragmentDoc}
`;

/**
 * __useGetOrderByCartIdQuery__
 *
 * To run a query within a React component, call `useGetOrderByCartIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderByCartIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderByCartIdQuery({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetOrderByCartIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrderByCartIdQuery,
    GetOrderByCartIdQueryVariables
  > &
    (
      | { variables: GetOrderByCartIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderByCartIdQuery, GetOrderByCartIdQueryVariables>(
    GetOrderByCartIdDocument,
    options
  );
}
export function useGetOrderByCartIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderByCartIdQuery,
    GetOrderByCartIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrderByCartIdQuery,
    GetOrderByCartIdQueryVariables
  >(GetOrderByCartIdDocument, options);
}
export function useGetOrderByCartIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrderByCartIdQuery,
    GetOrderByCartIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOrderByCartIdQuery,
    GetOrderByCartIdQueryVariables
  >(GetOrderByCartIdDocument, options);
}
export type GetOrderByCartIdQueryHookResult = ReturnType<
  typeof useGetOrderByCartIdQuery
>;
export type GetOrderByCartIdLazyQueryHookResult = ReturnType<
  typeof useGetOrderByCartIdLazyQuery
>;
export type GetOrderByCartIdSuspenseQueryHookResult = ReturnType<
  typeof useGetOrderByCartIdSuspenseQuery
>;
export type GetOrderByCartIdQueryResult = Apollo.QueryResult<
  GetOrderByCartIdQuery,
  GetOrderByCartIdQueryVariables
>;
export const PaymentIntentDocument = gql`
  query PaymentIntent($paymentIntentId: ID!, $storeId: ID!) {
    paymentIntent(id: $paymentIntentId, storeId: $storeId) {
      ...PaymentIntentDetails
    }
  }
  ${PaymentIntentDetailsFragmentDoc}
`;

/**
 * __usePaymentIntentQuery__
 *
 * To run a query within a React component, call `usePaymentIntentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentIntentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentIntentQuery({
 *   variables: {
 *      paymentIntentId: // value for 'paymentIntentId'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function usePaymentIntentQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaymentIntentQuery,
    PaymentIntentQueryVariables
  > &
    (
      | { variables: PaymentIntentQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentIntentQuery, PaymentIntentQueryVariables>(
    PaymentIntentDocument,
    options
  );
}
export function usePaymentIntentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaymentIntentQuery,
    PaymentIntentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentIntentQuery, PaymentIntentQueryVariables>(
    PaymentIntentDocument,
    options
  );
}
export function usePaymentIntentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PaymentIntentQuery,
    PaymentIntentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PaymentIntentQuery,
    PaymentIntentQueryVariables
  >(PaymentIntentDocument, options);
}
export type PaymentIntentQueryHookResult = ReturnType<
  typeof usePaymentIntentQuery
>;
export type PaymentIntentLazyQueryHookResult = ReturnType<
  typeof usePaymentIntentLazyQuery
>;
export type PaymentIntentSuspenseQueryHookResult = ReturnType<
  typeof usePaymentIntentSuspenseQuery
>;
export type PaymentIntentQueryResult = Apollo.QueryResult<
  PaymentIntentQuery,
  PaymentIntentQueryVariables
>;
export const GetWuiltShipmentRatesDocument = gql`
  query GetWuiltShipmentRates(
    $storeId: ID!
    $cityId: ID!
    $cartId: ID!
    $stateId: ID!
  ) {
    customerQueries(storeId: $storeId) {
      wuiltShipment {
        rates(cityId: $cityId, cartId: $cartId, stateId: $stateId) {
          rate {
            ...Money
          }
          provider
          shippingRateId
        }
      }
    }
  }
  ${MoneyFragmentDoc}
`;

/**
 * __useGetWuiltShipmentRatesQuery__
 *
 * To run a query within a React component, call `useGetWuiltShipmentRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWuiltShipmentRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWuiltShipmentRatesQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      cityId: // value for 'cityId'
 *      cartId: // value for 'cartId'
 *      stateId: // value for 'stateId'
 *   },
 * });
 */
export function useGetWuiltShipmentRatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWuiltShipmentRatesQuery,
    GetWuiltShipmentRatesQueryVariables
  > &
    (
      | { variables: GetWuiltShipmentRatesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWuiltShipmentRatesQuery,
    GetWuiltShipmentRatesQueryVariables
  >(GetWuiltShipmentRatesDocument, options);
}
export function useGetWuiltShipmentRatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWuiltShipmentRatesQuery,
    GetWuiltShipmentRatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWuiltShipmentRatesQuery,
    GetWuiltShipmentRatesQueryVariables
  >(GetWuiltShipmentRatesDocument, options);
}
export function useGetWuiltShipmentRatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetWuiltShipmentRatesQuery,
    GetWuiltShipmentRatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetWuiltShipmentRatesQuery,
    GetWuiltShipmentRatesQueryVariables
  >(GetWuiltShipmentRatesDocument, options);
}
export type GetWuiltShipmentRatesQueryHookResult = ReturnType<
  typeof useGetWuiltShipmentRatesQuery
>;
export type GetWuiltShipmentRatesLazyQueryHookResult = ReturnType<
  typeof useGetWuiltShipmentRatesLazyQuery
>;
export type GetWuiltShipmentRatesSuspenseQueryHookResult = ReturnType<
  typeof useGetWuiltShipmentRatesSuspenseQuery
>;
export type GetWuiltShipmentRatesQueryResult = Apollo.QueryResult<
  GetWuiltShipmentRatesQuery,
  GetWuiltShipmentRatesQueryVariables
>;
export const SignUpDocument = gql`
  mutation SignUp($storeId: ID!, $input: customerUserInput!) {
    signup(storeId: $storeId, input: $input)
  }
`;
export type SignUpMutationFn = Apollo.MutationFunction<
  SignUpMutation,
  SignUpMutationVariables
>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpMutation,
    SignUpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(
    SignUpDocument,
    options
  );
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>;
export const ForgotPasswordDocument = gql`
  mutation ForgotPassword(
    $storeId: ID!
    $storeUrl: String
    $email: String
    $locale: String
  ) {
    forgetCustomerPassword(
      storeId: $storeId
      storeUrl: $storeUrl
      email: $email
      locale: $locale
    )
  }
`;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      storeUrl: // value for 'storeUrl'
 *      email: // value for 'email'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >(ForgotPasswordDocument, options);
}
export type ForgotPasswordMutationHookResult = ReturnType<
  typeof useForgotPasswordMutation
>;
export type ForgotPasswordMutationResult =
  Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($storeId: ID!, $token: String, $newPassword: String) {
    resetCustomerPassword(
      storeId: $storeId
      token: $token
      newPassword: $newPassword
    )
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      id
      name
      email
      phone
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(
    MeDocument,
    options
  );
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const LoginDocument = gql`
  query Login($storeId: String!, $phoneOrEmail: String!, $password: String!) {
    customerLoginV2(
      storeId: $storeId
      phoneOrEmail: $phoneOrEmail
      password: $password
    )
  }
`;

/**
 * __useLoginQuery__
 *
 * To run a query within a React component, call `useLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      phoneOrEmail: // value for 'phoneOrEmail'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginQuery(
  baseOptions: Apollo.QueryHookOptions<LoginQuery, LoginQueryVariables> &
    ({ variables: LoginQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoginQuery, LoginQueryVariables>(
    LoginDocument,
    options
  );
}
export function useLoginLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LoginQuery, LoginQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoginQuery, LoginQueryVariables>(
    LoginDocument,
    options
  );
}
export function useLoginSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<LoginQuery, LoginQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LoginQuery, LoginQueryVariables>(
    LoginDocument,
    options
  );
}
export type LoginQueryHookResult = ReturnType<typeof useLoginQuery>;
export type LoginLazyQueryHookResult = ReturnType<typeof useLoginLazyQuery>;
export type LoginSuspenseQueryHookResult = ReturnType<
  typeof useLoginSuspenseQuery
>;
export type LoginQueryResult = Apollo.QueryResult<
  LoginQuery,
  LoginQueryVariables
>;
